import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";

// Hooks
import useTracking from '../Hooks/useTracking';
import useIntersection from '../Hooks/useIntersection';

// Utils
import { randomString } from "../Utils/md5-hash";

// Import Swiper React components
import SwiperCore, { Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

//Composant
import CLink from "./cLinks";
import Product from "./product";
import useApi from "../Hooks/useApiGlobal";
import { Loader } from "./loader";


SwiperCore.use([Pagination, EffectFade, Autoplay]);

function getRandom(arr, n) {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len) {
        throw new RangeError("getRandom: more elements taken than available");
    }
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}


const CarouselCashback = ({ desktop, title, brandID, universeID, shelfID, themeID, brandName, name, shielf, universe, offerRefs, showNoResults= false }) => {


    const economyCashbackPath = useSelector(state => state.routes).routes.economyCashback;

    const [button, setButton] = useState(economyCashbackPath);

    const [carouselLoader, setCarouselLoader] = useState(true);
    const [carouselParams, setCarouselParams] = useState({});
    const [carousel, setCarousel] = useState(null);
    const [carouselRequest, isLoadedCarouselRequest] = useApi(carouselParams);

    const [introParams, setIntroParams] = useState({});
    const [introRequest, isLoadedIntroRequest] = useApi(introParams);


    useEffect(() => {
        if (brandID && (Array.isArray(brandID))) {

            let offerIds = "";
            if (Array.isArray(offerRefs)) {
                offerRefs.map(id => {
                    offerIds += '&offerref[]=' + id;
                    return null
                })
            }

            let params = "";
            brandID.map(brand => {
                params += '&filter_value[]=' + brand;
                return null;
            });
            setCarouselParams({
                name: 'api/lba-cashback/filter/?filter=brand' + params + offerIds,
                method: 'get'
            });
            if (brandID.length === 1 && brandName) {
                setIntroParams({
                    name: 'api/node/economy_intro',
                    method: 'get',
                    include: 'field_common_tr_brand,field_common_tr_shelf,field_common_tr_universe',
                    filter: '&filter[field_economy_list_tab]=cashback&filter[field_common_tr_brand.field_api_remoteid_brandid]=' + brandID,
                    limit: 250
                });
            }

        } else {
            if (shelfID && (Array.isArray(shelfID))) {
                
                let params = "";
                shelfID.map(shelf => {
                    params += '&filter_value[]=' + shelf;
                    return null;
                });
                setCarouselParams({
                    name: 'api/lba-cashback/filter/?filter=shelf' + params,
                    method: 'get'
                });

            } else {
                if (universeID && Array.isArray(universeID)) {

                    let offerIds = "";
                    if (Array.isArray(offerRefs)) {
                        offerRefs.map(id => {
                            offerIds += '&offerref[]=' + id;
                            return null
                        })
                    }

                    let params = "";
                    universeID.map(universe => {
                        params += '&filter_value[]=' + universe;
                        return null;
                    });
                    setCarouselParams({
                        name: 'api/lba-cashback/filter/?filter=universe' + params + offerIds,
                        method: 'get'
                    });
                } else if (themeID) {
                    if (Array.isArray(themeID)) {
                        let params = "";
                        themeID.map(theme => {
                            if (theme[0].fieldCommunityTrThemes.fieldApiRemoteidThemeid !== null) {
                                params += '&filter_value[]=' + theme[0].fieldCommunityTrThemes.fieldApiRemoteidThemeid;
                                return null;
                            }
                        });
                        setCarouselParams({
                            name: `api/lba-cashback/filter/?filter=theme&filter_value[]=${params}`,
                            method: 'get'
                        });
                    } else {
                        setCarouselParams({
                            name: `api/lba-cashback/filter/?filter=theme&filter_value[]=${themeID}`,
                            method: 'get'
                        });
                    }
                } else {
                    let offerIds = "";
                    if (Array.isArray(offerRefs)) {
                        offerRefs.map(id => {
                            offerIds += '&offerref[]=' + id;
                            return null
                        })
                    }
                    setCarouselParams({
                        name: `api/lba-cashback/selection${offerIds?'?'+offerIds : ''}`,
                        method: 'get'
                    });
                }
            }
        }
    }, [brandID, universeID, shelfID, themeID, brandName]); // eslint-disable-line


    useEffect(() => {
        if (isLoadedCarouselRequest === true) {
            setCarouselLoader(false);
            if (carouselRequest && carouselRequest.data && carouselRequest.data.length > 0) {
                setCarousel(carouselRequest.data);
                //setCarousel(carouselRequest.data.filter((item, index) => index < 2));
            } else {
                setCarousel(null);
            }
        }
    }, [isLoadedCarouselRequest, carouselRequest]); // eslint-disable-line


    //Normalisation de la liste des landing
    useEffect(() => {
        if (introRequest && isLoadedIntroRequest) {
            let { status, data } = introRequest;
            if (status === 200 && data && data.data.length > 0) {
                setButton(data.data[0].attributes.path.alias);
            }
        }
    }, [introRequest, isLoadedIntroRequest]);

    return (
        <>
            {carouselLoader ?
                <Loader />
                :
                <>
                    {(Array.isArray(carousel) && carousel.length > 0) ?
                        <Carousel
                            carousel={carousel}
                            title={title}
                            button={button}
                            desktop={desktop}
                            name={name}
                            brandName={brandName}
                            shielf={shielf}
                            universe={universe} />
                        :
                            showNoResults &&
                                <div className="c-carouselCashback noPadding--">
                                    <div className="c-wysiwyg">
                                        <p>Vous avez demandé tous vos remboursements.</p>
                                    </div>
                                </div>
                    }
                </>
            }
        </>
    )
};

const Carousel = ({ carousel, title, button, desktop, brandName, name, shielf, universe }) => {
    // TRACKING - View item list (Products List)
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const ref = useRef(null);
    const inViewport = useIntersection(ref, '0px');

    const viewItemList = (refresh) => {
        let $item_list_name = name;
        if (!name) {
            $item_list_name = title;
        }
        setTrackingEvent({
            event: 'view_item_list',
            args: {
                currency: 'EUR',
                ecommerce: {
                    items: [{
                        quantity: carousel.length,
                        price: undefined,
                        item_variant: undefined,
                        item_list_name: $item_list_name,
                        item_list_id: undefined,
                        item_id: undefined,
                        item_category3: brandName,
                        item_category2: shielf,
                        item_category: universe,
                        index: undefined
                    }]
                }
            },
            refresh: refresh
        });
    };

    useEffect(() => {
        if (inViewport) {
            viewItemList(randomString(6));
        }
    }, [inViewport]);

    return (
        <div className="c-carouselCashback" ref={ref}>
            {title && <h2 className="c-carouselCashback__title">{title}</h2>}
            <Swiper className="c-carouselCashback_items"
                navigation={(carousel.length > 2 && desktop) || (carousel.length > 1 && !desktop)}
                slidesPerView="auto"
                watchSlidesVisibility={true}
                spaceBetween={desktop ? 20 : 10}
                //pagination={carousel.length > 2 ? { clickable: true } : false}
                pagination={(carousel.length > 2 && desktop) || (carousel.length > 1 && !desktop) ? { clickable: true } : false}
            >
                {
                    carousel.map((product, key) => {
                        return (
                            <SwiperSlide key={key} className="c-carouselCashback__item">
                                <Product {...product} type="refund" />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

            {button &&
                <div className="c-carouselCashback_button">
                    <CLink url={button} className="c-carouselCashback_button no-print"
                        title="Voir plus de réductions" />
                </div>
            }

        </div>
    )
}


export default CarouselCashback;