import React, {useEffect, useState} from "react";
import useApi from '../Hooks/useApiGlobal';
import Metatags from '../Layout/metas-tags/meta-tags';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {updateGlobalStorePage} from '../Store/action';

//lib
import normalize from 'json-api-normalizer';
import build from 'redux-object';

//composents
import {Loader} from "../Components/loader";
import VisuelLarge from "../Components/visuelLarge";
import ListBrands from "../Components/listBrands"


const TemplateUniverse = ({_uid}) => {

    const {desktop} = useSelector(state => state.breakpoint);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-univers'));
    }, [dispatch]); // isLoaded


    const [dataRedux, setDataRedux] = useState();//Infos principales de la page
    const [brandsRedux, setBrandsRedux] = useState();//Infos principales de la page


    //Requete pour récup les infos de l'univers
    const [dataFetch, isLoaded] = useApi({
        name: 'api/taxonomy_term/universe',
        _uid: _uid,
        fields: '&fields[taxonomy_term--universe]=name,field_universe_txt_title,field_common_pgph_ban,id,metatag',
        include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            let normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'taxonomyTermUniverse', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    //Requete qui permet de recup les marque de l'univers
    const [paramsBrands, setParamsBrands] = useState({name: '', method: ''});
    const [errorBrands, setErrorBrands] = useState(false);
    const [dataFetchBrands, isLoadedBrands] = useApi(paramsBrands);

    useEffect(() => {
        if (dataRedux && dataRedux.id) {
            setParamsBrands({
                name: 'api/node/brand_page',
                fields: '&fields[node--brand_page]=title,field_brand_img_logo,path,drupal_internal__nid',
                include: 'field_brand_img_logo',
                // include:'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop',
                filter: '&filter[marque][condition][path]=field_common_tr_brand.field_api_tr_shelves.0.field_api_tr_universe.id&filter[marque][condition][operator]=%3D&filter[marque][condition][value]=' + dataRedux.id,
                sort: '&sort[sort-weight][path]=field_content_weight',
                method: 'get',
                limit: 250
            })
        }
    }, [dataRedux]);//chargement requete ok

    useEffect(() => {
        if (isLoadedBrands && dataFetchBrands) {
            if (dataFetchBrands.data) {
                setErrorBrands(false);
                let normalizeJsonBrands = normalize(dataFetchBrands.data);//Normalisation du json
                setBrandsRedux(build(normalizeJsonBrands, 'nodeBrandPage'));
            } else {
                setErrorBrands(true);
            }
        }
    }, [isLoadedBrands, dataFetchBrands]);//chargement requete ok


    const renderPage = () => {
        const {fieldCommonPgphBan, fieldUniverseTxtTitle, metatag, name} = dataRedux;

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'A vos marques',
            category2: 'Univers',
            category3: dataRedux.name,
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}
                <VisuelLarge urlVisuel={fieldCommonPgphBan && fieldCommonPgphBan} altVisuelSup="titre du produit"
                             capsule={fieldUniverseTxtTitle} universe={name} desktop={desktop}/>

                <div className="container">
                    {brandsRedux ?
                        <>
                            {brandsRedux.length > 0 ?
                                <ListBrands desktop={desktop} brandsList={brandsRedux}/>
                                :
                                <p className="error-- msg--">Aucune marque ne correspond à votre recherche.</p>
                            }
                        </>
                        :
                        <>
                            {
                                errorBrands ?
                                    <p className="error-- msg--">Aucune marque ne correspond à votre recherche.</p> :
                                    <Loader/>
                            }
                        </>
                    }
                </div>

            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader/>
};

export default TemplateUniverse;