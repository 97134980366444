import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { useLocation } from 'react-router-dom';
// HOOKS
import useApi from '../Hooks/useApiGlobal';
// API NORMALISATION 
import normalize from 'json-api-normalizer';
import build from 'redux-object';
// LIBRAIRIES
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Keyboard } from 'swiper';
import ReactPaginate from 'react-paginate';
// COMPOSANTS
import CommunityPostCard from "./communityPostCard";
import Search from '../Layout/header/search';
import { Loader } from "./loader";
import Metatags from '../Layout/metas-tags/meta-tags';

const CommunityHpResults = ({ title, postType, inputPlaceholder, searchResultsPath, titleThematics, url = "" }) => {
    SwiperCore.use([Mousewheel, Keyboard]);
    let dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-communityHp-astuces'));
    }, [dispatch]);

    // HANDLE PAGINATION 
    const itemsPerPage = 20;
    let url2 = new URL (document.location.href);
    let page = url2.searchParams.get("page");

    const [itemOffset, setItemOffset] = useState(page ? (page - 1)* itemsPerPage : 0);

    const scrollToContainer = useRef(null);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % parseInt(dataFetchInfos.data.meta.count, 10);
        setItemOffset(newOffset);
        window.history.replaceState('', '', location.pathname+'?page='+parseInt(event.selected + 1));
        // scroll to top
        const yOffset = document.querySelector('.c-header').offsetHeight;
        const element = scrollToContainer.current.getBoundingClientRect().top;
        if (element && yOffset) {
            const y = element + window.pageYOffset - yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    // HANDLE ACTIVE THEMATICS ON CLICK
    // let url = new URL(document.location.href);
    const filterBtns = ["Les + populaires", "Les + récentes"]
    const [activeTheme, setActiveTheme] = useState();
    const [activeThemeId, setActiveThemeId] = useState("");
    const handleClickTheme = (thematic) => {


        if (thematic.drupalInternalTid === activeTheme) {
            setActiveTheme('')
            setActiveThemeId('')
            window.history.replaceState('', '', url);
        } else {
            setActiveTheme(thematic.drupalInternalTid);
            setActiveThemeId(thematic.id);
            window.history.replaceState('', '', url + thematic.fieldCommonTxtSlug);
            setItemOffset(0);

        }
    }
    // HANDLE ACTIVE FILTER
    const [activeFilter, setActiveFilter] = useState(1);
    const [activeFilterSort, setActiveFilterSort] = useState('created');
    const handleClickFilter = (index) => {
        setActiveFilter(index)
        setItemOffset(0)
    }
    useEffect(() => {
        setActiveFilterSort(activeFilter === 0 ? 'comments_count' : 'created')
    }, [activeFilter])

    // GET ALL THEMES FROM API 
    const [themeDataRedux, setThemeDataRedux] = useState();
    const [dataFetchThematics, isLoadedThematics] = useApi({
        name: 'api/taxonomy_term/community_theme',
        fields: `&fields[taxonomy_term--community_theme]=name,field_common_txt_slug,drupal_internal__tid`,
        sort: '&sort=weight',
        method: 'get'
    });

    // CHECK WHEN API IS LOAD FOR ALL THEMES
    useEffect(() => {
        if (isLoadedThematics && dataFetchThematics) {
            let normalizeJson = normalize(dataFetchThematics.data);
            setThemeDataRedux(build(normalizeJson, 'taxonomyTermCommunityTheme'));
        }
    }, [isLoadedThematics, dataFetchThematics]);// eslint-disable-line

    // GET ALL POSTS 
    const [dataRedux, setDataRedux] = useState();
    let infosDataInclude = [
        'field_community_tr_themes',
        'field_community_file_medias',
        'field_community_tr_tags',
        'uid',
        'uid.user_picture'
    ];
    const [dataFetchInfos, isLoadedInfos] = useApi({
        name: `api/node/${postType === 'astuce' ? 'tip' : 'question'}`,
        include: infosDataInclude.join(','),
        fields: `&fields[${postType === 'astuce' ? 'node--tip' : 'node--question'}]=title,created,field_common_txt_body,node_view,likes_count,comments_count,drupal_internal__nid,field_community_tr_themes,field_community_file_medias,uid,field_community_tr_tags,field_common_comments,path&fields[file--file]=uri,filemime,filename,links&fields[field_community_tr_tags]=name&fields[taxonomy_term--community_theme]=name&fields[users]=display_name,user_picture`,
        filter: activeThemeId ? `&filter[field_community_tr_themes.id]=${activeThemeId}` : '',
        sort: `&sort[sort-created][path]=${activeFilterSort}&sort[sort-created][direction]=DESC`,
        pagination: `&page[limit]=${itemsPerPage}&page[offset]=${itemOffset}`,
        method: 'get'
    });
    // CHECK WHEN API IS LOAD FOR ALL POSTS
    useEffect(() => {
        if (isLoadedInfos && dataFetchInfos) {
            let normalizeJson = normalize(dataFetchInfos.data);
            setDataRedux(build(normalizeJson, `${postType === 'astuce' ? 'nodeTip' : 'nodeQuestion'}`));
        }
    }, [isLoadedInfos, dataFetchInfos]);// eslint-disable-line


    const [decoupledApi, setDecoupledApi] = useState({});
    useEffect(() => {
        if (dataFetchThematics && themeDataRedux) {
            setDecoupledApi({
                name: 'router/translate-path',
                path: location.pathname,
                method: 'get',
            });
        }
    }, [dataFetchThematics, themeDataRedux]);

    const [dataDecoupled, isLoadedDecoupled] = useApi(decoupledApi);

    useEffect(() => {
        if (dataDecoupled) {
            if (dataDecoupled.data && dataDecoupled.data.lba_community) {
                setActiveThemeId(dataDecoupled.data.lba_community.contentByThemeFilter.community_theme.uuid);
                setActiveTheme(dataDecoupled.data.lba_community.contentByThemeFilter.community_theme.tid);
            }
        }
    }, [isLoadedDecoupled])

    let urlCanon = new URL(window.location)

    const metatag = {
        attributes: {
            rel: "canonical",
            href: urlCanon.href
        },
        tag: "link"
    }

    let pageviewEvent = {
        category: 'Communauté',
        category2: '',
        category3: ''
    };

    return (
        <>
            {metatag && <Metatags listMeta={[metatag]} pageviewEvent={pageviewEvent} />}
            <div className="c-communityHp-search">
                <div className="container_search">
                    <div className="container">
                        <h2 className="c-communityHp-search-title">{title}</h2>
                        {
                            postType === "astuce" &&
                            <Search
                                placeholder={inputPlaceholder}
                                searchResultsPath={searchResultsPath}
                            />
                        }
                        {
                            themeDataRedux ?
                                <>
                                    <div ref={scrollToContainer} className={`search-thematics ${postType}--`}>
                                        {
                                            titleThematics &&
                                            <h2 className="search-thematics-title">{titleThematics}</h2>
                                        }
                                        <div className='swiper-button-prev'></div>
                                        <div className='swiper-button-next'></div>
                                        <Swiper
                                            className="search-thematics-slider"
                                            spaceBetween={10}
                                            watchSlidesVisibility={true}
                                            keyboard={true}
                                            mousewheel={{
                                                enable: true,
                                                forceToAxis: true,
                                            }}
                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev'
                                            }}
                                            slidesPerView="auto">
                                            {
                                                themeDataRedux.map((thematic, index) => {
                                                    return (
                                                        <SwiperSlide key={index} className="search-thematics-slider-slide">
                                                            <div
                                                                id={thematic.id}
                                                                onClick={() => handleClickTheme(thematic)}
                                                                className={`search-thematics-slider-item ${activeTheme === thematic.drupalInternalTid ? "active" : ""}`}>
                                                                {thematic.name}
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </div>
                                    <div className={`search-filters ${postType}--`}>
                                        {
                                            filterBtns &&
                                            filterBtns.map((btn, index) => {
                                                return (
                                                    <h3
                                                        key={index}
                                                        onClick={(e) => handleClickFilter(index)}
                                                        className={`search-filters-btn ${activeFilter === index ? "active" : ""}`}>
                                                        {btn}
                                                    </h3>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                                : <Loader />
                        }
                    </div>
                </div>
                {
                    <div className="container results--">
                        {
                            dataRedux && Array.isArray(dataRedux) && isLoadedInfos && dataFetchInfos ?
                                <>
                                    {
                                        dataRedux.map((data, key) => {
                                            return (
                                                <CommunityPostCard
                                                    {...data}
                                                    key={key}
                                                    postType={postType}
                                                />
                                            )
                                        })
                                    }
                                    {
                                        dataFetchInfos.data.meta && Math.ceil(parseInt(dataFetchInfos.data.meta.count, 10) / itemsPerPage) > 1 &&
                                        <ReactPaginate
                                            className="c-communityHp-search-pagination"
                                            pageRangeDisplayed={0}
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            forcePage={Math.ceil(itemOffset / itemsPerPage)}
                                            pageCount={Math.ceil(parseInt(dataFetchInfos.data.meta.count, 10) / itemsPerPage)}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            hrefBuilder={(page, pageCount, selected) =>
                                                page >= 1 && page <= pageCount ? `?page=${page}` : '#'
                                            }
                                            hrefAllControls
                                        />
                                    }
                                </>
                                : dataRedux === null ?
                                    <p style={{ textAlign: 'center' }}>0 {postType}, selon vos critères de recherche</p>
                                    : <Loader />
                        }
                    </div>
                }
            </div>
        </>
    )
};
export default CommunityHpResults;