import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
import useTracking from '../../Hooks/useTracking';

const Search = ({ searchResultsPath, placeholder, trackingSearchType, btnSearchText }) => {
    const inputEl = useRef(null);
    // const searchResultsPath = useSelector(state => state.routes).routes.searchResults;

    // Event when input search is updated
    const handleChange = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    // TRACKING
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); //eslint-disable-line

    // Event on form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputEl && inputEl.current.value !== '') {
            // push search in url
            let url = searchResultsPath;    // get search results page url
            window.location.href = url + '?s=' + inputEl.current.value;
        }
    };

    // Get search value in url
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get('s');
    const [inputSearchValue, setInputSearchValue] = useState("");

    useEffect(() => {
        // Display search value in input field
        if (searchValue) {
            setInputSearchValue(searchValue);
            if (trackingSearchType) {
                setTrackingEvent({
                    event: 'search',
                    args: {
                        search_type: trackingSearchType,
                        search_term: searchValue,
                    },
                });
            }
        } else {
            setInputSearchValue('');
        }
    }, [searchValue]);

    return (
        <form className="c-search_form" method="get" onSubmit={handleSubmit} autoComplete="off">
            <div className="c-search_line">
                <input id="search-input" ref={inputEl} type="search" onChange={handleChange}
                    defaultValue={inputSearchValue} placeholder={placeholder} />
                <button type="button" className="icon-search" value="Rechercher" onClick={handleSubmit}></button>
            </div>
            {
                btnSearchText && 
                <button type="button" className="btn-2" onClick={handleSubmit}>{btnSearchText}</button>
            }
        </form>
    )
}
export default Search;