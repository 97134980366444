import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';

// Components
import TemplateEconomyGlobal from '../Templates/TemplateEconomyGlobal';


const TemplateEconomyWebcoupon = ({_uid}) => {

    const location = useLocation();
    const [params, setParams] = useState({loaded: false, brand: '', shelf: '', univers: ''});

    useEffect(() => {
        if (location.pathname.indexOf('bon-reduction-marque-') > 0 && params.loaded === false) {
            setParams({loaded: false, brand: location.pathname, shelf: "", univers:""})
        }
        else if (location.pathname.indexOf('bon-reduction-rayon-') > 0 && params.loaded === false) {
            setParams({loaded: false, brand: "", shelf: location.pathname, univers:""})
        }
        else if (location.pathname.indexOf('bon-reduction-univers-') > 0 && params.loaded === false) {
            setParams({loaded: false, brand: "", shelf: "", univers:location.pathname})
        }
        else if (params.loaded === false) {
            setParams({loaded: false, brand: "", shelf: "", univers: ""})
        }
    }, [location])

    return (
        <TemplateEconomyGlobal _uid={_uid} typeEco="webcoupon" {...params} />
    )
};

export default TemplateEconomyWebcoupon;