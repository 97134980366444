import { useState } from 'react';

/**
 * @typedef {Object} FileObject
 * @property {string} data
 * @property {string} type
 * @property {string} name
 * @property {File} file
 */

/**
 * @typedef {Object} State
 * @property {FileObject=} data
 * @property {boolean} isLoading
 * @property {Error} [error=null]
 * @property {number} progression
 * @property {() => Promise<void>} uploadFile
 * @property {(file: File) => Promise<string | ArrayBuffer | null> | undefined} readFile
 * @property {() => void} resetFile
 */

/**
 * Check if file is a image
 * @param {string} fileName 
 * @returns 
 */
function isValidFile(fileName) {
  return /\.(gif|jpg|jpeg|tiff|png|pdf)$/i.test(fileName);
}

/**
 * Custom hook to handle file upload
 * @param {React.RefObject<HTMLInputElement>} inputRef 
 * @param {FileObject=} defaultData
 * @returns {State}
 */
export function useFileUpload(inputRef, defaultData = undefined) {
    const [file, setFile] = useState(defaultData);
    const [progression, setProgression] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    /**@type {[Error, React.Dispatch<React.SetStateAction<Error>>]} */
    const [error, setError] = useState(null);

    const resetFile = () => setFile(undefined);

    /**
     * Handle file
     * @param {File} file 
     * @returns {Promise<string | ArrayBuffer | null> | undefined}
     */
    function readFile(file) {

        if (!isValidFile(file.name)) {
          return;
        }

        return new Promise(function (resolve, reject) {
            const reader = new FileReader();

            reader.addEventListener('error', function () {
                reader.abort();
                reject(new DOMException("Promblème durant l'upload"));
            });

            reader.addEventListener('load', function() {
                resolve(reader.result);
            });

            reader.addEventListener('progress', function(event) {
                if (event.loaded && event.total) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setProgression(progress);
                }
            });

            reader.readAsDataURL(file);
        });
    }

    async function uploadFile() {
        if (!inputRef.current.files || !inputRef.current.files[0]) {
            return;
        }

        setIsLoading(true);

        try {
            const file = inputRef.current.files[0];
            const data = (await readFile(file));
            const { name, type } = file;
            setFile({ data, name, type, file });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    }


    return { file, isLoading, readFile, resetFile, error, progression, uploadFile };
}
