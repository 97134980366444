import React from "react";
import { useHistory } from "react-router-dom";

export const GoBack = () => {
    const history = useHistory();

    return (
        <div className="container">
            <div className="goBack">
                <button type="button" className="goBack_btn" onClick={() => history.goBack()}>Retour</button>
            </div>
        </div>
    )
};
