import React, { useState, useEffect } from 'react';
import useAnchors from '../Hooks/useAnchors';
import { renderMarkup } from '../Utils/misc';

const Wysiwyg = ({ part1, part2, dataTrackingEventViewMore }) => {
  const [showMore, setshowMore] = useState(true);

  const handleShowMore = () => {
      setshowMore(true);
      // TRACKING - view_more_content
      if (dataTrackingEventViewMore) {
          window.customDataLayer.push({ ...dataTrackingEventViewMore });
          // console.log("wysiwygLoadmore.jsx -> 15: window.customDataLayer", window.customDataLayer  )
      }
  };

  const part1_extract = part1 + '...';
  const finalBodyText = part1 + part2;
  const markupContent = document.getElementById('wysiwyg-content');

  const { currentAnchor, indexAnchor } = useAnchors({ elements: [part1, part2] })

  useEffect(() => {
    if (currentAnchor && markupContent) {
      if (indexAnchor > 0) { // if step is not shown 
        setshowMore(true)
        setTimeout(() => { //set timeout to scroll down
          let currentElement = document.getElementById(currentAnchor)
          if (currentElement) currentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      }
    }
  }, [currentAnchor, indexAnchor,])

  return part1 && part2 ? (
    <div className="c-wysiwygSimple">
      <div className="c-wysiwygSimple_content c-wysiwyg">
        {!showMore && <div dangerouslySetInnerHTML={renderMarkup(part1_extract)}></div>}

        <button className={`t-article_more ${showMore ? 'hide--' : ''}`} onClick={handleShowMore}>
          Lire tout l&lsquo;article
        </button>
        <div className={`t-article_contentMore ${showMore ? 'show--' : ''}`}>
          <div
            id="wysiwyg-content"
            dangerouslySetInnerHTML={renderMarkup(finalBodyText, {
              allowedAttributes: { a: ['id', 'name', 'target', 'href'] },
            })}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default Wysiwyg;
