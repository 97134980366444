import React, { useState, useEffect, useMemo } from "react";
import normalize from "json-api-normalizer";
import build from "redux-object";

// Components
import CommentItem from "../Components/commentItem";
import CommentForm from "../Components/commentForm";
import { Loader } from "../Components/loader";
// import CLink from "../Components/cLinks";

//hook
// import Axios from "axios";
import useApi from "../Hooks/useApiGlobal";

// let commentsResults = [];
const Comments = ({ _uid, dataType, contentType, contentName }) => {
  // Voir les autres commentaires
  const [showMore, setshowMore] = useState(true);

  const handleShowMoreComments = () => {
    setshowMore(false);
    setFirstLoad(false);
  };

  // Loader
  const [showLoader, setShowLoader] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  // All comments
  const [allComments, setAllComments] = useState([]);

  const [commentsRedux, setCommentsRedux] = useState([]); //Liste commentaires
  const [pageOffset, setPageOffset] = useState(0);
  // const [totalPages, setTotalPages] = useState();
  const itemsPerPage = 20;
  const [dataFetchComment, isLoadedComment] = useApi({
    name: "api/comments",
    // fields: '&fields[comments]=body,createdAt,fields[users]=user_picture,display_name',
    include: "owner.user_picture",
    filter: "&filter[referencedInternalId.id]=" + _uid,
    sort: "&sort[sort-created][path]=created&sort[sort-created][direction]=DESC",
    method: "get",
    pagination: `&page[limit]=${itemsPerPage}&page[offset]=${pageOffset}`,
  });


  const handleLoadMore = () => {
    // Increase pageOffset
    setPageOffset(pageOffset + itemsPerPage);
    // Show loader
    setShowLoader(true);
  }

  useEffect(() => {
    if (isLoadedComment && dataFetchComment) {
      const normalizeJsonComments = normalize(dataFetchComment.data); //Normalisation du json
      // console.log('comments', dataFetchComment.data);
      setCommentsRedux(build(normalizeJsonComments, "comments"));
      // setTotalPages(itemsPerPage % parseInt(dataFetchComment.data.meta.count, 10))
    }
  }, [isLoadedComment, dataFetchComment]); //chargement requete ok

  /**
   * Get comments by parent to display data
   */
  const commentsByParent = useMemo(() => {
    if (!commentsRedux) return [];
    let parents = [];
    for (let comment of commentsRedux) {
      if (!comment.pid) {
        const parent = comment;
        const childrens = commentsRedux.filter(
          (c) => c.pid && c.pid.id == parent.id
        );

        parent.childrens = childrens
          .map((c) => {
            return { ...c, pid: null };
          })
          .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

        parents.push(parent);
      }
    }

    return parents.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
  }, [commentsRedux]);

  useEffect(() => {
    if(commentsRedux) {
      // console.log('commentsByParent', commentsByParent);
      setAllComments(commentsByParent);
      // if(allComments.length > 0) {
      //   // Add new page's comments to comments already displayed
      //   commentsResults = allComments.concat(commentsByParent);
      //   setAllComments(commentsResults);
      // } else {
      //   setAllComments(commentsByParent);
      // }

      setShowLoader(false);
    }
  }, [commentsRedux]);

  return (
    <>

    {
        dataType === 'node--tip' || dataType === 'node--question' ?
        <p className="title-comments-counter container">
            La communauté a apporté {commentsByParent.length} {dataType === 'node--tip' ? 'commentaire' : 'réponse'}{commentsByParent.length <= 1 ? '' : 's'}
        </p> 
        : ''
    }
    <div className="c-comments">
      <div className="container">
        <h2 className="section-title">{dataType === 'node--question' ? 'Réponses' : 'Commentaires'}</h2>
        <CommentForm
          _uid={_uid}
          dataType={dataType}
          contentType={contentType}
          commentsRedux={commentsRedux}
          setCommentsRedux={setCommentsRedux}
        />

        {isLoadedComment || !firstLoad ? (
          allComments.length > 0 ? (
            <>
              <div className="c-comments_list">
                {allComments.slice(0, 2).map((elt, index) => (
                  <CommentItem
                    elt={elt}
                    key={index}
                    _uid={_uid}
                    dataType={dataType}
                    contentName={contentName}
                    contentType={contentType}
                    commentsRedux={commentsRedux}
                    setCommentsRedux={setCommentsRedux}
                  />
                ))}

                <div
                  className={`c-comments_more-list ${showMore ? "" : "show--"}`}
                >
                  {allComments.slice(2).map((elt, index) => (
                    <CommentItem
                      elt={elt}
                      key={index}
                      _uid={_uid}
                      dataType={dataType}
                      contentName={contentName}
                      contentType={contentType}
                      commentsRedux={commentsRedux}
                      setCommentsRedux={setCommentsRedux}
                    />
                  ))}
                </div>
              </div>

              {showLoader &&
                <Loader/>
              }

              {commentsRedux && commentsRedux.length > 2 && showMore &&
                <button
                  className="t-article_more no-print"
                  onClick={handleShowMoreComments}
                >
                  {dataType === 'node--question' ? 'Voir plus de réponses' : 'Voir plus de commentaires'}
                </button>
              }

              {commentsRedux && !showMore && dataFetchComment.data.meta.count > itemsPerPage && dataFetchComment.data.meta.count >= (pageOffset + itemsPerPage) &&
              <button
                  className="t-article_more no-print"
                  onClick={handleLoadMore}
                >
                  {dataType === 'node--question' ? 'Voir les réponses suivantes' : 'Voir les commentaires suivants'}
                </button>
              }
            </>
          ) : (
              <p>{dataType === 'node--question' ? 'Aucune réponse' : 'Aucun commentaire'}</p>
            )
        ) : (
          <Loader />
        )}

      </div>
    </div>
    </>
  );
};

export default Comments;
