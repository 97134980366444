import {
    ROUTES_UPDATE
} from "../action";

let initialState = {
    routes: {}
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function routesReducer(state = initialState, action) {
    switch (action.type) {
        case ROUTES_UPDATE:
            return {...state, routes: action.value};
        default:
            return state;
    }
}
