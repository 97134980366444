import React from "react";
// Components
import CLink from "./cLinks";

const CommunityThematicsList = ({ thematicQuestions, thematicAstuces, postType, currentIdPost }) => {
    return (
        <div className="c-communityThematicsList">
            <div className="container">
                <h2 className="c-communityThematicsList-title">Sur la même thématique</h2>
            </div>
            <div className="c-communityThematicsList_container">
                <div className="container">
                    <ul className={`thematicsList ${postType === "astuce" ? "reverse" : ""}`}>
                        {
                            thematicQuestions && Array.isArray(thematicQuestions) &&
                            thematicQuestions.map((question, key) => {
                                if (question.id !== currentIdPost) {
                                    return (
                                        <li key={key} className="thematicsList-item">
                                            <CLink url={question.path.alias}>
                                                <div className="thematicsList-item-icon question--">
                                                    <i className="icono"></i>
                                                </div>
                                                <p className="thematicsList-item-text">{question.title}</p>
                                            </CLink>
                                        </li>
                                    )
                                }
                            })
                        }
                        {
                            thematicAstuces && Array.isArray(thematicAstuces) &&
                            thematicAstuces.map((astuce, key) => {
                                if (astuce.id !== currentIdPost) {
                                    return (
                                        <li key={key} className="thematicsList-item">
                                            <CLink url={astuce.path.alias}>
                                                <div className="thematicsList-item-icon astuce--">
                                                    <i className="icono"></i>
                                                </div>
                                                <p className="thematicsList-item-text">{astuce.title}</p>
                                            </CLink>
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default CommunityThematicsList;