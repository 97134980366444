import {useState, useEffect} from 'react';
import Axios from 'axios';

/**
 * @async Creation d'un hook d'appel des API sans oAuth
 * @param {string} name nom de l'API
 * @param {string} method post ou get
 * @param {formData} data formData
 * @returns {[Array, Boolean]} data Datafetch = [JSON], isLoaded = false
 */

export default ({name = '', method = 'get', data = ''}) => {

    const baseURL = process.env.REACT_APP_APP;

    const [dataFetch, setDataFetch] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(
        () => {
            setIsLoaded(false);
            let load = false;

            const fetchAPi = async () => {
                try {
                    const dataFetch = await Axios[method](
                        `${baseURL}/${name}`, data
                    );

                    if (!load) setDataFetch(dataFetch.data);
                    if (!load) setIsLoaded(true);
                } catch (err) {
                    console.log(err)
                }
            };
            fetchAPi();

            return () => {
                load = true;
            };
        },
        [name] // eslint-disable-line
    );

    // si l'api renvoi un string on le converti en JSON avant de retourner
    return [typeof dataFetch == 'string' ? JSON.parse(dataFetch) : dataFetch, isLoaded];
};
