import React from "react";

// Components
import TemplateArticle from './TemplateArticleGlobal';

const TemplateContentPage = ({ _uid }) => {
	const renderPage = () => {
        return (
            <>
				<TemplateArticle contentType="node--article" _uid={_uid} />
			</>
		)
	};
	return (renderPage())
};

export default TemplateContentPage;