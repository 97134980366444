import React, {useState} from 'react';

// Hooks
import useTracking from '../Hooks/useTracking';

// Components
import CLink from "./cLinks";

// Utils
import {randomString} from "../Utils/md5-hash";


const ListActu = ({desktop, brandsList}) => {
    // console.log('brandsList ', brandsList);

    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const selectContent = (contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: "Marque",
                content_name: contentName
            },
            refresh: refresh
        });
    };

    return (
        <>
            {brandsList &&
            <div className="c-listBrands">
                {
                    brandsList.map(({path, title, drupalInternalNid, fieldBrandImgLogo}, key) => {
                        return (
                            <div key={key} className="c-listBrands_item">
                                <CLink url={path.alias ? path.alias : '/node/' + drupalInternalNid} title={title}
                                       handleClick={() => selectContent(title, randomString(6))}>
                                    <div className="c-listBrands_visuel">
                                        {fieldBrandImgLogo && <img
                                            src={desktop ? fieldBrandImgLogo.links.commonLogoDesktop.href : fieldBrandImgLogo.links.commonLogoMobile.href}
                                            alt={title}/>}
                                    </div>
                                </CLink>
                            </div>
                        )
                    })
                }
            </div>
            }
        </>
    )
};

export default ListActu;