import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
// import normalize from 'json-api-normalizer';
// import build from 'redux-object';

//Hooks
import useApi from '../Hooks/useApiGlobal';
import {updateGlobalStorePage, updateModalsOpen, updateModalsLoginType} from '../Store/action';

// Libraries
import Axios from "axios";

//components
// import Metatags from '../Layout/metas-tags/meta-tags';
import VisuelLarge from "../Components/visuelLarge";
import Wysiwyg from "../Components/wysiwygSimple";
import {Loader} from "../Components/loader";


const TemplateRegisterConfirm = ({_uid}) => {
    const baseURL = process.env.REACT_APP_APP;

    // Breakpoint
    const {desktop} = useSelector(state => state.breakpoint);

    let [confirmationSuccess, setConfirmationSuccess] = useState(false);

    // TRACKING
    const formTracking = () => {
        // register
        // window.customDataLayer.push({
        //     event: 'sign_up',
        //     method: 'email'
        // });
        // console.log('customDataLayer [register] ', window.customDataLayer);
    };

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-register-confirm'));
    }, [dispatch]); // isLoaded

    // Get search value in url
    let [tokenValue, setTokenValue] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');

    useEffect(() => {
        if (urlToken) {
            // console.log('urlToken', urlToken);
            setTokenValue(urlToken);
        }
    }, [urlToken]);


    useEffect(() => {
        if (tokenValue !== '') {
            const dataSend = {
                token: tokenValue
            };

            // Récupération du token à passer ds le header
            let fetchJeton = Axios.post(`${baseURL}/session/token/`);

            fetchJeton.then(res => {
                let {status, data} = res;

                if (status === 200) {
                    SendData(data, dataSend);
                    // Display Loader
                    //  console.log('envoi', data);

                } else {
                    console.log('erreur', data)
                }
            })
        }
    }, [tokenValue]); //eslint-disable-line

    let [paramsSend, setParamsSend] = useState({});
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const SendData = (jeton, dataSend) => {
        const headers = {
            "Content-Type": "application/json",
            "X-CSRF-Token": jeton
        };
        setParamsSend({
            name: 'api/email-confirmation?_format=json',
            method: 'patch',
            data: dataSend,
            config: headers,
            stringifyRequest: false
        })
    };

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let {status} = responseSend;
            if (status === 200) {
                //tout est ok
                // console.log('200 : le compte est deja validé ', data);
                setConfirmationSuccess(true);

                // TRACKING
                formTracking();

            } else if (status === 201) {
                // console.log('201 validation avec succès', data)
                setConfirmationSuccess(true);
                
                // TRACKING
                formTracking();
            } else {
                //Erreur
                console.log('ERREUR status :', status);
            }
        }
    }, [responseSend, isLoadedSend]);

    // Open Login modal
    const modalsOpen = useSelector(state => state.modals).openModal;
    const handleOpen = () => {
        dispatch(updateModalsOpen({...modalsOpen, 'login': true, 'alert': true}));
        dispatch(updateModalsLoginType('login'));
    };


    const renderPage = () => {

        return (
            <>
                <VisuelLarge urlVisuel="" urlVisuelStatic={require('../assets/img/welcome.jpg')} desktop={desktop}/>

                <div className="container">
                    <div className="t-contentPage_container text-center">

                        {confirmationSuccess ?
                            <ConfirmationMessage title="Votre inscription est validée !"
                                                 content="Vous pouvez maintenant vous identifier"
                                                 handleOpen={handleOpen}
                                                 button="Je m’identifie"/>
                            :
                            <ConfirmationMessage title="Désolé, ce lien n'est plus valable"
                                                 content="Votre compte est déjà validé.<br/> Vous pouvez vous <strong>connecter à votre compte</strong> grâce à ce lien :"
                                                 button="Je m’identifie"
                                                 handleOpen={handleOpen}/>
                        }

                    </div>
                </div>

            </>
        )
    };
    return (isLoadedSend) ? renderPage() : <Loader/>
};

const ConfirmationMessage = ({title, content, button, handleOpen}) => {
    return (
        <>
            <Wysiwyg title={title} content={content}/>
            <button className="btn-2"
                    type="button"
                    onClick={handleOpen}>{button}</button>
        </>
    )
};


export default TemplateRegisterConfirm;