import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

//Hooks
import useApi from '../Hooks/useApiGlobal';
import {updateGlobalStorePage} from '../Store/action';

//components
import Metatags from '../Layout/metas-tags/meta-tags';
import Univers from "../Components/univers"
import SliderLarge from "../Components/sliderLarge"
import {Loader} from "../Components/loader";
import Video from "../Components/article/video";


const TemplateMosaic = ({_uid}) => {

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('mosaic'));
    }, [dispatch]); // isLoaded

    // Breakpoint
    const {desktop, mobile} = useSelector(state => state.breakpoint);

    const [dataRedux, setDataRedux] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/mosaic',
        _uid: _uid,
        include: 'field_mosaic_items,field_common_pgph_ban.field_respimg_img_desktop,field_common_pgph_ban.field_respimg_img_mobile,field_mosaic_items.field_respimg_img_desktop,field_mosaic_items.field_respimg_img_mobile,field_mosaic_items.field_background,field_mosaic_items.field_landing_video,field_mosaic_items.field_common_img_textimage',
        fields: '&fields[node--mosaic]=metatag,title,field_mosaic_items,field_common_pgph_ban',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeMosaic', _uid));

            // console.log('dataFetch Mosaic', dataFetch)
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    // Scroll to content on click on mosaic block
    const goToAnchor = (anchor) => {
        const yOffset = -document.querySelector('.c-header').offsetHeight;
        const element = document.getElementById(anchor);
        if (element && yOffset) {
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }


    const renderPage = () => {
        // console.log('dataRedux Mosaic', dataRedux);
        const {metatag, title, fieldMosaicItems, fieldCommonPgphBan} = dataRedux;

        console.log("dataRedux", dataRedux);

        //-- Tracking
        let pageviewEvent = {
            category: title,
            category2: '',
            category3: ''
        };

        let trueIndex = 1;

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}

                <div className="container">
                    
                    {fieldCommonPgphBan &&
                        <div className="mosaic_banner">
                            {mobile && fieldCommonPgphBan.fieldRespimgImgMobile ?
                                <img src={fieldCommonPgphBan.fieldRespimgImgMobile.links.commonFullWidthBanMobile.href} alt="" />
                            :
                                <img src={fieldCommonPgphBan.fieldRespimgImgDesktop.links.commonFullWidthBanDesktop.href} alt="" />
                            }
                        </div>
                    }

                    <div className="mosaic_grid">
                        {fieldMosaicItems.map(({fieldRespimgImgDesktop, fieldRespimgImgMobile, fieldType, fieldLinkCta, fieldAncrage}, key) => {
                            return(
                                <div className={`mosaic_grid-item${fieldType === "2" ? ' rect--' : ''}`} key={key}>
                                    {fieldLinkCta ?
                                        <a href={fieldLinkCta.uri} target={fieldLinkCta.options?.attributes?.target}>
                                            {(mobile && fieldRespimgImgMobile) ?
                                                <img src={fieldRespimgImgMobile.links.brandAccordionMobile.href} alt="" />
                                            :
                                                <img src={fieldRespimgImgDesktop.links.brandAccordionDesktop.href} alt="" />
                                            }
                                        </a>
                                    :
                                    <>
                                        {fieldAncrage ?
                                            <button type="button" onClick={() => goToAnchor(fieldAncrage)}>
                                                {(mobile && fieldRespimgImgMobile) ?
                                                    <img src={fieldRespimgImgMobile.links.brandAccordionMobile.href} alt="" />
                                                :
                                                    <img src={fieldRespimgImgDesktop.links.brandAccordionDesktop.href} alt="" />
                                                }
                                            </button>
                                        :
                                        <>
                                            {(mobile && fieldRespimgImgMobile) ?
                                                <img src={fieldRespimgImgMobile.links.brandAccordionMobile.href} alt="" />
                                            :
                                                <img src={fieldRespimgImgDesktop.links.brandAccordionDesktop.href} alt="" />
                                            }
                                        </>
                                        }
                                    </>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    {fieldMosaicItems.map(({fieldCommonTitle, fieldCommonTxtTextbody, links, fieldColor, fieldBackground, fieldLandingVideo, fieldCommonImgTextimage, fieldAncrage}, key) => {
                        return(
                            <>
                                {
                                    ((fieldCommonTitle && fieldCommonTitle.value) || (fieldCommonTxtTextbody && fieldCommonTxtTextbody.value) || (fieldLandingVideo)) && <div id={fieldAncrage} className="mosaic_detail-block" key={key}>
                                        <div className="mosaic_detail-header">
                                            <span className="mosaic_detail-header-bg" style={{backgroundColor: `${fieldColor.color}`}}></span>
                                            {fieldCommonTitle &&
                                                <h2>
                                                    <span className="mosaic_detail-nb" style={{backgroundColor: `${fieldColor.color}`}}>{trueIndex++}</span>
                                                    <span className="mosaic_detail-title" dangerouslySetInnerHTML={{__html: fieldCommonTitle.value}} />
                                                </h2>
                                            }
                                        </div>
                                        <div className={`mosaic_detail-bodyContent ${ fieldBackground ? "minheight--" : ""}`}>
                                            {fieldCommonTxtTextbody &&
                                                <div className="mosaic_detail-body">
                                                    <p dangerouslySetInnerHTML={{__html: fieldCommonTxtTextbody.value}} />
                                                </div>
                                            }

                                            {fieldBackground &&
                                                <img src={fieldBackground.links.brandAccordionDesktop.href} className="mosaic_detail-img" />
                                            }
                                        </div>

                                        {fieldLandingVideo &&
                                            <div className="mosaic_video">
                                                <Video infoVideo={fieldLandingVideo} infoImage={fieldCommonImgTextimage} />
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        )
                    })}
                </div>

            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader/>
};

export default TemplateMosaic;