import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateModalsOpen, updateModalsLoginType } from '../Store/action';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

//hooks
import useTracking from '../Hooks/useTracking';

// Libraries
import Axios from "axios";
import useApi from '../Hooks/useApiGlobal';

// Utils
import { randomString } from "../Utils/md5-hash";


const CommentForm = ({ _uid, dataType, contentType, commentsRedux, setCommentsRedux, commentParentId = "", onCommentSend }) => {

    const baseURL = process.env.REACT_APP_APP;
    let { uuid } = useSelector(state => state.user);
    const inputEl = useRef(null);
    const [commentValue, setCommentValue] = useState("");
    const [submiting, setSubmiting] = useState(false);
    const [rows, setRows] = useState(1);
    const minRows = 1;
    const maxRows = 5;
    const textareaLineHeight = 28;

    const commentFormRef = useRef(null);

    const handleChange = (event) => {
        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea 

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setRows(currentRows < maxRows ? currentRows : maxRows);
        setCommentValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        inputEl.current.blur();
        setSubmiting(true);

        if (inputEl && inputEl.current.value !== '') {
            let subjectSubstring = inputEl.current.value;
            subjectSubstring = subjectSubstring.substring(0, 40);
            let dataSend = {}
            if (commentParentId && commentParentId != '') {
                dataSend = {
                    data: {
                        type: "comments",
                        attributes: {
                            subject: subjectSubstring,
                            comment_body: inputEl.current.value,
                            entityTypeId: "node",
                            fieldName: "field_common_comments"
                        },
                        relationships: {
                            referencedInternalId: {
                                data: {
                                    type: dataType,
                                    id: _uid
                                }
                            },
                            pid: {
                                data: {
                                    type: 'comments',
                                    id: commentParentId
                                }
                            }
                        }
                    }
                };
            }
            else {
                dataSend = {
                    data: {
                        type: "comments",
                        attributes: {
                            subject: subjectSubstring,
                            comment_body: inputEl.current.value,
                            entityTypeId: "node",
                            fieldName: "field_common_comments"
                        },
                        relationships: {
                            referencedInternalId: {
                                data: {
                                    type: dataType,
                                    id: _uid
                                }
                            }
                        }
                    }
                };
            }
            // Object.assign(dataSend.data.attributes, inputEl.current.value);


            // Récupération du token à passer ds le header
            let fetchJeton = Axios.post(`${baseURL}/session/token/`);


            fetchJeton.then(res => {
                let { status, data } = res;
                if (status === 200) {
                    SendData(data, dataSend);
                    // Display Loader
                    setChangingDatas(true);
                    if (commentParentId && commentParentId != '') {
                        // tracking for comment child
                        commentAnswerTracking(randomString(6))
                    } else {
                        // tracking for comment parent
                        commentTracking(randomString(6));
                    }
                } else {
                    console.log('erreur', data)
                }
            })

        }
    };

    const [changingDatas, setChangingDatas] = useState(false);

    const handleCFocus = (event) => {
        // event.preventDefault()
        if (!uuid) {
            //Popin de log
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'password': false, 'economy': false }));
            dispatch(updateModalsLoginType('currentPage'));
        }
    };

    // TRACKING - add comment
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); //eslint-disable-line

    const commentTracking = (refresh) => {
        setTrackingEvent({
            event: 'add_comment',
            args: {
                content_type: contentType,
            },
            refresh: refresh
        });
    };
    const commentAnswerTracking = (refresh) => {
        setTrackingEvent({
            event: 'add_answer',
            args: {
                content_type: contentType,
            },
            refresh: refresh
        });
    };

    // Modals - Login
    const modalsOpen = useSelector(state => state.modals).openModal;
    const dispatch = useDispatch();


    let [paramsSend, setParamsSend] = useState({});
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const SendData = (jeton, dataSend) => {
        const headers = {
            "Content-Type": "application/vnd.api+json",
            "X-CSRF-Token": jeton
        };
        setParamsSend({
            name: 'api/comments',
            include: 'owner.user_picture',
            method: 'post',
            data: dataSend,
            config: headers
        })
    };

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            if (responseSend.data && responseSend.data.errors) {
                // console.log(responseSend.data.errors);
            }
            else {
                inputEl.current.value = '';
                // Reset comment value after post
                setCommentValue("");
                setRows(1);
                onCommentSend && onCommentSend()
                const normalizeJsonNew = normalize(responseSend.data);//Normalisation du json
                if (commentsRedux && commentsRedux.length > 0) {
                    setCommentsRedux(build(normalizeJsonNew, 'comments').concat(commentsRedux));
                }
                else {
                    setCommentsRedux(build(normalizeJsonNew, 'comments'));
                }
            }

            setSubmiting(false);
            setChangingDatas(false);
        }
    }, [isLoadedSend, responseSend]); // eslint-disable-line

    return (
        <div className="c-comments_write">
            <span className="c-comments_write-profil"><i className="icon-profil icon"></i></span>
            <form className="c-comments_form" method="get" autoComplete="off" onSubmit={handleSubmit}>
                <div className="c-search_line">
                    <textarea
                        rows={rows}
                        value={commentValue}
                        type="textarea" name="comment_general"
                        placeholder={`${commentParentId ? 'Participer à la discussion...' : dataType === 'node--question' ? 'Ajouter une réponse...' : 'Ajouter un commentaire...' }`} 
                        onChange={handleChange} 
                        ref={inputEl}
                        disabled={changingDatas} 
                        onFocus={handleCFocus}
                    />
                </div>
                <button
                    disabled={submiting || commentValue.length === 0}
                    className={`c-respond_button ${(submiting || commentValue.length === 0) && 'disabled'}`}
                    onClick={handleSubmit}
                >
                    {commentParentId ? "Répondre" : "Envoyer"}
                </button>
            </form>
        </div>
    )
}
export default CommentForm;