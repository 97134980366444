import { useEffect, useState } from "react"

/*
    Hook used for component who use anchor and need to display hidden elements like Steps or WysiwygLoadmore
    Return 
        - currentAnchor: get current anchor from url
        - indexAnchor: index where the currentAnchor is finded, -1 if is not finded
*/

export default ({ elements }) => {

    const [currentAnchor, setCurrentAnchor] = useState("")
    const [indexAnchor, setIndexAnchor] = useState(-1)

    useEffect(() => {
        let url = new URL(window.location.href)
        let anchor = decodeURI(url.hash)?.substring(1)
        setCurrentAnchor(anchor)
    }, [window.location.href])

    useEffect(() => {
        if (elements && currentAnchor) {
            let currentElement = elements.filter(element => element.includes(`"${currentAnchor}"`))[0]
            let index = elements.indexOf(currentElement)
            setIndexAnchor(index)
        }
    }, [currentAnchor, elements])

    return { currentAnchor, indexAnchor }
}