import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
//Hooks
import useApi from '../Hooks/useApiGlobal';
//Composant
import Metatags from '../Layout/metas-tags/meta-tags';
import VisuelLarge from "../Components/visuelLarge";
import CommunityBlockRoundedTitle from "../Components/communityBlockRoundedTitle";
import CommunityHpResults from "../Components/communityHpResults";
import { Loader } from "../Components/loader";
// Visuel
// import backgroundImage from "../assets/img/community/tips.png";

const TemplateCommunityHPAstuces = ({ _uid }) => {
    const postTipPath = useSelector(state => state.routes).routes.postTip;
    // CHANGE ROUTE WHEN DONE
    const searchResultsPath = useSelector(state => state.routes).routes.searchTip;
    const baseURL = process.env.REACT_APP_APP;
    const { desktop } = useSelector(state => state.breakpoint);
    //Requete pour récup les meta data
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        include: 'content_config_form_files',
        fields: '&fields[node--special]=metatag,title,field_special_configformref,content_config_form_files',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const getVisuals = (array, uuidDesktop, uuidMobile) => {
        let imgUrl
        array.forEach((obj) => {
            if (desktop) {
                if (Object.values(obj).indexOf(uuidDesktop) > -1) {
                    imgUrl = obj.uri.url
                    return false
                }
            } else {
                if (Object.values(obj).indexOf(uuidMobile) > -1) {
                    imgUrl = obj.uri.url
                    return false
                }
            }
        })
        return imgUrl
    }

    const renderPage = () => {
        const { metatag, contentConfigFormFiles } = dataRedux;
        const { hero, searchbar, themes } = dataRedux.fieldSpecialConfigformref.data;
        //-- Tracking
        let pageviewEvent = {
            category: 'Communauté',
            category2: 'Astuces'
        };
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <VisuelLarge
                    urlVisuelStatic={baseURL + getVisuals(contentConfigFormFiles, hero.images.imageDesktop.uuid, hero.images.imageMobile.uuid)}
                />
                <div className="container">
                    <CommunityBlockRoundedTitle
                        title="Astuces"
                        text={hero.text ? hero.text : "Faites nous part ici de vos meilleurs conseils et profitez de toutes les astuces de la communauté"}
                        btnUrl={postTipPath}
                        btnText="Je partage mon astuce"
                    />
                </div>
                <CommunityHpResults
                    postType='astuce'
                    title={searchbar.text ? searchbar.text : "Consulter toutes les astuces de la communauté"}
                    inputPlaceholder="Rechercher une astuce..."
                    titleThematics={themes.text ? themes.text : "Choisissez une thématique"}
                    searchResultsPath={searchResultsPath}
                    url='/communaute/astuces/'
                />
            </>
        )
    }
    return (isLoaded && dataRedux) ? renderPage() : <Loader />
};

export default TemplateCommunityHPAstuces;