import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

// Hooks
import useApi from "../../Hooks/useApiGlobal";
import {useSelector, useDispatch} from 'react-redux';
import {updateModalsOpen} from "../../Store/action";

import {
    updateUserUuid,
    updateUserIupid,
    updateUserPseudo,
    updateUserDateInscription,
    updateGlobalStoreLogFromPage,
    updateModalsDataIsLoaded
} from '../../Store/action';

import {getClientCredential} from '../../Utils/getClientService'

import {setToken, setRefreshToken, getRefreshToken} from '../../Utils/cookie';

// Libraries
import {useForm} from "react-hook-form";

// Components
import Modal from "./modal";
import CLink from "../cLinks";
import {Loader} from "../../Components/loader";


const LoginModal = () => {
    let dispatch = useDispatch();
    const history = useHistory();
    let currentUrl = useLocation().pathname;

    // Get user state
    let {iupid, pseudo} = useSelector(state => state.user);
    let userId = undefined;
    if (iupid && pseudo) userId = iupid;

    // TRACKING
    const formTracking = () => {
        // register
        window.customDataLayer.push({
            event: 'sign_up',
            method: 'email',
            user_id: userId
        });
    };

    const [registerConfirmation, setRegisterConfirmation] = useState(false);

    // Get show_login value in url
    const urlParams = new URLSearchParams(window.location.search);
    const showLoginValue = urlParams.get('show_login');

    // Show login modal with confirmation message
    useEffect(() => {
        if (showLoginValue && showLoginValue === 'confirmation') {
            setRegisterConfirmation(true);

            // TRACKING
            formTracking();
        }
    }, [showLoginValue]);

    const accountPath = useSelector(state => state.routes).routes.account;
    const registerPath = useSelector(state => state.routes).routes.register;
    const cartCashbackPath = useSelector(state => state.routes).routes.cartCashback;

    const currentTemplate = useSelector(state => state.page).page;
    const landingOperationPath = useSelector(state => state.modals).redirectButton;

    // Param for showing loader when login
    const pageDataIsLoaded = useSelector(state => state.modals).dataIsLoaded;

    // Get Log from url
    const logFromPath = useSelector(state => state.page).logFrom;

    // Modals workflow
    const useSelectorModals = useSelector(state => state.modals);
    const modalsOpen = useSelectorModals.openModal;
    const type = useSelectorModals.loginType;

    // Login modal

    // get global info
    let dataPopin = useSelector(state => state.global).global.popin_login;
    // Loader
    const [loading, setLoading] = useState(false);

    // Password
    const handlePassword = () => {
        dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'password': true}));
    };

    // Login form
    const {register, handleSubmit, errors} = useForm();

    let [globalError, setGlobalError] = useState(null);
    let [disableButton, setDisableButton] = useState(null);
    let [paramApi, setParamApi] = useState({
        name: '',
        grant_type: '',
        data: null
    });
    let [params, setParams] = useState({});
    let [loadApi, setLoadApi] = useState(false);

    const [initClient, isLoadedR] = useApi(params);

    useEffect(() => {
        if (loadApi) {
            setParams(paramApi)
        }
    }, [paramApi, loadApi]);


    const onSubmit = (data) => {
        setGlobalError(null);
        setDisableButton("disabled");
        setParamsUser({});

        const clientCredentials = getClientCredential('', 'bla_passord')

        let datas = new FormData();
        datas.append('username', data.loginEmail)
        datas.append('password', data.loginPassword);
        datas.append('grant_type', "lba_password");
        datas.append('client_id', clientCredentials.client_id);
        datas.append('client_secret', clientCredentials.client_secret);

        setLoadApi(true);
        setParamApi({
            name: 'oauth/token',
            grant_type: 'lba_password',
            data: datas
        });

        // Display loader
        setLoading(true);
        // Hide password
        setrevealPassword(false);

        // Get url
        dispatch(updateGlobalStoreLogFromPage(currentUrl));

        // If operation page - show loader while data is loading for next page
        if (type === 'operation') {
            dispatch(updateModalsDataIsLoaded(false));
        }
    };

    const handleSuccess = (data) => {

        if (sessionStorage.getItem("popinEconomyView")) {
            sessionStorage.removeItem('popinEconomyView');
        }
        if (sessionStorage.getItem("popinCompletionView")) {
            sessionStorage.removeItem('popinCompletionView');
        }
        if (sessionStorage.getItem("popinPromotionView")) {
            sessionStorage.removeItem('popinPromotionView');
        }
        if (sessionStorage.getItem("popinWelcomeView")) {
            sessionStorage.removeItem('popinWelcomeView');
        }
        if (sessionStorage.getItem("pageViews")) {
            sessionStorage.setItem("pageViews", 0);
        }
        // On met a jour l'uuid
        dispatch(updateUserUuid(data.user_uuid));
        // On met a jour les token
        setToken(data.access_token);
        setRefreshToken(data.refresh_token)

        //on ferme la popin de connexion
        dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'hasError': false}));

        //on reactive le bouton valider de la popin
        setDisableButton(null);

        setParamApi({
            name: '',
            grant_type: '',
            data: null
        });
        setLoadApi(false);

        // Remove loader
        setLoading(false);

        const currentHash = window.location.hash;

        //redirection vers la page en cours
        if (logFromPath) {
            if (type === 'operation' && !registerConfirmation) {
                let pathname = "";
                let search = "";
                if(landingOperationPath.split){
                    let tab = landingOperationPath.split("?");
                    search = (tab.length == 2 ? tab[1] : "");
                    pathname = (tab.length == 2 ? tab[0] : landingOperationPath)
                } else {
                    pathname = landingOperationPath.pathname;
                    search = landingOperationPath.search
                }
                history.replace({pathname, search});
            } else {
                // let anchor = (document.location.hash === "#more-info-profil" ? document.location.hash : "");
                history.replace({pathname: logFromPath});
                // document.location.hash = anchor;
                window.location.hash = currentHash;
            }
        } else {
            if (type === "currentPage") {
                window.location.hash = currentHash;
            }
            else if (type === 'cartCashback') {
                history.replace({pathname: cartCashbackPath});
            }
            else {
                history.replace({pathname: accountPath});
            }
        }

        if (registerConfirmation && !pageDataIsLoaded) {
            dispatch(updateModalsDataIsLoaded(true));
        }
    };

    const handleFailure = (message) => {
        setLoadApi(false);
        setParamApi({});
        setGlobalError(message);
        dispatch(updateModalsOpen({...modalsOpen, 'hasError': true}));

        // Remove loader
        setLoading(false);
    };

    const [paramsUser, setParamsUser] = useState({});
    const [dataUser, isLoadedUser] = useApi(paramsUser);


    useEffect(() => {
        if (initClient && isLoadedR) {
            let {status, data} = initClient;

            if (status === 200) {
                //tout est ok
                handleSuccess(data);
                // Get pseudo
                setParamsUser({name: `api/users/${data.user_uuid}`, method: 'get'})
            }
            else if (status === 401) {
                console.log('401 ERROR');
                handleFailure(data.message);
                setDisableButton(null);
            }
            else {
                console.log('erreur ', data);
                handleFailure("Erreur");
                setDisableButton(null);
            }
        }
    }, [initClient, isLoadedR]); // eslint-disable-line


    const closePopin = () => {
        dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'hasError': false}));

        // Get url
        dispatch(updateGlobalStoreLogFromPage(currentUrl));
    }


    useEffect(() => {
        if (dataUser && isLoadedUser) {
            // TRACKING - loggé
            window.customDataLayer.push({
                event: 'login',
                method: 'Email',
                user_id: dataUser.data.data.attributes.lba_userid,
            });
            // console.log('customDataLayer [login] ', window.customDataLayer);
            dispatch(updateUserPseudo(dataUser.data.data.attributes.lba_lbalogin));
            dispatch(updateUserDateInscription(dataUser.data.data.attributes.lba_dateemailverifie));
            dispatch(updateUserIupid(dataUser.data.data.attributes.lba_userid));
        }
    }, [isLoadedUser, dataUser]); // eslint-disable-line

    // Hide / Show password
    const [revealPassword, setrevealPassword] = useState(false);
    const handleClickPassword = (e) => {
        switch (e.target.dataset.type) {
            case 'loginPassword':
                setrevealPassword(!revealPassword);
                break;
            default:
                break;
        }

    };
    // END Hide / Show password

    const renderModal = (data) => {
        //const {title, form_title, socials, separator, fields, forgot_password, submit, create_account} = dataFetch;
        return (
            !getRefreshToken() && <Modal modalName="login" modalNameTracking="Connexion">

                <div className="c-login">
                    {registerConfirmation ?
                        <>
                            <h2 className="title-1">Votre inscription est validée !</h2>
                            <p>Vous pouvez maintenant vous identifier</p>
                        </>
                        :
                        <>
                            <p>
                                {
                                    currentTemplate.indexOf("brands") > -1 ? <><strong>Profitez de réductions sur vos marques préférées !</strong><br/><strong>Connectez-vous</strong> ou <strong>inscrivez-vous</strong> à la Belle Adresse pour bénéficier de nos offres promotionnelles</> 
                                    : currentTemplate.indexOf("communityHp") > -1  ? <><strong>Vos astuces nous intéressent !</strong><br/><strong>Connectez-vous</strong> ou <strong>inscrivez-vous</strong> pour échanger ou poser vos questions aux autres membres</>
                                        : currentTemplate.indexOf("article") > -1 ? <><strong>Quelques chose à ajouter ?</strong><br/><strong>Connectez-vous</strong> ou <strong>inscrivez-vous</strong> à La Belle Adresse pour pouvoir commenter tous nos articles</>
                                            : dataPopin.text_intro
                                }
                            </p>
                            <h2 className="title-1">{dataPopin.title_form}</h2>
                        </>
                    }
                    <form className="c-login_form c-form" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="c-form_line">
                                <div className="c-form_lineEltLarge">
                                    <input type="text" placeholder={`${dataPopin.placeholder_email}*`} name="loginEmail"
                                           id="loginEmail"
                                           ref={register({
                                               required: true
                                           })}
                                           data-error={errors.loginEmail ? true : false}
                                    />
                                    <div
                                        className="error--">{errors.loginEmail && "Le champs doit être renseigné."}</div>
                                </div>
                            </div>

                            <div className="c-form_line">
                                <div className="c-form_lineEltLarge">
                                    <div className="c-form_info">
                                        <input type={revealPassword ? 'text' : "password"}
                                               placeholder={`${dataPopin.placeholder_password}*`}
                                               name="loginPassword"
                                               id="loginPassword"
                                               ref={register({required: true})}
                                               className="has-tooltip"
                                               data-error={errors.loginPassword ? true : false}/>
                                        <div className="c-form_tooltip">
                                            <i className={revealPassword ? 'icon-eye-no' : "icon-eye"}
                                               data-type="loginPassword"
                                               onClick={(e) => handleClickPassword(e)}></i>
                                        </div>
                                    </div>
                                    <div
                                        className="error--">{errors.loginPassword && "Le champs doit être renseigné."}</div>
                                </div>
                            </div>
                            <p className="forgotPwd">
                                <button type="button" onClick={() => handlePassword()}
                                        className="c-modal-link">Mot de passe oublié ?
                                </button>
                            </p>
                            <div
                                className="error-- c-login_globalError">{globalError && modalsOpen.login && modalsOpen.hasError && "Login ou mot de passe non reconnus."}</div>
                        </div>

                        <button type="submit" className="c-button"
                                disabled={disableButton}>{dataPopin.label_button}</button>
                    </form>
                    <div className="c-login_inscription">
                        <h2 className="title-1">{dataPopin.title_inscription}</h2>
                        <p><CLink url={registerPath} title={dataPopin.button_inscription}
                                  className="btn-3" handleClick={() => closePopin()}/></p>
                    </div>

                    {loading && <Loader/>}
                </div>

            </Modal>
        )
    };
    return dataPopin ? renderModal() : <Loader/>
};

export default LoginModal;