import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import normalize from "json-api-normalizer";
import build from "redux-object";
import { SublinkSubMenu } from "../Layout/header/menu";

//Hooks
import {
  updateGlobalStorePage,
  updateGlobalScrollLock,
  updateModalsOpen,
  updateModalsLoginType,
} from "../Store/action";
import useApi from "../Hooks/useApiGlobal";

// Components
import Metatags from "../Layout/metas-tags/meta-tags";
import { Loader } from "../Components/loader";

const MenuItem = ({
  id,
  children,
  virtual_page,
  title,
  url,
  closeMenu,
  classe,
}) => {
  const modalsOpen = useSelector((state) => state.modals).openModal;
  const { uuid, pseudo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(updateGlobalScrollLock(false));
    dispatch(updateModalsOpen({ ...modalsOpen, login: true, alert: true }));
    dispatch(updateModalsLoginType("login"));
  };

  return (
    <div
      className={`t-sitemap_navItem ${children ? "hasChildren--" : ""}`}
      key={id}
    >
      <h2>
        {virtual_page === true ? (
          <span className="t-sitemap_navLink">{title}</span>
        ) : (
          <>
            {classe === "account" && !uuid && !pseudo ? (
              <span className="c-nav_link" onClick={handleOpen}>
                {" "}
                Mon compte
              </span>
            ) : (
              <NavLink
                activeClassName="selected"
                className="t-sitemap_navLink"
                to={url}
              >
                {title}
              </NavLink>
            )}
          </>
        )}
      </h2>
      {children && <SubMenu children={children} closeMenu={closeMenu} />}
    </div>
  );
};

const SubMenu = ({ children, closeMenu }) => {
  return (
    <ul className="t-sitemap_navLink">
      {children &&
        children.map(({ id, url, title, children }) => (
          <li className="c-nav_subItem" key={id} onClick={closeMenu}>
            <SublinkSubMenu
              children={children}
              url={url}
              title={title}
            />
          </li>
        ))}
    </ul>
  );
};

const TemplateSitemap = ({ _uid }) => {
  // Breakpoint
  // const {desktop} = useSelector(state => state.breakpoint);

  // Get global info
  const {
    header: { nav_menu, nav_menuSec },
    sitemap: { links },
  } = useSelector((state) => state.global).global;

  // Meta - Get page info
  const [dataRedux, setDataRedux] = useState();
  //Requete pour récup les infos de la page spéciale
  const [dataFetch, isLoaded] = useApi({
    name: "api/node/special",
    _uid: _uid,
    fields: "&fields[node--special]=metatag",
    method: "get",
  });

  useEffect(() => {
    if (isLoaded && dataFetch) {
      const normalizeJson = normalize(dataFetch.data); //Normalisation du json
      setDataRedux(build(normalizeJson, "nodeSpecial", _uid));
    }
  }, [isLoaded, dataFetch]); // eslint-disable-line

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("t-sitemap"));
  }, [dispatch]); // isLoaded

  const renderPage = () => {
    const { metatag } = dataRedux;

    return (
      <>
        {metatag && <Metatags listMeta={metatag} />}
        <div className="container">
          <h1 className="title-1">Plan du site</h1>
          <div className="t-sitemap_content">
            {nav_menu && nav_menu.length > 0 && (
              <div className="t-sitemap_nav">
                {nav_menu.map((item) => (
                  <MenuItem key={item.id} {...item} />
                ))}
              </div>
            )}

            {nav_menuSec && nav_menuSec.length > 0 && (
              <div className="t-sitemap_nav2">
                {nav_menuSec.map((item) => (
                  <MenuItem key={item.id} {...item} />
                ))}
              </div>
            )}
          </div>

          {links && links.length > 0 && (
            <div className="t-sitemap_content">
              <div className="t-sitemap_nav sitemap-links">
                {links.map((item) => (
                  <MenuItem key={item.id} {...item} />
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  return dataRedux ? renderPage() : <Loader />;
};

export default TemplateSitemap;
