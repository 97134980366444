import React from "react";
import {useDispatch, useSelector} from "react-redux";

// Hooks
import {updateModalsOpen} from "../../Store/action";

// Components
import Modal from "./modal";


// Utils
import {transformText} from '../../Utils/transformText';

function renderMarkup (markup) {
    return {__html: markup};
};
const EconomyModal = ({ params, type }) => {

    const dispatch = useDispatch();
    const modalsOpen = useSelector(state => state.modals).openModal;

    let newText = '';
    params.titles &&
    params.titles.map((item) =>
        newText !== '' ? newText += '<br />' + transformText(item) : newText += transformText(item)
    );

    const closeModal = () => {
        let newState = {
            ...modalsOpen,
            "economy": false
        };
        dispatch(updateModalsOpen(newState));
    };

    return (
        <Modal modalName="economy"
               modalNameTracking={type === "cashback" ? "Économies - Mode d'emploi pour remboursement" : "Économies - Mode d'emploi pour impression"}>
            <div className="c-modal_cashback">
                {newText !== '' && <h2 dangerouslySetInnerHTML={renderMarkup(newText)}></h2>}
                <div className="c-modal_cashback-list">
                    <ul>
                        {params.pictogramsTexts &&
                        Object.keys(params.pictogramsTexts).map((item, key) => {
                            if(item) {
                                return (
                                    <li key={key}><i className={`c-modal_cashback-picto picto-${item}`}></i>
                                        <div dangerouslySetInnerHTML={renderMarkup(transformText(params.pictogramsTexts[item]))} />
                                    </li>
                                )
                            }
                        })
                        }

                    </ul>
                </div>
                <div className="c-modal_cashback-button">
                    <button type="button" className="btn-2"
                            onClick={() => closeModal()}>{type === "cashback" ? 'Sélectionner les offres' : 'Sélectionner mes réductions'}</button>
                </div>
            </div>

        </Modal>
    )
};

export default EconomyModal;