import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import normalize from "json-api-normalizer";
import build from "redux-object";
import { useHistory } from "react-router-dom";

// Hooks
import { updateGlobalStorePage, updateModalsOpen, updateModalsLoginType } from '../Store/action'
import useTracking from '../Hooks/useTracking';
import useApi from "../Hooks/useApiGlobal";
import useApiJeton from '../Hooks/useApiJeton';


// Components
// import Metatags from '../Layout/metas-tags/meta-tags';
import { GoBack } from "../Components/goBack";

// Utils
import { transformText } from "../Utils/transformText";

const TemplateAccountNotif = () => {
    let history = useHistory();

    // TRACKING - Popin display
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-accountNotif'));

        if (dispatch) {
            // Set tracking event - page view
            setTrackingEvent({
                event: 'page_view',
                args: {
                    content_category: 'Mon compte',
                    content_category2: 'Notifications',
                    content_category3: '',
                }
            });
        }
    }, [dispatch]); // isLoaded


    // Get user
    let { uuid } = useSelector(state => state.user);

    const modalsOpen = useSelector(state => state.modals).openModal;//modal

    let [paramsUser, setParamsUser] = useState('');
    let [paramsViewNotif, setParamsViewNotif] = useState({}); //eslint-disable-line

    const [dataFetchUser, isLoadedUser] = useApi(paramsUser);
    const [userProfile, setUserProfile] = useState();

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);


    useEffect(() => {
        if (!uuid) {
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'password': false, 'economy': false }));
            dispatch(updateModalsLoginType('currentPage'));
        } else {
            setDataNotifParams({
                name: 'api/notifications',
                sort: 'sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                method: 'get'
            });
            setParamsUser({
                name: `api/users/${uuid}`,
                include: 'user_picture',
                fields: '&fields[users]=user_picture',
                method: 'get'
            });

            // Récupération du Jeton
            setInitJeton({
                callback: sendData,
                params: ''
            });
        }
    }, [uuid]); // eslint-disable-line


    const sendData = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "X-CSRF-Token": data
        };
        setParamsViewNotif({
            name: 'api/users/notifications',
            config: headers,
            method: 'patch'
        })
    };

    const [dataNotif, setDataNotif] = useState();
    const [dataNotifParams, setDataNotifParams] = useState({});
    const [dataFetchNotif, isLoadedNotif] = useApi(dataNotifParams);
    const [dataFetchViewNotif, isLoadedViewNotif] = useApi(paramsViewNotif); // eslint-disable-line


    useEffect(() => {
        if (isLoadedNotif && dataFetchNotif) {
            // console.log('dataFetchNotif', dataFetchNotif);
            const normalizeJson = normalize(dataFetchNotif.data);//Normalisation du json
            setDataNotif(build(normalizeJson, 'notifications'));
        }
    }, [isLoadedNotif, dataFetchNotif]); // eslint-disable-line

    /* useEffect(() => {
           if (isLoadedViewNotif && dataFetchViewNotif) {
               console.log(dataFetchViewNotif);
           }
       }, [isLoadedViewNotif]);*/
    /*
          useEffect(() => {
              if (dataNotif) {
                  console.log('dataNotif', dataNotif);
              }
          }, [dataNotif]);
      */
    useEffect(() => {
        if (isLoadedUser && dataFetchUser) {
            if (dataFetchUser.data && dataFetchUser.data.data) {
                setUserProfile(dataFetchUser.data.data);
            }
        }
    }, [isLoadedUser, dataFetchUser]); // eslint-disable-line

    function formatDate(d) {
        let date = new Date(d);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        return d = dd + '/' + mm + '/' + yyyy
    }

    return (
        <>
            {/*{metas && <Metatags {...metas} />}*/}

            <GoBack />

            <div className="t-accountNotif_wrapper">
                <div className="container">
                    <h1 className="title-2">Mes notifications</h1>
                </div>
                {(dataNotif && dataNotif.length > 0) &&
                    <div className="t-accountNotif_container">
                        <div className="container">
                            <div className="t-accountNotif_content">
                                {dataNotif.map(({ created, text }, key) => {
                                    // let hour = (new Date(created)).toUTCString().substring(17, 25);
                                    let hour = created.substring(11, 16);
                                    return (
                                        <div className="t-accountNotif_item" key={key}>
                                            <div className="t-accountNotif_avatar">
                                                <div style={{ backgroundImage: 'url(' + userProfile + ')' }} />
                                            </div>
                                            <div className="t-accountNotif_notif">
                                                <p>{formatDate(created)} {hour}</p>
                                                <p dangerouslySetInnerHTML={{ __html: transformText(text) }} />
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    )
};

export default TemplateAccountNotif;