import React from "react";

// Libraries
import ReactPlayer from 'react-player/youtube';
import {getImage} from '../../Utils/getMedia';


const Video = ({infoVideo: hrefVideo, infoImage, desktop, videoPoster}) => {

    const videoSettings = {
        youtube: {
            playerVars: {showinfo: 0}
        }
    };

    const hrefImage = getImage(infoImage, desktop, 'accordion');//Récupération de l'image

    return (

        <div className="c-video no-print">
            {(hrefVideo && hrefVideo.filename) ?
                <>
                    <div className="c-video_iframeCtn">
                        <ReactPlayer className="react-player c-video_iframe"
                                     config={videoSettings}
                                     controls={true}
                                     light={hrefImage ? hrefImage : videoPoster ? videoPoster : true}
                                     playing={true}
                                     url={`https://www.youtube.com/watch?v=${hrefVideo.filename}`}
                                     poster={hrefImage ? hrefImage : videoPoster ? videoPoster : ''}
                                     width='100%' height='100%'
                                     playIcon={<button className="c-video_button" type="button">
                                         <i className="icon-player"/></button>}/>
                    </div>
                </> :
                <>
                    <img src={hrefImage} alt="" className="c-video-iframe"/>
                </>

            }
        </div>
    )
};

export default Video;