import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class PictoProfil extends Component {
    // Props
    surname = this.props.surname;
    percent = this.props.percent;
    color = this.props.color;
    photo = this.props.photo;

    // Variable
    ismounted = false;
    lastWidth = -1;
    isAnimationDone = false;
    step = 0;

    // constantes
    LINE_WIDTH = 6;
    MARGIN_BETWEEN_LINE_AND_IMAGE = 5;
    COLOR_EMPTY = "#EAEAEA";
    ANGLE_START = Math.PI / 8;

    componentDidMount() {
        this.dom = ReactDOM.findDOMNode(this);
        this.canvas = this.dom.querySelector(".pictoprofil__canvas");
        // this.context = this.canvas.getContext("2d");
        this.ismounted = true;
        this.renderGraphics();
    }

    componentWillUnmount() {
        this.ismounted = false;
    }

    render() {
        return (
            <div className="pictoprofil">
                <canvas className="pictoprofil__canvas"></canvas>
                <p className="pictoprofil__name" style={{ color: this.color }}>{this.surname}</p>
            </div>
        )
    }

    renderGraphics(t = 0) {
        if (this.ismounted) {
            // let width = this.dom.clientWidth/10;
            let width = 100;

            if (this.lastWidth !== width || !this.isAnimationDone) {
                this.lastWidth = width;
                this.canvas.width = width;
                this.canvas.height = width;
                this.context = this.canvas.getContext('2d');
                let startAngle = 0.5 * Math.PI + this.ANGLE_START;
                let radius = (width - this.LINE_WIDTH) * 0.5;
                //Dessin total de l'arc
                this.context.beginPath();
                this.context.strokeStyle = this.COLOR_EMPTY;
                this.context.lineWidth = this.LINE_WIDTH;
                this.context.arc(width * 0.5, width * 0.5, radius, startAngle, startAngle + 2 * Math.PI - 2 * this.ANGLE_START);
                this.context.stroke();
                this.context.closePath();

                //Dessin des ronds a chaque bout de la courbe
                this.context.beginPath();
                this.context.fillStyle = this.COLOR_EMPTY;
                let posX3 = (width * 0.5) + (radius * Math.cos(startAngle + 2 * Math.PI - 2 * this.ANGLE_START));
                let posY3 = (width * 0.5) + (radius * Math.sin(startAngle + 2 * Math.PI - 2 * this.ANGLE_START));
                this.context.arc(posX3, posY3, this.LINE_WIDTH * 0.5, 0, 2 * Math.PI);
                this.context.fill();
                this.context.closePath();

                this.context.beginPath();
                this.context.fillStyle = this.color;
                let posX1 = (width * 0.5) + (radius * Math.cos(startAngle));
                let posY1 = (width * 0.5) + (radius * Math.sin(startAngle));
                this.context.arc(posX1, posY1, this.LINE_WIDTH * 0.5, 0, 2 * Math.PI);
                this.context.fill();
                this.context.closePath();

                this.context.beginPath();
                this.context.fillStyle = this.color;
                let posX2 = (width * 0.5) + (radius * Math.cos(startAngle + (this.step * 0.01) * (this.percent * 0.01) * (2 * Math.PI - 2 * this.ANGLE_START)));
                let posY2 = (width * 0.5) + (radius * Math.sin(startAngle + (this.step * 0.01) * (this.percent * 0.01) * (2 * Math.PI - 2 * this.ANGLE_START)));
                this.context.arc(posX2, posY2, this.LINE_WIDTH * 0.5, 0, 2 * Math.PI);
                this.context.fill();
                this.context.closePath();

                //Dessin de l'arc en cours
                this.context.beginPath();
                this.context.strokeStyle = this.color;
                this.context.lineWidth = this.LINE_WIDTH;
                this.context.arc(width * 0.5, width * 0.5, radius, startAngle, startAngle + (this.step * 0.01) * (this.percent * 0.01) * (2 * Math.PI - 2 * this.ANGLE_START));
                this.context.stroke();
                this.context.closePath();

                

                //Dessin de l'image
                // if (!this.img) {
                //     this.img = new Image();
                //     this.img.onload = () => {
                //         this.showImage(this.img, width, this.context);
                //     }
                //     this.img.src = this.photo;
                // }
                // else {
                //     this.showImage(this.img, width, this.context);
                // }

                this.step += 2;
                if (this.step > 100) {
                    this.step = 100;
                    this.isAnimationDone = true;
                }
            }

            requestAnimationFrame(this.renderGraphics.bind(this));
        }
    }

    showImage(img, width, context) {
        if(context) {
            context.beginPath();
            context.arc(width * 0.5, width * 0.5, width * 0.5 - (this.LINE_WIDTH + this.MARGIN_BETWEEN_LINE_AND_IMAGE), 0, 2 * Math.PI);
            context.clip();
            let imageSize = img.width > img.height ? img.height : img.width;
            if(img) {
                context.drawImage(img,
                    (img.width - imageSize) * 0.5,
                    (img.height - imageSize) * 0.5,
                    imageSize,
                    imageSize,
                    this.LINE_WIDTH + this.MARGIN_BETWEEN_LINE_AND_IMAGE,
                    this.LINE_WIDTH + this.MARGIN_BETWEEN_LINE_AND_IMAGE,
                    width - 2 * (this.LINE_WIDTH + this.MARGIN_BETWEEN_LINE_AND_IMAGE),
                    width - 2 * (this.LINE_WIDTH + this.MARGIN_BETWEEN_LINE_AND_IMAGE));
            }
            context.closePath();
        }
    }
}

export default PictoProfil;