import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import useTracking from '../Hooks/useTracking';

//Composant
import CLink from "../Components/cLinks";
import Metatags from "../Layout/metas-tags/meta-tags";

const Template404 = () => {
    // Home path
    const homePath = useSelector(state => state.routes).routes.home;

    // TRACKING - Popin display
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-404'));

        if (dispatch) {
            // Set tracking event - page view
            setTrackingEvent({
                event: 'page_view',
                args: {
                    content_category: '404',
                    content_category2: 'undefined',
                    content_category3: 'undefined',
                }
            });
        }
    }, [dispatch]);


    return (
        <>
            <Metatags listMeta={[{ tag: "meta", attributes: { name: 'prerender-status-code', content: '404' } }]} />
            <div className="container">
                <div className="t-404_content">
                    <div className="t-404_visuel">
                        <img src={require("../assets/img/404.png")} alt="La belle Adresse" />
                    </div>
                    <div className="t-404_capsule">
                        <h1>Vous vous êtes perdu !</h1>
                        <p>La page que vous cherchez n’existe pas ou plus.<br />Retrouvez le chemin de La Belle Adresse !
                        </p>
                        <p><CLink url={homePath} className="btn-3">Retour à la page d'accueil</CLink></p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Template404;