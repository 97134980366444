import React from "react";

// Components
import CLink from "./cLinks";


const CommunityHpCard = ({ title, text, imgSrc, link }) => {
    return (
        <CLink url={link ? link : '#'}>
            <div className="c-communityHpCard">
                <div className="c-communityHpCard-content">
                    {
                        title &&
                        <h2 className="c-communityHpCard-title lowercase--">{title}</h2>
                    }
                    {
                        text &&
                        <p className="c-communityHpCard-text" dangerouslySetInnerHTML={{ __html: text }}></p>
                    }
                </div>
                {
                    imgSrc &&
                    <img className="c-communityHpCard-img" src={imgSrc} alt="#" />
                }
            </div>
        </CLink>

    )
};

export default CommunityHpCard;