import { useEffect } from 'react';
import Axios from 'axios';
import { getCookie } from '../Utils/cookie';

/**
 * @async Creation d'un hook d'appel des API
 *
 * @returns dataFetch.data
 */

export default ({ callback, params, params2, success, errors }) => {

    const baseURL = process.env.REACT_APP_APP;

    let config = {};
    let srcid = getCookie('srcid');
    let srcid_auth_header_key = getCookie('srcid_auth_header_key');
    if (srcid && srcid_auth_header_key) {
        config[srcid_auth_header_key] = srcid;
    }

    useEffect(() => {

        const fetchAPi = async () => {
            try {
                const dataFetch = await Axios.post(`${baseURL}/session/token`, {}, {
                    headers: config
                });
                //console.log('jeton data', dataFetch.data);

                // Fonction appelée après avoir récupéré le jeton avec en paramètre le jeton + autres params si nécessaire
                if (callback) {
                    if (params)
                        callback(dataFetch.data, params, params2);
                    else
                        callback(dataFetch.data);
                }
                if (success) {
                    success(dataFetch.data);
                }

                return dataFetch.data;
            } catch (err) {
                console.log('error - get session token', err);
                if (errors) {
                    errors();
                }
                return null;
            }
        };

        if (callback && callback !== '') {
            fetchAPi();
        }
    }, [params, callback]); // eslint-disable-line
};