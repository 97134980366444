import React from "react"

/*// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';


SwiperCore.use([Navigation, EffectFade, Autoplay]);*/

const SubUniverse = ({name, desktop, slideActive}) => {
    return (
        <span className="c-subuniverse_title">
                {name}
            </span>
    )
};

export default SubUniverse;