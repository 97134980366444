import React, {useState} from 'react';

// Hooks
import useTracking from '../Hooks/useTracking';

// Import Swiper React components
import SwiperCore, {Pagination, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

// Components
import CLink from "./cLinks";

// Utils
// import { getImage } from '../Utils/getMedia';
import {randomString} from "../Utils/md5-hash";


SwiperCore.use([Pagination, EffectFade, Autoplay]);


const CarsouelProducts = ({carousel, desktop}) => {
    // console.log('carouselProducts ', carousel);

    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const selectContent = (contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: "Produit",
                content_name: contentName
            },
            refresh: refresh
        });
    };

    return (
        <>
            {carousel.length > 0 &&
            <div
                className={`c-carouselProducts ${((desktop && carousel.length < 3) || (!desktop && carousel.length < 2)) ? 'no-carousel' : ''}`}>
                <Swiper className="c-carouselProducts_items"
                        navigation={true}
                        slidesPerView="auto"
                        spaceBetween={desktop ? 20 : 10}
                        watchSlidesVisibility={true}
                        allowTouchMove={((desktop && carousel.length > 2) || (!desktop && carousel.length > 1)) ? true : false}
                        loop={((desktop && carousel.length > 2) || (!desktop && carousel.length > 1)) ? true : false}
                        pagination={((desktop && carousel.length > 2) || (!desktop && carousel.length > 1)) ? {clickable: true} : false}
                >
                    {
                        carousel.map(({title, fieldCommonPgphBan, fieldProductImg, path, drupalInternalTid}, key) => {

                            return (
                                <SwiperSlide key={key} className="c-carouselProducts_item">
                                    <CLink url={path.alias ? path.alias : '/node/' + drupalInternalTid}
                                           handleClick={() => selectContent(title, randomString(6))}>
                                        {/* <div className='c-carouselProducts_note'>4,5<i className="icon-star"/></div> */}
                                        {fieldProductImg &&
                                        <img src={fieldProductImg.links.commonLogoDesktop.href} alt={title}
                                             className="c-carouselProducts_visuel"/>
                                        }
                                        {/* fieldCommonPgphBan.fieldRespimgImgDesktop &&
                                            <div className="c-carouselProducts_visuel" style={fieldCommonPgphBan ? { backgroundImage: `url(${getImage(fieldCommonPgphBan, desktop, 'carrousel')})`} : {}}>
                                            </div>
                                        */}
                                        <div className="c-carouselProducts_container">
                                            <div className="c-carouselProducts_content">
                                                {title && <h3>{title}</h3>}
                                            </div>
                                        </div>
                                    </CLink>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            }
        </>
    )
};

export default CarsouelProducts;