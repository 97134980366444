import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

// Store
import {
    updateGlobalScrollLock,
    updateModalsOpen,
    updateModalsLoginType,
    updateUserUuid,
    updateUserIupid,
    updateUserPseudo,
    updateUserDateInscription,
    updateGlobalContactReset
} from '../../Store/action';

import { getRefreshToken } from '../../Utils/cookie';

// Hooks
import useApi from '../../Hooks/useApiGlobal';
import { eraseToken, eraseRefreshToken } from '../../Utils/cookie'

// Components
import MenuItem from './menu';
import MenuUnivers from './menuUnivers';
import Basket from '../../Components/basket';
// import Newsletter from '../../Components/newsletter';
import Search from '../header/search';

const ToggleMenu = ({ switchIcon }) => {
    return (
        <div className="c-header_toggle">
            <button className="c-header_toggleBtn" onClick={switchIcon}></button>
        </div>
    );
};

const LoginButton = ({ closeMenu }) => {

    const accountPath = useSelector(state => state.routes).routes.account;

    const dispatch = useDispatch();

    let [showUser, setShowUser] = useState(false);
    const { uuid, pseudo } = useSelector(state => state.user);


    const handleOpen = () => {
        closeMenu();
        dispatch(updateModalsOpen({ 'login': true }));
        dispatch(updateModalsLoginType('login'));
    };

    useEffect(() => {
        if (uuid && pseudo && getRefreshToken()) {
            setShowUser(true);
        }
        else {
            setShowUser(false);
        }
    }, [uuid, pseudo, getRefreshToken()]); // eslint-disable-line

    return (
        <div className="c-header_login">
            {
                showUser ?
                    <Link to={accountPath} className="c-header_loginBtn connected--"
                        title="Mon compte" />
                    :
                    <button
                        className="c-header_loginBtn"
                        type="button"
                        onClick={handleOpen}>
                    </button>
            }
        </div>
    );
};

const Logo = ({ img_src, closeMenu }) => {
    const homePath = useSelector(state => state.routes).routes.home;

    return (
        <div className="c-header_logo" onClick={closeMenu}>
            <Link to={homePath} title="Accueil">
                {/*<img src={img_src} alt="La Belle Adresse"/>*/}
                <img src={require('../../assets/img/logo-lba.svg')} alt="La Belle Adresse" />
            </Link>
        </div>
    )
};

const ContactButton = () => {
    let dispatch = useDispatch();
    const contactPath = useSelector(state => state.routes).routes.contact;

    return (
        <div className="c-header_contact">
            <Link to={contactPath} title="Contact" onClick={() => dispatch(updateGlobalContactReset(true))}><i
                className="icon-what-2"></i></Link>
        </div>
    );
};


const UserPseudo = ({ pseudoUser }) => {
    return (
        pseudoUser ?
            <div className="c-header_user">
                Bonjour <span>{pseudoUser}</span>
            </div>
            :
            null
    );
};


const Menu = ({ logo, items, itemsSec, closeMenu, newsletter }) => {
    let dispatch = useDispatch();
    const history = useHistory();
    const { desktop } = useSelector(state => state.breakpoint);
    const { uuid, pseudo } = useSelector(state => state.user);
    const searchResultsPath = useSelector(state => state.routes).routes.searchResults;
    // Gestion ouverture du menu
    const [open, setOpen] = useState();
    let submenuOpen = document.querySelector('.header_nav-item.is-open');

    useEffect(() => {
        if (submenuOpen) {
            submenuOpen.classList.remove('is-open');
        }
    });

    // Gestion du Log Out
    let [params, setParams] = useState({});
    let [paramApi, setParamApi] = useState({});
    let [loadApi, setLoadApi] = useState(false);

    const [initClient, isLoadedUser] = useApi(params);

    useEffect(() => {
        if (loadApi && paramApi) {
            setParams(paramApi)

            if(paramApi.method){
                setParamApi({name: ''});
            }
        }
    }, [paramApi, loadApi]);

    useEffect(() => {
        if (initClient && isLoadedUser) {
            let { status, data } = initClient;
            // console.log('global ', data);
            if (status === 200) {
                //tout est ok
                //console.log('deconnexion ok');
                eraseToken();
                eraseRefreshToken();
                dispatch(updateUserUuid(""));
                dispatch(updateUserIupid(""));
                dispatch(updateUserPseudo(""));
                dispatch(updateUserDateInscription(""));
                localStorage.removeItem("uuid");
                localStorage.removeItem("iupid");
                localStorage.removeItem("pseudo");
                localStorage.removeItem("dateInscription");

                closeMenu();
                //redirection HP
                // document.location.href = "/";
                history.push({ pathname: '/' });
                //history.push('./')
            } else {
                console.log('error logout', status, data);
            }
        }
    }, [initClient, isLoadedUser]); // eslint-disable-line

    const handleLogOut = () => {
        console.log('logout');

        if (sessionStorage.getItem("popinEconomyView")) {
            sessionStorage.removeItem('popinEconomyView');
        }
        if (sessionStorage.getItem("popinCompletionView")) {
            sessionStorage.removeItem('popinCompletionView');
        }
        if (sessionStorage.getItem("popinPromotionView")) {
            sessionStorage.removeItem('popinPromotionView');
        }
        if (sessionStorage.getItem("popinWelcomeView")) {
            sessionStorage.removeItem('popinWelcomeView');
        }
        if (sessionStorage.getItem("pageViews")) {
            sessionStorage.setItem("pageViews", 0);
        }
        
        setLoadApi(true);
        setParamApi({ name: 'api/logout', method: 'post' });

        return null;
    };

    // fix resize issue on safari mobile
    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return (
        <div className="c-header_nav c-nav">
            {
                !desktop &&
                <div className="c-nav_header">
                    <div className="container">
                        <Logo {...logo} closeMenu={closeMenu} />
                        {(uuid && pseudo && getRefreshToken()) &&
                            <UserPseudo pseudoUser={pseudo} />
                        }
                        <div className="c-nav_right">
                            <ContactButton />
                            <LoginButton closeMenu={closeMenu} />
                            <ToggleMenu switchIcon={closeMenu} />
                        </div>
                    </div>
                </div>
            }

            <nav className="c-nav_content">
                <div className="container">
                    <div className="c-nav_line">
                        <Search
                            searchResultsPath={searchResultsPath}
                            trackingSearchType={'Global'}
                            placeholder={"Rechercher une réduction, un article"} />
                    </div>
                    <div className="c-nav_line">
                        <div className="c-nav_list">
                            <ul>
                                {items.map((item) =>
                                    <MenuItem
                                        key={item.id}
                                        {...item}
                                        closeMenu={closeMenu}
                                        active={item.id === open}
                                        onClick={() => setOpen(item.id)}
                                    />
                                )}
                                {/* <li className="c-nav_item community">
                                    <div className="c-nav_itemElt">
                                        <span className="c-nav_link no-link"><i></i>Bientôt disponible</span>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                        <div className="c-nav_list">
                            <ul>
                                {itemsSec.map((item) =>
                                    <MenuItem
                                        key={item.id}
                                        {...item}
                                        closeMenu={closeMenu}
                                        active={item.id === open}
                                        onClick={() => setOpen(item.id)}
                                    />
                                )}
                            </ul>
                            {uuid && getRefreshToken() &&
                                <button className="c-nav_deconnexion" onClick={handleLogOut}>Se déconnecter</button>
                            }
                        </div>

                        {/*
                        <div className="c-nav_newsletter">
                            <Newsletter {...newsletter} />
                        </div>
                        */}
                    </div>
                </div>
            </nav>
        </div>
    )
};


const Header = () => {
    // Get global info

    const { header: { logo, nav_menu, nav_menuSec, sticky_menu }, footer: { newsletter } } = useSelector(state => state.global).global;

    let { desktop } = useSelector(state => state.breakpoint);
    const { uuid, pseudo } = useSelector(state => state.user);

    // SCROLL LOCK REDUCER
    let dispatch = useDispatch();
    let scrollStore = useSelector(state => state.global).lockScroll;
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));
    }, []); // eslint-disable-line

    // OPEN NAV
    const [navOpen, setOpen] = useState(false);

    // ÉTAT OUVERT / FERMÉ DU MENU
    const toggleMenu = () => {
        setOpen(!navOpen);
        dispatch(updateGlobalScrollLock(!scrollStore));
    };

    // Close menu when click on logo
    const closeMenu = () => {
        setOpen(false);
        dispatch(updateGlobalScrollLock(false));
    };

    return (
        <header className={`c-header ${!navOpen ? '' : 'navOpened--'}`}>
            <div className="c-header_container">
                <div className="container">
                    <div className="c-header_content">
                        <div className="c-header_left">
                            <Logo {...logo} closeMenu={closeMenu} />

                            <MenuUnivers items={sticky_menu} closeMenu={closeMenu} />
                        </div>

                        <div className="c-header_tools">
                            <ContactButton />
                            <LoginButton closeMenu={closeMenu} />
                            {(desktop && uuid && pseudo && getRefreshToken()) &&
                                <UserPseudo pseudoUser={pseudo} />
                            }
                            <Basket />
                        </div>
                        <div className="c-header_right">
                            <ToggleMenu switchIcon={toggleMenu} />
                            {(!desktop && uuid && pseudo && getRefreshToken()) &&
                                <UserPseudo pseudoUser={pseudo} />
                            }
                        </div>

                        <Menu logo={logo} items={nav_menu} itemsSec={nav_menuSec} closeMenu={toggleMenu}
                            newsletter={newsletter} />
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;