import React, {useEffect, useState} from "react";
import {useDispatch, useSelector/*, useSelector*/} from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { useHistory } from "react-router-dom";

// Hooks
import {updateGlobalStorePage} from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

// Libraries
import Select from "react-select";

// Components
import {Loader} from "../Components/loader";
import Article from "../Components/article";
import MaxiLinkCarousel from "../Components/maxiLinkCarousel"
import {GoBack} from "../Components/goBack";
import Metatags from "../Layout/metas-tags/meta-tags";

import {
    updateModalsOpen,
    updateModalsLoginType,
    updateUserUuid,
    updateUserDateInscription,
    updateUserIupid,
    updateUserPseudo
} from '../Store/action';

const TemplateAccountFavorite = () => {
        let history = useHistory();
        let dispatch = useDispatch();
        useEffect(() => {
            dispatch(updateGlobalStorePage('t-accountFavorite'));
        }, [dispatch]); // isLoaded

        const [loading, setLoading] = useState(true);
        const modalsOpen = useSelector(state => state.modals).openModal;

        //Requete pour récup les univers
        const [universRedux, setUniversRedux] = useState();//Infos Liste des Univers
        const [listUnivers, setListUnivers] = useState();//Infos Liste Univers pour liste déroulante
        const [listUniversParams, setListUniversParams] = useState({});
        const [dataFetchUnivers, isLoadedUnivers] = useApi(listUniversParams);

        //Normalize les univers
        useEffect(() => {
            if (isLoadedUnivers && dataFetchUnivers) {
                const normalizeJsonUnivers = normalize(dataFetchUnivers.data);//Normalisation du json
                setUniversRedux(build(normalizeJsonUnivers, 'taxonomyTermUniverse',));
            }
        }, [isLoadedUnivers, dataFetchUnivers]);//chargement requete ok

        let options = [];
        useEffect(() => {
            if (universRedux) {
                universRedux.map(({name, id}) => {
                    options.push({label: name, value: id});
                    return null;
                });
                options.push({label: "Tous", value: ""});
                setListUnivers(options)
            }
        }, [universRedux]); // eslint-disable-line
        // FIN Univers

        // User
        let {uuid, favorites} = useSelector(state => state.user);

        // DataRedux
        const [dataRedux, setDataRedux] = useState();
        const [dataReduxArticle, setDataReduxArticle] = useState();
        const [dataReduxArticleVideo, setDataReduxArticleVideo] = useState();
        const [dataReduxArticleDiy, setDataReduxArticleDiy] = useState();

        // Params
        const [paramsArticle, setParamsArticle] = useState({});
        const [paramsArticleVideo, setParamsArticleVideo] = useState({});
        const [paramsArticleDiy, setParamsArticleDiy] = useState({});

        // Filter
        const [articlesFavFilter, setArticlesFavFilter] = useState("");
        useEffect(() => {
            console.log("FAVORITES", uuid);
            if (favorites && favorites.length > 0) {
                let favFilter = '';
                favorites.map((fav, index) => {
                    favFilter += '&filter[uuid-filter][condition][value][' + parseInt(index + 1) + ']=' + fav.uuid;
                    return null;
                });
                //  console.log('favfilter', favFilter);
                setArticlesFavFilter('&filter[uuid-filter][condition][path]=id&filter[uuid-filter][condition][operator]=IN' + favFilter);

                setParamsArticle({
                    name: 'api/node/article',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                    fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,field_common_tr_universes,path,drupal_internal__nid,field_common_duration',
                    filter: '&filter[uuid-filter][condition][path]=id&filter[uuid-filter][condition][operator]=IN' + favFilter,
                    sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                    method: 'get'
                });

                setParamsArticleVideo({
                    name: 'api/node/article_video',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                    fields: '&fields[node--article_video]=field_common_txt_title,field_common_pgph_ban,field_common_tr_universes,path,drupal_internal__nid,field_common_duration,field_common_video',
                    filter: '&filter[uuid-filter][condition][path]=id&filter[uuid-filter][condition][operator]=IN' + favFilter,
                    sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                    method: 'get'
                });

                setParamsArticleDiy({
                    name: 'api/node/diy',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                    fields: '&fields[node--diy]=field_common_txt_title,field_common_pgph_ban,field_common_tr_universes,path,drupal_internal__nid,field_common_duration',
                    filter: '&filter[uuid-filter][condition][path]=id&filter[uuid-filter][condition][operator]=IN' + favFilter,
                    sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                    method: 'get'
                });

                setListUniversParams({
                    name: 'api/taxonomy_term/universe',
                    fields: '&fields[taxonomy_term--universe]=name,id',
                    method: 'get'
                });
            } else {
                //  console.log('case2');
                setLoading(false);
                setDataRedux();
            }
        }, [favorites]);


        const [dataFetchNodeArticle, isLoadedNodeArticle] = useApi(paramsArticle);
        const [dataFetchNodeArticleVideo, isLoadedNodeArticleVideo] = useApi(paramsArticleVideo);
        const [dataFetchNodeArticleDiy, isLoadedNodeArticleDiy] = useApi(paramsArticleDiy);

        useEffect(() => {
            if(!uuid){
                dispatch(updateUserUuid(""));
                dispatch(updateUserIupid(""));
                dispatch(updateUserPseudo(""));
                dispatch(updateUserDateInscription(""));
                dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'alert': true }));
                dispatch(updateModalsLoginType('login'));
                }
        }, [uuid]);

        useEffect(() => {
            if (dataFetchNodeArticle && isLoadedNodeArticle) {
                if (dataFetchNodeArticle.data && isLoadedNodeArticle) {
                    const normalizeJson = normalize(dataFetchNodeArticle.data);
                    const buildJson = build(normalizeJson, 'nodeArticle');
                    if (buildJson) {
                        setDataReduxArticle(buildJson);
                    } else {
                        setDataReduxArticle([]);
                    }
                } else {
                    setDataReduxArticle([]);
                }
            }
        }, [isLoadedNodeArticle, dataFetchNodeArticle]);

        useEffect(() => {
            if (dataFetchNodeArticleVideo && isLoadedNodeArticleVideo) {
                if (dataFetchNodeArticleVideo.data && isLoadedNodeArticleVideo) {
                    const normalizeJson = normalize(dataFetchNodeArticleVideo.data);
                    const buildJson = build(normalizeJson, 'nodeArticleVideo');
                    if (buildJson) {
                        setDataReduxArticleVideo(buildJson);
                    } else {
                        setDataReduxArticleVideo([]);
                    }
                } else {
                    setDataReduxArticleVideo([]);
                }
            }
        }, [isLoadedNodeArticleVideo, dataFetchNodeArticleVideo]);

        useEffect(() => {
            if (dataFetchNodeArticleDiy && isLoadedNodeArticleDiy) {
                if (dataFetchNodeArticleDiy && dataFetchNodeArticleDiy.data && isLoadedNodeArticleDiy) {
                    const normalizeJson = normalize(dataFetchNodeArticleDiy.data);
                    const buildJson = build(normalizeJson, 'nodeDiy');
                    if (buildJson) {
                        setDataReduxArticleDiy(buildJson);
                    } else {
                        setDataReduxArticleDiy([]);
                    }
                } else {
                    setDataReduxArticleDiy([]);
                }
            }
        }, [isLoadedNodeArticleDiy, dataFetchNodeArticleDiy]);

        useEffect(() => {
            if (dataReduxArticle && dataReduxArticleVideo && dataReduxArticleDiy) {
                /* console.log('dataReduxArticle', dataReduxArticle);
                 console.log('dataReduxArticleVideo', dataReduxArticleVideo);
                 console.log('dataReduxArticleDiy', dataReduxArticleDiy);*/
                reorderArray([...dataReduxArticle, ...dataReduxArticleVideo, ...dataReduxArticleDiy], favorites);
                setLoading(false);
            }
            /*else {
                           setLoading(true);
                       }*/
        }, [dataReduxArticle, dataReduxArticleVideo, dataReduxArticleDiy]); // eslint-disable-line

        const reorderArray = (arrayToSort, basedArray) => {
            let result = [];

            basedArray.forEach(({uuid}) => {
                let found = false;
                arrayToSort = arrayToSort.filter(function (item) {
                    if (!found && item.id === uuid) {
                        result.push(item);
                        found = true;
                        return false;
                    } else
                        return true;
                })
            });

            //console.log('result dataRedux', result);
            setDataRedux(result.reverse());
        };

        const handleFilter = (e) => {
            // console.log('idFilter', e.value);
            setParamsArticle({
                ...paramsArticle,
                filter: (e && e.value !== '') ? articlesFavFilter + '&filter[field_common_tr_universes.id]=' + e.value : articlesFavFilter
            });
            setParamsArticleVideo({
                ...paramsArticleVideo,
                filter: (e && e.value !== '') ? articlesFavFilter + '&filter[field_common_tr_universes.id]=' + e.value : articlesFavFilter
            });
            setParamsArticleDiy({
                ...paramsArticleDiy,
                filter: (e && e.value !== '') ? articlesFavFilter + '&filter[field_common_tr_universes.id]=' + e.value : articlesFavFilter
            });
            setLoading(true);
            setDataRedux();
            setDataReduxArticle();
            setDataReduxArticleVideo();
            setDataReduxArticleDiy();
        };

        const accountEditPath = useSelector(state => state.routes).routes.accountEdit;
        let moreProfilInfo = [
            {
                target: accountEditPath + '/#more-info-profil',
                targetTitle: "Mettez à jour votre profil",
                content: "Mettez à jour votre profil pour améliorer votre expérience La Belle Adresse...",
            },
        ];


        const renderPage = () => {
           /* if (dataRedux && dataRedux.length > 0) {
                console.log('dataRedux', dataRedux);
            }*/

            let pageviewEvent = {
                category: 'Mon compte',
                category2: 'Mes conseils et astuces',
                category3: 'Récapitulatif de tous les articles fav',
            };

            return (
                <>
                    <Metatags pageviewEvent={pageviewEvent}/>

                    <GoBack/>

                    <div className="t-accountFavorite_wrapper">
                        <div className="container">
                            <h1 className="title-2">Mes favoris</h1>
                            {(listUnivers && listUnivers.length > 0) &&
                            <div className="t-accountFavorite_filter">
                                <Select options={listUnivers} placeholder="Filtrer par"
                                        className="c-filter_select"
                                        classNamePrefix="c-filter_select"
                                        onChange={(e) => handleFilter(e)}
                                    // menuIsOpen="true"
                                />
                            </div>
                            }

                            <div className="t-accountFavorite_list">
                                {(dataRedux && dataRedux.length > 0) ?
                                    dataRedux.map((item, key) => {
                                        return (
                                            <Article key={key} {...item} className="t-accountFavorite_item"/>
                                        )
                                    })
                                    :
                                    <>
                                        {loading ?
                                            <Loader/>
                                            :
                                            <div className="t-accountFavorite_noresults">Pas de résultats</div>
                                        }
                                    </>
                                }
                            </div>

                        </div>
                    </div>

                    <MaxiLinkCarousel moreProfilInfo={moreProfilInfo}/>
                </>
            )
        };
        return renderPage();
    }
;

export default TemplateAccountFavorite;