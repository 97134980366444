import React, { useEffect, useRef, useState, useCallback } from "react";
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { useLocation } from 'react-router-dom';

import Metatags from '../Layout/metas-tags/meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateModalsOpen,
    updateGlobalStorePage,
    updateModalsLoginType,
    updateUserUuid,
    updateUserIupid,
    updateUserPseudo,
    updateUserDateInscription
} from '../Store/action';
import useApi from '../Hooks/useApiGlobal';
import { strengthValueIndicator, strengthIndicator } from '../Components/strength-password';
import useTracking from '../Hooks/useTracking';

import { Loader } from "../Components/loader";
import Logout from '../Components/logout';
import { GoBack } from "../Components/goBack";

// Libraries
import Axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { renderCountries } from '../Utils/renderCountry';

// Components
import Tooltip from "../Components/tooltip"
import CLink from "../Components/cLinks";
import PictoProfil from '../Components/pictoProfil';

import useApiJeton from '../Hooks/useApiJeton';

// Modals
import DeleteAccountModal from '../Components/modals/delete-account-modal';

// Utils
import { randomString } from "../Utils/md5-hash";


const TemplateHome = ({ _uid }) => {

    let [profileIsUpdated, setProfileIsUpdated] = useState(false);
    const modalsOpen = useSelector(state => state.modals).openModal;

    // Get account view path
    const accountInformationsPath = useSelector(state => state.routes).routes.accountInformations;

    let hash = useLocation().hash;

    let dispatch = useDispatch();
    let { uuid } = useSelector(state => state.user);

    let [params, setParams] = useState('');
    let [content, setContent] = useState({});

    const [initClient, isLoadedUser] = useApi(params);

    useEffect(() => {
        if (uuid) {
            setParams({
                name: `api/users/${uuid}`,
                include: 'user_picture',
                method: 'get'
            });
        }
    }, [uuid]);


    useEffect(() => {
        if (initClient && isLoadedUser && params) {
            let { status, data } = initClient;
            if (status === 200) {
                //tout est ok
                //Préremplissage des données
                setContent(data.data.attributes);

                // Profile picture
                if (file === '') {
                    if (data.included && data.included[0] && data.included?.links?.common_logo_desktop?.href !== null) {
                        setFileUrl(data.included[0].links.common_logo_desktop.href);
                    } else {
                        setFileUrl(require("../assets/img/default-profil.png"));
                    }
                }

                if (profileIsUpdated) {
                    // TRACKING
                    formTracking(randomString(6));
                    setProfileIsUpdated(false);
                    setTimeout(function () {
                        window.location.reload()
                    }, 500);
                }
            } else {
                console.log('erreur status', status);
            }
        }
    }, [initClient, isLoadedUser, params]); // eslint-disable-line


    // TRACKING
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent);

    const formTracking = (refresh) => {
        // user_information_progress
        setTrackingEvent({
            event: 'user_information_progress',
            args: {
                user_information_percent: content.profile_completion + "%",
                lead_type: 'email'
            },
            refresh: refresh
        });
    };

    // Check Strength password
    let [password, setPassword] = useState({
        indicator: strengthIndicator(''),
        value: strengthValueIndicator('')
    });
    const handlePwdChange = (e) => {
        setPassword({
            indicator: strengthIndicator(e.target.value),
            value: strengthValueIndicator(strengthIndicator(e.target.value))
        });
    };

    // Tooltip Info
    const tooltipInfoRef = useRef();
    const tooltipHelpRef = useRef();
    const tooltipHelp1Ref = useRef();
    const tooltipHelp2Ref = useRef();
    const [showTooltipInfo, setShowTooltipInfo] = useState({
        show: false,
        content: '',
        ref: '',
        type: 'info'
    });
    const handleMouseTooltip = (e) => {
        if (showTooltipInfo.show) {

            setShowTooltipInfo({
                show: false,
                content: '',
                ref: '',
                type: 'info'
            });
        }
        else {
            let refTooltip = ""
            switch (e.target.dataset.typeref) {
                case 'tooltipInfoRef':
                    refTooltip = tooltipInfoRef.current;
                    break;

                case 'tooltipHelpRef':
                    refTooltip = tooltipHelpRef.current;
                    break;

                case 'tooltipHelp1Ref':
                    refTooltip = tooltipHelp1Ref.current;
                    break;

                case 'tooltipHelp2Ref':
                    refTooltip = tooltipHelp2Ref.current;
                    break;

                default:
                    break;
            }
            setShowTooltipInfo({
                show: true,
                content: e.target.dataset.content,
                ref: refTooltip,
                type: e.target.dataset.type
            });
        }

    };
    // END Tooltip Info

    // Hide / Show password
    const [revealOldPassword, setrevealOldPassword] = useState(false);
    const [revealPassword, setrevealPassword] = useState(false);
    const [revealPasswordConfirm, setrevealPasswordConfirm] = useState(false);

    const handleClickPassword = (e) => {

        switch (e.target.dataset.type) {
            case 'current_password':
                setrevealOldPassword(!revealOldPassword);
                break;

            case 'lba_lbapassword':
                setrevealPassword(!revealPassword);
                break;

            case 'lba_lbapassword_confirm':
                setrevealPasswordConfirm(!revealPasswordConfirm);
                break;
            default:
                break;
        }

    };
    // END Hide / Show password


    //-- BIRTH
    const [birthError, setBirthError] = useState({ message: '' });

    // Get age
    const getAge = (dateString) => {
        let dateArray = dateString.split('/');
        dateString = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    // On key up on birth field
    const handleBirth = (e) => {
        //-- Add slashes
        if (e.target.value && e.target.value.length > 0) {
            if ((e.target.value.length == 2 || e.target.value.length == 5) && e.keyCode != 8) { //eslint-disable-line
                e.target.value = e.target.value + '/';
            }
        }

        //-- Check age
        if (e.target.value.length == 10) { //eslint-disable-line
            if (getAge(e.target.value) < 18) {
                setBirthError({ message: "Vous devez être majeur pour avoir un compte." })
            } else {
                setBirthError({ message: '' })
            }
        }
    };


    // Tooltip Adresse Autocomplete
    const tooltipContainer = useRef();
    const [showTooltip, setShowTooltip] = useState({
        show: false,
        value: ''
    });
    let [adresseAutocomplete, setAdresseAutocomplete] = useState({
        update: false,
        name: null,
        postcode: null,
        city: null
    });
    const handleChangeAdresse = (e) => {
        setAdresseAutocomplete({ ...adresseAutocomplete, update: true, name: e.target.value });
        let postcode = document.querySelector('#lba_zip').value;
        if (e.target.value.length > 3) {
            //appel de l'api
            let fetch = Axios.get(`https://api-adresse.data.gouv.fr/search/?type=&autocomplete=1&q=${e.target.value}&postcode=${postcode}&limit=5`);
            fetch.then(function (response) {
                // handle success
                const result = handleSuccessAutoCompletion(response.data.features);
                setShowTooltip({ show: true, value: result });
            });
        }
        else {
            setShowTooltip({ show: false, value: '' })
        }
    };
    const handleSuccessAutoCompletion = (data) => {
        return (
            <ul>
                {Object.keys(data).map((keyName, i) => (
                    <li key={keyName}
                        data-name={data[i].properties.name}
                        data-postcode={data[i].properties.postcode}
                        data-city={data[i].properties.city}
                        onClick={(e) => autocompleAdress(e)}>
                        {data[i].properties.label}
                    </li>
                ))}
            </ul>
        )
    };
    const autocompleAdress = useCallback(
        (e) => {
            // console.log('autocompleAdress');
            setAdresseAutocomplete({
                update: true,
                name: e.target.dataset.name,
                postcode: e.target.dataset.postcode,
                city: e.target.dataset.city
            });
            setShowTooltip({ show: false });
        }, [adresseAutocomplete] // eslint-disable-line
    ); // eslint-disable-line

    const handleLeaveAddressInput = (e) => {
        setAdresseAutocomplete({
            update: true,
            name: e.target.value,
            postcode: document.querySelector('#lba_zip').value,
            city: document.querySelector('#lba_city').value
        });
        setTimeout(() => {
            setShowTooltip({ show: false });
        }, 300);
    };
    // END Tooltip Adresse Autocomplete

    // Meta - Get page info
    const [dataRedux, setDataRedux] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get',
        needLogin: true
    });

    useEffect(() => {
        if (!uuid) {
            dispatch(updateUserUuid(""));
            dispatch(updateUserIupid(""));
            dispatch(updateUserPseudo(""));
            dispatch(updateUserDateInscription(""));
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'alert': true }));
            dispatch(updateModalsLoginType('login'));
        }
    }, [uuid]);

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    // Recupere la fin du formulaire
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-accountForm'));
    }, []); // eslint-disable-line


    const options = renderCountries();

    const labelCountry = (code) => {
        let labelCode = "";
        let item = options.find(item => item.value === code);

        if (item)
            labelCode = item.label;
        return labelCode;
    };

    let formAccordion = require('../api/form.json');
    const { forms } = formAccordion;

    // Register form
    const { register: register1, handleSubmit: handleSubmit1, errors: errors1, control: control1, watch, setValue, clearErrors } = useForm({});
    const { register: register3, handleSubmit: handleSubmit3 } = useForm({});

    // Check confirm password
    const newPassword = useRef({});
    newPassword.current = watch("lba_lbapassword", "");

    // Check current password
    let [invalidCurrentPwd, setInvalidCurrentPwd] = useState(false);

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);

    // Errors - existing fields
    const [existingEmail, setExistingEmail] = useState(false);
    const [existingName, setExistingName] = useState(false);

    // Remove error onChange
    const handleRemoveErrors = (e) => {
        // Reset error field
        if (existingEmail) setExistingEmail(false);
        if (existingName) setExistingName(false);
    };

    const onSubmit = (dataForm) => {
        if ('lba_civilite' in dataForm) {
            dataForm.lba_civilite = parseInt(dataForm.lba_civilite);
        }
        if ('lba_birth' in dataForm) {
            //On scinde les jours mois année
            const dateBirth = dataForm.lba_birth.split('/');
            dataForm.lba_birthday = parseInt(dateBirth[0]);
            dataForm.lba_birthmonth = parseInt(dateBirth[1]);
            dataForm.lba_birthyear = parseInt(dateBirth[2]);
            delete dataForm.lba_birth;
        }
        if ('lba_countrycode' in dataForm) {
            dataForm.lba_countrycode = dataForm.lba_countrycode.value;
        }
        if ('lba_lbapassword' in dataForm) {
            // Hide password
            setrevealOldPassword(false);
            setrevealPassword(false);
            setrevealPasswordConfirm(false);
            delete dataForm.lba_lbapassword_confirm;
            if (!dataForm.lba_lbapassword) {
                delete dataForm.lba_lbapassword;
            }
        }

        const dataSend = {
            data: {
                type: "users",
                id: uuid,
                attributes: {}
            }
        }
        Object.assign(dataSend.data.attributes, dataForm);

        // Display Loader
        setChangingDatas(true);

        // Récupération du Jeton
        setInitJeton({
            callback: SendData,
            params: dataSend
        });
    };


    let [paramsSend, setParamsSend] = useState({})
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const SendData = (jeton, dataSend) => {
        const headers = {
            "Content-Type": "application/vnd.api+json",
            "Accept": "application/vnd.api+json",
            "X-CSRF-Token": jeton
        };

        setParamsSend({
            name: `api/users/${uuid}`,
            method: 'patch',
            data: dataSend,
            config: headers,
            stringifyRequest: false
        })
    };

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let { status, data } = responseSend;
            if (status === 200) {
                //tout est ok
                setContent(data.data.attributes);
                setChangingDatas(false);
                // Get updated user info
                setValue('lba_lbapassword', '');
                setValue('lba_lbapassword_confirm', '');
                setValue('current_password', '');
                clearErrors('lba_lbapassword');
                clearErrors('lba_lbapassword_confirm');
                clearErrors('current_password');
                setProfileIsUpdated(true);
                setPassword({
                    indicator: strengthIndicator(''),
                    value: strengthValueIndicator(strengthIndicator(''))
                });

                dispatch(updateUserPseudo(data?.data?.attributes?.lba_lbalogin));
                setAdresseAutocomplete({ update: false })
            }
            else {
                //Erreur
                if (data.errors[0].source) {
                    // Wrong password 
                    if (data.errors[0].source.pointer == '/data') {
                        setInvalidCurrentPwd(true);
                    }
                    // Existing email
                    if (data.errors[0].source.pointer == '/data/attributes/mail' || data.errors[0].source.pointer == '/data/attributes/lba_email') { // eslint-disable-line
                        setExistingEmail(true);
                    }
                    // Existing name
                    if (data.errors[0].source.pointer == '/data/attributes/name' || data.errors[0].source.pointer == '/data/attributes/lba_lbalogin') { // eslint-disable-line
                        setExistingName(true);
                    }
                }
                // Remove loader
                setChangingDatas(false);
            }
            // Reset params
            setParamsSend({})
        }
    }, [responseSend, isLoadedSend]); //eslint-disable-line

    useEffect(() => {
        if (profileIsUpdated) {
            // TRACKING
            formTracking(randomString(6));
            setProfileIsUpdated(false);
        }
    }, [profileIsUpdated]); //eslint-disable-line

    //-- MODIFICATION PHOTO DE PROFIL
    // Profile Picture
    const [file, setFile] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('');//eslint-disable-line
    const [editingPpict, setEditingPpict] = useState(false);


    const onSubmitProfil = (data) => {
        // Loader
        setChangingDatas(true);

        // Récupération du Jeton
        setInitJeton({
            callback: sendProfilPicture,
            errors: function () {
                setChangingDatas(false);
            }
        });
    };

    // Send Profil Picture
    let [paramsSendProfilPicture, setParamsSendProfilPicture] = useState({});
    const [responseSendProfilPicture, isLoadedSendProfilPicture] = useApi(paramsSendProfilPicture);

    const sendProfilPicture = (sessionToken) => {
        const headers = {
            "X-CSRF-Token": sessionToken
        };

        const formData = new FormData();

        let extension = file.name.substring(file.name.lastIndexOf("."));
        let filename = Math.floor(Math.random() * 10000000000) + extension;
        formData.append("file", file, filename);

        setParamsSendProfilPicture({
            name: `api/users/picture`,
            format: 'json',
            method: 'post',
            data: formData,
            config: headers,
            stringifyRequest: false // Body raw
        });
    };

    useEffect(() => {
        if (responseSendProfilPicture && isLoadedSendProfilPicture) {
            let { status } = responseSendProfilPicture;
            setChangingDatas(false);

            if (status === 201) {
                // Remove editing mode
                setEditingPpict(false);
            } else {
                // Error
                console.log('error - update profil picture');
            }
        }
    }, [responseSendProfilPicture, isLoadedSendProfilPicture]); // eslint-disable-line
    

    const FileImporter = () => {
        const handleChange = (event) => {
            // Récupère le fichier
            setFile(event.target.files[0]);

            // Récupère l'url du fichier pour l'afficher temporairement
            setFileUrl(URL.createObjectURL(event.target.files[0]));
            setFileName(event.target.files[0].name);

            // Editing mode active
            setEditingPpict(true);
        };
        const handleClickAvatar = () => {
            document.getElementById('btnFile').click();
        };

        return (
            <div className="c-form_avatar">
                <form className={`c-formEdit ${editingPpict ? 'editing--' : ''}`}
                    onSubmit={handleSubmit3(onSubmitProfil)} autoComplete="off">
                    <div className="c-form_avatarContent">
                        <PictoProfil
                            photo={fileUrl}
                            surname={content.display_name}
                            percent={content.profile_completion}
                            color="#5f205d">
                        </PictoProfil>
                        <span style={{ backgroundImage: `url(${fileUrl})` }}></span>
                    </div>
                    <div className="c-form_avatarButton">
                        <button type="button" className="c-button" onClick={handleClickAvatar}>Modifier ma photo
                        </button>
                        <input name="lba_profilepicture" type="file" id="btnFile" onChange={handleChange}
                            accept="image/png, image/jpeg" ref={register3()} filename={file} />
                        <button type="submit" className="c-button">Valider</button>
                    </div>
                </form>
            </div>
        );
    };

    const ItemAccordion = (data) => {
        const refItem = useRef(null)

        // Calcul du nombre de questions répondues
        const answeredCount = data.questions ? data.questions.reduce((count, question) => {
            return count + (content[question.id] ? 1 : 0);
        }, 0) : 0;

        const totalQuestions = data.questions ? data.questions.length : 0;

        return (
            <div id={data.title.replace(/ /g, '').toLowerCase()} className="c-formAccordion_item">
                <div>
                    <div className="c-formAccordion_title">
                        <h3>{data.title}</h3>
                        <p>{`(${answeredCount}/${totalQuestions} question${totalQuestions > 1 ? 's' : ''})`}</p>
                    </div>
                    <div className={`c-formAccordion_container open--`} ref={refItem}>
                        <div className="c-formAccordion_content">
                            {data.questions && data.questions.map((elt, index) =>
                                <ContentAccordion {...elt} key={index} />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const ContentAccordion = ({ ...data }) => {
        return (
            <div className="c-form_line">
                <div className="c-form_lineEltLarge">
                    <label>{data.libelle}</label>
                    <div className={`c-form_radio2 ${data.type && data.type}`}>
                        {data.answer && Object.keys(data.answer).map((key) =>
                            <div className="c-form_radio2Item" key={key} id={key}>
                                {
                                    <input type="radio" name={data.id}
                                        value={key}
                                        className={`selected-${content[data.id]}`}
                                        id={data.id + '-' + key}
                                        {...(content[data.id] == key && { defaultChecked: true })} //eslint-disable-line
                                        ref={register1()}
                                    />
                                }
                                <label htmlFor={data.id + '-' + key}>{data.answer[key]}</label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    };

    // Suppression de compte - Open modal
    const deleteAccount = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'deleteAccount': true }));
    };

    // Loader pendant l'editing
    const [changingDatas, setChangingDatas] = useState(false);
    const [scrollOnLoad, setScrollOnLoad] = useState(true);

    const renderPage = () => {
        const { metatag } = dataRedux;
        // Scoll to anchor
        if (hash && scrollOnLoad) {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const yOffset = -document.querySelector('.c-header').offsetHeight;
                const element = document.getElementById(id);
                if (element && yOffset) {
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
                setScrollOnLoad(false);
            }, 0);
        }

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'Mon compte',
            category2: 'Gérer mon profil',
            category3: 'Modifier mes données',
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}

                <div className="on-top--">
                    <Logout />
                </div>

                <GoBack />

                <div className="container">
                    {changingDatas &&
                        <div className="main-loader">
                            <Loader />
                        </div>
                    }
                    <div className="t-accountForm_form c-form">

                        <FileImporter />

                        <h2 className="title-1 lowercase--">Mes identifiants</h2>
                        <div className="c-form_line">
                            <div className="c-form_lineElt">
                                <form onSubmit={handleSubmit1(onSubmit)} data-form="formEdit4"
                                    autoComplete="off">
                                    <label>Email <sup>*</sup></label>
                                    <input type="email" defaultValue={content.lba_email ? content.lba_email : ''}
                                        placeholder="Ex. mariedupont@gmail.com" name="lba_email"
                                        id="lba_email"
                                        ref={register1({
                                            required: true,
                                            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                        })}
                                        data-error={(errors1.lba_email || existingEmail) ? true : false}
                                        onChange={handleRemoveErrors}
                                    />
                                    <div className="error--">{errors1.lba_email && "Le champs doit être renseigné."}</div>
                                    <div className="error--">{existingEmail && "Cet e-mail existe déjà ou est invalide."}</div>
                                </form>
                            </div>
                            <div className="c-form_lineElt">
                                <form onSubmit={handleSubmit1(onSubmit)} data-form="formEdit4"
                                    autoComplete="off">
                                    <label htmlFor="lba_lbalogin">Pseudo <sup>*</sup>
                                    </label>
                                    <input type="text" defaultValue={content.lba_lbalogin ? content.lba_lbalogin : ''}
                                        placeholder=""
                                        name="lba_lbalogin"
                                        id="lba_lbalogin"
                                        ref={register1({
                                            required: true,
                                            minLength: {
                                                value: 5,
                                                message: 'Votre pseudo doit contenir au moins 5 caractères'
                                            },
                                            pattern: {
                                                value: /^[^@]*[^\.]*$/i,
                                                message: "Votre pseudo ne doit pas être une adresse email"
                                            }
                                        })}
                                        data-error={errors1.lba_lbalogin || existingName ? true : false}
                                    />
                                    <div className="error--">{errors1.lba_lbalogin && errors1.lba_lbalogin.message}</div>
                                    <div className="error--">{existingName && "Ce nom d'utilisateur existe déjà."}</div>
                                </form>
                            </div>
                        </div>
                        
                        <form className="c-formEdit editing--" onSubmit={handleSubmit1(onSubmit)} autoComplete="off">
                            <div className="c-form_line">
                                <div className="c-form_lineElt">
                                    <label>Mot de passe<span className="light">&nbsp;actuel</span><sup>*</sup></label>
                                    <div className="c-form_info">
                                        <input type={revealOldPassword ? 'text' : "password"} placeholder="********"
                                            name="current_password"
                                            id="current_password"
                                            ref={register1({ required: false })}
                                            className="has-tooltip"
                                            data-error={(errors1.current_password || invalidCurrentPwd) ? true : false}
                                        />
                                        <div className="c-form_tooltip" ref={tooltipHelpRef}>
                                            <i className={revealOldPassword ? 'icon-eye-no light' : "icon-eye light"}
                                                data-type="current_password"
                                                onClick={handleClickPassword}></i>
                                            <i className="icon-what light"
                                                onMouseOver={handleMouseTooltip}
                                                onMouseOut={handleMouseTooltip}
                                                data-content="Votre mot de passe doit faire 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre"
                                                data-type="help"
                                                data-typeref="tooltipHelpRef"
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="error--">
                                        {errors1.current_password && "Le champs doit être renseigné."}
                                        {invalidCurrentPwd && "Votre mot de passe actuel est incorrect."}
                                    </div>
                                </div>
                            </div>
                            <div className="c-formEdit_more">
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label>Nouveau mot de passe <sup>*</sup></label>
                                        <div className="c-form_info">
                                            <input type={revealPassword ? 'text' : "password"}
                                                placeholder="Mot de passe*" name="lba_lbapassword"
                                                id="lba_lbapassword"
                                                ref={register1({
                                                    required: false,
                                                    minLength: {
                                                        value: 8,
                                                        message: 'Votre mot de passe doit contenir au moins 8 caractères'
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                                                        message: 'Votre mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre',
                                                    }
                                                })}
                                                className="has-tooltip"
                                                data-error={(errors1.lba_lbapassword) ? true : false}
                                                onChange={(e) => handlePwdChange(e)} />

                                            <div className="c-form_tooltip" ref={tooltipHelp1Ref}>
                                                <i className={revealPassword ? 'icon-eye-no' : "icon-eye"}
                                                    data-type="lba_lbapassword"
                                                    onClick={handleClickPassword}></i>
                                                <i className="icon-what"
                                                    onMouseOver={handleMouseTooltip}
                                                    onMouseOut={handleMouseTooltip}
                                                    data-content="Votre mot de passe doit faire 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre"
                                                    data-type="help"
                                                    data-typeref="tooltipHelp1Ref"
                                                ></i>
                                            </div>
                                        </div>
                                        <div className={`c-form_password ${password.indicator ? 'level' + password.indicator + '--' : null} `}>
                                            {
                                                errors1.lba_lbapassword || password.indicator ?
                                                <div className="progressBar"><span>{password.value}</span></div> :
                                                null
                                            }
                                        </div>
                                        {errors1.lba_lbapassword && (
                                            <div className="error--">{errors1.lba_lbapassword.message}</div>
                                        )}
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label>Confirmation du mot de passe <sup>*</sup></label>
                                        <div className="c-form_info">
                                            <input type={revealPasswordConfirm ? 'text' : "password"}
                                                placeholder="Mot de passe*" name="lba_lbapassword_confirm"
                                                id="lba_lbapassword_confirm"
                                                ref={register1({
                                                    validate: value =>
                                                        value === newPassword.current
                                                })}
                                                className="has-tooltip"
                                                data-error={errors1.lba_lbapassword_confirm ? true : false} />
                                            <div className="c-form_tooltip" ref={tooltipHelp2Ref}>
                                                <i className={revealPasswordConfirm ? 'icon-eye-no' : "icon-eye"}
                                                    data-type="lba_lbapassword_confirm"
                                                    onClick={handleClickPassword}></i>
                                                <i className="icon-what"
                                                    onMouseOver={handleMouseTooltip}
                                                    onMouseOut={handleMouseTooltip}
                                                    data-content="Votre mot de passe doit faire 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre"
                                                    data-type="help"
                                                    data-typeref="tooltipHelp2Ref"
                                                ></i>
                                            </div>
                                        </div>
                                        <div className="error--">{errors1.lba_lbapassword_confirm && "Les nouveaux mots de passe ne correspondent pas"}</div>
                                    </div>
                                    {showTooltipInfo.show && <Tooltip type={showTooltipInfo.type}
                                        target={showTooltipInfo.ref}>{showTooltipInfo.content}</Tooltip>}
                                </div>
                            </div>
                        </form>

                        <form onSubmit={handleSubmit1(onSubmit)} data-form="formEdit4" autoComplete="off">
                            <button type="submit" className="c-button save--"><span>Enregistrer</span></button>
                            <div id="coordonnees" onSubmit={handleSubmit1(onSubmit)} data-form="formEdit4"
                                autoComplete="off">
                                <h2 className="title-1 lowercase--">Coordonnées</h2>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label>Civilité <sup>*</sup></label>
                                        <div className="c-form_radio">
                                            <div className="c-form_radioItem">
                                                <input type="radio" name="lba_civilite"
                                                    value="2"
                                                    id="lba_civiliteWoman"
                                                    {...(content.lba_civilite === 2 && { defaultChecked: true })}
                                                    ref={register1({
                                                        required: true
                                                    })}
                                                />
                                                <label htmlFor="lba_civiliteWoman">Madame</label>
                                            </div>
                                            <div className="c-form_radioItem">
                                                <input type="radio" name="lba_civilite"
                                                    id="lba_civiliteMen"
                                                    value="1"
                                                    {...(content.lba_civilite === 1 && { defaultChecked: true })}
                                                    ref={register1({
                                                        required: true
                                                    })}
                                                /><label htmlFor="lba_civiliteMen">Monsieur</label>
                                            </div>
                                        </div>
                                        <div className="error--">{errors1.lba_civilite && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_fname">Prénom <sup>*</sup></label>
                                        <input type="text" placeholder="Marie"
                                            defaultValue={content.lba_fname && content.lba_fname} name="lba_fname"
                                            id="lba_fname"
                                            ref={register1({
                                                required: 'Le champs doit être renseigné.',
                                                minLength: {
                                                    value: 1,
                                                    message: 'Votre prénom doit contenir au moins 1 caractère'
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: 'Votre prénom doit contenir au maximum 50 caractères'
                                                },
                                                pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                                            })}
                                            data-error={!!errors1.lba_fname}
                                        />
                                        {(errors1.lba_fname && errors1.lba_fname.type === 'pattern') &&
                                            <div className="error--">Votre nom doit être valide.</div>}
                                        {(errors1.lba_fname && errors1.lba_fname.type === 'required') &&
                                            <div className="error--">Le champs doit être renseigné.</div>}
                                        {(errors1.lba_fname && errors1.lba_fname.type === 'minLength') &&
                                            <div className="error--">Votre nom doit contenir au moins 1 caractère.</div>}
                                        {(errors1.lba_fname && errors1.lba_fname.type === 'maxLength') &&
                                            <div className="error--">Votre nom doit contenir au maximum 50 caractères.</div>}
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_lname">Nom <sup>*</sup></label>
                                        <input type="text" defaultValue={content.lba_lname && content.lba_lname}
                                            placeholder="Dupont" name="lba_lname"
                                            id="lba_lname"
                                            ref={register1({
                                                required: 'Le champs doit être renseigné.',
                                                minLength: {
                                                    value: 1,
                                                    message: 'Votre nom doit contenir au minimum 1 caractère'
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: 'Votre nom doit contenir au maximum 50 caractères'
                                                },
                                                pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                                            })}
                                            data-error={!!errors1.lba_lname}
                                        />
                                        {(errors1.lba_lname && errors1.lba_lname.type === 'pattern') &&
                                            <div className="error--">Votre nom doit être valide.</div>}
                                        {(errors1.lba_lname && errors1.lba_lname.type === 'required') &&
                                            <div className="error--">Le champs doit être renseigné.</div>}
                                        {(errors1.lba_lname && errors1.lba_lname.type === 'minLength') &&
                                            <div className="error--">Votre nom doit contenir au moins 1 caractère.</div>}
                                        {(errors1.lba_lname && errors1.lba_lname.type === 'maxLength') &&
                                            <div className="error--">Votre nom doit contenir au maximum 50 caractères.</div>}
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineElt" ref={tooltipContainer}>
                                        <label htmlFor="lba_address">Adresse <sup>*</sup></label>
                                        <input type="text" name="lba_address"
                                            id="lba_address"
                                            ref={register1({
                                                required: true,
                                                pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                                            })}
                                            value={adresseAutocomplete.update ? adresseAutocomplete.name : content.lba_address}
                                            data-error={errors1.lba_address}
                                            autoComplete="off"
                                            onChange={handleChangeAdresse}
                                            onBlur={handleLeaveAddressInput}
                                        />
                                        {showTooltip.show && <Tooltip type="autocomplete"
                                            target={tooltipContainer.current}>{showTooltip.value}</Tooltip>}
                                        <div
                                            className="error--">{errors1.lba_address && "Le champs doit être renseigné."}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_address2">Complément d’adresse</label>
                                        <input type="text" placeholder="Complément d'adresse"
                                            defaultValue={content.lba_address2 ? content.lba_address2 : ''}
                                            name="lba_address2"
                                            id="lba_address2"
                                            ref={register1({
                                                pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                                            })}
                                        />
                                        <div className="error--">{errors1.lba_address2 && "Le champs doit avoir des lettres et des chiffres"}</div>
                                    </div>
                                </div>

                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_zip">Code postal <sup>*</sup></label>
                                        <input type="text" name="lba_zip"
                                            id="lba_zip"
                                            ref={register1({
                                                required: true,
                                                pattern: /^(([0-8][0-9])|(9[0-9]))[0-9]{3}$/
                                            })}
                                            value={adresseAutocomplete.update ? adresseAutocomplete.postcode : (content.lba_zip && content.lba_zip)}
                                            onChange={e => setAdresseAutocomplete({
                                                update: true,
                                                postcode: e.target.value
                                            })}
                                            data-error={errors1.lba_zip ? true : false}
                                        />
                                        <div className="error--">{errors1.lba_zip && "Le champs doit être renseigné."}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_city">Ville <sup>*</sup></label>
                                        <input type="text" placeholder="Ex. Paris" name="lba_city"
                                            id="lba_city"
                                            ref={register1({
                                                required: true,
                                                pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                                            })}
                                            value={adresseAutocomplete.update ? adresseAutocomplete.city : (content.lba_city && content.lba_city)}
                                            data-error={errors1.lba_city ? true : false}
                                            onChange={e => setAdresseAutocomplete({ update: true, city: e.target.value })}
                                        />
                                        <div className="error--">{errors1.lba_city && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>

                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_countrycode"
                                            data-country={labelCountry(content.lba_countrycode)}>Pays <sup>*</sup></label>
                                        <Controller
                                            id="lba_countrycode"
                                            name="lba_countrycode"
                                            placeholder={content.lba_countrycode ? labelCountry(content.lba_countrycode) : 'Pays'}
                                            as={Select}
                                            className="c-form_select lba_countrycode"
                                            classNamePrefix="c-form_select"
                                            options={options}
                                            control={control1}
                                            defaultValue={labelCountry(content.lba_countrycode)}
                                            ref={register1({
                                                required: true
                                            })}
                                        />
                                        <div
                                            className="error--">{errors1.lba_countrycode && "Le champs doit être renseigné."}</div>
                                    </div>
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_mobilephone">Mobile</label>
                                        <input type="tel" placeholder="Ex. 0651286694"
                                            defaultValue={content.lba_mobilephone && content.lba_mobilephone}
                                            name="lba_mobilephone"
                                            id="lba_mobilephone"
                                            ref={register1({
                                                required: false,
                                                minLength: 10,
                                                maxLength: 10,
                                                pattern: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
                                            })}
                                            data-error={errors1.lba_mobilephone ? true : false}
                                            maxLength="10"
                                        />
                                        {(errors1.lba_mobilephone && (errors1.lba_mobilephone.type == 'pattern' || errors1.lba_mobilephone.type == 'minLength' || errors1.lba_mobilephone.type == 'maxLength')) &&    // eslint-disable-line
                                            <div
                                                className="error--">Votre numéro doit être valide et comporter 10 chiffres.</div>}
                                        {(errors1.lba_mobilephone && errors1.lba_mobilephone.type === 'required') &&
                                            <div className="error--">Le champs doit être renseigné.</div>}
                                    </div>
                                </div>

                                <div className="c-form_line">
                                    <div className="c-form_lineElt">
                                        <label htmlFor="lba_birth">Date de naissance <sup>*</sup></label>
                                        <input type="text" placeholder="JJ/MM/AAAA"
                                            defaultValue={(content.lba_birthday && content.lba_birthmonth && content.lba_birthyear) ? (content.lba_birthday < 10 ? '0' + content.lba_birthday : content.lba_birthday) + '/' + (content.lba_birthmonth < 10 ? '0' + content.lba_birthmonth : content.lba_birthmonth) + '/' + content.lba_birthyear : ''}
                                            name="lba_birth"
                                            id="lba_birth"
                                            ref={register1({
                                                required: true,
                                                maxLength: 10,
                                                pattern: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
                                            })}
                                            data-error={(errors1.lba_birth || birthError.message) ? true : false}
                                            onKeyUp={handleBirth}
                                            maxLength="10"
                                        />
                                        {(errors1.lba_birth && (errors1.lba_birth.type === 'pattern' || errors1.lba_birth.type === 'maxLength')) &&
                                            <div className="error--">Votre date de naissance doit respecter format suivant : JJ/MM/AAAA.</div>}
                                        {(errors1.lba_birth && errors1.lba_birth.type === 'required') &&
                                            <div className="error--">Le champs doit être renseigné.</div>}
                                        {birthError &&
                                            <div className="error--">{birthError.message}</div>}
                                    </div>
                                </div>
                            </div>
                            <div id="communication" data-form="formEdit4">
                                <h2 className="title-1 lowercase--">Mes avantages</h2>
                                <div className="c-form_line">
                                    <div className="c-form_lineEltLarge">
                                        <div className="c-form_optin c-form_icon">
                                            <label
                                                className="email--"><span>J’accepte de recevoir par email les  newsletters de La Belle Adresse (réductions, DIY, astuces, idées, innovations, ...).*</span></label>
                                            <div className="c-form_radio">
                                                <div className="c-form_radioItem">
                                                    <input type="radio" name="lba_optinemail"
                                                        value="1"
                                                        id="registerOptinEmailYes"
                                                        {...(content.lba_optinemail !== undefined && { defaultChecked: content.lba_optinemail })}
                                                        ref={register1({
                                                            required: true
                                                        })}
                                                    />
                                                    <label htmlFor="registerOptinEmailYes">Oui</label>
                                                </div>
                                                <div className="c-form_radioItem"><input type="radio" name="lba_optinemail"
                                                    id="registerOptinEmailNo"
                                                    value="0"
                                                    {...(content.lba_optinemail !== undefined && { defaultChecked: !content.lba_optinemail })}
                                                    ref={register1({
                                                        required: true
                                                    })}
                                                /><label htmlFor="registerOptinEmailNo">Non</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="error--">{errors1.lba_optinemail && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineEltLarge">
                                        <div className="c-form_optin c-form_icon">
                                            <label
                                                className="mail--"><span>J’accepte de recevoir par courrier les messages de La Belle Adresse.*</span></label>
                                            <div className="c-form_radio">
                                                <div className="c-form_radioItem">
                                                    <input type="radio" name="lba_optincourrier"
                                                        value="1"
                                                        id="registerOptinMailYes"
                                                        {...(content.lba_optincourrier !== undefined && { defaultChecked: content.lba_optincourrier })}
                                                        ref={register1({
                                                            required: true
                                                        })}
                                                    />
                                                    <label htmlFor="registerOptinMailYes">Oui</label>
                                                </div>
                                                <div className="c-form_radioItem"><input type="radio"
                                                    name="lba_optincourrier"
                                                    id="registerOptinMailNo"
                                                    value="0"
                                                    {...(content.lba_optincourrier !== undefined && { defaultChecked: !content.lba_optincourrier })}
                                                    ref={register1({
                                                        required: true
                                                    })}
                                                />
                                                    <label htmlFor="registerOptinMailNo">Non</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="error--">{errors1.lba_optincourrier && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>
                                <div className="c-form_line">
                                    <div className="c-form_lineEltLarge">
                                        <div className="c-form_optin c-form_icon">
                                            <label
                                                className="sms--"><span>J’accepte de recevoir par SMS les  informations de La Belle Adresse (promotions, innovation, information,...).*</span></label>
                                            <div className="c-form_radio">
                                                <div className="c-form_radioItem">
                                                    <input type="radio" name="lba_optinsms"
                                                        value="1"
                                                        id="lba_optinsmsYes"
                                                        {...(content.lba_optinsms !== undefined && { defaultChecked: content.lba_optinsms })}
                                                        ref={register1({
                                                            required: true
                                                        })}
                                                    />
                                                    <label htmlFor="lba_optinsmsYes">Oui</label>
                                                </div>
                                                <div className="c-form_radioItem"><input type="radio" name="lba_optinsms"
                                                    id="lba_optinsmsNo"
                                                    value="0"
                                                    {...(content.lba_optinsms !== undefined && { defaultChecked: !content.lba_optinsms })}
                                                    ref={register1({
                                                        required: true
                                                    })}
                                                />
                                                    <label htmlFor="lba_optinsmsNo">Non</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="error--">{errors1.lba_optinsms && "Le champs doit être renseigné."}</div>
                                    </div>
                                </div>
                            </div>
                            <div id="more-info-profil">
                                <h2 className="title-1 lowercase--">Parlez nous de vous</h2>
                                <div className="c-formAccordion">
                                    {forms && forms.map((elt, index) => <ItemAccordion {...elt} value={content}
                                        key={index} />)}
                                </div>
                            </div>
                        </form>
                        <div>
                            <CLink url={accountInformationsPath} title="Toutes mes données" className="btn-4">
                                Accéder à toutes mes données
                            </CLink>
                        </div>
                        <div className="t-accountForm_delete-section text-center">
                            <button type="button" className="btn-2 t-accountForm_delete-btn"
                                onClick={deleteAccount}>Supprimer le compte
                            </button>
                        </div>
                    </div>
                </div>
                <DeleteAccountModal />
            </>
        )
    };
    return (isLoadedUser && dataRedux) ? renderPage() : <Loader />
};

export default TemplateHome;