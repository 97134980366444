import React, {useState} from 'react';

//Hooks
import useTracking from '../Hooks/useTracking';

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import {getImage} from '../Utils/getMedia';

// Components
import CLink from "./cLinks";

// Utils
import {randomString} from "../Utils/md5-hash";


SwiperCore.use([Navigation, EffectFade, Autoplay]);


const CarouselActu = ({carousel, desktop}) => {
    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const selectContent = (contentType, contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: contentType,
                content_name: contentName
            },
            refresh: refresh
        });
    };
    
    return (
        <>
            {carousel.length > 0 &&
            <div className="c-carouselActus">
                <Swiper className="c-carouselActus_items"
                        navigation={true}
                        slidesPerView="auto"
                        spaceBetween={desktop ? 20 : 10}
                        watchSlidesVisibility={true}
                        allowTouchMove={((desktop && carousel.length > 2) || (!desktop && carousel.length > 1)) ? true : false}
                        loop={((desktop && carousel.length > 2) || (!desktop && carousel.length > 1)) ? true : false}
                        pagination={((desktop && carousel.length > 2) || (!desktop && carousel.length > 1)) ? {clickable: true} : false}
                >
                    {
                        carousel.map(({fieldCommonTxtTitle, fieldCommonPgphBan, title, path, drupalInternalNid, fieldCommonTrUniverses, fieldLandingImgMobThumbnail, fieldLandingImgDeskThumbnail}, key) => {
                            let image = fieldLandingImgDeskThumbnail?.links?.commonCarrouselDesktop.href
                            if(!desktop && fieldLandingImgMobThumbnail)image = fieldLandingImgMobThumbnail?.links?.commonCarrouselDesktop?.href
                            else if(!desktop) image  = fieldLandingImgDeskThumbnail?.links?.commonCarrouselMobile?.href
                            return (
                                <SwiperSlide key={key} className="c-carouselActus_item">
                                    <CLink url={path.alias ? path.alias : '/node/' + drupalInternalNid}
                                           title={fieldCommonTxtTitle}
                                           handleClick={() => selectContent(((fieldCommonTrUniverses && fieldCommonTrUniverses[0].name) ? fieldCommonTrUniverses[0].name : 'undefined'), fieldCommonTxtTitle || title, randomString(6))}>
                                        {image && <img src={image} className="c-carouselActus_visuel" alt={fieldCommonTxtTitle || title}/>}
                                        {!image && fieldCommonPgphBan  && <img src={getImage(fieldCommonPgphBan, desktop)} className="c-carouselActus_visuel" alt={fieldCommonTxtTitle || title}/>}
                                        <div className="c-carouselActus_container">
                                            <div className="c-carouselActus_content">
                                                {(fieldCommonTxtTitle || title) &&
                                                <h3>{fieldCommonTxtTitle || title}</h3>}
                                            </div>
                                        </div>
                                    </CLink>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            }
        </>
    )
};

export default CarouselActu;