import React, { useEffect, useState } from "react";
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import SearchResults from "../Components/searchResults";
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from "../Components/loader";

// Utils
// import {randomString} from "../Utils/md5-hash";

const TemplateSearchPage = ({ _uid }) => {

    // Get search value in url
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get('s');

    const [pageLanding, setPageLanding] = useState(0);
    const [pageEconomy, setPageEconomy] = useState(0);
    const [pageTips, setPageTips] = useState(0);
    const [pageBrands, setPageBrands] = useState(0);
    const [pageCommunity, setPageCommunity] = useState(0);

    // Infos sur la page
    const [dataRedux, setDataRedux] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));

            // console.log('search dataFetch', normalizeJson)
        }
    }, [isLoaded, dataFetch]); //eslint-disable-line


    //-- LANDING
    const [landingRedux, setLandingRedux] = useState([]);
    const [landingTotal, setLandingTotal] = useState([]);
    //Requete de recherche sur les pages Economies
    const [dataFetchLanding, isLoadedLanding] = useApi({
        name: 'api/search/event-pages',
        fields: 'keys=' + searchValue + '&page=' + pageLanding,
        method: 'get',
    });

    useEffect(() => {
        if (isLoadedLanding && dataFetchLanding) {
            let results = dataFetchLanding.data.results_nb === 0 ? [] : dataFetchLanding.data.results;

            setLandingRedux([...landingRedux, ...results]);
            setLandingTotal(dataFetchLanding.data.results_nb);

            // console.log('dataFetchLanding ', dataFetchLanding);
        }
    }, [isLoadedLanding, dataFetchLanding]); //eslint-disable-line


    //-- ECONOMY
    const [economyRedux, setEconomyRedux] = useState([]);
    const [economyTotal, setEconomyTotal] = useState([]);
    //Requete de recherche sur les pages Economies
    const [dataFetchEconomy, isLoadedEconomy] = useApi({
        name: 'api/search/economies',
        fields: 'keys=' + searchValue + '&fields[node--special]=title,field_special_list_pagetype,field_special_configformref&page=' + pageEconomy,
        method: 'get',
    });

    useEffect(() => {
        if (isLoadedEconomy && dataFetchEconomy) {
            let results = dataFetchEconomy.data.results_nb === 0 ? [] : dataFetchEconomy.data.results;
            setEconomyRedux([...economyRedux, ...results]);
            setEconomyTotal(dataFetchEconomy.data.results_nb);

            // console.log('dataFetchEconomy ', dataFetchEconomy);
        }
    }, [isLoadedEconomy, dataFetchEconomy]); //eslint-disable-line


    //-- TIPS AND TRICKS
    const [tipsRedux, setTipsRedux] = useState([]);
    const [tipsTotal, setTipsTotal] = useState(0);
    //Requete de recherche sur les pages Conseils Astuces
    const [dataFetchTips, isLoadedTips] = useApi({
        name: 'api/search/tips-and-tricks',
        fields: 'keys=' + searchValue + '&fields[node--article_video]=title,field_article_txt_intro,field_common_txt_title&fields[node--article]=title,field_article_txt_intro,field_common_txt_title&fields[node--diy]=title,field_diy_txt_intro,field_common_txt_title&page=' + pageTips,
        method: 'get',
    });

    useEffect(() => {
        if (isLoadedTips && dataFetchTips) {
            let results = dataFetchTips.data.results_nb === 0 ? [] : dataFetchTips.data.results;
            setTipsRedux([...tipsRedux, ...results]);
            setTipsTotal(dataFetchTips.data.results_nb);

            // console.log('dataFetchTips ', dataFetchTips);
        }
    }, [isLoadedTips, dataFetchTips]); //eslint-disable-line


    //-- BRANDS
    const [brandsRedux, setBrandsRedux] = useState([]);
    const [brandsTotal, setBrandsTotal] = useState([]);
    //Requete de recherche sur les pages Marques
    const [dataFetchBrands, isLoadedBrands] = useApi({
        name: 'api/search/brands',
        fields: 'keys=' + searchValue + '&fields[node--brand_page]=title,field_brand_txt_introbody&fields[node--product]=title,field_product_txt_introbody&page=' + pageBrands,
        method: 'get',
    });

    useEffect(() => {
        if (isLoadedBrands && dataFetchBrands) {
            let results = dataFetchBrands.data.results_nb === 0 ? [] : dataFetchBrands.data.results;
            setBrandsRedux([...brandsRedux, ...results]);
            setBrandsTotal(dataFetchBrands.data.results_nb);

            // console.log('dataFetchBrands ', dataFetchBrands);
        }
    }, [isLoadedBrands, dataFetchBrands]); //eslint-disable-line


    //-- COMMUNITY  --- à décommenter quand la partie Communauté sera développée
    const [communityRedux, setCommunityRedux] = useState([]); //eslint-disable-line
    const [communityTotal, setCommunityTotal] = useState([]); //eslint-disable-line
    // //Requete de recherche sur les pages Communauté
    const [dataFetchCommunity, isLoadedCommunity] = useApi({
        name: 'api/search/community',
        fields: 'keys=' + searchValue + '&fields[node--question]=title,field_common_txt_body&fields[node--tip]=title,field_common_txt_body&page=' + pageCommunity,
        method: 'get',
    });

    useEffect(() => {
        if (isLoadedCommunity && dataFetchCommunity) {
            let results = dataFetchCommunity.data.results_nb === 0 ? [] : dataFetchCommunity.data.results;
            setCommunityRedux([...communityRedux, ...results]);
            setCommunityTotal(dataFetchCommunity.data.results_nb);
        }
    }, [isLoadedCommunity, dataFetchCommunity]); //eslint-disable-line


    // TRACKING
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); //eslint-disable-line

    useEffect(() => {
        const totalResults = parseInt(+landingTotal + +economyTotal + +tipsTotal + +brandsTotal + +communityTotal);

        // If results
        if (totalResults !== 0) {
            // Tracking
            setTrackingEvent({
                event: 'view_search_results',
                args: {
                    search_type: 'Global',
                    search_term: searchValue,
                },
                // refresh: randomString(6)
            });
        }

    }, [landingRedux, economyRedux, tipsRedux, brandsRedux, communityRedux, searchValue]); //eslint-disable-line


    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-search'));
    }, [dispatch]);


    const renderPage = () => {

        const metatag = dataRedux ? dataRedux.metatag : null;
        const totalResults = parseInt(+landingTotal + +economyTotal + +tipsTotal + +brandsTotal + +communityTotal);

        return (
            <>
                {metatag && <Metatags listMeta={metatag} />}

                <div className="t-search_count">
                    <div className="container">
                        {(totalResults === 0) ?
                            <p className="text-center">Il n'y a pas de résultat de recherche pour <strong>"{searchValue}"</strong>
                            </p>
                            :
                            <h1><strong>{totalResults} résultats</strong><br />correspondent à votre recherche</h1>
                        }
                    </div>
                </div>

                <div className="t-search_results">
                    <div className="container">

                        {economyRedux && economyRedux.length > 0 &&
                            <div className="t-search_category economy--">
                                <h2>Économies</h2>
                                <SearchResults data={economyRedux} total={economyTotal} page={pageEconomy} setPage={setPageEconomy} />
                            </div>
                        }

                        {tipsRedux && tipsRedux.length > 0 &&
                            <div className="t-search_category tips--">
                                <h2>Conseils et astuces</h2>
                                <SearchResults data={tipsRedux} total={tipsTotal} page={pageTips} setPage={setPageTips} />
                            </div>
                        }

                        {landingRedux && landingRedux.length > 0 &&
                            <div className="t-search_category landing--">
                                <h2>Actualités et jeux</h2>
                                <SearchResults data={landingRedux} total={landingTotal} page={pageLanding} setPage={setPageLanding} />
                            </div>
                        }

                        {brandsRedux && brandsRedux.length > 0 &&
                            <div className="t-search_category brands--">
                                <h2>À vos marques</h2>
                                <SearchResults data={brandsRedux} total={brandsTotal} page={pageBrands}
                                    setPage={setPageBrands} />
                            </div>
                        }

                        {communityRedux && communityRedux.length > 0 &&
                            <div className="t-search_category community--">
                                <h2>Communauté</h2>
                                <SearchResults data={communityRedux} total={communityTotal} page={pageCommunity}
                                    setPage={setPageCommunity} />
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
    return ((pageLanding > 0 || pageEconomy > 0 || pageTips > 0 || pageBrands > 0 || pageCommunity > 0) || (isLoaded && isLoadedLanding && isLoadedEconomy && isLoadedTips && isLoadedBrands)) ? renderPage() :
        <Loader />
}

export default TemplateSearchPage;