import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';

//Hooks
import useTracking from '../Hooks/useTracking';
import useElementOnScreen from '../Hooks/useElementOnScreen';

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, Pagination, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {updateModalsOpen, updateModalsLoginType} from '../Store/action';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

// Components
import CLink from "./cLinks";

// Utils
import {randomString} from "../Utils/md5-hash";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);


const ButtonPopinCashback = ({title, color}) => {
    const modalsOpen = useSelector(state => state.modals).openModal;
    const dispatch = useDispatch();

    const handleOpen = () => {
        dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'password': false, 'economy': true}));
        dispatch(updateModalsLoginType('login'));
    };

    return (
        <button
            title={title}
            dangerouslySetInnerHTML={{ __html: title }}
            className={`c-sliderLarge_button ${color && color + '--'}`}
            onClick={handleOpen}></button>
    );
};


const SliderLarge = ({carousel, images, desktop, name = "", isActiveTab = false}) => {
    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    const [carouselVisible, setCarouselVisible] = useState([]);
    useEffect(() => {
        if (carousel && carousel.length > 0)
            setCarouselVisible(carousel.sort((a, b) => a.weight - b.weight).filter(item => item.disabled === false))
    }, [carousel]);


    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const selectContent = (universeIndex, contentName, refresh) => {
        let contentType = '';

        // Get universe
        if (universeIndex === 1) contentType = 'Linge';
        if (universeIndex === 5) contentType = 'Beauté';
        if (universeIndex === 2) contentType = 'Maison';

        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: contentType,
                content_name: contentName
            },
            refresh: refresh
        });
    };


    // TRACKING - View content (Slider)
    const ref = useRef(null);
    const inViewport = useElementOnScreen({
        root: null,
        rootMargin: '0px',
        threshold: 0.3
    }, ref);
    const [onScreen, setOnScreen] = useState(inViewport);

    const viewContentSlide = (item, refresh) => {
        let contentType = '';
        let contentName = item.title;
        // if(name) $content_name = name;

        // Get universe
        const universeIndex = item.universe.fieldApiRemoteidUniverseid;
        // Get universe
        if (universeIndex === 1) contentType = 'Linge';
        if (universeIndex === 5) contentType = 'Beauté';
        if (universeIndex === 2) contentType = 'Maison';

        if (inViewport) {
            setTrackingEvent({
                event: 'view_content',
                args: {
                    content_type: contentType,
                    content_name: contentName
                },
                refresh: refresh
            });
        }
        //  console.log('customDataLayer [on slide] '+$content_name);
     };

    useEffect(() => {
        if (inViewport) {
            setOnScreen(true);

            var activeSlide = document.querySelector('.c-sliderLarge_show .c-sliderLarge .swiper-slide-active');

            // First slide tracking when change tab
            if(onScreen && isActiveTab === 'true' && activeSlide) {
                var activeIndex = activeSlide.getAttribute('data-index');
                viewContentSlide(carouselVisible[activeIndex], randomString(6));
            }
        }
    }, [inViewport, isActiveTab]);


    return (
        <div className="c-sliderLargeWrapper" ref={ref}>
            {carouselVisible && carouselVisible.length > 0 &&
            <div className="c-sliderLarge">
                <Swiper className={`c-sliderLarge_items ${name}`}
                        navigation={true}
                        pagination={{
                            clickable: true,
                            paginationUpdate: true
                        }}
                        autoplay={carouselVisible.length > 1}
                        // loop={carouselVisible.length > 1}
                        effect="fade"
                        onSwiper={e => {
                            if (carouselVisible.length > 1) {
                                if (e.slides[e.activeIndex]) {
                                    e.slides[e.activeIndex].style.opacity = 1
                                }
                            }
                        }}

                        onInit={e => {
                            // First slide tracking
                            if(e.slides[e.activeIndex].closest('.t-economy')) {
                                if(e.slides[e.activeIndex].closest('.c-sliderLarge_show')) {
                                    viewContentSlide(carouselVisible[0], randomString(6));
                                }
                            } else {
                                viewContentSlide(carouselVisible[0], randomString(6));
                            }
                        }}

                        onSlideChange={e => {
                            if (carouselVisible.length > 1) {
                                e.slides.map((item, key) => {
                                    e.slides[key].style.opacity = 0;
                                    return null;
                                });
                                if (e.slides[e.activeIndex]) {
                                    e.slides[e.activeIndex].style.opacity = 1;
                                }

                                // TRACKING view content
                                // Get active slide infos
                                const activeSlide = carouselVisible[e.realIndex];

                                // If not already displayed, call tracking event
                                if(onScreen) {
                                    if(e.slides[e.activeIndex].closest('.t-economy')) {
                                        if(e.slides[e.activeIndex].closest('.c-sliderLarge_show')) {
                                            viewContentSlide(activeSlide, randomString(6));
                                        }
                                    } else {
                                        viewContentSlide(activeSlide, randomString(6));
                                    }
                                }
                            }
                        }}
                >
                    {/* {carousel.length>1 &&
                        <>
                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                        </>
                    } */}
                    {
                        carouselVisible.map(({title, description, imageDesktop, imageMobile, button, universe}, key) => {
                            // console.log(parseInt(images[0].drupalInternalFid));
                            // console.log(imageDesktop.id);
                            const imageUrl = images.find(image => parseInt(image.drupalInternalFid) === imageDesktop.id)?.links?.commonFullWidthBanDesktop?.href;
                            const imageMobileUrl = images.find(image => parseInt(image.drupalInternalFid) === imageMobile.id)?.links?.commonFullWidthBanMobile?.href;
                            return (
                                <SwiperSlide key={key} data-index={key} className="c-sliderLarge_item">
                                    {desktop ?
                                        <div className="c-sliderLarge_visuel"
                                             style={imageUrl ? {backgroundImage: `url(${imageUrl})`} : {}}></div>
                                        :
                                        <div className="c-sliderLarge_visuel c-visuelLarge_visuel--truesize"
                                             style={imageMobileUrl ? {backgroundImage: `url(${imageMobileUrl})`} : {}}></div>
                                    }
                                    <div className="c-sliderLarge_container">
                                        <div className="container">
                                            <div className="c-sliderLarge_content">
                                                {title && <h2 dangerouslySetInnerHTML={renderMarkup(title)}></h2>}
                                                {description &&
                                                <div dangerouslySetInnerHTML={renderMarkup(description)}/>}
                                                {button.linkType === "url" ?
                                                    button.url && button.title ?
                                                        <div className="c-sliderLarge_button-wrapper" data-index={key}>
                                                            <CLink
                                                                url={button.url} target={button.target}
                                                                title={button.title}
                                                                className={`c-sliderLarge_button ${button.color && button.color + '--'}`}
                                                                external={button.target === "_blank"}
                                                                handleClick={() => selectContent(universe.fieldApiRemoteidUniverseid, title, randomString(6))}
                                                            />
                                                        </div>
                                                        : null
                                                    :
                                                    <div className="c-sliderLarge_button-wrapper">
                                                        <ButtonPopinCashback title={button.title} color={button.color}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )

                        })
                    }
                </Swiper>
            </div>
            }
        </div>
    )
};

export default SliderLarge;