class chatBoxManager {
    static getBody() {
        this.body = this.body || document.querySelector("body");
        return this.body;
    }

    static loadScript() {
        this.scriptLoaded = true;

        const supportsSpread = () => {
            try {
                new Function('let a = {a: "a", b: "b", c:"c"};let c = {a:"A", ...a}')
                return true;
            } catch (e) {
                return false;
            }
        }

        const loadScript = (url) => {
            let scriptElt = document.createElement("script")
            scriptElt.type = "text/javascript";
            scriptElt.src = url
            scriptElt.async = false;
            document.querySelector('body').appendChild(scriptElt);
        }

        const loadStyle = (url) => {
            let cssElt = document.createElement("link");
            cssElt.rel = 'stylesheet';
            cssElt.type = 'text/css';
            cssElt.href = url;
            document.querySelector('header').appendChild(cssElt);
        }

        if (supportsSpread()) {
            console.log("%cindex.html -> 101 : spead operator supported", 'background: #cddc39; color:#FFFFFF',)
            loadScript("https://henkel-cognigy.s3.eu-central-1.amazonaws.com/lp/fr/atc_fr/src/webchat.js")
            loadScript("https://henkel-cognigy.s3.eu-central-1.amazonaws.com/lp/fr/atc_fr/src/atc_webchat.js")
            loadStyle("https://henkel-cognigy.s3.eu-central-1.amazonaws.com/lp/fr/atc_fr/src/atc_webchat.css")
        } else {
            console.log("%cindex.html -> 101 : spead operator unsupported", 'background: #f44336; color:#FFFFFF',)
        }
    }

    static check(tags, globalTags) {
        if(tags){
            tags.forEach(tag => {
                if(globalTags.indexOf(tag.id) > -1){

                    if(!this.scriptLoaded){
                        this.loadScript();
                    }

                    this.getBody().classList.add("withChatBox")
                }
            })
        }
    }

    static reset() {
        this.getBody().classList.remove("withChatBox");
    }
}

export default chatBoxManager;