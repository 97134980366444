import React, { useEffect, useState } from "react";

const BtnScrollToTop = () => {

    const [isTop, setTop] = useState(1);

    useEffect(() => {
        const checkScroll = () => {
            if (window.scrollY === 0 && isTop === 0) {
                setTop(1);
            } else if (window.scrollY > 0 && isTop === 1) {
                setTop(0);
            }
        }

        window.addEventListener("scroll", checkScroll)
        checkScroll();

        return () => {
            window.removeEventListener("scroll", checkScroll)
        }
    }, [isTop]);

    return (
        <button className={`btnScrollToTop ${isTop === 1 ? "" : "btnScrollToTop--visible"}`} onClick={() => window.scrollTo(0, 0)}><span>Remonter en<br/>haut de page</span></button>
    )
}

export default BtnScrollToTop;