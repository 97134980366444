import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

// Hooks
import {updateModalsOpen} from "../../Store/action";
import useApi from '../../Hooks/useApiGlobal';

// Components
import Modal from "./modal";
import {useForm} from "react-hook-form";
import {Loader} from "../../Components/loader";

const PasswordModal = () => {
    // const baseURL = process.env.REACT_APP_APP;
    const domain = 'https://' + window.location.host

    let dispatch = useDispatch();
    // Modal info
    const modalsOpen = useSelector(state => state.modals).openModal;

    // Error
    const [loginError, setLoginError] = useState(false);

    // Confirmation content
    const [emailSent, setEmailSent] = useState(false);
    // Loader
    let [loading, setLoading] = useState(false);

    // Close modal
    const closeModal = () => {
        let newState = {
            ...modalsOpen,
            'password': false
        };
        dispatch(updateModalsOpen(newState));
    };

    // Password form
    const {register, handleSubmit, errors} = useForm();

    const [username, setUsername] = useState('');
    const [usernameValidation, setUsernameValidation] = useState(false);

    const [paramsSend, setParamsSend] = useState({name: '', method: ''});
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const handleEmailChange = (e) => {
        // Remove error message
        setLoginError(false);
    };

    const onSubmit = (data) => {
        // On récupère l'username ou l'email
        setUsername(data.user_name);

        // Show loader
        setLoading(true);

        setUsernameValidation(true);

        // Tracking - recover_password
        window.customDataLayer.push({
            event: 'recover_password'
        });
        //   console.log('customDataLayer [pwd] ', window.customDataLayer);
    };

    useEffect(() => {
        if (username) {
            // console.log('username', username);

            // Requête pour avoir l'url du password reset (envoi un mail à l'utilisateur avec le lien)
            setParamsSend({
                name: 'api/password-recovery',
                fields: 'password_form_url=' + domain + '/password-reset&identifier=' + username,
                method: 'get'
            })
        }
    }, [username]);    // eslint-disable-line

    useEffect(() => {
        if (responseSend && isLoadedSend && username) {
            let {status} = responseSend;

            setUsernameValidation(false);
            if (status === 200) {
                //  console.log('status 200', data);
                // Show success message
                setEmailSent(true);
            } else {
                //Erreur
                console.log('ERREUR status :', status);
                // Display error message
                setLoginError(true);
                dispatch(updateModalsOpen({...modalsOpen, 'hasError': true}));
            }

            // Hide loader
            setLoading(false);
        }
    }, [responseSend, isLoadedSend, usernameValidation]);    // eslint-disable-line


    return (
        <Modal modalName="password" modalNameTracking="Mot de passe oublié">
            {!emailSent ?
                <div className="c-modal_password">
                    <div className="c-modal_password-intro">
                        <h2 className="title-1">Vous avez perdu votre mot de passe ?<br/> Pas de panique !</h2>
                        <p>Veuillez saisir l’adresse email que vous avez renseigné lors de votre inscription à La Belle Adresse.</p>
                        <p>Un lien pour réinitialiser votre mot de passe vous sera envoyé directement par email.​</p>
                    </div>
                    <form className="c-login_form c-form" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="c-form_line">
                                <div className="c-form_lineEltLarge">
                                    <input type="text" placeholder="Email*" name="user_name"
                                           id="user_name"
                                           ref={register({
                                               required: true,
                                               pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                           })}
                                           data-error={(errors.user_name || modalsOpen.hasError) ? true : false}
                                           onChange={(e) => handleEmailChange(e)}
                                    />
                                    <div className="error--">
                                        {errors.user_name && "La valeur saisie n'est pas reconnue. Merci de saisir votre adresse email."}
                                        {loginError && !errors.user_name && modalsOpen.password && modalsOpen.hasError && "Login ou mot de passe non reconnus."}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="c-button">Valider</button>
                    </form>

                    {loading && <div className="full-loader"><Loader/></div>}
                </div>
                :
                <div className="c-modal_password">
                    <h2 className="title-1">Vérifiez votre boîte mail,</h2>
                    <p>des instructions détaillées viennent de vous être envoyées.</p>
                    <button type="button" className="btn-2" onClick={() => closeModal()}>Retour</button>
                </div>
            }

        </Modal>
    )
};

export default PasswordModal;