import React from "react";
import CLink from "./cLinks";


const Univers = ({children, type, styleType, target, targetTitle, index, hasArrow}) => {
    return (
        <div className={`c-univers_container c-univers_${type} ${!target && `noHover--`}`}>
            {
                type ?
                <i className="c-univers_icon"></i> :
                <div className="c-univers_icon"></div> 
                
            }
            {target ?
                <CLink url={target} title={targetTitle}>
                    <span className={`c-univers_content ${styleType && styleType}`}>
                        {children}
                    </span>
                </CLink>
            :
                <div className={`c-univers_content ${styleType && styleType}`}>
                    {children}
                </div>
            }
        </div>
    )
};

export default Univers;