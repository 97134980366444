import React from "react";

// Components
import CLink from "./cLinks";

const CommunitySuccessForm = ({ title, text, ctaUrl, ctaText }) => {
    return (
        <div className="c-communitySuccessForm">
            <div className="container">
                <h1 className="c-communitySuccessForm-title">{title}</h1>
                <div className="c-communitySuccessForm-text" dangerouslySetInnerHTML={{ __html: text }} >
                </div>
                {
                    ctaUrl && 
                    <CLink url={ctaUrl} className="btn-2">
                        {ctaText}
                    </CLink>
                }
            </div>
        </div>
    )
};

export default CommunitySuccessForm;