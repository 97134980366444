import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';

// Libraries
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import useApi from '../Hooks/useApiGlobal';
import {updateGlobalStorePage} from '../Store/action';

//components
import Metatags from '../Layout/metas-tags/meta-tags';
import Accordion from "../Components/accordion";
import {Loader} from "../Components/loader";


const TemplateMentions = ({_uid}) => {

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-mentions'));
    }, [dispatch]); // isLoaded

    const {desktop} = useSelector(state => state.breakpoint);

    const [dataRedux, setDataRedux] = useState();//Infos principales de la page
    const [accordionRedux, setAccordionRedux] = useState('');// Infos Accordéon
    const [itemsAccordion, setItemsAccordion] = useState();//Accordéon remanié

    //Requete pour récup les infos de l'univers
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/accordions_page',
        _uid: _uid,
        include: 'field_common_pgph_contents.field_common_img_textimage',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            // console.log(dataFetch);
            let normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeAccordionsPage', _uid));
            setAccordionRedux(build(normalizeJson, 'paragraphCommonTextImage'));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

   /* useEffect(() => {
        if (dataRedux) {
            console.log(dataRedux);
        }
    }, [dataRedux]);*/

//Infos accordéons recup et normalisées
    useEffect(() => {
        if (accordionRedux) {
            const accordion = [];
            accordionRedux.map((elt) => {
                let value = {
                    id: elt.id,
                    title: elt.fieldCommonTxtTexttitle,
                    content: elt.fieldCommonTxtTextbody.processed,
                    visuel: '',
                    altVisuel: ''
                }
                if (elt.fieldCommonImgTextimage) {
                    if (desktop) {
                        value.visuel = elt.fieldCommonImgTextimage.links.brandAccordionDesktop.href
                    }
                    else {
                        value.visuel = elt.fieldCommonImgTextimage.links.brandAccordionMobile.href
                    }
                }
                accordion.push(value);
                return null;
            });
            setItemsAccordion(accordion);
        }
    }, [accordionRedux, desktop]); // eslint-disable-line


    const renderPage = () => {
        const {metatag, title} = dataRedux;


        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'Mentions Légales'
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}
                <div className="container">
                    <h1 className="title-2">{title}</h1>
                    <div className="t-mentions_accordion">
                        {itemsAccordion && <Accordion items={itemsAccordion}/>}
                    </div>
                </div>

            </>
        )
    };
    return (dataRedux && isLoaded) ? renderPage() : <Loader/>
};

export default TemplateMentions;