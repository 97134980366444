import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import {useLocation} from 'react-router-dom';

// Hooks
import useApi from '../Hooks/useApiGlobal';
import { updateGlobalStorePage } from '../Store/action';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from "../Components/loader";
import ListBrands from "../Components/carouselBrands"
import ListUniverse from "../Components/listUniverse"
import CarouselActus from "../Components/carouselActus";
import CarouselGames from "../Components/carouselGames";
import BannerBrand from "../Components/bannerBrand";


const TemplateBrands = ({ _uid }) => {

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateGlobalStorePage('t-onYourMarks'));
    }, [dispatch]); // isLoaded

    

    // Get user state
    // let {uuid, pseudo} = useSelector(state => state.user);

    const [dataRedux, setDataRedux] = useState({});//Infos de la page

    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag,title',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    const renderPage = () => {
        const { metatag } = dataRedux;
        // console.log('MARQUES dataRedux', dataRedux);

        // Tracking page_view event
        let pageviewEvent = {
            category: 'A vos marques',
            category2: '',
            category3: '',
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}

                <ContentPage />

            </>
        )
    };
    return (Object.keys(dataRedux).length > 0) ? renderPage() : <Loader />
};

const ContentPage = () => {
    const { desktop } = useSelector(state => state.breakpoint);

    const [brandsRedux, setBrandsRedux] = useState([]);//Infos Liste des marques
    const [universRedux, setUniversRedux] = useState([]);//Infos Liste Univers

    //Requete pour récup la liste des marques ayant une page dédiée
    const [dataFetchBrands, isLoadedBrands] = useApi({
        name: 'api/node/brand_page',
        include: 'field_brand_img_logo,field_common_tr_brand',//field_common_tr_brand
        fields: '&fields[node--brand_page]=title,field_brand_img_logo,path,drupal_internal__nid,field_common_tr_brand',
        sort: '&sort[sort-weight][path]=field_common_tr_brand.weight',
        method: 'get',
        limit: 250
    });

    useEffect(() => {
        if (isLoadedBrands && dataFetchBrands) {
            const normalizeJsonBrands = normalize(dataFetchBrands.data);//Normalisation du json
            setBrandsRedux(build(normalizeJsonBrands, 'nodeBrandPage'));
        }
    }, [isLoadedBrands, dataFetchBrands]);//chargement requete ok


    //Requete pour récup les univers
    const [dataFetchUnivers, isLoadedUnivers] = useApi({
        name: 'api/taxonomy_term/universe',
        fields: '&fields[taxonomy_term--universe]=name,path,field_api_remoteid_universeid,drupal_internal__tid',
        method: 'get'
    });

    //Normalize les univers
    useEffect(() => {
        if (isLoadedUnivers && dataFetchUnivers) {
            const normalizeJsonUnivers = normalize(dataFetchUnivers.data);//Normalisation du json
            setUniversRedux(build(normalizeJsonUnivers, 'taxonomyTermUniverse'));
        }
    }, [isLoadedUnivers, dataFetchUnivers]);//chargement requete ok

    //Requete qui permet de recup les landings + Mosaic (New Version pour gerer l'ordre sur les 2 en meme temps)
    const [dataFetchContent, isLoadedContent] = useApi({
        name: 'api/brands-actus-slider',
        include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_landing_img_mob_thumbnail,field_landing_img_desk_thumbnail',
        method: 'get',
    });

    const [contentRedux, setContentRedux] = useState([]);//Infos actu des marques

    useEffect(() => {
        if (dataFetchContent && isLoadedContent) {
            if(dataFetchContent.data.data) {
                dataFetchContent.data.data.forEach((item, index) => {
                    item.truetype = item.type;
                    item.type = "node--content";
                })
            }
            const normalizeJsonActu = normalize(dataFetchContent.data);//Normalisation du json
            let dataFetchActuNormalize = build(normalizeJsonActu, 'nodeContent');
            setContentRedux(dataFetchActuNormalize);
        }
    }, [dataFetchContent, isLoadedContent])

    //Requete qui permet de recup les jeux en cours
    const [dataFetchGame, isLoadedGame] = useApi({
        name: 'api/operations',
        include: 'thumbnail,thumbnail_mobile',
        fields: '&fields[operations]=name,alias,thumbnail,thumbnail_mobile,lba_operation_state',
        filter: '&filter[promote][value]=1',
        sort: '&sort[sort-weight][path]=weight&sort[sort-weight][direction]=ASC',
        method: 'get',
    });

    const [gameRedux, setGameRedux] = useState([]);//Infos actu des marques
    const location = useLocation();
    useEffect(() => {
        if (dataFetchGame && isLoadedGame) {
            const normalizeJsonGame = normalize(dataFetchGame.data);//Normalisation du json
            let dataFetchGameNormalize = build(normalizeJsonGame, 'operations');
            setGameRedux(dataFetchGameNormalize);


           
        }
    }, [dataFetchGame, isLoadedGame])

    useEffect(() => {
         const hash = location.hash;
            if (hash && document.getElementById(hash.substr(1))) {
                // Check if there is a hash and if an element with that id exists
                document.getElementById(hash.substr(1)).scrollIntoView({behavior: "smooth"})
            }
    }, [gameRedux])
    return (

        <>
            {(brandsRedux && brandsRedux.length > 0) &&
                <div className="container">
                    <h2 className="section-title">Marques</h2>
                    <ListBrands desktop={desktop} carousel={brandsRedux} />
                </div>
            }

            {(universRedux && universRedux.length > 0) &&
                <div className="container">
                    <h2 className="section-title">Univers</h2>
                    <ListUniverse universList={universRedux} desktop={desktop} />
                </div>
            }
            {(contentRedux && contentRedux.length > 0) &&
                <div className="container">
                    {
                        console.log("redux", contentRedux)
                    }
                    <h2 className="section-title">Ça vient de sortir</h2>
                    <CarouselActus carousel={contentRedux} desktop={desktop} />
                </div>
            }

            <BannerBrand />


            {(gameRedux && gameRedux.length > 0) &&
                <div className="container">
                    <h2 className="section-title" id="operations">Opérations en cours</h2>
                    <CarouselGames carousel={gameRedux} desktop={desktop} />
                </div>
            }
        </>
    )
};

export default TemplateBrands;