import React from "react";
import CLink from "./cLinks";

const maxiLink = ({children, target, targetTitle}) => {
    return (
        <div className="c-maxiLink">
            <div className={`container${!target ? ' noLink' : ''}`}>
                {target ?
                    <CLink url={target} title={targetTitle}>
                        <div className="c-maxiLink_container">
                            <div className="c-maxiLink_content">
                                {children}
                            </div>
                        </div>
                    </CLink>
                    :
                    <div className="c-maxiLink_container">
                        <div className="c-maxiLink_content">
                            {children}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default maxiLink;