import React from "react";

// Components
import MaxiLink from "../Components/maxiLink"

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

SwiperCore.use([Navigation, EffectFade, Autoplay]);

const maxiLinkCarousel = ({moreProfilInfo}) => {
    return (
        <Swiper className={`c-profil_information ${moreProfilInfo.length < 2 ? ' no-carousel' : ''}`}
            slidesPerView="1"
            watchSlidesVisibility={true}
            loop={true}
            navigation={moreProfilInfo.length > 1 ? true : false }
            effect={'fade'}
        >
            {
                moreProfilInfo.map(({target, targetTitle, content}, key) => {
                    return (
                        <SwiperSlide key={key} className="c-profil_information-slide">
                            <MaxiLink target={target} targetTitle={targetTitle}>{content}</MaxiLink>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
};

export default maxiLinkCarousel;