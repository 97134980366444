import React from "react";
import { useDispatch, useSelector } from "react-redux";
// Hooks
import { updateModalsOpen } from "../../Store/action";
// Components
import Modal from "./modal";
import CLink from "../cLinks";

const OfferBasketModal = () => {

    let dispatch = useDispatch();
    const modalsOpen = useSelector(state => state.modals).openModal;

    const handleClosePopin = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'offerBasket': false }));
    }

    return (
        <>
            {
                modalsOpen && modalsOpen.value && 
                <Modal modalName="offerBasket">
                    <div className="c-offerBasket">
                        <div className="c-modal_offerBasket-intro">
                            <h2 className="title-1">Vous nous quittez déjà&nbsp;?</h2>
                            <p>Avant de partir, n'oubliez pas de <strong>profiter des offres de réductions</strong> disponibles dans votre panier</p>
                            {
                                modalsOpen.value.data && modalsOpen.value.data.cta_link && 
                                <CLink 
                                    url={modalsOpen.value.data.cta_link} 
                                    title={"J'accède à mon panier"}
                                    className="btn-3" 
                                    handleClick={() => handleClosePopin()}
                                />
                            }
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
};

export default OfferBasketModal;