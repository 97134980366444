import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateGlobalScrollLock,
  updateModalsOpen,
  updateModalsLoginType,
} from "../../Store/action";
import { getRefreshToken } from "../../Utils/cookie";

const MenuItem = ({
  id,
  children,
  virtual_page,
  title,
  url,
  closeMenu,
  active,
  onClick,
  classe,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(true);
  const modalsOpen = useSelector((state) => state.modals).openModal;
  const { uuid } = useSelector((state) => state.user);
  const { mobile } = useSelector((state) => state.breakpoint);
  const dispatch = useDispatch();

  const handleOpen = () => {
    closeMenu();
    dispatch(updateGlobalScrollLock(false));
    dispatch(updateModalsOpen({ ...modalsOpen, login: true, alert: true }));
    dispatch(updateModalsLoginType("login"));
  };

  const navLinkEvents = () => {
    closeMenu();
  };

  const navLinkMobile = (e) => {
    if (!subMenuOpen) {
      e.preventDefault();
      setSubMenuOpen(true);
    } else {
      closeMenu();
      setSubMenuOpen(false);
    }
  };

  return (
    <li
      className={`c-nav_item  ${classe ? classe : ""} ${
        children ? "hasChildren--" : ""
      } ${subMenuOpen ? "is-open--" : ""}`}
      key={id}
      data-sub-opened={active}
      onClick={() => !mobile && onClick}
    >
      <div className="c-nav_itemElt">
        {virtual_page === true ? (
          <span className="c-nav_link">
            <i></i>
            {title}
          </span>
        ) : (
          <>
            {children ? (
              <>
                {mobile ? (
                  <NavLink
                    className="c-nav_link"
                    title={title}
                    to={url}
                    onClick={(e) => navLinkMobile(e)}
                  >
                    <i></i>
                    {title}
                  </NavLink>
                ) : (
                  <NavLink
                    activeClassName="selected"
                    className="c-nav_link"
                    title={title}
                    to={url}
                  >
                    <i onClick={navLinkEvents}></i>
                    <span onClick={navLinkEvents}>{title}</span>
                  </NavLink>
                )}
              </>
            ) : (
              <>
                {classe === "account" && !uuid && !getRefreshToken() ? (
                  <span className="c-nav_link" onClick={handleOpen}>
                    <i></i> Se connecter
                  </span>
                ) : (
                  <NavLink
                    activeClassName="selected"
                    className="c-nav_link"
                    title={title}
                    to={url}
                  >
                    <i onClick={navLinkEvents}></i>
                    <span onClick={navLinkEvents}>{title}</span>
                  </NavLink>
                )}
              </>
            )}
          </>
        )}
      </div>
      {children && (
        <SubMenu children={children} navLinkEvents={navLinkEvents} />
      )}
    </li>
  );
};

export default MenuItem;

const SubMenu = ({ children, navLinkEvents }) => {
  return (
    <div className="c-nav_sub">
      <ul className="c-nav_subMenu">
        {children &&
          children.map(({ id, url, title, children }) => (
            <li className="c-nav_subItem" key={id}>
              <SublinkSubMenu
                children={children}
                url={url}
                title={title}
                navLinkEvents={navLinkEvents}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export const SublinkSubMenu = ({ children, url, title, navLinkEvents }) => {
  const [visible, setVisible] = useState(false);
  const subMenuRef = useRef(null);

  useEffect(() => {
    if (!subMenuRef.current) return;
    !visible
      ? (subMenuRef.current.style.maxHeight = 0)
      : (subMenuRef.current.style.maxHeight =
          subMenuRef.current.scrollHeight + "px");
  }, [visible]);

  return (
    <>
      <span
        className={`c-nav_subLink ${visible ? "visible" : ""}`}
        title={title}
        to={url}
        onClick={() => setVisible(!visible)}
      >
        <span>{title}</span>
      </span>
      {children && (
        <ul
          ref={subMenuRef}
          className={`c-nav_subLink_subMenu ${visible ? "visible" : ""}`}
        >
          {children.map(({ id, url, title }) => (
            <li className="c-nav_subLink_subItem" key={id}>
              <NavLink activeClassName="selected" to={url} title={title} onClick={() => navLinkEvents && navLinkEvents()}>
                - {title}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
