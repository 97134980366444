import React, {useState, useRef} from 'react';
import Video from "../Components/article/video";

const ItemAccordion = ({title, content, visuel, altVisuel, infoVideo, videoPoster, desktop}) => {
    let [open, setOpen] = useState(false);
    const refItem = useRef(null);
    const renderMarkup = (markup) => {
        return {__html: markup};
    };
    let cookiesLink = document.querySelectorAll('p a');
    let oneTrustFilter = document.querySelector('.onetrust-pc-dark-filter');
    let oneTrustPopup = document.querySelector('#onetrust-pc-sdk');

    cookiesLink.forEach(function (item) {
        item.addEventListener('click', function (e) {
            if (e.target.getAttribute('href') === '#cookies') {
                // Open settings popup
                oneTrustFilter.classList.remove('ot-hide');
                oneTrustFilter.classList.add('ot-fade-in');
                oneTrustFilter.style.removeProperty("visibility");
                oneTrustFilter.style.removeProperty("opacity");
                oneTrustFilter.style.removeProperty("transition");
                oneTrustFilter.style.removeProperty("display");
                oneTrustPopup.classList.remove('ot-hide');
                oneTrustPopup.classList.add('ot-fade-in');
                oneTrustPopup.style.removeProperty("visibility");
                oneTrustPopup.style.removeProperty("opacity");
                oneTrustPopup.style.removeProperty("transition");
                oneTrustPopup.style.removeProperty("display");
                oneTrustPopup.style.height = "90vh";
            }
        });
    });

    return (
        <div className="c-accordion_item" data-faq-open={open}>
            <div className="c-accordion_title" onClick={() => setOpen(!open)}>
                <h2>{title}</h2>
                <button type="button" className={`c-accordion_button ${ !open ? "up--" : "down--"}`}></button>
            </div>
            <div className="c-accordion_content c-wysiwyg" ref={refItem}
                 style={{height: open ? `${refItem.current.scrollHeight}px` : 0}}>
                {visuel && <img src={visuel} alt={altVisuel}/>}
                <div dangerouslySetInnerHTML={renderMarkup(content)}></div>
                {
                    infoVideo &&
                    <Video infoVideo={infoVideo} desktop={desktop} videoPoster={videoPoster}/>
                }
            </div>
        </div>
    )
};

const accordion = ({items}) => {
    return (
        <div className="c-accordion">
            {items && items.map((elt, index) => <ItemAccordion {...elt} key={index}/>)}
        </div>
    )
};

export default accordion;
export { ItemAccordion };