import React from "react";
import { useSelector, useDispatch } from "react-redux";
// Hooks
import { updateModalsOpen } from '../Store/action';
// Components
import Search from '../Layout/header/search';
import CLink from "./cLinks";

const CommunityBlockRoundedTitle = ({ title, text, btnUrl, btnText, inputPlaceholder, searchResultsPath, btnSearchText }) => {
    const dispatch = useDispatch();
    // Modal
    const { uuid } = useSelector(state => state.user);
    const modalsOpen = useSelector(state => state.modals).openModal;

    const handleOpen = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'alert': true }));
    };
    return (
        <div className="c-communityBlockRounded_container">
            {
                title &&
                <h1 className="title">{title}</h1>
            }
            {
                text &&
                <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
            }
            {
                btnUrl &&
                <div className="c-communityBlockRounded-btn">
                    {
                        uuid ?
                            <CLink url={btnUrl} className="btn-2">{btnText}</CLink> :
                            <button onClick={() => handleOpen()} className="btn-2">{btnText}</button>
                    }
                </div>
            }
            {
                inputPlaceholder &&
                <div className="c-communityBlockRounded-search">
                    <p className="text">Vous n’avez pas de questions spécifiques ? Consultez les questions les plus fréquemment posées ou recherchez une question déjà posée.</p>
                    <p className="text label">Recherchez si la communauté a déjà répondu à votre question...</p>
                    <Search
                        btnSearchText={btnSearchText}
                        placeholder={inputPlaceholder}
                        searchResultsPath={searchResultsPath}
                    />
                </div>
            }
        </div>
    )
};

export default CommunityBlockRoundedTitle;