import React from "react";
import { getImage } from '../Utils/getMedia';

const visuelLarge = ({ urlVisuel, bgColor, urlVisuelStatic = "", urlVisuelSup, altVisuelSup, positionVisuelSup, desktop, capsule = "", universe, isTrueSize = false }) => {
    //Récupération de l'image
    let href = urlVisuelStatic;
    if (urlVisuel && urlVisuel.fieldRespimgImgDesktop) {
        href = getImage(urlVisuel, desktop);
    }

    const renderMarkup = (markup) => {
        return { __html: markup };
    };

    return (
        <div className={`c-visuelLarge ${capsule && 'capsule--'}`}>
            <div className={`c-visuelLarge_visuel ${isTrueSize && 'c-visuelLarge_visuel--truesize'} no-print ${capsule && 'capsule--'} ${bgColor && 'contain--'}`}
                style={href ? { backgroundImage: `url(${href})`, backgroundColor: bgColor ? bgColor : "" } : { backgroundColor: "#f9f8f8" }}>
                {urlVisuelSup &&
                    <div
                        className={`c-visuelLarge_visuelSup container ${positionVisuelSup ? positionVisuelSup + '--' : ''}`}>
                        <img src={urlVisuelSup} alt={altVisuelSup && altVisuelSup} />
                    </div>
                }
            </div>
            {capsule &&
                <div className="c-visuelLarge_capsule">
                    <div className="container">
                        <div className="c-visuelLarge_content">
                            <h1 dangerouslySetInnerHTML={renderMarkup(capsule)}></h1>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default visuelLarge;