import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateModalsOpen, updateModalsLoginType } from '../Store/action';
// HOOKS
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';
// UTILS
import { randomString } from "../Utils/md5-hash";


const Comment = React.forwardRef(({ body, createdAt, owner, internalId, likesCount, contentType }, ref) => {

    //Unformisation des liens
    // let bloc_comment = document.createElement("div");
    // bloc_comment.innerHTML = body;
    // bloc_comment.innerHTML = bloc_comment.innerText
    // bloc_comment.querySelectorAll("a").forEach(a => a.outerHTML = a.href);
    // let comment_text = bloc_comment.innerText;
    // let regex = new RegExp(/(http[^\s]?:\/\/[^\s]+)/g);
    // body = comment_text.replace(regex, '<a style="word-break: break-all" href="$1">$1</a>')

    const timeSince = (date) => {
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " an(s)";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " mois";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + "j";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + "h";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + "min";
        }
        return Math.floor(seconds) + "s";
    };

    // TRACKING - Like content
    const [trackingLikeEvent, setTrackingLikeEvent] = useState({});
    const trackingLike = useTracking(trackingLikeEvent); // eslint-disable-line

    // Modals - Login
    let { uuid } = useSelector(state => state.user);
    const modalsOpen = useSelector(state => state.modals).openModal;
    const dispatch = useDispatch();

    // STATE FOR UPDATE API COUNT AND STYLE LIKE BTN
    const [likeApi, setLikeApi] = useState({});
    const [dataFetchLike, isLoadedLike] = useApi(likeApi); // eslint-disable-line
    const [isLike, setIsLike] = useState(false);
    const [loggedLikeCount, setLoggedLikeCount] = useState();
    // INIT COUNTER LIKE 
    useEffect(() => {
        if (uuid) {
            const headers = {
                "Content-Type": "application/json"
            };
            setLikeApi({
                name: `api/like/comment/${internalId}`,
                method: 'get',
                noData: true,
                config: headers
            });
        }
    }, [uuid]);// eslint-disable-line

    // TRIGGER LIKE CHANGE
    useEffect(() => {
        if (dataFetchLike && isLoadedLike) {
            setLoggedLikeCount(dataFetchLike.data.count)
            setIsLike(dataFetchLike.data.current_user_like)
        }
    }, [dataFetchLike, isLoadedLike]);// eslint-disable-line

    // TRIGGER CLICK LIKE AND POST IN API 
    const onClickLike = () => {
        if (!uuid) {
            //Popin de log
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'password': false, 'economy': false }));
            dispatch(updateModalsLoginType('currentPage'));
        } else {
            const headers = {
                "Content-Type": "application/json"
            };
            setLikeApi({
                name: `api/like/comment/${internalId}?${randomString(4)}`,
                method: 'post',
                noData: true,
                config: headers
            });

            setTrackingLikeEvent({
                event: 'community_engagement',
                args: {
                    engagement_type: isLike === true ? 'dislike' : 'like',
                    content_type: contentType
                },
                refresh: randomString(6)
            });
        }
    }

    return (
        <div ref={ref} className="c-comment">
            {(owner && owner.userPicture && owner.userPicture.links.thumbnail) ?
                <div className="c-comment_picture"
                    style={{ backgroundImage: `url(${owner.userPicture.links.thumbnail.href})` }}></div>
                :
                <div className="c-comment_picture noPicture"><span className="icon-profil-on"></span></div>
            }
            <div className="c-comment_content">
                <p className="c-comment_title">{owner && owner.displayName}</p>
                <p className="c-comment_subtitle">{timeSince(new Date(createdAt))}</p>
                <div className="c-comment_text" dangerouslySetInnerHTML={{ __html: body }}></div>
                <div className="c-comment_like">
                    {
                        !uuid ?
                            <span>{likesCount}</span> :
                            loggedLikeCount !== undefined && loggedLikeCount !== 0 ?
                                <span>{loggedLikeCount}</span> : ""
                    }
                    <i onClick={() => onClickLike()} className={`icon-up ${isLike ? 'active' : ''}`}></i>
                </div>
            </div>
        </div>
    )
});

export default Comment;