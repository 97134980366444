import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "scroll-behavior-polyfill";

// Redux
import {createStore, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import {globalReducer} from './Store/Reducers/globalReducer';
import {pageReducer} from './Store/Reducers/pageReducer';
import {breakpointReducer} from './Store/Reducers/breakpointReducer';
import {modalsReducer} from './Store/Reducers/modalReducer';
import {userReducer} from './Store/Reducers/userReducer';
import {routesReducer} from './Store/Reducers/routesReducer';
import {cartReducer} from './Store/Reducers/cartReducer';

const reducer = combineReducers({
    global: globalReducer,
    page: pageReducer,
    breakpoint: breakpointReducer,
    modals: modalsReducer,
    user: userReducer,
    routes: routesReducer,
    cart: cartReducer
});

const store = createStore(reducer,  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }));

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
