import React from "react";

import CLink from "../Components/cLinks";

export const Cta = ({url, target, label}) => {
    return (
        <div className="container">
            <div className="c-visuelLegend_button">
                <CLink url={url} className="btn-3" target={target} external={target === "_blank"}>{label}</CLink>
            </div>
        </div>
    )
};

export default Cta;