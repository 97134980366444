import React from "react";
import { useEffect } from "react";

const Qualifio = ({ qualifio_domain, channel_id, jws }) => {

    useEffect(() => {

        window.eventqualifio = () => {
            let elem = document.querySelector("#qualifio_insert_place");

            if (elem) {
                let PADDING = 40;
                elem.style.transform = "";
                elem.style.width = "";
                let width = window.innerWidth - (2 * PADDING);
                let offset = elem.getBoundingClientRect().left - PADDING;
                elem.style.transform = "translateX(-" + offset + "px)";
                elem.style.width = width + "px";
                elem.style.display = "flex";
                elem.style.justifyContent = "center";
            } else {
                setTimeout(window.eventqualifio, 1000);
            }
        }

        window.addEventListener("resize", window.eventqualifio);
        window.eventqualifio();
        
        window.qualifio(qualifio_domain, channel_id, jws);

        return () => {
            window.removeEventListener("resize", window.eventqualifio)
        };

    }, []);

    return (
        <div id='qualifio_insert_place' className='qualifio_iframe_wrapper'></div>
    )
};

export default Qualifio;
