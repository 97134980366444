import React, { useRef, useEffect } from "react";
// Import Swiper React components
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';

const CommunityCarouselUploads = ({ files, closePopin, activeSlide }) => {
    SwiperCore.use([Navigation])
    const baseURL = process.env.REACT_APP_APP;

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const swiperContainer = useRef(null)

    useEffect(() => {
        if (activeSlide) {
            swiperContainer.current.swiper.slideTo(activeSlide, 0, false);
        }
    }, [])

    const pauseVideo = () => {
        document.querySelectorAll('.slide-video video').forEach((video) => {
            video.pause()
        })
    }

    return (
        <div className="c-communityCarouselUploads">
            <div className="c-communityCarouselUploads_container">
                <button onClick={closePopin} className="close-btn"></button>
                <Swiper
                    ref={swiperContainer}
                    spaceBetween={100}
                    slidesPerView={1}
                    navigation={true}
                    centeredSlides={true}
                    pagination={{ clickable: true }}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}

                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                >
                    {
                        files.map((file, key) => {
                            if (file.filemime === "image/jpeg" || file.filemime === "image/jpg" || file.filemime === "image/png") {
                                return (
                                    <SwiperSlide key={key}>
                                        <div className="slide-img" style={{ backgroundImage: `url(${baseURL + file.uri.url})` }}></div>
                                    </SwiperSlide>
                                )
                            } else if (file.filemime === "video/mp4" || file.filemime === "video/mov") {
                                return (
                                    <SwiperSlide key={key}>
                                        <div className="slide-video">
                                            <video controls src={baseURL + file.uri.url} />
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        })
                    }
                    <div onClick={() => pauseVideo()} className="swiper-arrow prev--" ref={navigationPrevRef} />
                    <div onClick={() => pauseVideo()} className="swiper-arrow next--" ref={navigationNextRef} />
                </Swiper>
            </div>
        </div>
    )
};

export default CommunityCarouselUploads;