import React from "react";
import CLink from "./cLinks";

const notification = ({target, targetTitle, number}) => {
    return (
        <div className="c-notification">
            <CLink url={target} title={targetTitle}>
                <i className="icon-notif"></i>
                {(number && number > 0) ? <span className="c-notification_number">{number}</span> : ''}
            </CLink>
        </div>
    )
};

export default notification;