import React, { useEffect, useState } from "react";
import normalize from 'json-api-normalizer';
import build from 'redux-object';
// HOOKS
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';
//Composant
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from "../Components/loader";
import CommunitySearchResults from "../Components/communitySearchResults";
import Search from '../Layout/header/search';

const TemplateCommunitySearchQuestion = ({ _uid }) => {
    const searchResultsPath = useSelector(state => state.routes).routes.searchQuestion;
    const postQuestionPath = useSelector(state => state.routes).routes.postQuestion;
    // Get search value in url
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get('s');
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-communitySearch-question'));
    }, [dispatch]);

    // TRACKING
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); //eslint-disable-line

    useEffect(() => {
        setTrackingEvent({
            event: 'view_search_results',
            args: {
                search_type: 'Question',
                search_term: searchValue,
            },
        });
    }, [searchValue])

    //Requete pour récup les meta data
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag,title',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const renderPage = () => {
        const { metatag } = dataRedux;
        //-- Tracking
        let pageviewEvent = {
            category: 'Communauté',
            category2: 'Questions et réponses',
            category3: 'Recherche - Page de résultats'
        };
        return (

            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <div className="container">
                    <Search
                        placeholder={'Rechercher une question...'}
                        searchResultsPath={searchResultsPath}
                        trackingSearchType={'Question'}
                    />
                </div>
                <CommunitySearchResults
                    searchType={'question'}
                    btnUrl={postQuestionPath}
                    btnText={"Je pose ma question"} />
            </>
        )
    }
    return (isLoaded && dataRedux) ? renderPage() : <Loader />
};

export default TemplateCommunitySearchQuestion;