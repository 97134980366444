import React, {useEffect, useState} from "react";

import {useSelector, useDispatch} from 'react-redux';

// Hooks
import { updateGlobalStorePage } from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from '../Components/loader';
import VisuelLarge from '../Components/visuelLarge';
import Wysiwyg from '../Components/wysiwygSimple';

const TemplateContentPage = ({_uid}) => {
    const {desktop} = useSelector(state => state.breakpoint);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-contentPage'));
    }, [dispatch]); // isLoaded

    const [dataRedux, setDataRedux] = useState();//Infos principales de la page

  //Requete pour récup toutes le contenu principal de la marque
  const [dataFetch, isLoaded] = useApi({
    name: 'api/node/page',
    _uid: _uid,
    fields: '&fields[node--page]=field_common_pgph_ban,field_common_txt_body,field_common_txt_title,metatag',
    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop',
    method: 'get',
  });

  useEffect(() => {
    if (isLoaded && dataFetch) {
      const normalizeJson = normalize(dataFetch.data); //Normalisation du json
      setDataRedux(build(normalizeJson, 'nodePage', _uid));
    }
    }, [isLoaded, dataFetch]); // eslint-disable-line

  const renderPage = () => {
    const { fieldCommonTxtTitle, fieldCommonTxtBody, fieldCommonPgphBan, metatag } = dataRedux;

    return (
      <>
        {metatag && <Metatags listMeta={metatag} />}
        {fieldCommonPgphBan && (
          <VisuelLarge urlVisuel={fieldCommonPgphBan} altVisuelSup={fieldCommonTxtTitle} desktop={desktop} />
        )}
        {(fieldCommonTxtTitle || fieldCommonTxtBody.processed) && (
          <div className="container">
            <div className="t-contentPage_container">
              {<Wysiwyg title={fieldCommonTxtTitle} content={fieldCommonTxtBody.processed} />}
            </div>
          </div>
        )}
      </>
    );
  };
  return isLoaded && dataRedux ? renderPage() : <Loader />;
};

export default TemplateContentPage;