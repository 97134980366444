export const getMsgError = (code) => {
  console.log(code)
  let msg="Une erreur est survenue : "+code;
  let msgArray = {
      "SOGEC_PARTICIP_CREATE_MISSING_PARAMETER" : "Il manque un élément pour réaliser la soumission (preuve d'achat, sélection du produit acheté…)", 
      "SOGEC_PARTICIP_MAIL_TOO_LONG" : "Votre adresse e-mail est trop longue (supérieure à 50 caractères)",
      "SOGEC_PARTICIP_RECEIPT_BAD_FILE_TYPE" : "Votre preuve d'achat  n'est pas au bon format : taille maximale 10 Mo et pas de format .heif accepté",
      "SOGEC_PARTICIP_INVALID_BARCODE" : "Le produit sélectionné n'est plus éligible pour cette offre de remboursement",
      "SOGEC_PARTICIP_PRODUCT_OR_OFFER_NOT_FOUND" : "Le produit sélectionné ne correspond pas à l'offre de remboursement",
      "SOGEC_PARTICIP_PAYMENT_TYPE_MISSING" : "Vous n'avez pas sélectionné votre type de paiement",
      "SOGEC_PARTICIP_PAYMENT_TYPE_INVALID" : "Votre type de paiement est invalide",
      "SOGEC_PARTICIP_PAYMENT_EMAIL_INVALID" : "L'e-mail fourni pour le remboursement est invalide",
      "SOGEC_PARTICIP_PAYMENT_BANK_INFO_MISSING" : "Il manque vos informations bancaires",
      "SOGEC_PARTICIP_PAYMENT_IBAN_INVALID" : "L'IBAN fourni est invalide",
      "SOGEC_PARTICIP_PAYMENT_BIC_INVALID" : "Le BIC fourni est invalide",
      "SOGEC_PARTICIP_SUBMIT_ERROR" : "Une erreur s'est produite lors de la soumission",
      "SOGEC_GET_SUBMITTED_INVALID_START_DATE" : "La date de début de l'offre de remboursement est incorrecte",
      "SOGEC_GET_SUBMITTED_INVALID_END_DATE" : "La date de fin de l'offre de remboursement est incorrecte",
      "SOGEC_BASKET_NO_OFFERREF" : "La référence de l'offre n'a pas été soumise",
      "SOGEC_BASKET_ALREADY_IN_BASKET" : "L'offre de remboursement est déjà dans le panier",
      "SOGEC_BASKET_NO_OFFER" : "Il n'existe pas d'offre correspondante à la référence produit sélectionnée",
      "SOGEC_MAX_SUBMISSION_OR_EXPIRED" : "Le quota maximal de cette offre de remboursement a été atteint ou la date de fin de l'offre est incorrecte",
      "COUPON_BASKET_BAD_EAN" : "Le panier contient un webcoupon avec un EAN invalide",
      "COUPON_BASKET_COUPON_NOT_FOUND" : "Le panier contient un webcoupon inexistant",
      "COUPON_MAX_SUBMISSION_OR_EXPIRED" : "Le quota maximal de ce webcoupon a été atteint ou la date de fin du coupon est incorrecte",
      "COUPON_BASKET_ALREADY_IN_BASKET" : "Le panier contient déjà ce webcoupon",
      "COUPON_BASKET_OFFER_MISSING" : "Le panier ne contient pas ce webcoupon",
      "COUPON_BASKET_NO_VALID_COUPON" : "Le panier contient un webcoupon invalide",
      "COUPON_BASKET_NO_VALID_COUPON_TO_SEND" : "Le panier contient un webcoupon invalide pour un envoi par email",
      "COUPON_BASKET_SEND_ERROR" : "L'impression du panier webcoupons renvoie une erreur",
      "COUPON_BASKET_SEND_NO_PRINTURL" : "Le service d'impression du webcoupon a rejeté le panier",
      "COUPON_GET_PRINTED_INVALID_START_DATE" : "La date de début d'impression du webcoupon est incorrecte",
      "COUPON_GET_PRINTED_INVALID_END_DATE" : "La date de fin d'impression du webcoupon  est incorrecte",
      "MISSING_REQUEST_BODY" : "L'API a reçu une requête vide", 
      "MEMBER_AUTHENTICATION_ERROR": "Erreur d'authentification par l'API",
      "ACCESS_DENIED" : "Accès refusé par l'API",
      "MISSING_FILTER_VALUE": "L'API attend la valeur de filtrage",
      "INVALID_FILTER_VALUE": "La valeur de filtrage est invalide",
      "MISSING_FILTER_KEY" : "L'API attend la clef de filtrage",
      "INVALID_FILTER_KEY" : "La clef de filtrage est invalide",
      "START_DATE_PARAM_MISSING": "La date de début de filtrage des données est manquante",
      "END_DATE_PARAM_MISSING": "La date de fin de filtrage des données est manquante",
      "MISSING_RECEIPT" : "La preuve d'achat est manquante",
      "HTTP_REQUEST_ERROR" : "Erreur de requête",
      "OAUTH_INVALID_TOKEN" : "Le token d'authentification est invalide",
      "PASSWORD_RECOVERY_BAD_TOKEN" : "Le token d'oubli de mot de passe est incorrect",
      "INVALID_ENTITY_FIELD_VALUE" : "La valeur du champ est invalide ou déjà utilisée",
      "ENTITY_ERROR" : "Erreur sur une entité",

      "INVALID_CASHBACK_PAYMENT_TYPE": "Le type de remboursement est invalide",
      "IBAN_NOT_AVAILABLE": "Votre IBAN a déjà été utilisé",
      "IBAN_NOT_VALID ": "Votre IBAN doit être valide",
      "IBAN_PARAMETER_MISSING": "Le paramètre Iban est manquant",
      "REGISTRATION_CODE_INVALID_PARAMETER" : "Ce code n'est pas valide",
      "REGISTRATION_CODE_NOT_FOUND" : "Ce code n'est pas valide",
      "REGISTRATION_CODE_ALREADY_USED" : "Ce code a déjà été utilisé"
  }

  // msgArray.forEach(function(value, key) {
  //     console.log(value, key);
  //     if(code.indexOf(key)) {
  //         msg=value
  //     }
  // })
  // console.log(msgArray[code])
  if(msgArray[code]) {
      msg=msgArray[code];
  }
  
  return msg;
};
