import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";

// Hooks
import useTracking from "../Hooks/useTracking";
import useIntersection from '../Hooks/useIntersection';

// Utils
import {randomString} from "../Utils/md5-hash";

// Components
import CLink from "./cLinks";


const Game = ({alias, name, thumbnail, thumbnailMobile, lbaOperationState}) => {
    const {desktop} = useSelector(state => state.breakpoint);
    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    // Get user state
    let {iupid, pseudo} = useSelector(state => state.user);
    let userId = undefined;
    if (iupid && pseudo) userId = iupid;

    const selectContent = (contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: "Jeu en cours",
                content_name: contentName
            },
            refresh: refresh
        });
    };

    // TRACKING - View content (Article)
    const ref = useRef(null);
    const inViewport = useIntersection(ref, '0px');

    const viewContent = () => {
        window.customDataLayer.push({
            event: 'view_content',
            user_id: userId,
            content_type: 'Jeu',
            content_name: name
        });
    };

    useEffect(() => {
        if (inViewport) {
            viewContent();
        }
    }, [inViewport]);

    let image = thumbnail?.links?.commonCarrouselDesktop.href
    if(!desktop && thumbnailMobile)image = thumbnailMobile?.links?.commonCarrouselDesktop?.href
    else if(!desktop) image  = thumbnail?.links?.commonCarrouselMobile?.href
    return (
        <div ref={ref} className={`${lbaOperationState === "passed" ? `passed--` : lbaOperationState === "incoming" &&  `incoming--`}`}>
            <CLink url={alias}
                    title={name}
                    handleClick={() => selectContent(name, randomString(6))}>
                <div className="c-carouselActus_visuelContent">
                    {image && <img src={image} className="c-carouselActus_visuel" alt={name}/>}
                    {
                        lbaOperationState === "passed" ?
                            <div className="c-article_state passed--"><span>Terminé</span></div>
                        :
                            lbaOperationState === "incoming" && 
                                <div className="c-article_state incoming--"><span>À venir</span></div>
                    }
                </div>
                <div className="c-carouselActus_container">
                    <div className="c-carouselActus_content">
                        {(name) &&
                        <h3>{name}</h3>}
                    </div>
                </div>
            </CLink>
        </div>
    )
};

export default Game;