import React, { useState, useEffect, useRef } from 'react';

// Hooks
import { useSelector } from "react-redux";
import useTracking from '../Hooks/useTracking';
import useIntersection from '../Hooks/useIntersection';

// Components
import Product from "./product";

// Utils
import { randomString } from "../Utils/md5-hash";

// Import Swiper React components
import SwiperCore, { Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import { Loader } from './loader';


SwiperCore.use([Pagination, EffectFade, Autoplay]);

const ListProducts = ({ title, button, products, type, sliderMobile = false, deleteProduct, name, relatedProducts = false, needLoader = false }) => {

    // Breakpoint
    const [showLoader, setLoader] = useState(true);
    const { mobile } = useSelector(state => state.breakpoint);
    const nbLoadMore = 6;
    const [maxLoadmore, setMaxLoadmore] = useState(nbLoadMore);
    const handleClick = () => {
        setMaxLoadmore(products.length);

        // Tracking - view item list
        viewItemList(randomString(6), true);
    };

    // TRACKING - View item list (Products List)
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const ref = useRef(null);
    const inViewport = useIntersection(ref, '0px');

    const viewItemList = (refresh, viewMore = false) => {
        let $item_list_name = undefined;
        if (name) $item_list_name = name;
        if (type === 'refund') {
            $item_list_name += ' - Me faire rembourser'
        } else {
            $item_list_name += ' - Imprimer mes bons'
        }

        let $quantity = products.length;
        if (products.length > 6 && !viewMore) {
            $quantity = "6"
        }
        let $items = []
        for (let index = 0; index < products.length && index < $quantity; index++) {
            $items.push(products[index])
        }
        setTrackingEvent({
            event: 'view_item_list',
            args: {
                currency: 'EUR',
                ecommerce: {
                    items:
                        $items.map((item) => {
                            return {
                                quantity: item.products != null ? item.products.length : 1,
                                price: item.discount,
                                item_variant: item.comment,
                                item_list_name: item.name,
                                item_list_id: item.brandid,
                                item_id: item.ean,
                                item_category3: item.brandname,
                                item_category2: item.shelfname,
                                item_category: item.universename,
                                index: item.offerid
                            }
                        })
                }
            },
            refresh: refresh
        });

        // console.log('customDataLayer products list', window.customDataLayer);
    };

    const [productsViewed, setProductsViewed] = useState([]);
    const [firstRender, setFirstRender] = useState(true);
    
    useEffect(() => {
        if(inViewport && !firstRender){
            products.forEach(product => {
                if (inViewport && !productsViewed.includes(product.offerref || product.ean)){
                    setProductsViewed(productsViewed => [...productsViewed, product.offerref || product.ean])
                }
            })
        } else {
            setFirstRender(false)
        }

        setTimeout(() => setLoader(false), 500);
        
    }, [products]);

    
    useEffect(() => {
        if (inViewport && type) {
            viewItemList(randomString(6));
        }
    }, [inViewport, type]);

    return (
        <>
        <div className="c-listProducts" ref={ref} style={{display : (showLoader && needLoader ? "none" : "")}}>
            <div className="container">
                {title &&
                    <div className="c-listProducts__title">{title}</div>
                }
                {

                    (products.length > 0 && Array.isArray(products)) ?
                        (mobile && sliderMobile) ?
                            <Swiper className="c-listProducts__slider"
                                slidesPerView="auto"
                                watchSlidesVisibility={true}
                                spaceBetween={10}
                                pagination={products.length > 1 ? { clickable: true } : false}
                            >
                                {
                                    products.map((product, key) => {
                                        return (
                                            <SwiperSlide key={key} className="c-listProducts__slider-item">
                                                <Product {...product} type={type} index={key} productsViewed={productsViewed}
                                                    deleteProduct={deleteProduct}
                                                    pageName={`${name} ${type === 'refund' ? ' - Me faire rembourser' : ' - Imprimer mes bons'}`} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            :
                            <div className="c-listProducts__list">
                                {
                                    products.map((product, key) => {
                                        return (
                                            (key < maxLoadmore) &&
                                            <Product key={key} {...product} type={type} index={key} productsViewed={productsViewed}
                                                deleteProduct={deleteProduct}
                                                pageName={`${name} ${type === 'refund' ? ' - Me faire rembourser' : ' - Imprimer mes bons'}`} />
                                        )
                                    })
                                }
                            </div>
                        :
                        <div
                            className="c-listProducts__noresults">Aucun résultat correspondant à votre recherche</div>
                }

                {(button && products.length > maxLoadmore) &&
                    <div className="c-listProducts__buttonCtn">
                        <button className="btn-2" onClick={handleClick} title={button.title}>{button.title}</button>
                    </div>
                }
            </div>
            
        </div>
        {
            showLoader && needLoader && <Loader />
        }
        </>
    )
};

export default ListProducts;