import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
//Hooks
import useApi from '../Hooks/useApiGlobal';
//Composant
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from "../Components/loader";
import VisuelLarge from "../Components/visuelLarge";
import CommunityBlockRoundedTitle from "../Components/communityBlockRoundedTitle";
import CommunityHpResults from "../Components/communityHpResults";

// Visuel
// import backgroundImage from "../assets/img/community/questions.png";

const TemplateCommunityHPQuestions = ({ _uid }) => {

    const postQuestionPath = useSelector(state => state.routes).routes.postQuestion;
    // CHANGE ROUTE WHEN DONE
    const searchResultsPath = useSelector(state => state.routes).routes.searchQuestion;
    const baseURL = process.env.REACT_APP_APP;
    const { desktop } = useSelector(state => state.breakpoint);
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-communityHp-questions'));
    }, [dispatch]);

    //Requete pour récup les meta data
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        include: 'content_config_form_files',
        fields: '&fields[node--special]=metatag,title,field_special_configformref,content_config_form_files',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const getVisuals = (array, uuidDesktop, uuidMobile) => {
        let imgUrl
        array.forEach((obj) => {
            if (desktop) {
                if (Object.values(obj).indexOf(uuidDesktop) > -1) {
                    imgUrl = obj.uri.url
                    return false
                }
            } else {
                if (Object.values(obj).indexOf(uuidMobile) > -1) {
                    imgUrl = obj.uri.url
                    return false
                }
            }
        })
        return imgUrl
    }

    const renderPage = () => {
        const { metatag, contentConfigFormFiles } = dataRedux;
        const { hero, searchbar, themes } = dataRedux.fieldSpecialConfigformref.data;
        //-- Tracking
        let pageviewEvent = {
            category: 'Communauté',
            category2: 'Questions et Réponses'
        };
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <VisuelLarge
                    urlVisuelStatic={baseURL + getVisuals(contentConfigFormFiles, hero.images.imageDesktop.uuid, hero.images.imageMobile.uuid)}
                />
                <div className="container">
                    <CommunityBlockRoundedTitle
                        title="Questions & réponses"
                        text={hero.text ? hero.text : "La communauté d’entreaide interagit tous les jours. Posez votre question ou aidez les membres en leur apportant une réponse !"}
                        btnUrl={postQuestionPath}
                        btnText="Je pose ma question"
                        btnSearchText="Rechercher"
                        inputPlaceholder="Tapez votre question"
                        searchResultsPath={searchResultsPath}
                    />
                </div>
                <CommunityHpResults
                    postType="question"
                    title={searchbar.text ? searchbar.text : "Consultez ou répondez à toutes les questions de la communauté"}
                    titleThematics={themes.text ? themes.text : "Choisissez une thématique"}
                    url='/communaute/questions/'
                />
            </>
        )
    }
    return (isLoaded && dataRedux) ? renderPage() : <Loader />
};

export default TemplateCommunityHPQuestions;