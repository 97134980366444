import React, {useEffect, useState} from "react";
import useApi from '../../Hooks/useApiGlobal';
import {useLocation, matchPath} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Breadcrumb = () => {
    const location = useLocation();
    const [dataRedux, setDataRedux] = useState(null);

    const [dataFetch, isLoaded] = useApi({
        name: 'api/breadcrumb',
        path:location.pathname,
        method: "get"
    });

    // Loader
    let listRoutes = useSelector(state => state.global).routes;

    useEffect(() => {
        if (isLoaded && dataFetch) {
            if (dataFetch.data && dataFetch.data.length > 0) {
                setDataRedux(dataFetch.data);
            } else {
                setDataRedux([{uri: "/", title: "Home"}, {uri: "/*", title: "404"}])
            }
        }
    }, [isLoaded, dataFetch]);


    const renderBreadCrumb = () => {
        return (
            <div className="c-breadcrumb" aria-label="breadcrumb">
                <div className="container">
                    <ol
                        itemScope={true}
                        itemType="http://schema.org/BreadcrumbList"
                    >
                        {
                            dataRedux.map(({uri, title}, key) => {
                                let linked = false;
                                for (let item of listRoutes) {
                                    if (!!matchPath(uri, item) && item.component !== "lba_common.404_page") {
                                        linked = true
                                    }
                                    if (linked === true) {
                                        break;
                                    }
                                }

                                // listRoutes.forEach(function (item) {
                                //     if(!!matchPath(uri, item)) {
                                //         linked = true
                                //     }
                                //     if(linked === true) {
                                //         break;
                                //     }
                                // })

                                return (
                                    <li
                                        key={key}
                                        itemProp="itemListElement"
                                        itemScope={true}
                                        itemType="http://schema.org/ListItem"
                                    >
                                        {
                                            key < parseInt(dataRedux.length - 1) ?
                                                linked ?
                                                    <a
                                                        href={uri}
                                                        itemScope={true}
                                                        itemType="http://schema.org/Thing"
                                                        itemProp="item"
                                                        itemID={uri}
                                                    >
                                                        <span itemProp="name">{title}</span>
                                                    </a>
                                                    :
                                                    <span
                                                        href={uri}
                                                        itemScope={true}
                                                        itemType="http://schema.org/Thing"
                                                        itemProp="item"
                                                        itemID={uri}
                                                    >
                                                        <span itemProp="name">{title}</span>
                                                    </span>
                                                :
                                                <span
                                                    itemScope={true}
                                                    itemType="http://schema.org/Thing"
                                                    itemProp="item"
                                                    itemID={uri}
                                                >
                                                   <span itemProp="name">{title}</span>
                                               </span>
                                        }

                                        <meta itemProp="position" content={key.toString()}/>
                                    </li>
                                )
                            })
                        }
                    </ol>
                </div>
            </div>
        )
    };

    return dataRedux && Array.isArray(dataRedux) ? renderBreadCrumb() : null
};

export default Breadcrumb