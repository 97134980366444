import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';

// Components
import TemplateEconomyGlobal from '../Templates/TemplateEconomyGlobal';

const TemplateEconomyCashback = ({_uid}) => {

    const location = useLocation();
    const [params, setParams] = useState({loaded: false, brand: '', shelf: '', univers: ''});

    useEffect(() => {
        if (location.pathname.indexOf('remboursement-marque-') > 0 && params.loaded === false) {
            setParams({loaded: false, brand: location.pathname, shelf: "", univers:""})
        }
        else if (location.pathname.indexOf('remboursement-rayon-') > 0 && params.loaded === false) {
            setParams({loaded: false, brand: "", shelf: location.pathname, univers:""})
        }
        else if (location.pathname.indexOf('remboursement-univers-') > 0 && params.loaded === false) {
            setParams({loaded: false, brand: "", shelf: "", univers:location.pathname})
        }
        else if (params.loaded === false) {
            setParams({loaded: false, brand: "", shelf: "", univers: ""})
        }
    }, [location])

    const renderPage = () => {

        return (
            <>
                <TemplateEconomyGlobal _uid={_uid} typeEco="cashback" {...params} />
            </>
        )
    };
    return renderPage()
};

export default TemplateEconomyCashback;