import React from "react";

const Timeline = ({activeStep}) => {
    let steps = 3;
    return (
        <>
            {(activeStep <= steps && activeStep > 0) &&
            <div className="c-timeline">
                {[...Array(steps)].map((e, i) => (
                    <div className="c-timeline__item" data-completed={(i + 1) <= activeStep}
                         data-width={Math.round(1 / (steps - 1) * 100)} key={i}><span>{i + 1}</span></div>
                ))}
            </div>
            }
        </>
    )
};

export default Timeline;