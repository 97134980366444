import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";

// Hooks
import useTracking from "../Hooks/useTracking";
import useIntersection from '../Hooks/useIntersection';

// Utils
import {getImage} from "../Utils/getMedia";
import {randomString} from "../Utils/md5-hash";

// Components
import CLink from "./cLinks";
import Favorite from "./favorite";


const Article = ({fieldCommonTxtTitle, fieldCommonDuration, fieldCommonPgphBan, id, fieldCommonTrUniverses, fieldCommonVideo, path, drupalInternalNid}) => {
    const {desktop} = useSelector(state => state.breakpoint);
    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    // Get user state
    let {iupid, pseudo} = useSelector(state => state.user);
    let userId = undefined;
    if (iupid && pseudo) userId = iupid;

    const selectContent = (contentType, contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: contentType,
                content_name: contentName
            },
            refresh: refresh
        });
    };

    // TRACKING - View content (Article)
    const ref = useRef(null);
    const inViewport = useIntersection(ref, '0px');

    const viewContent = () => {
        let $content_type = fieldCommonTrUniverses[0].name;
        let $content_name = fieldCommonTxtTitle;

        window.customDataLayer.push({
            event: 'view_content',
            user_id: userId,
            content_type: $content_type,
            content_name: $content_name
        });
        // console.log('customDataLayer', window.customDataLayer);
    };

    useEffect(() => {
        if (inViewport) {
            viewContent();
        }
    }, [inViewport]);

    return (
        <div className="c-article" ref={ref}>
            <Favorite id={drupalInternalNid} universe={fieldCommonTrUniverses[0].name}/>
            <CLink url={path.alias ? path.alias : '/node/' + drupalInternalNid}
                   handleClick={() => selectContent(fieldCommonTrUniverses[0].name, fieldCommonTxtTitle, randomString(6))}>
                <div className="c-article_visuel" data-category={fieldCommonTrUniverses[0].name}
                     style={fieldCommonPgphBan.fieldRespimgImgDesktop ? {backgroundImage: `url(${getImage(fieldCommonPgphBan, desktop, 'carrousel')})`} : {}}>
                    {fieldCommonPgphBan.fieldRespimgImgDesktop &&
                    <img src={getImage(fieldCommonPgphBan, desktop, 'carrousel')}
                         alt={fieldCommonTxtTitle && fieldCommonTxtTitle}
                         className="d-none print-image"/>
                    }
                    {fieldCommonTrUniverses &&
                    fieldCommonTrUniverses[0].name ?
                        <div className="c-article_category">{fieldCommonTrUniverses[0].name}</div> : ''
                    }
                    {fieldCommonVideo &&
                    <div className="c-article_video"></div>
                    }
                </div>
                <div className="c-article_container">
                    <div className="c-article_content">
                        {fieldCommonTxtTitle && <h3>{fieldCommonTxtTitle}</h3>}
                        {fieldCommonDuration &&
                        <div
                            className="c-article_time">{Math.floor(fieldCommonDuration.seconds / 60) + "min"}{(fieldCommonDuration.seconds - Math.floor(fieldCommonDuration.seconds / 60) * 60) > 0 && fieldCommonDuration.seconds - Math.floor(fieldCommonDuration.seconds / 60) * 60}</div>
                        }
                    </div>
                </div>
            </CLink>
        </div>
    )
};

export default Article;