export const getImage = (urlVisuel, desktop, type="") => {
    let href=''
    if(urlVisuel) {
        switch (type) {
            case 'carrousel' :
                if(desktop) { //sur desktop
                    if(urlVisuel.fieldRespimgImgDesktop && urlVisuel.fieldRespimgImgDesktop.links.commonCarrouselDesktop)
                        href = urlVisuel.fieldRespimgImgDesktop.links.commonCarrouselDesktop.href
                }
                else { //sur mobile

                    if(urlVisuel.fieldRespimgImgMobile) { // image mobile fournie
                        href = urlVisuel.fieldRespimgImgMobile.links.commonCarrouselMobile.href
                    }
                    else { //pas d'image mobile fournie == image desktop avec style mobile
                        href = urlVisuel.fieldRespimgImgDesktop.links.commonCarrouselMobile.href
                    }
                }
                break;
            case 'accordion' :
                if(desktop) { //sur desktop
                    if(urlVisuel.fieldRespimgImgDesktop && urlVisuel.fieldRespimgImgDesktop.links.brandAccordionDesktop)
                        href = urlVisuel.fieldRespimgImgDesktop.links.brandAccordionDesktop.href
                }
                else { //sur mobile
                    if(urlVisuel.fieldRespimgImgMobile) { // image mobile fournie
                        href = urlVisuel.fieldRespimgImgMobile.links.brandAccordionMobile.href
                    }
                    else { //pas d'image mobile fournie == image desktop avec style mobile
                        if(urlVisuel.fieldRespimgImgDesktop) {
                            href = urlVisuel.fieldRespimgImgDesktop.links.brandAccordionMobile.href
                        } else {
                            href = urlVisuel.links.brandAccordionDesktop.href
                        }
                    }
                }
                break;
            case 'logo' :
                if(desktop) { //sur desktop
                    if(urlVisuel.fieldRespimgImgDesktop && urlVisuel.fieldRespimgImgDesktop.links.commonLogoDesktop)
                        href = urlVisuel.fieldRespimgImgDesktop.links.commonLogoDesktop.href
                }
                else { //sur mobile
                    if(urlVisuel.fieldRespimgImgMobile) { // image mobile fournie
                        href = urlVisuel.fieldRespimgImgMobile.links.commonLogoMobile.href
                    }
                    else { //pas d'image mobile fournie == image desktop avec style mobile
                        href = urlVisuel.fieldRespimgImgDesktop.links.commonLogoMobile.href
                    }
                }
                break;
            
                case 'ban' :
                    if(desktop) { //sur desktop
                        if(urlVisuel.fieldRespimgImgDesktop && urlVisuel.fieldRespimgImgDesktop.links.pushBan)
                            href = urlVisuel.fieldRespimgImgDesktop.links.pushBan.href
                    }
                    else { //sur mobile
                        if(urlVisuel.fieldRespimgImgMobile) { // image mobile fournie
                            href = urlVisuel.fieldRespimgImgMobile.links.pushBanMobile.href
                        }
                        else { //pas d'image mobile fournie == image desktop avec style mobile
                            href = urlVisuel.fieldRespimgImgDesktop.links.pushBanMobile.href
                        }
                    }
                    break;
            default :
                if(desktop) { //sur desktop
                    if(urlVisuel.fieldRespimgImgDesktop && urlVisuel.fieldRespimgImgDesktop.links.commonFullWidthBanDesktop)
                        href = urlVisuel.fieldRespimgImgDesktop.links.commonFullWidthBanDesktop.href
                }
                else { //sur mobile
                    if(urlVisuel.fieldRespimgImgMobile) { // image mobile fournie
                        href = urlVisuel.fieldRespimgImgMobile.links.commonFullWidthBanMobile.href
                    }
                    else { //pas d'image mobile fournie == image desktop avec style mobile
                        href = urlVisuel.fieldRespimgImgDesktop.links.commonFullWidthBanMobile.href
                    }
                }
                break;
        }
        
    }
    return href;
};

export const getVideo = (infoVideo) => {
    let url=''
    if(infoVideo) {
        // url = urlVisuel.fieldRespimgImgDesktop.links.commonFullWidthBanMobile.href;
        url=infoVideo.fieldRespimgImgVideo.filename;
    }
    return url;
};