import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import { updateGlobalStorePage } from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

import { camelize } from '../Utils/transformText';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import VisuelLarge from "../Components/visuelLarge";
import Video from "../Components/article/video";
import Outils from "../Components/article/outils";
import WysiwygSimple from "../Components/wysiwygSimple";
import WysiwygLoadmore from "../Components/wysiwygLoadmore";
import CarouselArticles from "../Components/carouselArticles";
import Comments from "../Components/comments";
import Steps from "../Components/article/stepText";
import { Loader } from "../Components/loader";
import CarouselAllReduction from "../Components/carouselAllReduction";
import chatBoxManager from "../Utils/chatBoxManager";
import BannerBrand from "../Components/bannerBrand";
import Tableau from '../Components/tableau';
import Cta from "../Components/cta";
import CarouselCashback from "../Components/carouselCashback";
import CarouselWebcoupon from "../Components/carouselWebcoupon";
import VisuelLegend from "../Components/visuelLegend";

const TemplateContentPage = ({ _uid, contentType }) => {
    const { desktop } = useSelector(state => state.breakpoint);
    const dataUser = useSelector(state => state.user);

    const { chatbot } = useSelector(state => state.global).global;
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-article'));
    }, [dispatch]); // isLoaded

    let contentTypeCamel = camelize(contentType);

    //recup de la bonne api selon le contentTypeCamel
    let apiUrl = {};
    switch (contentTypeCamel) {
        case "nodeArticleVideo":
            apiUrl = {
                name: `api/node/article_video/${_uid}`,
                include: 'field_common_tr_universes,field_common_tr_shelves,field_common_tr_brands,field_common_tr_hubs,field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_video',
                fields: '&fields[node--article_video]=field_common_tr_shelves,field_article_txt_body,field_article_txt_intro,field_common_txt_title,field_common_duration,field_common_pgph_ban,field_common_video,metatag,field_common_tr_universes,field_common_tr_hubs,field_common_tr_brands,drupal_internal__nid&fields[taxonomy_term--universe]=name,field_api_remoteid_universeid&fields[taxonomy_term--shelf]=name,field_api_remoteid_shelfid&fields[taxonomy_term--brand]=field_api_remoteid_brandid',
                method: "get"
            };
            break;
        case "nodeDiy":
            apiUrl = {
                name: `api/node/diy/${_uid}`,
                include: 'field_common_tr_universes,field_common_tr_shelves,field_common_tr_brands,field_common_tr_hubs,field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_diy_pgph_requrmnt,field_diy_pgph_requrmnt.field_diy_img_step,field_diy_pgph_steps,field_diy_pgph_steps.field_diy_img_step,field_common_video',
                fields: '&fields[node--diy]=field_common_tr_hubs,field_common_tr_shelves,field_common_tr_brands,field_diy_txt_intro,field_common_txt_title,field_common_duration,field_common_pgph_ban,metatag,field_diy_pgph_steps,field_diy_pgph_requrmnt,field_diy_txt_requmnt_title,field_diy_txt_steps_title,field_common_video,field_common_tr_universes,drupal_internal__nid&fields[taxonomy_term--universe]=name,field_api_remoteid_universeid&fields[taxonomy_term--shelf]=name,field_api_remoteid_shelfid&fields[taxonomy_term--brand]=field_api_remoteid_brandid',
                method: "get"
            };
            break;
        case "nodeArticle":
            apiUrl = {
                name: `api/node/article/${_uid}`,
                include: 'field_common_tr_universes,field_common_tr_shelves,field_common_tr_brands,field_common_tr_hubs,field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_pgph_contents,field_common_pgph_contents.paragraph_type,field_common_pgph_contents.field_landing_pgph_steps,field_common_pgph_contents.field_landing_pgph_steps.field_diy_img_step,field_common_pgph_contents.field_common_pgph_ban.field_respimg_img_desktop,field_common_pgph_contents.field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_contents.field_landing_video',
                fields: '&fields[node--article]=field_common_pgph_contents,field_common_tr_shelves,field_article_txt_body,field_article_txt_intro,field_common_txt_title,field_common_duration,field_common_pgph_ban,metatag,field_common_tr_universes,field_common_tr_hubs,field_common_tr_brands,drupal_internal__nid&fields[taxonomy_term--universe]=name,field_api_remoteid_universeid&fields[taxonomy_term--shelf]=name,field_api_remoteid_shelfid&fields[taxonomy_term--brand]=field_api_remoteid_brandid',
                method: "get"
            };
            break;
        default:
            break;
    }

    //Requete de réupération des infos de la page (meta + article)
    const [dataFetch, isLoaded] = useApi(apiUrl);

    //Normalisation des resultats de dataFetch
    const [dataRedux, setDataRedux] = useState();//info article
    const [universRedux, setUniversRedux] = useState();//info univers
    const [showArticle, setShowArticle] = useState(false);
    useEffect(() => {
        if (isLoaded && dataFetch && dataFetch.data) {
            const normalizeJson = normalize(dataFetch.data);
            setDataRedux(build(normalizeJson, contentTypeCamel, _uid));
            setUniversRedux(build(normalizeJson, 'taxonomyTermUniverse'));
            if(Array.isArray(chatbot.hub_terms)){
                let globalTags = chatbot.hub_terms.map(hub => hub.uuid)
                chatBoxManager.check(dataFetch?.data?.data?.relationships?.field_common_tr_hubs?.data, globalTags);
            }
            setShowArticle(false)
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    useEffect(() => {
        if (dataRedux && Object.keys(dataRedux).length > 0) {
            setFilterApi({
                name: 'api/node/article',
                include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes,field_common_pgph_contents',
                fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes,field_common_tr_hubs',
                filter: '&filter[field_common_tr_universes.id]=' + dataRedux.fieldCommonTrUniverses[0].id + '&filter[field_common_tr_hubs.id]=' + dataRedux.fieldCommonTrHubs[0].id + '&filter[field_common_list_seasonalities]=' + parseInt(new Date().getMonth() + 1) + '&filter[no-current][condition][path]=id&filter[no-current][condition][operator]=<>&filter[no-current][condition][value]=' + _uid,
                sort: '&sort[sort-view][path]=node_view&sort[sort-view][direction]=DESC&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                limit: 15,
                method: 'get'
            })
        }
    }, [dataRedux]); // eslint-disable-line

    const [filterApi, setFilterApi] = useState({});

    //Requete qui permet de recup les articles
    const [dataFetchArticles, isLoadedArticles] = useApi(filterApi);

    //Normalisation de dataFetcharticle
    const [articlesRedux, setArticlesRedux] = useState('');// Infos Articles
    useEffect(() => {
        if (isLoadedArticles && dataRedux) {
            if (dataFetchArticles.data.data.length > 0) {
                if (isLoadedArticles && dataFetchArticles) {
                    
                    const normalizeJsonArticles = normalize(dataFetchArticles.data);//Normalisation du json
                    setArticlesRedux(build(normalizeJsonArticles, 'nodeArticle'));
                }
            }
            else if (!showArticle) {
                setFilterApi({
                    name: 'api/node/article',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                    fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes',
                    filter: '&filter[field_common_tr_universes.id]=' + dataRedux.fieldCommonTrUniverses[0].id + '&filter[field_common_tr_hubs.id]=' + dataRedux.fieldCommonTrHubs[0].id + '&filter[no-current][condition][path]=id&filter[no-current][condition][operator]=<>&filter[no-current][condition][value]=' + _uid,
                    sort: '&sort[sort-view][path]=node_view&sort[sort-view][direction]=DESC&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                    limit: 15,
                    method: 'get'
                });
                setShowArticle(true)
            }
            const headers = {
                "Content-Type": "application/json"
            };
            setVueApi({
                name: 'api/node-view/' + dataRedux.drupalInternalNid,
                method: 'post',
                noData: true,
                config: headers
            });
        }

    }, [isLoadedArticles, dataFetchArticles, dataRedux, showArticle]); // eslint-disable-line

    //Permet d'ajouter au compteur de vue
    const [vueApi, setVueApi] = useState({}); // eslint-disable-line
    const [dataFetchVue, isLoadedVue] = useApi(vueApi); // eslint-disable-line

    const handlePrint = () => {
        window.print()
    };

    const splitTxt = (txt) => {
        const contentTosplit = txt.processed + '';
        const part1 = contentTosplit.substr(0, 500);
        const part2 = contentTosplit.substr(500, contentTosplit.length);
        return { 'part1': part1, 'part2': part2 }
    };

    const renderMarkup = (markup) => {
        return { __html: markup };
    };


    const renderPage = () => {
        const { fieldCommonTrHubs, fieldArticleTxtBody, fieldArticleTxtIntro, fieldDiyTxtIntro, fieldCommonTxtTitle, fieldCommonDuration, fieldCommonPgphBan, fieldCommonVideo, fieldDiyPgphRequrmnt, fieldDiyTxtRequmntTitle, fieldDiyPgphSteps, fieldDiyTxtStepsTitle, fieldCommonTrUniverses, fieldCommonTrShelves, metatag, fieldCommonTrBrands, fieldCommonPgphContents: flexibles} = dataRedux;

        let brandIDs;
        let universeIDs;
        let shelfIDs;

        if (fieldCommonTrBrands.length > 0) {
            let brandsArray = [];
            fieldCommonTrBrands.map(brand => {
                if (brandsArray.indexOf(parseInt(brand.fieldApiRemoteidBrandid)) === -1) {
                    brandsArray.push(parseInt(brand.fieldApiRemoteidBrandid));
                }
                return null;
            });
            brandIDs = brandsArray
        }


        if (fieldCommonTrUniverses.length > 0) {
            let universesArray = [];
            fieldCommonTrUniverses.map(universe => {
                if (universesArray.indexOf(parseInt(universe.fieldApiRemoteidUniverseid)) === -1) {
                    universesArray.push(parseInt(universe.fieldApiRemoteidUniverseid));
                }
                return null;
            });
            universeIDs = universesArray;
        }


        if (fieldCommonTrShelves.length > 0) {
            let shelvesArray = [];
            fieldCommonTrShelves.map(shelf => {
                if (shelvesArray.indexOf(parseInt(shelf.fieldApiRemoteidShelfid)) === -1) {
                    shelvesArray.push(parseInt(shelf.fieldApiRemoteidShelfid));
                }
                return null;
            });
            shelfIDs = shelvesArray;
        }

        const universe = fieldCommonTrUniverses[0].name;
        const shielf = fieldCommonTrShelves.length > 0 ? fieldCommonTrShelves[0].name : "";

        // Text divided
        let contentSplit = { part1: '', part2: '' };
        if (fieldArticleTxtBody) {
            let bodyLength = fieldArticleTxtBody.processed.length;
            if (bodyLength > 500) {
                contentSplit = splitTxt(fieldArticleTxtBody);
            }
        }

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'Conseils et Astuces',
            category2: fieldCommonTrHubs[0].name,
            category3: universRedux[0].name,
        };

        let dataTrackingEventViewMore = {
            event: 'view_more_content',
            content_type: universRedux.reduce((pv, cv, index) => index === 0 ? `${cv.name}` : `${pv}, ${cv.name}`, ""), // agregation of univers name
            content_name: dataRedux.fieldCommonTxtTitle,
            user_id: dataUser.uuid,
            membership_level: "", //Not implemented
        }

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                {(fieldCommonVideo && fieldCommonPgphBan) ?
                    <Video infoVideo={fieldCommonVideo} infoImage={fieldCommonPgphBan} desktop={desktop} />
                    :
                    fieldCommonPgphBan &&
                    <VisuelLarge urlVisuel={fieldCommonPgphBan && fieldCommonPgphBan} desktop={desktop} isTrueSize={true} />
                }

                
                <div className="container" id="printContainer">
                    <div className="t-article_container rounded--">
                        <Outils time={fieldCommonDuration && fieldCommonDuration.seconds}
                            category={universRedux && universRedux} printElt={handlePrint} contentType={universe} />
                        {fieldDiyTxtIntro &&
                            <WysiwygSimple title={fieldCommonTxtTitle && fieldCommonTxtTitle}
                                content={fieldDiyTxtIntro && fieldDiyTxtIntro.processed} />
                        }
                        {fieldArticleTxtIntro &&
                            <WysiwygSimple title={fieldCommonTxtTitle && fieldCommonTxtTitle}
                                content={fieldArticleTxtIntro && fieldArticleTxtIntro.processed} />
                        }
                        {fieldDiyPgphRequrmnt &&
                            <Steps listStep={[fieldDiyPgphRequrmnt]} title={fieldDiyTxtRequmntTitle} desktop={desktop}
                                className="column--" />
                        }
                    </div>

                    <div className="container">
                        <CarouselAllReduction desktop={desktop}
                            brandID={brandIDs}
                            shelfID={shelfIDs}
                            universeID={universeIDs}
                            name={fieldCommonTxtTitle}
                            universe={universe}
                            shielf={shielf} />
                    </div>

                    <div className="t-article_container">
                        {fieldDiyPgphSteps &&
                            <Steps listStep={fieldDiyPgphSteps} title={fieldDiyTxtStepsTitle} desktop={desktop} dataTrackingEventViewMore={dataTrackingEventViewMore} />
                        }

                        {(contentSplit.part1 && contentSplit.part2) ?
                            <WysiwygLoadmore part1={contentSplit.part1} part2={contentSplit.part2} dataTrackingEventViewMore={dataTrackingEventViewMore} />
                            :
                            fieldArticleTxtBody &&
                            <div className="t-article_container">
                                <div className="c-wysiwygSimple">
                                    <div className="c-wysiwygSimple_content c-wysiwyg">
                                        <div dangerouslySetInnerHTML={renderMarkup(fieldArticleTxtBody.processed)} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="container">
                    {(flexibles && flexibles.length > 0) &&
                        flexibles.map(({ paragraphType: { label }, fieldCommonTitle, fieldCommonTxtTextbody, fieldEconomyTxtEanCodes, fieldEconomyTxtOfferrefs, fieldCtaLanding: cta, fieldLandingVideo: video, fieldCommonPgphBan: img, fieldCommonTxtTexttitle: legend, fieldLandingPgphSteps: steps }, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {
                                        {
                                            'Texte': (fieldCommonTitle || fieldCommonTxtTextbody) &&
                                                <div className="t-landingPage_container">
                                                    <WysiwygSimple title={fieldCommonTitle && fieldCommonTitle.processed}
                                                        content={fieldCommonTxtTextbody && fieldCommonTxtTextbody.processed} transform={false} />
                                                </div>,
                                            'Tableau': fieldCommonTxtTextbody &&
                                                <div className="t-landingPage_container">
                                                    <Tableau {...fieldCommonTxtTextbody} />
                                                </div>,
                                            'CTA ': (cta && cta.uri && cta.title) &&
                                                <div className="t-landingPage_container">
                                                    <Cta url={cta.uri.replace('internal:', '')}
                                                        target={cta.uri.includes("internal:/") ? "_self" : "_blank"}
                                                        label={cta.title} />
                                                </div>,
                                            'Bloc Cashback': <CarouselCashback desktop={desktop}
                                                universeID={universeIDs && universeIDs}
                                                brandID={brandIDs && brandIDs} 
                                                offerRefs={fieldEconomyTxtOfferrefs && fieldEconomyTxtOfferrefs}/>,
                                            'Bloc Webcoupons': <CarouselWebcoupon desktop={desktop}
                                                universeID={universeIDs && universeIDs}
                                                brandID={brandIDs && brandIDs} 
                                                eanCodes={fieldEconomyTxtEanCodes && fieldEconomyTxtEanCodes}/>,
                                            'Vidéo': (video && video.filename) &&
                                                <div className="t-landingPage_container">
                                                    <Video infoVideo={video}
                                                        infoImage={img} desktop={desktop} />
                                                </div>,
                                            'Image responsive avec légende': img &&
                                                <div className="t-landingPage_container">
                                                    <VisuelLegend visuel={img}
                                                        legend={legend && legend} desktop={desktop} />
                                                </div>,
                                            'Etapes': (steps && steps.length > 0) &&
                                                <div className="t-landingPage_container">
                                                    <Steps listStep={steps} desktop={desktop}
                                                        className="column--" />
                                                </div>
                                        }[label]
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </div>

                <BannerBrand />

                <div className="t-diy_grey">
                    <Comments _uid={_uid} dataType={contentType} contentType={universe} />
                    {(articlesRedux && articlesRedux.length > 0) &&
                        <div className="container">
                            <div className="t-diy_articles">
                                <h2 className="section-title">Cela pourrait vous plaire</h2>
                                <CarouselArticles carousel={articlesRedux} desktop={desktop}
                                    carouselName="more-c-articles" />
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    };
    return (isLoaded && dataRedux && universRedux) ? renderPage() : <Loader />
};

export default TemplateContentPage;