import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

// Hooks
import {updateModalsOpen, updateModalsLoginType} from "../../Store/action";
import useApi from '../../Hooks/useApiGlobal';

// Libraries
import {useForm} from "react-hook-form";
import Axios from "axios";

// Components
import Modal from "./modal";
import {Loader} from "../../Components/loader";
import {strengthIndicator, strengthValueIndicator} from "../../Components/strength-password";


const ReinitPasswordModal = () => {
    const baseURL = process.env.REACT_APP_APP;

    let dispatch = useDispatch();
    // Modal info
    const modalsOpen = useSelector(state => state.modals).openModal;
    const pwdToken = useSelector(state => state.modals).passwordToken;

    // Loader
    let [loading, setLoading] = useState(false);

    // Confirmation content
    const [passwordChanged, setPasswordChanged] = useState(false);


    // Password form
    const {register, handleSubmit, errors, watch} = useForm();

    // Check confirm password
    const newPassword = useRef({});
    newPassword.current = watch("newPassword", "");

    // Check password format
    const [invalidPwd, setInvalidPwd] = useState(false);
    // const handlePwdChange = (e) => {
    //     if(!e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)){
    //         // Doit contenir 1 majuscule, 1 minicule, 1 chiffre
    //         setInvalidPwd(true)

    //         // Peut contenir caractère special
    //         if(e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/)) {    // eslint-disable-line
    //             setInvalidPwd(false)
    //         }
    //     } else {
    //         setInvalidPwd(false)
    //     }
    // };

    let [password, setPassword] = useState({
        indicator: strengthIndicator(''),
        value: strengthValueIndicator('')
    });
    const handlePwdChange = (e) => {
        setPassword({
            indicator: strengthIndicator(e.target.value),
            value: strengthValueIndicator(strengthIndicator(e.target.value))
        });

        // Check password format
        if (!e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) {
            // Doit contenir 1 majuscule, 1 minicule, 1 chiffre
            setInvalidPwd(true)

            // Peut contenir caractère special
            if (e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/)) {    // eslint-disable-line
                setInvalidPwd(false)
            }
        } else {
            setInvalidPwd(false)
        }
    };

    // Hide / Show password
    const [revealPassword, setrevealPassword] = useState(false);
    const [revealPasswordConfirm, setrevealPasswordConfirm] = useState(false);
    const handleClickPassword = (e) => {
        switch (e.target.dataset.type) {
            case 'newPassword':
                setrevealPassword(!revealPassword);
                break;

            case 'confirmNewPassword':
                setrevealPasswordConfirm(!revealPasswordConfirm);
                break;
            default:
                break;
        }

    };
    // END Hide / Show password

    const onSubmit = (dataForm) => {
        // console.log('dataForm', dataForm);

        const dataSend = {
            "lbapassword": dataForm.newPassword,
            "token": pwdToken
        };

        // Récupération du token à passer ds le header
        let fetchJeton = Axios.post(`${baseURL}/session/token/`);

        fetchJeton.then(res => {
            let {status, data} = res;

            if (status === 200) {
                SendData(data, dataSend);

                // Show loader
                setLoading(true);

                //console.log('dataform ', data);

            } else {
                console.log('erreur', data);
                // Hide loader
                setLoading(false);
            }
        });
    };

    let [paramsSend, setParamsSend] = useState({})
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const SendData = (jeton, dataSend) => {
        const headers = {
            headers: {
                "X-CSRF-Token": jeton
            }
        }
        setParamsSend({
            name: 'api/password-update',
            method: 'patch',
            data: dataSend,
            config: headers,
            stringifyRequest: false
        })
    };

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let {status, data} = responseSend;
            // console.log(data);
            if (status === 200) {
                //tout est ok
                //console.log('success / data : ', data);
                // Show success screen
                setPasswordChanged(true);
            }
            else {
                //Erreur
                console.log('ERREUR', data.errors);
            }

            // Hide loader
            setLoading(false);
        }
    }, [responseSend, isLoadedSend]);

    // Open Modal
    const handleOpen = () => {
        dispatch(updateModalsOpen({...modalsOpen, 'login': true, 'changePassword': false, 'alert': true}));
        dispatch(updateModalsLoginType('login'));
    };


    return (
        <Modal modalName="changePassword" modalNameTracking="Réinitialisation du mot de passe" disableClosing="true">
            {!passwordChanged ?
                <div className="c-modal_password c-modal_change-password">
                    <div className="c-modal_password-intro">
                        <h2 className="title-1">Réinitialisation de votre mot de passe</h2>
                        <p>Vous avez demandé la réinitialisation de votre mot de passe.<br/>
                           Merci de renseigner votre nouveau mot de passe.</p>
                        <p>Vous serez ensuite redirigé vers la page d'accueil et vous devrez vous reconnecter.</p>
                    </div>
                    <form className="c-login_form c-form" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="c-form_line">
                                <div className="c-form_lineEltLarge">
                                    <div className="c-form_info">
                                        <input type={revealPassword ? 'text' : "password"}
                                               placeholder="Nouveau mot de passe*" name="newPassword"
                                               id="newPassword"
                                               ref={register({
                                                   required: 'Le champs doit être renseigné.',
                                                   minLength: {
                                                       value: 8,
                                                       message: 'Votre mot de passe doit contenir au moins 8 caractères'
                                                   },
                                               })}
                                               onChange={(e) => handlePwdChange(e)}
                                               className="has-tooltip"
                                               data-error={(errors.newPassword || invalidPwd) ? true : false}
                                        />
                                        <div className="c-form_tooltip">
                                            <i className={revealPassword ? 'icon-eye-no' : "icon-eye"}
                                               data-type="newPassword"
                                               onClick={handleClickPassword}></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`c-form_password ${password.indicator && 'level' + password.indicator + '--'} `}>
                                        <div className="progressBar"><span>{password.value}</span></div>
                                    </div>
                                    <div className="error--">{errors.newPassword && errors.newPassword.message}</div>
                                    <div
                                        className="error--">{invalidPwd && !errors.lba_lbapassword && "Votre mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre"}</div>
                                </div>
                            </div>
                            <div className="c-form_line">
                                <div className="c-form_lineEltLarge">
                                    <div className="c-form_info">
                                        <input type={revealPasswordConfirm ? 'text' : "password"}
                                               placeholder="Confirmer le mot de passe*"
                                               name="confirmNewPassword"
                                               id="confirmNewPassword"
                                               ref={register({
                                                   validate: value =>
                                                       value === newPassword.current
                                               })}
                                               className="has-tooltip"
                                               data-error={errors.confirmNewPassword ? true : false}
                                        />
                                        <div className="c-form_tooltip">
                                            <i className={revealPasswordConfirm ? 'icon-eye-no' : "icon-eye"}
                                               data-type="confirmNewPassword"
                                               onClick={handleClickPassword}></i>
                                        </div>
                                    </div>

                                    <div
                                        className="error--">{errors.confirmNewPassword && "Les mots de passe ne correspondent pas."}</div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="c-button">Valider</button>
                    </form>

                    {loading && <div className="full-loader"><Loader/></div>}
                </div>
                :
                <div className="c-modal_password">
                    <h2 className="title-1">Votre mot de passe a bien été réinitialisé !</h2>
                    <button type="button" className="btn-2" onClick={() => handleOpen()}>Se connecter</button>
                </div>
            }

        </Modal>
    )
};

export default ReinitPasswordModal;