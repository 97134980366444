import { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import qs from 'qs';

import {
  getToken,
  setToken,
  eraseToken,
  eraseRefreshToken,
  getRefreshToken,
  setRefreshToken,
} from '../../Utils/cookie';
// import {updateUserUuid, updateUserIupid, updateUserPseudo} from '../Store/action';
import { getClientCredential } from '../../Utils/getClientService';
import {
  updateModalsLoginType,
  updateModalsOpen,
  updateUserIupid,
  updateUserPseudo,
  updateUserDateInscription,
  updateUserUuid,
} from '../../Store/action';

/**
 * @async Creation d'un hook d'appel des API avec oAuth Anonyme
 * @param {string} name : nom de l'API
 * @param {string} _uid : id de la page (route)
 * @param {string} include : données à inclure dans le résultat de la requete
 * @param {string} exclude : données à exclure dans le résultat de la requete
 * @param {string} fields : champs à récupérer
 * @param {string} filter : filtre des résultats
 * @param {string} sort : ordonnancement du résultat
 * @param {string} limit : limitation du nombre de résultat
 * @param {string} path : url de la page (decoupled router)
 * @param {string} method : post / get
 * @param {string} data : données à envoyer en parametres
 */

/*
let dataFetch = [];
let isLoaded = false;*/

const baseURL = process.env.REACT_APP_APP;

// Whether the mark is being refreshed
let isRefreshing = false;

// Retry queue, each item will be a form of function to be executed
let retryRequests = [];

let UserModule = {
  RefreshToken: (data) => {
    setToken(data.access_token);
    if (data.refresh_token) {
      setRefreshToken(data.refresh_token);
    }
  },
};

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_APP,
  timeout: 180000
});

let uuidStore = '';

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {};
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }
    // config.headers['Content-Type'] = 'application/vnd.api+json'
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // on renvoi le JSON
    return response;
  },
  (error) => {
    if (!error.response) {
      if (error.code === 'ECONNABORTED') {
        return 'timeout';
      } else {
        return Promise.reject(error);
      }
    }

    // const dispatch = useDispatch();
    // const history = useHistory();

    if ((error.response.status === 401 || error.response.status === 403) && !error.response.data.error) {
      const config = error.config;
      console.info('G - Token périmé ! ');
      // Add attempt request mechanism
      // Request give up after 3 attempts
      if (config.headers['AttemptNumber'] > 3) {
        return Promise.reject(error);
      }
      if (!isRefreshing) {
        isRefreshing = true;
        console.info('G - On rafraichit le Token ');
        return getRefreshTokenFunc(uuidStore)
          .then((res) => {
            console.info('G - Le rafraichissement du token est ok');
            // reset token
            UserModule.RefreshToken(res.data);
            config.headers['Authorization'] = 'Bearer ' + getToken();
            // The token has been refreshed, retry all the requests in the queue
            // @ts-ignore
            console.info('G - Rafraichissement de token ok - pile de requete', retryRequests);
            retryRequests.forEach((cb) => cb(getToken()));

            // Empty the queue after retrying
            retryRequests = [];
            return axiosInstance(config);
          })
          .catch((err) => {
            console.info('On deconnecte');
            eraseToken();
            eraseRefreshToken();
            return Promise.reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // Refreshing the token, returning a promise that has not been resolved
        console.info('G - Le token a été rafraichit on relance les requetes');
        return new Promise((resolve) => {
          // Put resolve into the queue, save it in a function form, and execute directly after the token is refreshed
          // @ts-ignore
          console.info('G - Le token a été rafraichit pile de requete', retryRequests);
          retryRequests.push((token) => {
            config.headers['Authorization'] = 'Bearer ' + token;
            resolve(axiosInstance(config));
          });
        });
      }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

function getRefreshTokenFunc(uuid) {
  console.info('getRefreshTokenFunc', uuid);
  let refresh = getRefreshToken();
  if (!uuid || uuid === '') {
    console.info(uuid);
    refresh = '';
    setRefreshToken('');
  }
  return Axios({
    method: 'post',
    url: baseURL + '/oauth/token',
    data: qs.stringify(
      getClientCredential(
        refresh,
        refresh !== 'undefined' && refresh !== null && refresh !== '' ? 'refresh_token' : 'client_credentials'
      )
    ),
  });
}

export const fetchActionTypes = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  RESET: 'RESET',
};

const initialFetchState = {
  data: null,
  error: null,
  status: 'INIT',
};

function fetchReducer(state = initialFetchState, action) {
  switch (action.type) {
    case fetchActionTypes.REQUEST:
      return { ...initialFetchState, status: 'LOADING' };
    case fetchActionTypes.SUCCESS:
      return { ...state, data: action.payload.data, status: 'SUCCESS' };
    case fetchActionTypes.FAILED:
      return { ...state, error: action.payload.error, status: 'FAILED' };
    case fetchActionTypes.RESET:
      return initialFetchState;
    default:
      return state;
  }
}

/**
 * @typedef {object} IOptions
 * @property {string} name
 * @property {string} [_uid='']
 * @property {string} grant_type
 * @property {string} include
 * @property {string} [exclude='']
 * @property {string} [fields='']
 * @property {string} [filter='']
 * @property {string} [sort='']
 * @property {string} [limit='']
 * @property {string} path
 * @property {string} [method='post']
 * @property {string} [currentData='']
 * @property {string} [consumerId='']
 * @property {string} [format='']
 * @property {boolean} [noData=false]
 * @property {object} [config={}]
 * @property {string} [recaptcha_token='']
 */

/**@type {IOptions} */
const requestInitOptions = {
  _uid: '',
  exclude: '',
  fields: '',
  filter: '',
  sort: '',
  limit: '',
  method: 'post',
  currentData: '',
  consumerId: '',
  format: '',
  noData: false,
  config: {},
  recaptcha_token: '',
};

/**
 * Custom hook to fetch data using axios
 * @param {IOptions} options
 * @param {Array<unknown>} props
 * @returns
 */
export function useApiGlobal(options = requestInitOptions, props = []) {
  const [state, dispatch] = useReducer(fetchReducer, initialFetchState);
  const appDispatch = useDispatch();
  uuidStore = useSelector((state) => state.user).uuid;
  const modalsOpen = useSelector((state) => state.modals).openModal;

  useEffect(() => {
    if (options.name && options.name !== '') {
      dispatch({ type: 'REQUEST' });
      (async () => {
        try {
          const data = await axiosInstance({
            method: options.method,
            url: `/${options.name}${options._uid ? `/${options._uid}` : ''}${
              options.format ||
              options.recaptcha_token ||
              options.include ||
              options.exclude ||
              options.fields ||
              options.filter ||
              options.sort ||
              options.limit ||
              options.path ||
              options.consumerId
                ? '?'
                : ''
            }${options.format ? `_format=${options.format}` : ''}${
              options.recaptcha_token ? `&recaptcha_token=${options.recaptcha_token}` : ''
            }${options.include ? `&include=${options.include}` : ''}${
              options.exclude ? `&exclude=${options.exclude}` : ''
            }${options.fields && options.fields}${options.filter && options.filter}${options.sort && options.sort}${
              options.limit && `&page[limit]=${options.limit}`
            }${options.path ? '&path=' + options.path : ''}${
              options.consumerId ? '&consumerId=' + options.consumerId : ''
            }`,
            data: !options.noData ? options.currentData : '',
            headers: options.config ? options.config : '',
          });
          dispatch({ type: 'SUCCESS', payload: { data } });
        } catch (error) {
          console.error({ error });
          if (error.response.data.message && error.response.data.message.includes('permission is required')) {
            appDispatch(updateUserUuid(''));
            appDispatch(updateUserIupid(''));
            appDispatch(updateUserPseudo(''));
            appDispatch(updateUserDateInscription(''));
            appDispatch(updateModalsOpen({ ...modalsOpen, login: true, alert: true }));
            appDispatch(updateModalsLoginType('login'));
          }
          dispatch({ type: 'FAILED', payload: { error } });
        }
      })();
    }
    // Cleanup state willunmount
    return () => {
      dispatch({ type: 'RESET' });
    };
  }, [
    appDispatch,
    modalsOpen,
    options._uid,
    options.config,
    options.consumerId,
    options.currentData,
    options.exclude,
    options.fields,
    options.filter,
    options.format,
    options.include,
    options.limit,
    options.method,
    options.name,
    options.noData,
    options.path,
    options.recaptcha_token,
    options.sort,
  ]);

  const { data, error, status } = state;

  return { data, error, status };
}
