import {
    GLOBAL_UPDATE_GLOBAL, GLOBAL_UPDATE_ROUTES, GLOBAL_IS_LOADED, GLOBAL_IS_SCROLLLOCKED,
    GLOBAL_IS_YOUTUBE, GLOBAL_PREVIOUS_PATH, GLOBAL_CONTACT_RESET, GLOBAL_CART_STEPS_RESET
} from "../action";

let initialState = {
    isLoaded: false,
    global: {},
    routes: {},
    lockScroll: false,
    youtube: false,
    previousPath: 'undefined',
    contactReset: false,
    cartStepsReset: false
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function globalReducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_UPDATE_GLOBAL:
            return {...state, global: action.value};
        case GLOBAL_UPDATE_ROUTES:
            return {...state, routes: action.value};
        case GLOBAL_IS_LOADED:
            return {...state, isLoaded: action.value};
        case GLOBAL_IS_SCROLLLOCKED:
            if (action.value === true) {
                document.documentElement.classList.add('scrollLocked');
                document.body.classList.add('scrollLocked');
                document.body.style.height = window.innerHeight + 'px';
            } else {
                document.documentElement.classList.remove('scrollLocked');
                document.body.classList.remove('scrollLocked');
                document.body.style.height = null;
            }
            return {...state, lockScroll: action.value};
        case GLOBAL_IS_YOUTUBE:
            return {...state, youtube: action.value};
        case GLOBAL_PREVIOUS_PATH:
            return {...state, previousPath: action.value};
        case GLOBAL_CONTACT_RESET:
            return {...state, contactReset: action.value};
        case GLOBAL_CART_STEPS_RESET:
            return {...state, cartStepsReset: action.value};
        default:
            return state;
    }
}
