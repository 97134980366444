import React from 'react';
import { NavLink } from "react-router-dom";


const MenuStickyItem = ({ id, classe, title, url, current, target, closeMenu }) => {
    return (
        <li className={`${classe ? classe : ''} ${current ? "current--" : ''}`}
            key={id}

        >
            {current === true ? (
                <span className="c-stickyNav_link"><i className="icono"></i><i className="icono-bgd"></i>{title}</span>
            ) : (
                    <NavLink activeClassName="selected" className="c-stickyNav_link" to={url} target={target} title={title}><i
                        className="icono"></i><i className="icono-bgd"></i>{title}</NavLink>
                )}
        </li>
    )
};


const MenuSticky = ({ items, closeMenu }) => {
    return (
        <nav className="c-stickyNav">
            <ul>
                {items.map((item, key) =>
                    <MenuStickyItem
                        key={key}
                        closeMenu={closeMenu}
                        {...item}
                    />
                )}
            </ul>
        </nav>
    )
};

export default MenuSticky;


