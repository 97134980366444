import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { useHistory } from "react-router-dom";

// Hooks
import {updateGlobalStorePage/*, updateUserPseudo*/} from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import {Loader} from "../Components/loader";
import Logout from '../Components/logout';
import {GoBack} from "../Components/goBack";

import {
    updateModalsOpen,
    updateModalsLoginType,
    updateUserUuid,
    updateUserDateInscription,
    updateUserIupid,
    updateUserPseudo
} from '../Store/action';

const TemplateAccountMyInfo = ({_uid}) => {
    let history = useHistory();
    // let dataFetch = require('../api/page.json');

    // Meta - Get page info
    const [dataRedux, setDataRedux] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get',
        needLogin : true
    });

    const modalsOpen = useSelector(state => state.modals).openModal;

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    // USER
    let dispatch = useDispatch();
    let {uuid} = useSelector(state => state.user);
    
    useEffect(() => {
        if(!uuid){
            dispatch(updateUserUuid(""));
            dispatch(updateUserIupid(""));
            dispatch(updateUserPseudo(""));
            dispatch(updateUserDateInscription(""));
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'alert': true }));
            dispatch(updateModalsLoginType('login'));
        }
    }, [uuid]);
    
    let [params, setParams] = useState('');
    let [content, setContent] = useState({});

    const [initClient, isLoadedUser] = useApi(params);

    // Récup infos client
    useEffect(() => {
        if (uuid) {
            setParams({name: `api/users/${uuid}`, method: 'get'});
        }
    }, [uuid]);

    // Pays
    const options = [
        {value: 'FR', label: 'France'},
        {value: 'ES', label: 'Espagne'},
        {value: 'US', label: 'Etats-unis'}
    ];
    const labelCountry = (code) => {
        let labelCode = "";
        let item = options.find(item => item.value === code);

        if (item)
            labelCode = item.label;
        return labelCode;
    };
    // let [countryName, setCountryName] = useState('');

    useEffect(() => {
        if (initClient && isLoadedUser && params) {
            let {status, data} = initClient;
            // console.log(data);
            if (status === 200) {
                //tout est ok
                //Préremplissage des données
               // console.log("INFO PERSO ", data.data);
                // data.data.attributes.lba_countrycode =
                setContent(data.data.attributes);
                // setCountryName(labelCountry(data.data.attributes.lba_countrycode));
            }
        }
    }, [initClient, isLoadedUser, params]); // eslint-disable-line


    useEffect(() => {
        dispatch(updateGlobalStorePage('t-accountMyInfo'));
    }, [dispatch]); // isLoaded

    // Accordeon content
    let formAccordion = require('../api/form.json');
    // const {forms} = formAccordion;


    const renderPage = () => {
        const {metatag} = dataRedux;

        // console.log('formAccordion ',formAccordion);

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'Mon compte',
            category2: 'Gérer mon profil',
            category3: 'Toutes mes données',
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}

                <div className="on-top--">
                    <Logout/>
                </div>

                <GoBack/>

                <div className="t-accountMyInfo_wrapper">
                    <div className="container">
                        <h1 className="section-title">Toutes mes données</h1>

                        <div className="t-accountMyInfo_container">

                            {/* MES IDENTIFIANTS */}
                            <div className="t-accountMyInfo_section">
                                <h2 className="title-2">Mes identifiants</h2>
                                <p className="t-accountMyInfo_info"><strong>Email :</strong> {content.lba_email}</p>
                                <p className="t-accountMyInfo_info"><strong>Pseudo :</strong> {content.display_name}</p>
                                <p className="t-accountMyInfo_info"><strong>Mot de passe :</strong> ********</p>
                            </div>

                            {/* MES COORDONNEES */}
                            <div className="t-accountMyInfo_section">
                                <h2 className="title-2">Mes coordonnées</h2>
                                <p className="t-accountMyInfo_info">
                                    <strong>Civilité :</strong> {content.lba_civilite === 1 ? "Monsieur" : "Madame"}</p>
                                <p className="t-accountMyInfo_info"><strong>Prénom :</strong> {content.lba_fname}</p>
                                <p className="t-accountMyInfo_info"><strong>Nom :</strong> {content.lba_lname}</p>
                                <p className="t-accountMyInfo_info"><strong>Adresse :</strong> {content.lba_address}</p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Complément d'adresse :</strong> {content.lba_address2}</p>
                                <p className="t-accountMyInfo_info"><strong>Code postal :</strong> {content.lba_zip}</p>
                                <p className="t-accountMyInfo_info"><strong>Ville :</strong> {content.lba_city}</p>
                                <p className="t-accountMyInfo_info"><strong>Pays :</strong> {labelCountry(content.lba_countrycode)}
                                </p>
                                <p className="t-accountMyInfo_info"><strong>Mobile :</strong> {content.lba_mobilephone}
                                </p>
                            </div>

                            {/* MES AVANTAGES */}
                            <div className="t-accountMyInfo_section">
                                <h2 className="title-2">Mes avantages</h2>
                                <p className="t-accountMyInfo_info">
                                    <strong>J’accepte de recevoir par email les newsletters de La Belle Adresse (réductions, DIY, astuces, idées, innovations, ...) :</strong> {content.lba_optinemail == null ? "Non" : "Oui"}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>J’accepte de recevoir par courrier les messages de La Belle Adresse :</strong> {content.lba_optincourrier == null ? "Non" : "Oui"}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>J’accepte de recevoir par SMS les informations de La Belle Adresse (promotions, innovation, information,...) :</strong> {content.lba_optinsms == null ? "Non" : "Oui"}
                                </p>
                            </div>

                            {/* PARLEZ-NOUS DE VOUS */}
                            <div className="t-accountMyInfo_section">
                                <h2 className="title-2">Parlez-nous de vous</h2>

                                {/* FOYER */}
                                <h3 className="title-3">Mon foyer</h3>
                                <p className="t-accountMyInfo_info">
                                    <strong>Combien de personnes composent votre foyer ?</strong> {formAccordion.forms[0].questions[0].answer[content.lba_householdcount]}
                                </p>

                                {/* LESSIVE */}
                                <h3 className="title-3">Ma lessive</h3>
                                <p className="t-accountMyInfo_info">
                                    <strong>Combien de fois par semaine faites-vous votre lessive ?</strong> {formAccordion.forms[1].questions[0].answer[content.lba_laundrycount]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Diriez-vous que vous êtes plutôt ?</strong> {formAccordion.forms[1].questions[1].answer[content.lba_laundrychoice]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Quel type de lessive utilisez-vous ?</strong> {formAccordion.forms[1].questions[2].answer[content.lba_laundrytype]}
                                </p>

                                {/* CHEVEUX */}
                                <h3 className="title-3">Mes cheveux</h3>
                                <p className="t-accountMyInfo_info">
                                    <strong>De quelle longueur sont vos cheveux ?</strong> {formAccordion.forms[2].questions[0].answer[content.lba_hairlength]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>De quelle épaisseur sont vos cheveux ?</strong> {formAccordion.forms[2].questions[1].answer[content.lba_hairthickness]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Vos cheveux sont...</strong> {formAccordion.forms[2].questions[2].answer[content.lba_haircolor]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Comment décririez-vous votre type de cheveux ?</strong> {formAccordion.forms[2].questions[3].answer[content.lba_hairproblem]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>A quelle fréquence lavez-vous vos cheveux ?</strong> {formAccordion.forms[2].questions[4].answer[content.lba_hairwashfrequency]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Faites-vous régulièrement un soin à vos cheveux ?</strong> {formAccordion.forms[2].questions[5].answer[content.lba_haircarefrequency]}
                                </p>
                                <p className="t-accountMyInfo_info">
                                    <strong>Pour sublimer vos cheveux, vous aimeriez...</strong> {formAccordion.forms[2].questions[6].answer[content.lba_hairgoal]}
                                </p>

                                {/* MAISON */}
                                <h3 className="title-3">Ma maison</h3>
                                <p className="t-accountMyInfo_info">
                                    <strong>Utilisez-vous des blocs WC ?</strong> {formAccordion.forms[3].questions[0].answer[content.lba_usedwcblock]}
                                </p>
                            </div>

                            <p className="t-accountMyInfo_info">Vous désirez accéder à vos données personnelles depuis la création de votre compte ? Contactez-nous par mail à l'adresse <a href="compliance.france@henkel.com">compliance.france@henkel.com</a> ou par courrier à l'adresse Henkel France, 245 rue du Vieux Pont de Sèvres, 92100 Boulogne Billancourt</p>

                        </div>
                    </div>
                </div>


            </>
        )
    };

    return (isLoadedUser && dataRedux) ? renderPage() : <Loader/>
};

export default TemplateAccountMyInfo;
