// GLOBAL STATE
export function updateGlobalStoreGlobal(datas) {
    return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas) {
    return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStorePage(datas) {
    return {type: GLOBAL_UPDATE_PAGE, value: datas}
}

export function updateGlobalStoreLogFromPage(datas) {
    return {type: GLOBAL_UPDATE_LOGFROM_PAGE, value: datas}
}

export function updateGlobalisLoaded(bool) {
    return {type: GLOBAL_IS_LOADED, value: bool}
}

export function updateGlobalPreviousPath(string) {
    return {type: GLOBAL_PREVIOUS_PATH, value: string}
}

export function updateGlobalContactReset(bool) {
    return {type: GLOBAL_CONTACT_RESET, value: bool}
}

export function updateGlobalCartStepsReset(bool) {
    return {type: GLOBAL_CART_STEPS_RESET, value: bool}
}

//USER
export function updateUserUuid(datas) {
    return {type: USER_UUID, value: datas}
}

export function updateUserIupid(datas) {
    return {type: USER_IUPID, value: datas}
}

export function updateUserPseudo(datas) {
    return {type: USER_PSEUDO, value: datas}
}

export function updateUserDateInscription(datas) {
    return {type: USER_DATEINSCRIPTION, value: datas}
}

export function updateUserFavorites(datas) {
    return {type: USER_FAVORITES, value: datas}
}


//MODAL
export function updateModalsOpen(datas) {
    return {type: MODALS_OPEN, value: datas}
}

export function updateModalsLoginType(datas) {
    return {type: MODALS_LOGINTYPE, value: datas}
}

export function updateModalsRedirectButton(datas) {
    return {type: MODALS_REDIRECTBUTTON, value: datas}
}

export function updateModalsPasswordReset(datas) {
    return {type: MODALS_PASSWORDRESET, value: datas}
}

export function updateModalsDataIsLoaded(datas) {
    return {type: MODALS_DATAISLOADED, value: datas}
}


// TARTEAUCITRON YOUTUBE STATE
export function updateGlobalYoutube(bool) {
    // console.log(bool)
    return {type: GLOBAL_IS_YOUTUBE, value: bool}
}


// SCROLL STATE
export function updateGlobalScrollLock(bool) {
    return {type: GLOBAL_IS_SCROLLLOCKED, value: bool}
}

// Breakpoints
export function updateIsMobile(bool) {
    return {type: IS_MOBILE, value: bool}
}

export function updateIsDesktopOnly(bool) {
    return {type: IS_DESKTOP_ONLY, value: bool}
}

export function updateIsDesktop(bool) {
    return {type: IS_DESKTOP, value: bool}
}

// Routes
export function updateRoutesUpdate(datas) {
    return {type: ROUTES_UPDATE, value: datas}
}

// Cart
export function updateCartCashback(datas) {
    //  console.log('cashbackdatas', datas);
    return {type: CART_CASHBACK, value: datas}
}

export function updateCartCashbackError(string) {
    return {type: CART_CASHBACK_ERROR, value: string}
}

export function updateCartWebcoupon(datas) {
    //  console.log('webcoupondatas', datas);
    return {type: CART_WEBCOUPON, value: datas}
}

export function updateCartWebcouponError(string) {
    return {type: CART_WEBCOUPON_ERROR, value: string}
}

export function updateCartBeginCheckout(boolean) {
    return {type: CART_BEGIN_CHECKOUT, value: boolean}
}

export const GLOBAL_UPDATE_GLOBAL = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_PAGE = 'UPDATE_PAGE';
export const GLOBAL_UPDATE_LOGFROM_PAGE = 'UPDATE_LOGFROM_PAGE';
export const GLOBAL_IS_LOADED = 'IS_LOADED';
export const GLOBAL_PREVIOUS_PATH = 'GLOBAL_PREVIOUS_PATH';
export const GLOBAL_CONTACT_RESET = 'GLOBAL_CONTACT_RESET';
export const GLOBAL_CART_STEPS_RESET = 'GLOBAL_CART_STEPS_RESET';
export const MODALS_OPEN = 'MODALS_OPEN';
export const MODALS_LOGINTYPE = 'MODALS_LOGINTYPE';
export const MODALS_REDIRECTBUTTON = 'MODALS_REDIRECTBUTTON';
export const MODALS_PASSWORDRESET = 'MODALS_PASSWORDRESET';
export const MODALS_DATAISLOADED = 'MODALS_DATAISLOADED';
export const GLOBAL_IS_YOUTUBE = 'IS_YOUTUBE';
export const GLOBAL_IS_SCROLLLOCKED = 'IS_SCROLLLOCKED';
export const IS_MOBILE = 'IS_MOBILE';
export const IS_DESKTOP_ONLY = 'IS_DESKTOP_ONLY';
export const IS_DESKTOP = 'IS_DESKTOP';
export const USER_UUID = 'USER';
export const USER_IUPID = 'IUP_ID';
export const USER_PSEUDO = 'USER_PSEUDO';
export const USER_DATEINSCRIPTION = 'USER_DATEINSCRIPTION';
export const USER_FAVORITES = 'USER_FAVORITES';
export const ROUTES_UPDATE = 'ROUTES_UPDATE';
export const CART_CASHBACK = 'CART_CASHBACK';
export const CART_WEBCOUPON = 'CART_WEBCOUPON';
export const CART_CASHBACK_ERROR = 'CART_CASHBACK_ERROR';
export const CART_WEBCOUPON_ERROR = 'CART_WEBCOUPON_ERROR';
export const CART_BEGIN_CHECKOUT = 'CART_BEGIN_CHECKOUT';
