import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import {
    updateUserUuid, updateUserIupid, updateUserPseudo, updateUserDateInscription
} from '../Store/action';
import useApi from '../Hooks/useApiGlobal';
import {eraseToken, eraseRefreshToken} from '../Utils/cookie';

export const Logout = () => {
    // Gestion du Log Out
    let dispatch = useDispatch();
    const history = useHistory();

    let [params, setParams] = useState('');
    let [paramApi, setParamApi] = useState({});
    let [loadApi, setLoadApi] = useState(false);

    const [initClient, isLoadedUser] = useApi(params);

    useEffect(() => {
        if (loadApi && paramApi) {
            setParams(paramApi)
        }
    }, [paramApi, loadApi]);

    useEffect(() => {
        if (initClient && isLoadedUser) {
            let {status, data} = initClient;
            // console.log('global ', data);
            if (status === 200) {
                //tout est ok
                //console.log('deconnexion ok');
                eraseToken();
                eraseRefreshToken();
                dispatch(updateUserUuid(""));
                dispatch(updateUserIupid(""));
                dispatch(updateUserPseudo(""));
                dispatch(updateUserDateInscription(""));
                localStorage.removeItem("uuid");
                localStorage.removeItem("iupid");
                localStorage.removeItem("pseudo");
                localStorage.removeItem("dateInscription");
                //redirection HP
                // document.location.href = "/";
                history.push({pathname: '/'});
            } else {
                console.log('error - not logout', data)
            }
        }
    }, [initClient, isLoadedUser]); // eslint-disable-line

    const handleLogOut = () => {
        if (sessionStorage.getItem("popinEconomyView")) {
            sessionStorage.removeItem('popinEconomyView');
        }
        if (sessionStorage.getItem("popinCompletionView")) {
            sessionStorage.removeItem('popinCompletionView');
        }
        if (sessionStorage.getItem("popinPromotionView")) {
            sessionStorage.removeItem('popinPromotionView');
        }
        if (sessionStorage.getItem("popinWelcomeView")) {
            sessionStorage.removeItem('popinWelcomeView');
        }
        if (sessionStorage.getItem("pageViews")) {
            sessionStorage.setItem("pageViews", 0);
        }
        setLoadApi(true);
        setParamApi({name: 'api/logout', method: 'post'});

        return null;
    };

    return (
        <div className="c-logout">
            <div className="container">
                <button type="button" className="c-logout_button" onClick={handleLogOut}><i
                    className="icon-logout icon"></i> Se déconnecter
                </button>
            </div>
        </div>
    )
};

export default Logout;