import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

// Hooks
import {
    updateModalsLoginType,
    updateModalsOpen,
    updateUserFavorites
} from "../Store/action";
import useApi from "../Hooks/useApiGlobal";
import useApiJeton from '../Hooks/useApiJeton';

// Libraries
import useTracking from "../Hooks/useTracking";
import {randomString} from "../Utils/md5-hash";

const Favorite = ({id, universe}) => {
    const dispatch = useDispatch();

    const {uuid, favorites} = useSelector(state => state.user);
    const modalsOpen = useSelector(state => state.modals).openModal;
    const [selectClicked, setSelectClicked] = useState(false);

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);

    // Tracking
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const addToFavTracking = (refresh) => {
        setTrackingEvent({
            event: 'add_to_favorite',
            args: {
                membership_level: uuid ? 'member' : 'no-member',
                content_type: universe,
            },
            refresh: refresh
        });
    };


    const handleOpen = () => {
        dispatch(updateModalsOpen({...modalsOpen, 'login': true}));
        dispatch(updateModalsLoginType('currentPage'));
    };

    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        if (favorites) {
            const localFav = favorites;
            // console.log('favoritesComponent', favorites);
            // console.log('filter', localFav.filter(item => item.nid === id));
            if (localFav.filter(item => item.nid === id).length > 0) {
                setFavorite(true);
            } else {
                setFavorite(false);
            }
        } else {
            setFavorite(false);
        }
    }, [favorites]); // eslint-disable-line

    const handleFavorite = (e) => {
        e.preventDefault();
        if (uuid) {
            setSelectClicked(false);
            // Récupération du Jeton
            setInitJeton({
                callback : updateFavorite,
                params: ''
            });
        } else {
            setSelectClicked(true);
            handleOpen();
        }
    };


    useEffect(() => {
        if (selectClicked && uuid) {
            setSelectClicked(false);
        }
    }, [setSelectClicked, uuid]); // eslint-disable-line


    let [paramsToggleFav, setParamsToggleFav] = useState({});
    const [responseToggleFav, isLoadedResponseToggleFav] = useApi(paramsToggleFav);

    const updateFavorite = (sessionToken) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "X-CSRF-Token": sessionToken
        };
        if (favorite) {
            // remove
            setParamsToggleFav({
                name: 'api/users/favorites',
                method: 'delete',
                data: {
                    "nid": id
                },
                config: headers,
                stringifyRequest: false // Body raw
            });
        } else {
            // add
            setParamsToggleFav({
                name: 'api/users/favorites',
                method: 'patch',
                data: {
                    "nid": id
                },
                config: headers,
                stringifyRequest: false // Body raw
            });
        }
    };

    useEffect(() => {
        if (responseToggleFav && isLoadedResponseToggleFav) {
            let {status, data} = responseToggleFav;
            if (status === 200 || status === 201) {
                if (favorite) {
                    // remove
                    dispatch(updateUserFavorites(favorites.filter(fav => fav.nid !== id)));
                } else {
                    // add
                    dispatch(updateUserFavorites(data));
                    addToFavTracking(randomString(6));
                }
            } else {
                console.log('error - toggle favorite', responseToggleFav);
            }
        }
    }, [responseToggleFav, isLoadedResponseToggleFav]); //eslint-disable-line

    return (
        <button className={`c-article_favorite no-print ${favorite ? ' favorite--' : ''}`}
                onClick={(e) => handleFavorite(e)}></button>
    )
};

export default Favorite;