import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// Hooks
import useApi from '../../Hooks/useApiGlobal';
import {updateModalsOpen, updateUserUuid, updateUserPseudo, updateUserIupid, updateUserDateInscription} from '../../Store/action';
import {eraseToken, eraseRefreshToken} from '../../Utils/cookie';

// Components
import Modal from "./modal";
import {Loader} from "../../Components/loader";

const DeleteAccountModal = () => {

    const history = useHistory();

    let dispatch = useDispatch();
    // Modal info
    const modalsOpen = useSelector(state => state.modals).openModal;

    // Confirmation content
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    // Close modal
    const closeModal = () => {
        let newState = {
            ...modalsOpen,
            'deleteAccount': false
        };
        dispatch(updateModalsOpen(newState));
    };


    // Loader
    const [loading, setLoading] = useState(false); // eslint-disable-line

    let [paramsSend, setParamsSend] = useState({});
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    // SUPPRESSION COMPTE
    const deleteAccount = () => {
        // Requete suppression
        setParamsSend({
            name: 'api/unsubscribe',
            method: 'delete',
        });

        // Display loader
        setLoading(true);
    };

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let {status, data} = responseSend;
            if (status === 204) {
                //tout est ok
               // console.log('success data : ', data);

                setDeleteConfirmation(true);

                setTimeout(function () {
                    eraseToken();
                    eraseRefreshToken();
                    dispatch(updateUserUuid(""));
                    dispatch(updateUserIupid(""));
                    dispatch(updateUserPseudo(""));
                    dispatch(updateUserDateInscription(""));
                    localStorage.removeItem("uuid");
                    localStorage.removeItem("iupid");
                    localStorage.removeItem("pseudo");
                    localStorage.removeItem("dateInscription");

                    //redirection HP
                    // document.location.href = "/";
                    history.push({pathname: '/'});
                }, 2000);
            }
            else {
                //Erreur
                console.log('ERREUR', data);
            }

            // Reset params
            setParamsSend({});

            // Remove loader
            setLoading(false);
        }
    }, [responseSend, isLoadedSend]); //eslint-disable-line

    return (
        <Modal modalName="deleteAccount" modalNameTracking="Suppression de compte">
            <div className="c-modal_deleteAccount">
                <div className="c-modal_deleteAccount-intro">
                    {!deleteConfirmation ?
                        <>
                            <h2 className="title-1">Êtes-vous sûr de vouloir supprimer votre compte ?</h2>
                            <button type="button" className="btn-1" onClick={() => closeModal()}>Annuler</button>
                            <button type="button" className="btn-2"
                                    onClick={() => deleteAccount()}>Supprimer mon compte
                            </button>
                        </>
                        :
                        <>
                            <h2 className="title-1">Votre compte a été supprimé.</h2>
                            <button type="button" className="btn-2" onClick={() => closeModal()}>Fermer</button>
                        </>
                    }

                    {loading && <div className="full-loader"><Loader/></div>}
                </div>
            </div>
        </Modal>
    )
};

export default DeleteAccountModal;