import { IS_MOBILE, IS_DESKTOP_ONLY, IS_DESKTOP } from "../action";

/**
 * REDUCER DE LA CONFIGURATION DES BREAKPOINTS
 * @param {*} state
 * @param {*} action
 */

const initialState = {
    mobile: window.matchMedia('(max-width:1023px)').matches ? true : false,
    desktop: window.matchMedia('(min-width:1024px)').matches ? true : false,
    desktopOnly: window.matchMedia('(min-width:1025px)').matches ? true : false,
};

export function breakpointReducer(state = initialState , action){
   switch (action.type) {
       case IS_MOBILE:
           return { ...state, mobile: action.value }
        case IS_DESKTOP_ONLY:
            return { ...state, desktopOnly: action.value };
        case IS_DESKTOP :
            return {...state, desktop: action.value };
       default:
           return state;
   }
}