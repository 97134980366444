import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Librairies
import Axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
// Hooks
import { updateModalsOpen } from "../Store/action";
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';
// API NORMALISATION 
import normalize from 'json-api-normalizer';
import build from 'redux-object';
// Components
import { Loader } from "../Components/loader";
import CommunityPostPreviewModal from "../Components/modals/community-post-preview-modal";
// Utils
import { randomString } from "../Utils/md5-hash";

const CommunityForm = ({ onSuccessSend, formType }) => {
    const baseURL = process.env.REACT_APP_APP;
    const { register, control, handleSubmit, formState: { errors } } = useForm();

    const [ errorsDisplay, setErrorsDisplay] = useState({}) // used to display informations when field reach its limit
    
    const [loading, setLoading] = useState(false);
    // GET INFO PROFIL
    let { pseudo, uuid } = useSelector(state => state.user);
    // Modals
    let dispatch = useDispatch();
    const modalsOpen = useSelector(state => state.modals).openModal;
    const [themeDataRedux, setThemeDataRedux] = useState();
    const [tagDataRedux, setTagDataRedux] = useState();
    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line
    const submitTracking = (refresh, thematics) => {
        setTrackingEvent({
            event: formType === "astuce" ? "add_experience" : "add_question",
            args: {
                content_type: thematics
            },
            refresh: refresh
        });
    };
    const decodeHTML = (text) => {
        const div = document.createElement("div");
        div.innerHTML = text;
        return div.textContent;
    }
    // GET ALL THEMES FROM API 
    const [dataFetchTheme, isLoadedTheme] = useApi({
        name: 'api/taxonomy_term/community_theme',
        fields: `&fields[taxonomy_term--community_theme]=name`,
        sort: '&sort=weight',
        method: 'get'
    });
    useEffect(() => {
        if (isLoadedTheme && dataFetchTheme) {
            let normalizeJson = normalize(dataFetchTheme.data);
            setThemeDataRedux(build(normalizeJson, 'taxonomyTermCommunityTheme'));
        }
    }, [isLoadedTheme, dataFetchTheme]);// eslint-disable-line

    // GET ALL TAGS FROM API 
    const [dataFetchTag, isLoadedTag] = useApi({
        name: 'api/taxonomy_term/community_tag',
        fields: `&fields[taxonomy_term--community_tag]=name,revision_created`,
        sort: '&sort[sort-updated][path]=revision_created&sort[sort-updated][direction]=DESC',
        method: 'get'
    });
    useEffect(() => {
        if (isLoadedTag && dataFetchTag) {
            let normalizeJson = normalize(dataFetchTag.data);
            console.log(normalizeJson);
            setTagDataRedux(build(normalizeJson, 'taxonomyTermCommunityTag'));

        }
    }, [isLoadedTag, dataFetchTag]);// eslint-disable-line

    // GET PROFILE PICTURE 
    const [profilePictureDataRedux, setProfilePictureDataRedux] = useState();
    let profilePictureDataInclude = [
        'user_picture',
    ];
    const [dataFetchProfilePicture, isLoadedProfilePicture] = useApi({
        name: `api/users/${uuid}`,
        include: profilePictureDataInclude.join(','),
        fields: `&fields[users]=user_picture`,
        method: 'get'
    });
    useEffect(() => {
        if (dataFetchProfilePicture && isLoadedProfilePicture && uuid) {
            let normalizeJson = normalize(dataFetchProfilePicture.data);
            setProfilePictureDataRedux(build(normalizeJson, 'users'));
        }
    }, [dataFetchProfilePicture, isLoadedProfilePicture]);// eslint-disable-line

    // HANDLE CHANGE INPUT UPLOADS 
    const [filesUpload, setFilesUpload] = useState([]);
    const [datasPreview, setDataPreview] = useState(null);
    const [isValidUploadType, setIsValidUploadType] = useState(true);
    const [totalSizeUploads, setTotalSizeUploads] = useState(0);
    // max size des uploads -> 10MO
    const maxSizeUploads = 10485760; //octets

    const handleInputUploadsChange = (e) => {
        if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg") {
            setFilesUpload([...filesUpload, e.target.files[0]])
            // setTotalSizeUploads(totalSizeUploads + e.target.files[0].size)
            setIsValidUploadType(true);
            e.target.value = "";
        } else {
            setIsValidUploadType(false);
        }
    }
    // Check Total size of uploads 
    let totalFilesSize = 0;
    useEffect(() => {
        if (filesUpload.length > 0) {
            filesUpload.forEach(file => {
                totalFilesSize += file.size
                setTotalSizeUploads(totalFilesSize)
            })
        } else {
            setTotalSizeUploads(0)
        }
    }, [filesUpload])

    const handleInputUploadsDelete = (id) => {
        // setTotalSizeUploads(totalSizeUploads - id.size)
        setFilesUpload(filesUpload.filter(file => file.name !== id.name))
    }

    // START PREVIEW 
    // ON CLICK BTN PREVIEW, REFORMATE DATA AND DISPLAY POPIN
    const onPreview = (data) => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'postPreview': true }));
        const reformatTags = () => {
            let formatedTagsArray = []
            data.tags.forEach((tag) => {
                formatedTagsArray.push({ 'name': tag.value })
            })
            return formatedTagsArray
        }
        const reformatUploads = () => {
            let formatedUploadsArray = []
            filesUpload.forEach((file) => {
                formatedUploadsArray.push({
                    'filemime': file.type,
                    'isPreview': true,
                    'links': {
                        'commonCarrouselDesktop':
                            { 'href': URL.createObjectURL(file) }
                    },
                    'uri': {
                        'url': URL.createObjectURL(file)
                    }
                })
            })
            return formatedUploadsArray
        }
        setDataPreview({
            postType: formType,
            created: new Date(),
            uid: { displayName: pseudo, userPicture: profilePictureDataRedux[0].userPicture },
            title: data.title,
            fieldCommonTxtBody: { processed: data.description },
            fieldCommunityTrThemes: { name: data.thematics.value },
            fieldCommunityTrTags: reformatTags(),
            fieldCommunityFileMedias: reformatUploads()
        })
    }
    // END PREVIEW 

    const [dataToSend, setDataToSend] = useState({});
    const [bearerToken, setBearerToken] = useState();
    const [isFetch, setIsFetch] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    // SUBMIT FORM 
    const onSubmit = (formData) => {
        formData.files = filesUpload
        setLoading(true);
        setDataToSend({
            data: {
                type: `${formType === 'astuce' ? 'node--tip' : 'node--question'}`,
                attributes: {
                    title: formData.title,
                    field_common_txt_body: formData.description,
                    field_community_bool_email_notif: formData.optin
                },
                relationships: {
                    field_community_tr_themes: {
                        data: {
                            type: "taxonomy_term--community_theme",
                            id: formData.thematics.id,
                            name: formData.thematics.value
                        }
                    },
                    field_community_tr_tags: {
                        data: []
                    },
                    field_community_file_medias: {
                        data: []
                    }
                }
            }
        })


        let fetchToken = Axios.post(`${baseURL}/session/token/`);
        fetchToken.then(res => {
            let { status, data } = res;
            setBearerToken(data)
            if (status === 200) {
                setIsFetch(true)
                submitTracking(randomString(6), formData.thematics.value)
            }
        })
    };
    const [isUploadPosted, setIsUploadPosted] = useState(false);
    const [isTagPosted, setIsTagPosted] = useState(false);
    useEffect(() => {
        if (Object.keys(dataToSend).length !== 0 && isFetch) {
            // waiting tags posted 
            if (!isTagPosted) {
                // post tags to api
                sendTags(bearerToken)
            } else {
                // 'checking for uploads'
                if (Array.isArray(filesUpload) && filesUpload.length > 0) {
                    // waiting uploads posted 
                    if (!isUploadPosted) {
                        // post uploads to api
                        sendUploads(bearerToken, filesUpload);
                    } else {
                        // 'posting with uploads'
                        sendData(bearerToken, dataToSend);
                    }
                } else {
                    // 'posting without uploads'
                    sendData(bearerToken, dataToSend);
                }
            }
        }
    }, [isFetch, isUploadPosted, isTagPosted])

    // POST DATAS TO API 
    let [paramsSend, setParamsSend] = useState({})
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    const sendData = (token, dataSent) => {
        const headers = {
            "Content-Type": "application/vnd.api+json",
            "X-CSRF-Token": token
        };
        setParamsSend({
            name: `api/node/${formType === 'astuce' ? 'tip' : 'question'}`,
            method: 'post',
            data: dataSent,
            config: headers,
        });
    }

    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let { status, data } = responseSend;
            if (status === 201) {
                onSuccessSend();
                setLoading(false);
            } else if (status === 500) {
                setLoading(false);
                console.log('ERREUR', data.errors);
            }
            else {
                console.log('ERREUR', data.errors);
            }
        }
    }, [responseSend, isLoadedSend]);

    // --------------------------> POST TAGS TO API <--------------------------------- //
    let [tagsSent, setTagsSent] = useState({})
    const [tagsResponse, isLoadedTags] = useApi(tagsSent);
    // posting uploads to API
    const sendTags = (token) => {
        let formatedArrayTags = [];
        selectedTags.forEach(tag => {
            if (formatedArrayTags.indexOf(tag.value) === -1) {
                formatedArrayTags.push(tag.value)
            }
        })
        const headers = {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
        };
        setTagsSent({
            name: 'api/lba-community/tags',
            method: 'post',
            data: formatedArrayTags,
            config: headers,
        });
    }
    // update form data before submit 
    useEffect(() => {
        if (tagsResponse, isLoadedTags) {
            if (tagsResponse.status === 200) {
                setDataToSend(dataToSend => ({
                    data: {
                        ...dataToSend.data,
                        relationships: {
                            ...dataToSend.data.relationships,
                            field_community_tr_tags: {
                                data: tagsResponse.data
                            }
                        }
                    }
                }))
                setIsTagPosted(true);
            }
        }
    }, [tagsResponse, isLoadedTags]);

    // --------------------------> POST UPLOADS TO API <--------------------------------- //
    let [uploadsSent, setUploadsSent] = useState({})
    const [uploadsResponse, isLoadedUploads] = useApi(uploadsSent);
    // posting uploads to API
    const sendUploads = (token, uploads) => {
        var fd = new FormData();
        uploads.forEach((upload, index) => {
            fd.append(`file${index + 1}`, upload);
        })
        const headers = {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": token
        };
        setUploadsSent({
            name: `api/lba-community/${formType === 'astuce' ? 'tip' : 'question'}/upload`,
            format: 'json',
            method: 'post',
            data: fd,
            config: headers,
        });
    }
    // update form data before submit 
    useEffect(() => {
        if (uploadsResponse, isLoadedUploads) {
            if (uploadsResponse.status === 201) {
                setDataToSend(dataToSend => ({
                    data: {
                        ...dataToSend.data,
                        relationships: {
                            ...dataToSend.data.relationships,
                            field_community_file_medias: {
                                data: uploadsResponse.data
                            }
                        }
                    }
                }))
                setIsUploadPosted(true);
            }
        }
    }, [uploadsResponse, isLoadedUploads]);

    const onChange = (e, validator) => {
        let value = e.target.value
        let name = e.target.name
        if(!errorsDisplay[name] && !validator(value) ){
            setErrorsDisplay({...errorsDisplay, [name]: true})
        }else if(errorsDisplay[name] && validator(value) ){
            setErrorsDisplay({...errorsDisplay, [name]: false})
        }
    }

    const renderPage = () => {
        return (
            <>
                {
                    datasPreview &&
                    <CommunityPostPreviewModal
                        params={
                            datasPreview
                        }
                    />
                }
                <form className="c-communityForm" >
                    <div className="c-communityForm-input">
                        <label htmlFor="title">Titre <sup>*</sup></label>
                        <input
                            type="text"
                            placeholder={formType === "question" ? "Titre de ma question" : "Titre de mon astuce"}
                            name="title"
                            maxLength={200}
                            ref={register( {
                                required: true,
                                maxLength: 200
                            })}
                            onChange={(e)=>{
                                onChange(e, (value)=>value.length<200)
                            }}
                            
                             
                        />
                        
                        {errors.title && errors.title.type === "required" && <p className="c-communityForm-input-error">Veuillez remplir ce champ</p>}
                        {((errors.title && errors.title.type === "maxLength") || errorsDisplay.title) && <p className="c-communityForm-input-error">Votre titre dépasse le nombre de caractères autorisés (200 caractères)</p>}
                    </div>
                    <div className="c-communityForm-input">
                        <label htmlFor="description">Message <sup>*</sup></label>
                        <textarea
                            rows="15"
                            type="text"
                            placeholder={formType === "question" ? "Je pose ma question..." : "Je décris mon astuce..."}
                            name="description"
                            maxLength={65_000}
                            ref={register({
                                required: true,
                                maxLength: 65_000
                            })}
                            onChange={(e)=>{
                                onChange(e, (value)=>value.length<65_000)
                            }}
                        />
                        {errors.description && errors.description.type === "required" && <p className="c-communityForm-input-error">Veuillez remplir ce champ</p>}
                        {((errors.description && errors.description.type === "maxLength") || errorsDisplay.description) && <p className="c-communityForm-input-error">Votre message dépasse le nombre de caractères autorisés (65 000 caractères)</p>}
                    </div>
                    <div className="c-communityForm-input file--">
                        <label htmlFor="files">Ajouter une photo</label>
                        <div className="community-input-file">
                            <button type="button" className="community-input-file-btn">
                                <i className="icon-download" />
                                <span>
                                    <strong dangerouslySetInnerHTML={{ __html: "Importer une ou plusieurs images" }} /><br />au format png, jpg, jpeg<br />Taille maximale : 10 Mo
                            </span>
                            </button>
                            <input
                                ref={register({
                                    required: false
                                })}
                                multiple
                                onChange={(e) => handleInputUploadsChange(e)}
                                name="files"
                                type="file"
                                accept="image/png, image/jpeg"
                            />
                            <ul className="community-input-file-list">
                                {filesUpload.map((file, key) => {
                                    return (
                                        <li key={key} onClick={() => handleInputUploadsDelete(file)}>{file.name}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        {!isValidUploadType && <p className="c-communityForm-input-error">Veuillez selectionner des fichiers au format png, jpg ou jpeg</p>}
                        {totalSizeUploads > maxSizeUploads &&
                            <p className="c-communityForm-input-error">Taille des images supérieures à 10Mo</p>
                        }
                    </div>
                    <div className="c-communityForm-two-columns">
                        {
                            themeDataRedux && Array.isArray(themeDataRedux) &&
                            <div className="c-communityForm-select">
                                <label htmlFor="thematics">Thématique associée <sup>*</sup></label>
                                <Controller
                                    name="thematics"
                                    defaultValue={null}
                                    required
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ onChange }) =>
                                        <Select
                                            name="thematics"
                                            maxMenuHeight={200}
                                            noOptionsMessage={() => "Thématique introuvable"}
                                            className="c-form_select"
                                            classNamePrefix="c-form_select"
                                            placeholder="Choisissez une thématique..."
                                            isClearable={false}
                                            options={
                                                themeDataRedux.map((theme) => {
                                                    return (
                                                        { value: theme.name, label: theme.name, id: theme.id }
                                                    )
                                                })
                                            }
                                            onChange={option => {
                                                onChange({ value: option.value, id: option.id })
                                            }}
                                        />
                                    }
                                />
                                {errors.thematics && <p className="c-communityForm-input-error">Veuillez selectionner une thématique</p>}
                            </div>
                        }
                        {
                            tagDataRedux && Array.isArray(tagDataRedux) &&
                            <div className="c-communityForm-select">
                                <label htmlFor="tags">Mots clés <sup>*</sup></label>
                                <Controller
                                    name="tags"
                                    defaultValue={null}
                                    required
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ onChange }) =>
                                        <CreatableSelect
                                            name="tags"
                                            maxMenuHeight={200}
                                            isMulti
                                            formatCreateLabel={(userInput) => `Créer la mot clé '${userInput}'`}
                                            noOptionsMessage={() => "Mot clé introuvable"}
                                            className="c-form_select"
                                            classNamePrefix="c-form_select"
                                            placeholder="Écrivez votre mot clé"
                                            isClearable={false}
                                            options={
                                                tagDataRedux.map((tag) => {
                                                    return (
                                                        { value: decodeHTML(tag.name), label: decodeHTML(tag.name), id: tag.id }
                                                    )
                                                })
                                            }
                                            onChange={options => {
                                                let selectedOptions = []
                                                if (Array.isArray(options)) {
                                                    options.forEach(option => {
                                                        selectedOptions.push({ value: option.value, id: option.id })
                                                    });
                                                    onChange(selectedOptions)
                                                    setSelectedTags(selectedOptions)
                                                } else {
                                                    onChange(null)
                                                }
                                            }}
                                        />
                                    }
                                />
                                {errors.tags && <p className="c-communityForm-input-error">Veuillez selectionner un mot clé</p>}
                            </div>
                        }
                    </div>
                    {
                        formType === "question" &&
                        <div className="c-communityForm-checkbox">
                            <input
                                type="checkbox"
                                id="optin"
                                name="optin"
                                ref={register({
                                    required: false
                                })}
                            />
                            <label htmlFor="optin">Recevoir les notifications de réponses par mail</label>
                        </div>
                    }
                    <p className="form-text"><sup>*</sup> Champs obligatoires</p>
                    {!loading ?
                        <div className="c-communityForm-btns_container">
                            <button onClick={handleSubmit(onPreview)} type="submit" className="btn-2" disabled={totalSizeUploads > maxSizeUploads}>Prévisualiser</button>
                            <button onClick={handleSubmit(onSubmit)} type="submit" className="btn-2" disabled={totalSizeUploads > maxSizeUploads}>Valider</button>
                        </div> :
                        <Loader />
                    }
                </form>
            </>
        )
    };
    return (isLoadedTheme && themeDataRedux && isLoadedTag && tagDataRedux) ? renderPage() : <Loader />
};
export default CommunityForm;