import React from "react";

// Utils
import {getImage} from '../Utils/getMedia';

const visuelLegend = ({visuel, legend, desktop}) => {
    let href = '';
    if (visuel && visuel.fieldRespimgImgDesktop) {
        href = getImage(visuel, desktop);
    }
    /* const renderMarkup = (markup) => {
         return {__html: markup};
     };*/

    return (
        <>
            {
                href &&
                <div className="c-visuelLegend">
                    {/* <div className="c-visuelLegend_text c-wysiwyg"
                         dangerouslySetInnerHTML={renderMarkup(content)}></div>*/}
                    <figure>
                        <img src={href} alt={legend}/>
                        <figcaption>{legend}</figcaption>
                    </figure>
                </div>
            }
        </>
    )
};

export default visuelLegend;