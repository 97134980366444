import React from "react";
import ReactDOM from 'react-dom';

const tooltip = ({children, type, target}) => {
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className={`c-tooltip c-tooltip_${type}`}>{children}</div>
        </React.Fragment>, target ? target : document.body
        
    )
};

export default tooltip;