export const getClientCredential = (refreshToken, grant_type) => {
    let clientCredential = {};
    const desktop = window.matchMedia('(min-width:1024px)').matches;

    if (grant_type) {
        if(grant_type === "client_credentials") {
            if(desktop) {
                clientCredential = {
                    client_id: process.env.REACT_APP_ANONYMOUS_DESKTOP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_ANONYMOUS_DESKTOP_CLIENT_SECRET
                }
            }
            else {
                clientCredential = {
                    client_id: process.env.REACT_APP_ANONYMOUS_MOBILE_CLIENT_ID,
                    client_secret: process.env.REACT_APP_ANONYMOUS_MOBILE_CLIENT_SECRET
                }
            }
        }
        else
        {
            if(desktop) {
                clientCredential = {
                    client_id: process.env.REACT_APP_MEMBER_DESKTOP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_MEMBER_DESKTOP_CLIENT_SECRET
                }
            }
            else {
                clientCredential = {
                    client_id: process.env.REACT_APP_MEMBER_MOBILE_CLIENT_ID,
                    client_secret: process.env.REACT_APP_MEMBER_MOBILE_CLIENT_SECRET
                }
            }
            if(grant_type!=="lba_password")
                clientCredential = {...clientCredential, refresh_token:refreshToken};
        }
        clientCredential = {...clientCredential, grant_type:grant_type}
    }

    return clientCredential
}