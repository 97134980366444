import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
// import normalize from 'json-api-normalizer';
// import build from 'redux-object';

//Hooks
import useApi from '../Hooks/useApiGlobal';
import {
    updateGlobalStorePage,
    updateModalsOpen,
    updateModalsPasswordReset,
} from '../Store/action';

//components
// import Metatags from '../Layout/metas-tags/meta-tags';
// import Modal from "../Components/modals/modal";
import {Loader} from "../Components/loader";


const TemplatePasswordReset = () => {
    // Modal
    const modalsOpen = useSelector(state => state.modals).openModal;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-password-reset'));
    }, [dispatch]); // isLoaded

    // Get search value in url
    let [tokenValue, setTokenValue] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');

    const [paramsSend, setParamsSend] = useState({name: '', method: ''});
    const [responseSend, isLoadedSend] = useApi(paramsSend);

    useEffect(() => {
        if (urlToken) {
            // console.log('urlToken', urlToken);
            setTokenValue(urlToken);
            dispatch(updateModalsPasswordReset(urlToken));
        }
    }, [urlToken]);    // eslint-disable-line

    useEffect(() => {
        if (tokenValue !== '') {
            // console.log('tokenValue', tokenValue);
            setParamsSend({
                name: 'api/password-token-validate',
                fields: 'token=' + tokenValue,
                method: 'get'
            })
        }
    }, [tokenValue]);


    useEffect(() => {
        if (responseSend && isLoadedSend) {
            let {status} = responseSend;
            // console.log('status', status);

            if (status === 200) {
                //  console.log('200 status', data);
                // Open Login modal
                dispatch(updateModalsOpen({...modalsOpen, 'changePassword': true, 'login': false}));

            } else {
                //Erreur
                console.log('ERREUR status - popin erreur', status);
                // Error model
                dispatch(updateModalsOpen({...modalsOpen, 'errorMessage': true}));
            }

        }
    }, [responseSend, isLoadedSend]);    // eslint-disable-line


    const renderPage = () => {
        return <div className="container"></div>;
    };

    return isLoadedSend ? renderPage() : <Loader/>;
};


export default TemplatePasswordReset;