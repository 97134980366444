import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateModalsOpen, updateModalsLoginType } from '../Store/action';
// HOOKS
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';
// UTILS
import { randomString } from "../Utils/md5-hash";
import CLink from "./cLinks";

const ConditionalLink = ({ children, url, condition }) => {
    if (condition) {
        return (
            <CLink url={url}>{children}</CLink>
        )
    } else {
        return (
            <>{children}</>
        )
    }
}

const CommunityPostCard = ({
    postType,
    created,
    title,
    fieldCommonTxtBody,
    uid: { displayName, userPicture },
    nodeView,
    likesCount,
    fieldCommunityTrThemes,
    fieldCommunityTrTags,
    fieldCommunityFileMedias,
    drupalInternalNid,
    openPreview,
    path,
    fieldCommonComments,
    isPreview
}) => {

    const baseURL = process.env.REACT_APP_APP;
    // Modals - Login
    let { uuid, iupid } = useSelector(state => state.user);
    const modalsOpen = useSelector(state => state.modals).openModal;
    const dispatch = useDispatch();
    // TRACKING - Like content
    const [trackingLikeEvent, setTrackingLikeEvent] = useState({});
    const trackingLike = useTracking(trackingLikeEvent); // eslint-disable-line

    // STATE FOR UPDATE API COUNT AND STYLE LIKE BTN
    const [likeApi, setLikeApi] = useState({});
    const [dataFetchLike, isLoadedLike] = useApi(likeApi); // eslint-disable-line
    const [isLike, setIsLike] = useState(false);
    const [loggedLikeCount, setLoggedLikeCount] = useState();
    // INIT COUNTER LIKE 
    useEffect(() => {
        if (!isPreview && uuid) {
            const headers = {
                "Content-Type": "application/json"
            };
            setLikeApi({
                name: `api/like/node/${drupalInternalNid}`,
                method: 'get',
                noData: true,
                config: headers
            });
        }
    }, [uuid]);// eslint-disable-line

    // TRIGGER LIKE CHANGE
    useEffect(() => {
        if (dataFetchLike && isLoadedLike) {
            setLoggedLikeCount(dataFetchLike.data.count)
            setIsLike(dataFetchLike.data.current_user_like)
        }
    }, [dataFetchLike, isLoadedLike]);// eslint-disable-line

    // TRIGGER CLICK LIKE AND POST IN API 
    const onClickLike = (event) => {
        event.preventDefault();
        if (!uuid) {
            //Popin de log
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'password': false, 'economy': false }));
            dispatch(updateModalsLoginType('currentPage'));
        } else {
            const headers = {
                "Content-Type": "application/json"
            };
            setLikeApi({
                name: `api/like/node/${drupalInternalNid}?${randomString(4)}`,
                method: 'post',
                noData: true,
                config: headers
            });
            setTrackingLikeEvent({
                event: 'community_engagement',
                args: {
                    engagement_type: isLike === true ? 'dislike' : 'like',
                    content_type: fieldCommunityTrThemes.name
                },
                refresh: randomString(6)
            });
        }
    }
    const trackingSeeAnswers = () => {
        // Tracking
        window.customDataLayer.push({
            event: 'view_answers',
            user_id: iupid ? iupid : undefined,
            content_type: fieldCommunityTrThemes.name,
            content_name: title
        });
    }

    // FORMAT TIMESTAMP CREATION DATE 
    const formatDate = (timestamp) => {
        if (timestamp) {
            const date = new Date(timestamp);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }
            return (
                `${day}/${month}/${year}`
            )
        }
    }

    const decodeHTML = (text) => {
        const div = document.createElement("div");
        div.innerHTML = text;
        return div.textContent;
    }

    return (
        <div className={`c-communityPostCard ${postType}--`}>
            <ConditionalLink url={path ? path.alias : ""} condition={path}>
                <div className="c-communityPostCard-header">
                    {
                        postType &&
                        <div className="c-communityPostCard-header-icon">
                            <i className="icono-bgd"></i>
                            <i className="icono"></i>
                        </div>
                    }
                    {!path ?
                        <h1 className="c-communityPostCard-header-title">{title}</h1>
                        :
                        <p className="c-communityPostCard-header-title">{title}</p>
                    }
                </div>
            
            <div className="c-communityPostCard-content">
                <div className="c-communityPostCard-content-profilePic">
                    <img src={userPicture === undefined || userPicture === null ? require("../assets/img/default-profil.png") : `${baseURL + userPicture.uri.url}`} alt="" />
                </div>
                <div className="c-communityPostCard-content-infos">
                    <h3 className="info-name">{displayName}</h3>
                    <p className="info-text">{formatDate(created)}
                        {nodeView && <strong> - {nodeView} {nodeView <= 1 ? 'vue' : 'vues'}</strong>}
                    </p>
                    <div className="info-text">
                        <div dangerouslySetInnerHTML={{ __html: fieldCommonTxtBody.processed }}></div>
                        {
                            fieldCommunityTrTags && Array.isArray(fieldCommunityTrTags) &&
                            fieldCommunityTrTags.map((tag, key) => {
                                if (tag.name) {
                                    return (
                                        <p key={key} className="tags--">{`#${decodeHTML(tag.name.replace(/\s+/g, '').toLowerCase())}`}</p>
                                    )
                                }
                            })
                        }
                    </div>
                    {
                        fieldCommunityTrThemes &&
                        <ul className="info-thematics-list">
                            <li>{fieldCommunityTrThemes.name}</li>
                        </ul>
                    }
                    {
                        fieldCommunityFileMedias &&
                        <ul className="info-uploads-list">
                            {
                                Array.isArray(fieldCommunityFileMedias) && fieldCommunityFileMedias.length > 0 &&
                                fieldCommunityFileMedias.map((file, key) => {
                                    if (file.filemime === "image/jpeg" || file.filemime === "image/jpg" || file.filemime === "image/png") {
                                        if (file.links.commonCarrouselDesktop) {
                                            return (
                                                <li key={key} onClick={() => openPreview && openPreview(key)} className={`info-uploads-list-item ${openPreview ? 'clickable' : ''}`}>
                                                    <img src={file.links.commonCarrouselDesktop.href} alt="" />
                                                </li>
                                            )
                                        }
                                    } else if (file.filemime === "video/mp4" || file.filemime === "video/mov") {
                                        return (
                                            <li key={key} onClick={() => openPreview && openPreview(key)} className={`info-uploads-list-item ${openPreview ? 'clickable' : ''}`}>
                                                <button className="video-btn"></button>
                                                <video
                                                    src={file.isPreview ? file.uri.url : baseURL + file.uri.url} />
                                            </li>
                                        )
                                    } else {
                                        return ""
                                    }
                                })
                            }
                        </ul>
                    }
                    {
                        path &&
                        <div className="c-communityPostCard-content-actions">
                            <p className="redirection"><i className="icon-comment"></i>{postType === 'astuce' ? "Commentaire" : "Répondre"}</p>
                            {
                                fieldCommonComments &&
                                <p
                                    onClick={trackingSeeAnswers}
                                    className="redirection underline--">
                                    {postType === 'question' ? 
                                        fieldCommonComments.commentCount <= 0 ?
                                            ''
                                            : fieldCommonComments.commentCount <= 1 ?
                                                `Voir ${fieldCommonComments.commentCount} réponse`
                                                : `Voir ${fieldCommonComments.commentCount} réponses` : 
                                        fieldCommonComments.commentCount <= 0 ?
                                        ''
                                        : fieldCommonComments.commentCount <= 1 ?
                                            `Voir ${fieldCommonComments.commentCount} commentaire`
                                            : `Voir ${fieldCommonComments.commentCount} commentaires`
                                    }
                                </p>
                            }
                        </div>
                    }
                    {
                        postType === 'astuce' && !isPreview &&
                        <div className="c-communityPostCard-content-likes">
                            <span>{uuid ? loggedLikeCount : likesCount}</span>
                            <i onClick={(e) => onClickLike(e)} className={`icon-up ${isLike ? 'active' : ''}`}></i>
                        </div>
                    }
                </div>
            </div>
            </ConditionalLink>
        </div>
    )
};

export default CommunityPostCard;
