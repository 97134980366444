import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// HOOKS
import useApi from '../Hooks/useApiGlobal';
import { getClientCredential } from '../Utils/getClientService';
import { getRefreshToken } from '../Utils/cookie';
// API NORMALISATION 
import normalize from 'json-api-normalizer';
import build from 'redux-object';
//Composants
import { Loader } from "../Components/loader";
import CommunityPostCard from "../Components/communityPostCard";
import CommunityThematicsList from "../Components/communityThematicsList";
import CLink from "../Components/cLinks";
import CarouselCashback from "../Components/carouselCashback";
// LIBRAIRIES
import ReactPaginate from 'react-paginate';

const CommunitySearchResults = ({ searchType, btnUrl, btnText }) => {
    const { desktop } = useSelector(state => state.breakpoint);
    // Get search value in url
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get('s');
    // Infos to get resized img
    const consumerId = getClientCredential(getRefreshToken, 'client_credentials');
    // Infos to know if user is connected
    const { uuid } = useSelector(state => state.user);

    // HANDLE PAGINATION 
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const handlePageClick = (event) => {
        setCommunityRedux([]);
        setPostIdVisible([]);
        setSameThematicId([]);
        setPageNumber(event.selected);
    };
    // BUILDER TO FORMAT QUERIES
    const queryBuilderFields = (objParams, prefix) => {
        var query = Object.keys(objParams).map(key => `[${key}]` + '=' + objParams[key]).join(`&${prefix}`);
        query = `&${prefix}` + query
        return query
    }
    const queryBuilderFilter = (obj, prefix) => {
        let query = [], p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                let k = prefix ? prefix + `[${p}]` : p,
                    v = obj[p];
                query.push((v !== null && typeof v === "object") ?
                    queryBuilderFilter(v, k) :
                    k + "=" + v);
            }
        }
        return query.join("&");
    }
    // GET ALL POSTS 
    const [communityRedux, setCommunityRedux] = useState([]);
    let communityDataInclude = [
        'field_community_tr_themes', 'field_community_file_medias', 'field_community_tr_tags', 'node_type', 'uid', 'uid.user_picture'
    ];
    let searchFields = {
        "node_type--node_type": [
            'drupal_internal__type'
        ],
        "node--question": [
            'node_type', 'title', 'created', 'field_common_txt_body', 'node_view', 'likes_count,drupal_internal__nid', 'field_community_tr_themes', 'field_community_file_medias', 'uid', 'field_community_tr_tags', 'field_common_comments', 'path'
        ],
        "node--tip": [
            'node_type', 'title', 'created', 'field_common_txt_body', 'node_view', 'likes_count,drupal_internal__nid', 'field_community_tr_themes', 'field_community_file_medias', 'uid', 'field_community_tr_tags', 'field_common_comments', 'path'
        ],
        "file--file": [
            'uri', 'filemime', 'filename', 'links'
        ],
        "field_community_tr_tags": [
            'name'
        ],
        "taxonomy_term--community_theme": [
            'name', 'field_api_remoteid_themeid'
        ],
        "users": [
            'display_name', 'user_picture'
        ]
    }
    const [dataFetchCommunity, isLoadedCommunity] = useApi({
        name: `api/search/community-${searchType === "astuce" ? 'tip' : 'question'}`,
        include: communityDataInclude.join(','),
        fields: `&keys=${searchValue}${queryBuilderFields(searchFields, 'fields')}`,
        method: 'get',
        pagination: `&page=${pageNumber}`,
        consumerId: consumerId.client_id
    });
    // ON ALL POST LOAD 
    useEffect(() => {
        if (isLoadedCommunity && dataFetchCommunity) {
            setTotalPages(dataFetchCommunity.data.results_nb);
            setItemsPerPage(dataFetchCommunity.data.items_per_page);
            let results = dataFetchCommunity.data.results_nb === 0 ? [] : dataFetchCommunity.data.results;
            results.forEach((post) => {
                let normalizeJson = normalize(post.node);
                setCommunityRedux(communityRedux => [...communityRedux, build(normalizeJson, `${normalizeJson["nodeQuestion"] !== undefined ? 'nodeQuestion' : 'nodeTip'}`)])
            })
        }
    }, [isLoadedCommunity, dataFetchCommunity]); //eslint-disable-line

    // GET SAME THEMATICS QUESTIONS AND TIPS
    const [questionThematics, setQuestionThematics] = useState();
    const [astuceThematics, setAstuceThematics] = useState();
    const [postIdVisible, setPostIdVisible] = useState([]);
    const [sameThematicId, setSameThematicId] = useState([]);
    const [sortDataLoaded, setSortDataLoaded] = useState(false)
    let thematicsDataInclude = [
        'field_community_tr_themes'
    ];
    let tipsFields = {
        "node--tip": [
            'title', 'field_community_tr_themes', 'path', 'likes_count'
        ],
        "taxonomy_term--community_theme": [
            'name'
        ]
    }
    let questionsFields = {
        "node--tip": [
            'title', 'field_community_tr_themes', 'path'
        ],
        "taxonomy_term--community_theme": [
            'name'
        ]
    }

    let thematicsFilter = {
        "and-group": {
            "group": {
                "conjunction": "AND"
            }
        },
        "or-group": {
            "group": {
                "conjunction": "OR",
                "memberOf": "and-group"
            }
        },
        "id-filter": {
            "condition": {
                "path": "id",
                "operator": "NOT IN",
                "value": postIdVisible,
                "memberOf": "and-group"
            }
        },
        "theme-filter-1": {
            "condition": {
                "path": "field_community_tr_themes.id",
                "operator": "%3D",
                "value": sameThematicId[0],
                "memberOf": "or-group"
            },
        },
        "theme-filter-2": {
            "condition": {
                "path": "field_community_tr_themes.id",
                "operator": "%3D",
                "value": sameThematicId[1],
                "memberOf": "or-group"
            },
        },
        "theme-filter-3": {
            "condition": {
                "path": "field_community_tr_themes.id",
                "operator": "%3D",
                "value": sameThematicId[2],
                "memberOf": "or-group"
            },
        }
    }

    const [dataFetchQuestions, isLoadedQuestions] = useApi({
        name: `api/node/question`,
        include: thematicsDataInclude.join(','),
        fields: queryBuilderFields(questionsFields, 'fields'),
        filter: `&${queryBuilderFilter(thematicsFilter, 'filter')}`,
        limit: 2,
        method: 'get'
    });
    const [dataFetchAstuces, isLoadedAstuces] = useApi({
        name: `api/node/tip`,
        include: thematicsDataInclude.join(','),
        fields: queryBuilderFields(tipsFields, 'fields'),
        filter: `&${queryBuilderFilter(thematicsFilter, 'filter')}`,
        limit: 2,
        method: 'get'
    });
    // SET IDS VISIBLE TO FILTER ON SAME THEMATICS
    useEffect(() => {
        if (communityRedux.length > 0) {
            const tematicsArr = []
            communityRedux.forEach(post => {
                setPostIdVisible(postIdVisible => [...postIdVisible, post[0].id])
                if (tematicsArr.indexOf(post[0].fieldCommunityTrThemes.id) === -1) {
                    tematicsArr.push(post[0].fieldCommunityTrThemes.id)
                }
            })
            setSameThematicId(tematicsArr)
            setSortDataLoaded(true)
        }
    }, [communityRedux])
    // CHECK WHEN API IS LOAD FOR ALL THEMATICS QUESTIONS
    useEffect(() => {
        if (isLoadedQuestions && dataFetchQuestions && sortDataLoaded) {
            let normalizeJson = normalize(dataFetchQuestions.data);
            setQuestionThematics(build(normalizeJson, 'nodeQuestion'));
        }
    }, [isLoadedQuestions, dataFetchQuestions]);// eslint-disable-line

    useEffect(() => {
        if (dataFetchAstuces && isLoadedAstuces && sortDataLoaded) {
            let normalizeJson = normalize(dataFetchAstuces.data);
            setAstuceThematics(build(normalizeJson, 'nodeTip'));
        }
    }, [dataFetchAstuces, isLoadedAstuces]);// eslint-disable-line

    const renderPage = () => {
        return (
            <div className="c-communitySearch">
                <div className="c-communitySearch-title container">
                    <h1 className="c-communitySearch-title-number">{totalPages <= 1 ? `${totalPages} résultat` : `${totalPages} résultats`}</h1>
                    <p className="c-communitySearch-title-text">{totalPages <= 1 ? 'correspond' : 'correspondent'} à votre recherche</p>
                </div>
                {
                    communityRedux && totalPages > 0 &&
                    <div className="c-communitySearch-results">
                        <div className="container">
                            {
                                communityRedux.map((post, key) => {
                                    return (
                                        <CommunityPostCard
                                            {...post[0]}
                                            key={key}
                                            postType={post[0].nodeType.drupalInternalType === 'tip' ? 'astuce' : 'question'}
                                        />
                                    )
                                })
                            }
                            {
                                !(totalPages <= itemsPerPage) &&
                                <ReactPaginate
                                    className="c-communityHp-search-pagination"
                                    breakLabel="..."
                                    nextLabel=">"
                                    previousLabel="<"
                                    onPageChange={handlePageClick}
                                    forcePage={Math.ceil(pageNumber / itemsPerPage)}
                                    pageCount={Math.ceil(parseInt(dataFetchCommunity.data.results_nb, 10) / itemsPerPage)}
                                />
                            }
                        </div>
                    </div>
                }
                <div className="container">
                    {
                        uuid && btnUrl &&
                        <div className="c-communitySearch-btn">
                            <CLink url={btnUrl} className="btn-2">{btnText}</CLink>
                        </div>
                    }
                </div>
                {
                    communityRedux && sortDataLoaded &&
                    <>
                        <CommunityThematicsList
                            thematicQuestions={questionThematics}
                            thematicAstuces={astuceThematics}
                            postType={searchType}
                        />
                        <div className="container">
                            <CarouselCashback desktop={desktop}
                                themeID={communityRedux}
                            />
                        </div>
                    </>
                }
            </div>
        )
    }
    return (
        isLoadedCommunity && dataFetchCommunity ? renderPage() : <Loader />
    )
};

export default CommunitySearchResults;