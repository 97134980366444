import React, {useEffect, useState} from "react";
import useAnchors from "../../Hooks/useAnchors";

const StepText = ({listStep, title, className, desktop, dataTrackingEventViewMore }) => {
    const lengthStep = listStep.length;
    const [showMore, setshowMore] = useState(2);

    const markupContent = document.getElementById('stepText_content');
    const { currentAnchor, indexAnchor } = useAnchors({ elements: listStep.map(step => step.fieldDiyTxtStepDesc ? step.fieldDiyTxtStepDesc.value : '')})

    useEffect(() => {
        if (currentAnchor && markupContent) {
            if (indexAnchor > showMore) { // if step is not shown 
                setshowMore(indexAnchor + 1)
                setTimeout(() => { //set timeout to scroll down
                    let currentElement = document.getElementById(currentAnchor)
                    if (currentElement) currentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 500);
            }
        }
    }, [currentAnchor, indexAnchor, markupContent])

    const renderMarkup = (markup) => {
        return { __html: markup };
    };

	const handleShowMore = () => {
        setshowMore(lengthStep);
        // TRACKING - view_more_content
        if (dataTrackingEventViewMore) {
            window.customDataLayer.push({ ...dataTrackingEventViewMore });
            // console.log("stepText.jsx -> 15: customDataLayer", window.customDataLayer  )
        }
    };

    return (
        
        <div className="c-stepText">
            {title &&
                <div className="c-stepText_title"><h2>{title}</h2></div>
            }
            {lengthStep > 0 &&
                <div className={`c-stepText_container ${className ? className : ''}`}>
                {
                    
                    // listStep.slice(0, state).map
                    listStep.slice(0, showMore).map(({fieldDiyImgStep,fieldDiyTxtStepDesc,fieldDiyTxtStepTitle}, key) => {
                        return (
                            <div key={key} className="c-stepText_content" id="stepText_content">
                                {fieldDiyImgStep && 
                                    <div className="c-stepText_visuel"><img src={desktop ? fieldDiyImgStep.links.commonLogoDesktop.href : fieldDiyImgStep.links.commonLogoMobile.href} alt="" /></div>
                                }
                                
                                <div className="c-wysiwyg">
                                    {fieldDiyTxtStepTitle && <h3>{fieldDiyTxtStepTitle}</h3>}
                                    {(fieldDiyTxtStepDesc && fieldDiyTxtStepDesc.processed) && <div dangerouslySetInnerHTML={renderMarkup(fieldDiyTxtStepDesc.processed)}></div>}
                                </div>
                            </div>
                        )
                    })
                }
                    <button className={`t-article_more ${showMore>=lengthStep ? 'hide--' : ''}`} onClick={handleShowMore}>Voir plus</button>
                </div>
            }
        </div>
    )
};

export default StepText;