import React, { useState, useEffect } from 'react';
import CLink from "./cLinks";
import { Loader } from "../Components/loader";

const SearchResults = ({ data, total, page, setPage }) => {
    const [postsToShow, setPostsToShow] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const postsPerPage = 10;
    const [next, setNext] = useState(2);
    const posts = data;
    const postsMax = total;
    const totalPosts = posts.length;
    let arrayForHoldingPosts = [];


    const Extract = ({ content }) => {
        const renderMarkup = (markup) => {
            return { __html: markup };
        };

        let contentLength = content.length;
        let extract_content = content;
        if (contentLength > 195) extract_content = content.substr(0, 195) + '...';

        return (
            <div dangerouslySetInnerHTML={renderMarkup(extract_content)} />
        )
    };

    const Posts = ({ postsToRender }) => {
        return (
            <>
                {postsToRender.map((item, key) => (
                    <div className="t-search_container" key={key}>
                        <div className="t-search_content">
                            {/*{console.log(item.node.data.attributes.field_common_txt_title)}*/}
                            {((item.type === 'Article' || item.type === 'Vidéo' || item.type === 'DIY') && item.node.data.attributes.field_common_txt_title) ?
                                <h3>{item.node.data.attributes.field_common_txt_title}</h3>
                                :
                                <h3>{item.title}</h3>
                            }

                            {((item.type === 'Article' || item.type === 'Vidéo') && item.node.data.attributes.field_article_txt_intro.value) &&
                                <Extract content={item.node.data.attributes.field_article_txt_intro.value} />
                            }
                            {(item.type === 'DIY' && item.node.data.attributes.field_diy_txt_intro.value) &&
                                <Extract content={item.node.data.attributes.field_diy_txt_intro.value} />
                            }
                            {(item.type === 'Page produit' && item.node.data.attributes.field_product_txt_introbody.value) &&
                                <Extract content={item.node.data.attributes.field_product_txt_introbody.value} />
                            }
                            {(item.type === 'Page marque' && item.node.data.attributes.field_brand_txt_introbody.value) &&
                                <Extract content={item.node.data.attributes.field_brand_txt_introbody.value} />
                            }
                            {(item.type === 'Question' || item.type === 'Astuce' && item.node.data.attributes.field_common_txt_body.processed) &&
                                <Extract content={item.node.data.attributes.field_common_txt_body.processed} />
                            }
                            {item.type === 'Page spéciale' &&
                                <>
                                    {(item.node.data.attributes.field_special_list_pagetype === "economy_cashback" && item.node.data.attributes.field_special_configformref.data.introductions.cashback.text.value) &&
                                        <Extract
                                            content={item.node.data.attributes.field_special_configformref.data.introductions.cashback.text.value} />
                                    }
                                    {(item.node.data.attributes.field_special_list_pagetype === "economy_webcoupon" && item.node.data.attributes.field_special_configformref.data.introductions.webcoupon.text.value) &&
                                        <Extract
                                            content={item.node.data.attributes.field_special_configformref.data.introductions.webcoupon.text.value} />
                                    }
                                </>
                            }
                            <p className="t-search_link"><CLink url={item.link}
                                title={item.title}>En savoir plus</CLink>
                            </p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const loopWithSlice = (start, end) => {
        const slicedPosts = posts.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };

    useEffect(() => {
        if (page === 0 && next < totalPosts) {
            loopWithSlice(0, 2);
        } else {
            setPostsToShow(posts);
        }

        // Hide loader when results
        setShowLoader(false);

    }, [data]); //eslint-disable-line

    const handleShowMorePosts = () => {
        if (page === 0 && next < totalPosts) {
            loopWithSlice(0, next + postsPerPage);
            setNext(next + postsPerPage);
        } else {
            setPage(page + 1);
            // Show loader
            setShowLoader(true)
        }
    };

    return (
        <>
            <Posts postsToRender={postsToShow} />

            {!showLoader && totalPosts > 2 && (postsToShow.length < postsMax) &&
                <button className={`t-search_more`}
                    onClick={handleShowMorePosts}>Afficher plus de résultats
            </button>
            }

            {showLoader &&
                <Loader />
            }
        </>
    )
};

export default SearchResults;
