import { useState, useEffect } from 'react';

const useIntersection = (element, rootMargin) => {
    require('intersection-observer');
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setState(entry.isIntersecting);
                    observer.unobserve(element.current);
                }
            }, { rootMargin }
        );

        element && observer.observe(element.current);

        return () => {

            if (element && element.current) {
                observer.unobserve(element.current);
            } else {
                observer.disconnect();
            }
        }

    }, []);

    return isVisible;
};

export default useIntersection;