export const setCookie = (name, value, days) => {
    let d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
};

export const setCookieSeconds = (name, value, seconds) => {
    let d = new Date();
    d.setTime(d.getTime() + 1000 * seconds);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
};

export const eraseCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
};

export const getCookie = (name) => {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};


// Systeme de cookies pour les Token
export const setToken = (data) => {
    const days = 10; //valable 10jrs
    let d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = 'accessToken='+ data + ";path=/;expires=" + d.toGMTString();
};

export const setRefreshToken = (data) => {
    const days = 10; //valable 10jrs
    let d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = 'refreshToken='+ data + ";path=/;expires=" + d.toGMTString();
};
export const getToken = () => {
    let v = document.cookie.match('(^|;) ?accessToken=([^;]*)(;|$)');
    return v ? v[2] : null;
};

export const eraseToken = () => {
    document.cookie = 'accessToken=; Max-Age=-99999999;';
};


export const getRefreshToken = () => {
    let v = document.cookie.match('(^|;) ?refreshToken=([^;]*)(;|$)');
    return v ? v[2] : null;
};

export const eraseRefreshToken = () => {
    document.cookie = 'refreshToken=; Max-Age=-99999999;';
};
