import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import { updateGlobalStorePage, updateModalsOpen, updateModalsLoginType } from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import CLink from "../Components/cLinks";
import Univers from "../Components/univers"
// import MaxiLinkCarousel from "../Components/maxiLinkCarousel"
import Notification from "../Components/notification"
import Logout from '../Components/logout';
import { Loader } from "../Components/loader";


const TemplateAccountHome = ({ _uid }) => {

    let dispatch = useDispatch();

    // Get Global resources
    let globalStore = useSelector(state => state.global).global;
    // Get update account path
    const accountEditPath = useSelector(state => state.routes).routes.accountEdit;

    // Get path of account notifications
    const accountNotificationsPath = useSelector(state => state.routes).routes.accountNotifications;
    const accountEconomyPath = useSelector(state => state.routes).routes.accountEconomy;
    const accountFavoritesPath = useSelector(state => state.routes).routes.accountFavorites;
    const accountCommunity = useSelector(state => state.routes).routes.accountCommunity;

    // Get user
    let { uuid, favorites } = useSelector(state => state.user);

    const modalsOpen = useSelector(state => state.modals).openModal;//modal

    let [params, setParams] = useState('');
    let [paramsNotif, setParamsNotif] = useState('');
    useEffect(() => {
        if (uuid) {
            setParams({
                name: `api/users/${uuid}`,
                include: 'user_picture',
                //fields: '&fields[users]=display_name,lba_profilepicture,profile_completion',
                method: 'get'
            });
            setParamsNotif({
                name: 'api/users/notifications',
                format: 'json',
                method: 'get'
            })
        }
        else {
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'password': false, 'economy': false }));
            dispatch(updateModalsLoginType('account'));
        }
    }, [uuid]); // eslint-disable-line


    // Requete pour récupérer les infos de l'user
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi(params);
    const [dataFetchNotif, isLoadedNotif] = useApi(paramsNotif); // eslint-disable-line

    useEffect(() => {
        if (isLoaded && dataFetch) {
            setDataRedux(dataFetch.data.data);
        }
    }, [isLoaded, dataFetch]);

    // Meta - Get page info
    const [dataPage, setDataPage] = useState();
    //Requete pour récupérer les infos de la page spéciale
    const [dataFetchPage, isLoadedPage] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get'
    });

    useEffect(() => {
        if (isLoadedPage && dataFetchPage) {
            const normalizeJson = normalize(dataFetchPage.data);//Normalisation du json
            setDataPage(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoadedPage, dataFetchPage]); // eslint-disable-line


    // GET ALL PROFILE STATS
    const [totalDataStats, setTotalDataStats] = useState();
    const [dataFetchStats, isLoadedStats] = useApi({
        name: "api/lba-community/stats",
        needLogin: true,
        method: "get",
    });
    useEffect(() => {
        if (isLoadedStats && dataFetchStats) {
            if (dataFetchStats.status === 200) {
                setTotalDataStats(dataFetchStats.data.total)
            }
        }
    }, [isLoadedStats, dataFetchStats]); // eslint-disable-line


    useEffect(() => {
        dispatch(updateGlobalStorePage('t-accountHome'));
    }, [dispatch]); // isLoaded


    // SAVINGS
    const [savings, setSavings] = useState(null);

    function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    let oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);

    const startDate = formatDate(oneYearFromNow);

    const d = new Date();
    let endDate = d.setDate(d.getDate() + 1);
    endDate = new Date(endDate).toISOString().slice(0, 10);

    // Webcoupon Savings
    const [webcouponSavings, setWebcouponsSavings] = useState(null);
    const [webcouponSavingsRequest, isLoadedWebcouponSavingsRequest] = useApi({
        name: 'api/lba-economy/savings/' + startDate + '/' + endDate,
        method: 'get',
        needLogin: true
    });

    useEffect(() => {
        if (isLoadedWebcouponSavingsRequest && webcouponSavingsRequest) {
            let { status } = webcouponSavingsRequest;
            if (status === 200) {
                if (webcouponSavingsRequest.data && webcouponSavingsRequest.data.mesEconomies) {
                    setWebcouponsSavings(webcouponSavingsRequest.data.mesEconomies);
                } else {
                    setWebcouponsSavings(0);
                }
            } else {
                console.log('error - get webcoupon savings');
            }
        }
    }, [isLoadedWebcouponSavingsRequest, webcouponSavingsRequest]); // eslint-disable-line

    // Cashback Savings
    const [cashbackSavings, setCashbackSavings] = useState(null);
    const [cashbackSavingsRequest, isLoadedCashbackSavingsRequest] = useApi({
        name: 'api/lba-cashback/savings/' + startDate + '/' + endDate,
        method: 'get'
    });

    useEffect(() => {
        if (isLoadedCashbackSavingsRequest && cashbackSavingsRequest) {
            let { status } = cashbackSavingsRequest;
            if (status === 200) {
                if (cashbackSavingsRequest.data && cashbackSavingsRequest.data.mesEconomies) {
                    setCashbackSavings(cashbackSavingsRequest.data.mesEconomies);
                } else {
                    setCashbackSavings(0);
                }
            } else {
                console.log('error - get cashback savings');
            }
        }
    }, [isLoadedCashbackSavingsRequest, cashbackSavingsRequest]); // eslint-disable-line

    useEffect(() => {
        if (webcouponSavings !== null && cashbackSavings !== null) {
            setSavings(parseFloat(webcouponSavings + cashbackSavings).toFixed(2));
        }
    }, [webcouponSavings, cashbackSavings]);

    const renderPage = () => {
        const { metatag } = dataPage;

        // More info
        let moreInfoLink = '';
        let moreInfoLabel = '';
        if (globalStore.account_homepage.link.label) {
            moreInfoLabel = globalStore.account_homepage.link.label
        }
        if (globalStore.account_homepage.link.enable) {
            moreInfoLink = accountEditPath + '/#more-info-profil'
        }

        let moreProfilInfo = [
            {
                target: moreInfoLink,
                targetTitle: "Gérer mon profil",
                content: moreInfoLabel,
            },
        ];

        // Profil picture
        let userInfo = dataRedux.attributes;

        let userPicture = null;
        let {data} = dataFetch;
        if (data.included && data.included[0] && data.included?.links?.common_logo_desktop?.href !== null) {
            userPicture = data.included[0].links.common_logo_desktop.href;
        }

        if (userPicture == null) {
            userPicture = require("../assets/img/default-profil.png");
        }

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'Mon compte',
        };
        let formAccordion = require('../api/form.json');
        const {forms} = formAccordion;

        const calculateAnsweredCount = (questions, attributes) => {
            return questions.reduce((count, question) => {
                return count + (attributes[question.id] ? 1 : 0);
            }, 0);
        };
        
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <div className="on-top--">
                    <Logout />
                </div>

                <div className="c-pictoProfil_container">
                    <div className="container">
                        <div className="c-pictoProfil">
                            {userInfo &&
                                <div className="c-pictoProfil_img">
                                    <img src={userPicture} alt="photo de profil" />
                                </div>
                            }
                            <div className="c-pictoProfil_info">
                                <h2 className="c-pictoProfil_title">Bienvenue dans votre compte {userInfo?.display_name}&nbsp;!</h2>
                                <div className="c-pictoProfil_info__wrapper">
                                    {userInfo &&
                                        <div className="c-pictoProfil_img only-mobile">
                                            <img src={userPicture} alt="photo de profil" />
                                        </div>
                                    }
                                    <div className="c-pictoProfil_info__container">
                                        <p className="c-pictoProfil_text">Profil complété à {userInfo.profile_completion}%.</p>
                                        <p>Parlez-nous de vous et compléter votre profil pour bénéficier d’une expérience plus personnalisée</p>
                                    </div>
                                    <div className="c-pictoProfil_progressBar">
                                        <img style={{left: `${userInfo.profile_completion}%`}} src={require('../assets/img/account/progress-icon.png')} alt="" />
                                        <div className="bar">
                                            <span style={{width: `${userInfo.profile_completion}%`}} className="progress">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="t-accountHome_redirections container">
                    <div className="mainRedirections">
                        <div className="mainRedirections_card">
                            <h2>Mes informations personnelles</h2>
                            <CLink url={`${accountEditPath}#coordonnees`} title="Modifier">
                                <span className="btn-2">Modifier</span>
                            </CLink>
                        </div>
                        <div className="mainRedirections_card">
                            <h2>Mes préférences de communication</h2>
                            <CLink url={`${accountEditPath}#communication`} title="Modifier">
                                <span className="btn-2">Modifier</span>
                            </CLink>
                        </div>
                    </div>
                    <div className="questionsRedirections">
                        <h2>Mes habitudes de consommation</h2>
                        <p>Prenez quelques minutes pour répondre à ces questions, elles nous permettront de mieux vous connaitre et de vous proposer des communications et des offres personnalisées ! Merci</p>
                        <div className="c-univers">
                            {
                                forms && forms.length > 0 &&
                                forms.map((card, key)=> {
                                    const answeredCount = card.questions ? calculateAnsweredCount(card.questions, data?.data?.attributes) : 0;
                                    return (
                                        <Univers
                                            key={key}
                                            targetTitle={card.title}
                                            target={`${accountEditPath}#${card.title.replace(/ /g, '').toLowerCase()}`}
                                            index={key}>
                                            <h3>{card.title}</h3>
                                            <p>{answeredCount}/{card?.questions?.length} question{card?.questions?.length > 1 ? 's': ''}</p>
                                        </Univers>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="myactivities">
                        <h2 className="title-2">Mon activité
                            <Notification target={accountNotificationsPath} targetTitle="Voir les notifications"
                                number={(dataFetchNotif && dataFetchNotif.data) && dataFetchNotif.data.unread_count} />
                        </h2>
                        <div className="c-univers">
                            {savings &&
                                <Univers type="universEconomy" target={accountEconomyPath} targetTitle="Voir mes économies"
                                    index="0"><strong>{savings.toString().replace('.', ',')} €</strong>
                                    <span>d’économies sur 12 mois</span></Univers>
                            }
                            <Univers type="universTips" target={accountFavoritesPath} targetTitle="Voir mes favoris"
                                index="1"><strong>{(favorites && Array.isArray(favorites)) ? favorites.length : '0'}</strong>
                                <span>{(favorites && Array.isArray(favorites) && favorites.length > 1) ? 'articles' : 'article'} favoris</span></Univers>
                            {/* <Univers type="universBrands" targetTitle="Voir les tickets d'or"
                                index="2">Bientôt disponible</Univers> */}
                            <Univers type="universCommunity"
                                targetTitle="Voir mes contributions"
                                target={accountCommunity}
                                index="3">
                                {typeof totalDataStats === 'number' && totalDataStats >= 0 && 
                                    <p>
                                        <strong>{totalDataStats}</strong><br /><span>contribution{totalDataStats > 1 && 's'}</span>
                                    </p>
                                }
                            </Univers>
                        </div>
                    </div>

                </div>
                {/* {(moreInfoLink || moreInfoLabel) &&
                    <MaxiLinkCarousel moreProfilInfo={moreProfilInfo} />
                } */}
            </>
        )
    };
    return (isLoaded && dataRedux && dataPage) ? renderPage() : <Loader />
};

export default TemplateAccountHome;