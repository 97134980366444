import React, {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from "react-router-dom";
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import useApi from '../Hooks/useApiGlobal';
import {updateGlobalContactReset, updateGlobalStorePage, updateModalsOpen} from '../Store/action';
import useTracking from "../Hooks/useTracking";
import useApiJeton from '../Hooks/useApiJeton';

// Libraries
import {useForm, Controller} from "react-hook-form";
import ReCaptcha from "react-google-recaptcha";
import Select from "react-select";

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import VisuelLarge from "../Components/visuelLarge";
import Tooltip from "../Components/tooltip"
import {Loader} from "../Components/loader";

// Utils
import {transformText, slugify} from "../Utils/transformText";
import {randomString} from "../Utils/md5-hash";
import {decodeEntities} from "../Utils/decodeEntities";

export const TemplateContactHome = ({_uid}) => {
    let config = {
        'callState': false,
        'callbackRequestState': false,
        'emailState': false,
        'doserState': false
    };
    return (
        <TemplateContact _uid={_uid} {...config}/>
    )
};

export const TemplateContactCall = ({_uid}) => {
    let config = {
        'callState': true,
        'callbackRequestState': false,
        'emailState': false,
        'doserState': false
    };
    return (
        <TemplateContact _uid={_uid} {...config}/>
    )
};

export const TemplateContactCallbackRequest = ({_uid}) => {
    let config = {
        'callState': false,
        'callbackRequestState': true,
        'emailState': false,
        'doserState': false
    };
    return (
        <TemplateContact _uid={_uid} {...config}/>
    )
};

export const TemplateContactEmail = ({_uid}) => {
    let config = {
        'callState': false,
        'callbackRequestState': false,
        'emailState': true,
        'doserState': false
    };
    return (
        <TemplateContact _uid={_uid} {...config}/>
    )
};

export const TemplateContactDoseur = ({_uid}) => {
    let config = {
        'callState': false,
        'callbackRequestState': false,
        'emailState': false,
        'doserState': true
    };
    return (
        <TemplateContact _uid={_uid} {...config}/>
    )
};


//Template prinicpal
const TemplateContact = ({_uid, callState, callbackRequestState, emailState, doserState}) => {
    const dispatch = useDispatch();

    const {desktop} = useSelector(state => state.breakpoint);
    const {contactReset} = useSelector(state => state.global);


    // Tracking
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line


    const [defaultValue, setDefaultValue] = useState();

    const [showMode, setShowMode] = useState(doserState ? false : !(!callState && !callbackRequestState && !emailState));
    const [showDoseur, setShowDoseur] = useState(doserState);

    const [callMode, setCallMode] = useState(callState);
    const [callbackMode, setCallbackMode] = useState(callbackRequestState);
    const [mailMode, setMailMode] = useState(emailState);

    /*  useEffect(() => {
          console.log('showMode', showMode);
          console.log('showDoseur', showDoseur);
          console.log('callMode', callMode);
          console.log('callbackMode', callbackMode);
          console.log('mailMode', mailMode);
      }, [showMode, showDoseur, callMode, callbackMode, mailMode]);*/


    // Get page info
    const [dataRedux, setDataRedux] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        include: 'content_config_form_files',
        fields: '&fields[node--special]=metatag,field_special_configformref,content_config_form_files',
        method: 'get'
    });


    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    // Get user's info if user exists
    const [userInfos, setUserInfos] = useState({});
    const [paramsUser, setParamsUser] = useState('');
    const [initClient, isLoadedUser] = useApi(paramsUser);
    let {uuid} = useSelector(state => state.user);
    useEffect(() => {
        if (uuid) {
            setParamsUser({name: `api/users/${uuid}`, method: 'get'})
        }
    }, [uuid]);
    useEffect(() => {
        if (initClient && isLoadedUser) {
            let {status, data} = initClient;
            //  console.log('user', initClient);
            if (status === 200) {
                // console.log(data.data.attributes);
                let {lba_civilite, lba_mobilephone, lba_fname, lba_lname, lba_email, lba_address, lba_address2, lba_city, lba_zip} = data.data.attributes;
                
                setUserInfos({
                    'civility': lba_civilite,
                    'lname': lba_lname,
                    'fname': lba_fname,
                    'email': lba_email,
                    'address': lba_address,
                    'address2': lba_address2,
                    'city': lba_city,
                    'zip': lba_zip,
                    'phone': lba_mobilephone
                });
            }
            else {
                console.log('error - get user\'s info');
            }
        }
    }, [initClient, isLoadedUser]);

    // Manage param in url to prefilter form
    let location = useLocation();
    let searchParams = location.search;

    useEffect(() => {
        if (dataRedux) {
            let {options} = dataRedux.fieldSpecialConfigformref.data.sections.hero.requestType;
            if (options && Object.keys(options).length > 0) {
                let optionProduct = null;
                let optionFonction = null;
                let optionAutre = null;
                let optionDoser = null;
                let optionObject = null;
                for (const [key, valueObject] of Object.entries(options)) {
                    if (valueObject.indexOf('produit') > -1) {
                        optionProduct = {value: key, label: valueObject};
                    }
                    else if (valueObject.indexOf('fonctionnement') > -1) {
                        optionFonction = {value: key, label: valueObject};
                    }
                    else if (valueObject.indexOf('Autre') > -1 || valueObject.indexOf('autre') > -1) {
                        optionAutre = {value: key, label: valueObject};
                    }
                    else if (valueObject.indexOf('doseur') > -1) {
                        optionDoser = {value: key, label: valueObject};
                    }
                }

                if (searchParams !== '') {
                    let searchArray = searchParams.split('&');

                    searchArray.map((term, index) => {
                        let searchTerm = term.split('=');
                        let taxonomy = searchTerm[0];
                        let valeur = searchTerm[1];

                        if (index === 0) taxonomy = searchTerm[0].replace('?', '');

                        if (taxonomy === 'object') {
                            if(valeur!=='') {
                                if(valeur.indexOf('doseur') > -1) {
                                    optionObject = optionDoser;
                                    setShowDoseur(true);
                                }
                                else {
                                    setShowMode(true);

                                    if (valeur.indexOf('produit') > -1) {
                                        optionObject = optionProduct;
                                    }
                                    else if (valeur.indexOf('fonctionnement') > -1) {
                                        optionObject = optionFonction;
                                    }
                                    else if (valeur.indexOf('Autre') > -1 || valeur.indexOf('autre') > -1) {
                                        optionObject = optionAutre;
                                    }
                                }
                            }
                        }

                        if(taxonomy === 'type') {
                            if(valeur === "call") {
                                setCallMode(true);
                            }
                            else if(valeur=== "callback") {
                                setCallbackMode(true);
                            }
                            else if(valeur=== "mail") {
                                setMailMode(true);
                            }
                        }
                        return null;
                    });
                }

                let defValue = (callState || callbackRequestState || emailState) ? optionProduct : (doserState ? optionDoser : (optionObject ? optionObject : null));
                let defType = defValue?.value;

                if(defType){
                    setRequestType(defType);
                }

                setDefaultValue((callState || callbackRequestState || emailState) ? optionProduct : (doserState ? optionDoser : (optionObject ? optionObject : null)));
            }
        }
    }, [dataRedux, searchParams]); // eslint-disable-line

    // useEffect(() => {
    //       if (defaultValue) {
    //           console.log('defaultValue', defaultValue);
    //       }
    // }, [defaultValue]);


    useEffect(() => {
        dispatch(updateGlobalStorePage('t-contact'));
    }, [dispatch]); // isLoaded


    const [requestTypeFAQ, setRequestTypeFAQ] = useState(false);
    const [requestType, setRequestType] = useState();
    const handleChangeObject = (newValue) => {
        setRequestType(parseInt(newValue.value));
        setDefaultValue(newValue);


        setTrackingEvent({
            event: 'lead_step',
            args: {
                lead_type: newValue.label,
                lead_step_number: 1,
                lead_step_name: "Demande"
            },
            refresh: randomString(6)
        });

        if (newValue.dataElement) {
            // console.log(slugify(newValue.label))
            switch (newValue.dataElement) {
                case 'doseur':
                    setShowMode(false);
                    setShowDoseur(true);
                    setCallMode(false);
                    setCallbackMode(false);
                    setMailMode(false);
                    setRequestTypeFAQ(false);
                    break;
                default :
                    setShowMode(true);
                    setShowDoseur(false);
                    setRequestTypeFAQ(false);
                    if (newValue.label.indexOf('fonctionnement') > -1) {
                        setRequestTypeFAQ(true);
                    }

                    break;
            }
        }

        window.history.replaceState('', '', location.pathname+'?object='+slugify(newValue.label))
    };


    useEffect(() => {
        if (contactReset) {
            setShowMode(false);
            setShowDoseur(false);
            setCallMode(false);
            setCallbackMode(false);
            setMailMode(false);
            setDefaultValue('');
            setRequestType('');
            dispatch(updateGlobalContactReset(false));
        }
    }, [contactReset]); // eslint-disable-line

    const handleContactByCall = (e) => {
        setCallMode(true);
        setCallbackMode(false);
        setMailMode(false);
        window.history.replaceState('', '', location.pathname+'?object='+slugify(defaultValue.label)+"&type=call")

        setTrackingEvent({
            event: 'lead_step',
            args: {
                lead_type: "call",
                lead_step_number: 2,
                lead_step_name: "Choix du contact"
            },
            refresh: randomString(6)
        });
    };

    const handleContactByCallBack = () => {
        setCallMode(false);
        setCallbackMode(true);
        setMailMode(false);

        window.history.replaceState('', '', location.pathname+'?object='+slugify(defaultValue.label)+"&type=callback")

        setTrackingEvent({
            event: 'lead_step',
            args: {
                lead_type: "callback",
                lead_step_number: 2,
                lead_step_name: "Choix du contact"
            },
            refresh: randomString(6)
        });
    };

    const handleContactByMail = () => {
        setCallMode(false);
        setCallbackMode(false);
        setMailMode(true);

        window.history.replaceState('', '', location.pathname+'?object='+slugify(defaultValue.label)+"&type=mail")

        // TRACKING - select content + view content
        //const $content_type = 'Nous envoyer un email';

        setTrackingEvent({
            event: 'lead_step',
            args: {
                lead_type: "email",
                lead_step_number: 2,
                lead_step_name: "Choix du contact"
            },
            refresh: randomString(6)
        });
    };


    const renderPage = () => {
        // console.log('dataRedux contact', dataRedux);
        const {metatag, fieldSpecialConfigformref: {data: {sections: {callbackRequest, civility, contactOptions, doser, email, hero, legals, phoneCall}}}, contentConfigFormFiles: images} = dataRedux;

        const imageUrl = images.find(image => parseInt(image.drupalInternalFid) === parseInt(hero.images.imageDesktop.id)).links.commonFullWidthBanDesktop.href;

        const options = [];
        for (const [key, valueObject] of Object.entries(hero.requestType.options)) {
            let dataElementType = "classic";
            if (valueObject.indexOf('doseur') > -1) {
                dataElementType = "doseur";
            }
            options.push({value: key, label: decodeEntities(valueObject), dataElement: dataElementType});
        }


        return (
            <>
                {metatag && <Metatags listMeta={metatag}/>}

                {imageUrl &&
                <VisuelLarge urlVisuel="" urlVisuelStatic={imageUrl} desktop={desktop}/>
                }

                <div className="container">
                    <div className="t-contact_container rounded--">
                        <div className="t-contact_intro">
                            {hero.title && <h1>{hero.title}</h1>}
                            {(hero.text && hero.text.processed) &&
                            <p dangerouslySetInnerHTML={{__html: hero.text.processed}}/>}
                        </div>
                        <div className="t-contact_object">
                            {hero.requestType.label && <h2>{hero.requestType.label}</h2>}
                            <Select
                                isSearchable={false}
                                className="t-contact_select"
                                classNamePrefix="t-contact_select"
                                options={options}
                                placeholder="Choisissez..."
                                onChange={handleChangeObject}
                                value={defaultValue}
                                // menuIsOpen="true"
                            />
                        </div>

                        <div className={`t-contact_mode ${showMode ? 'show--' : ''}`}>
                            <h2>Comment voulez-vous nous contacter ?</h2>
                            <div
                                className={`t-contact_content ${(callMode || callbackMode || mailMode) ? 'column--' : ''}`}>
                                <div
                                    className={`t-contact_list ${(callMode || callbackMode || mailMode) ? 'column--' : ''}`}>
                                    <div className={`t-contact_item ${callMode ? 'active--' : ''}`}>
                                        <button type="button" onClick={handleContactByCall}
                                                className="btn-contact contactByCall"><h3>Nous appeler</h3></button>
                                        {contactOptions.phoneCall &&
                                        <p className="t-contact_itemInfo"
                                           dangerouslySetInnerHTML={{__html: transformText(contactOptions.phoneCall)}}/>}
                                        {!desktop &&
                                        <CallBloc
                                            className={`t-contact_more ${callMode ? 'active--' : ''}`}  {...phoneCall}
                                            images={images}
                                            setTrackingEvent={setTrackingEvent}/>
                                        }
                                    </div>
                                    <div className={`t-contact_item ${callbackMode ? 'active--' : ''}`}>
                                        <button type="button" onClick={handleContactByCallBack}
                                                className="btn-contact contactByCallback"><h3>Se faire<br/>appeler</h3>
                                        </button>
                                        {contactOptions.callbackRequest &&
                                        <p className="t-contact_itemInfo"
                                           dangerouslySetInnerHTML={{__html: transformText(contactOptions.callbackRequest)}}/>}

                                        {!desktop &&
                                        <CallbackBloc className={`t-contact_more ${callbackMode ? 'active--' : ''}`}
                                                      callbackMode={callbackMode} {...phoneCall} images={images}
                                                      text={callbackRequest} setTrackingEvent={setTrackingEvent}
                                                      requestType={requestType}
                                                      isShown={callbackMode && showMode} userInfos={userInfos}
                                                      legals={legals}/>
                                        }
                                    </div>
                                    <div className={`t-contact_item ${mailMode ? 'active--' : ''}`}>
                                        <button type="button" onClick={handleContactByMail}
                                                className="btn-contact contactByMail"><h3>Nous envoyer<br/>un email</h3>
                                        </button>
                                        {contactOptions.email &&
                                        <p className="t-contact_itemInfo"
                                           dangerouslySetInnerHTML={{__html: transformText(contactOptions.email)}}/>}
                                        {!desktop &&
                                        <MailBloc className={`t-contact_more ${mailMode ? 'active--' : ''}`}
                                                  mailMode={mailMode} {...email} {...civility}
                                                  setTrackingEvent={setTrackingEvent} requestType={requestType}
                                                  isShown={mailMode && showMode} userInfos={userInfos}
                                                  faq={requestTypeFAQ} legals={legals}/>
                                        }
                                    </div>
                                </div>
                                {desktop &&
                                <div
                                    className={`t-contact_right ${(callMode || callbackMode || mailMode) ? 'show--' : ''}`}>
                                    <div className={`t-contact_item ${callMode ? 'active--' : ''}`}>
                                        <button type="button" onClick={handleContactByCall}
                                                className="btn-contact contactByCall"><h3>Nous appeler</h3></button>
                                        <CallBloc
                                            className={`t-contact_more ${callMode ? 'active--' : ''}`} {...phoneCall}
                                            images={images} setTrackingEvent={setTrackingEvent}/>
                                    </div>
                                    <div className={`t-contact_item ${callbackMode ? 'active--' : ''}`}>
                                        <button type="button" onClick={handleContactByCallBack}
                                                className="btn-contact contactByCallback"><h3>Se faire<br/>rappeler</h3>
                                        </button>
                                        <CallbackBloc className={`t-contact_more ${callbackMode ? 'active--' : ''}`}
                                                      callbackMode={callbackMode} text={callbackRequest}
                                                      setTrackingEvent={setTrackingEvent} requestType={requestType}
                                                      isShown={callbackMode && showMode} userInfos={userInfos}
                                                      legals={legals}/>
                                    </div>
                                    <div className={`t-contact_item ${mailMode ? 'active--' : ''}`}>
                                        <button type="button" onClick={handleContactByMail}
                                                className="btn-contact contactByMail"><h3>Nous envoyer<br/>un email</h3>
                                        </button>
                                        <MailBloc className={`t-contact_more ${mailMode ? 'active--' : ''}`}
                                                  mailMode={mailMode} {...email} {...civility}
                                                  setTrackingEvent={setTrackingEvent} requestType={requestType}
                                                  isShown={mailMode && showMode} userInfos={userInfos}
                                                  faq={requestTypeFAQ} legals={legals}/>
                                    </div>
                                </div>
                                }
                            </div>

                            <p className="t-contact_notice">
                                <sup>*</sup> Moyennes observées dans les 12 derniers mois<br/>
                                             pendant les jours et heures d'ouverture du service.</p>
                        </div>
                        <div className={`t-contact_doseur ${showDoseur ? 'show--' : ''}`}>
                            <DoseurBloc {...doser} {...civility} setTrackingEvent={setTrackingEvent}
                                        requestType={requestType} isShown={showDoseur} userInfos={userInfos}
                                        legals={legals}/>
                        </div>
                    </div>
                </div>

            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader/>
};

//Bloc Affichage Numéro Tel
const CallBloc = ({className, hours, number, image, images, setTrackingEvent}) => {
    const {desktop} = useSelector(state => state.breakpoint);

    let imageUrl = '';
    if (desktop) {
        imageUrl = images.find(img => parseInt(img.drupalInternalFid) === parseInt(image.id)).links.brandAccordionDesktop.href;
    } else {
        imageUrl = images.find(img => parseInt(img.drupalInternalFid) === parseInt(image.id)).links.brandAccordionMobile.href;
    }

    return (
        <>
            <div className={className}>
                <div className="t-contact_callbloc">
                    {hours && <p dangerouslySetInnerHTML={{__html: transformText(hours)}}/>}
                    {number && <a href={`tel:${number}`} onClick={() => {
                        setTrackingEvent({
                            event: 'generate_lead',
                            args: {
                                lead_type: "call"
                            },
                            refresh: randomString(6)
                        });
                    }
                    }><img src={imageUrl} alt={number}/></a>}
                </div>
            </div>
        </>
    )
};

//Bloc Affichage Form Être appelé
const CallbackBloc = ({className, callbackMode, text, setTrackingEvent, isShown, requestType, userInfos, legals}) => {
    // Register form
    const {register, handleSubmit, errors} = useForm();

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);


    let [successMessage, setSuccessMessage] = useState();
    let [loaderCta, setLoaderCta] = useState(false);
    let [errorsStep, setErrorsStep] = useState({
        'step': false,
        'token': false
    });

    const clearForm = () => {
        // Clear form
        document.getElementById("formCallback").reset();
        setSuccessMessage();
        setErrorsStep({
            'step': false,
            'token': false
        });
    };

    useEffect(() => {
        if (!isShown) {
            clearForm()
        }
    }, [isShown]);

    useEffect(() => {
        return () => {
            clearForm();
        }
    }, []);

    const tooltipHelpRef = useRef();
    const [showTooltipInfo, setShowTooltipInfo] = useState({
        show: false,
        content: ''
    });

    const handleMouseTooltip = (e) => {
        if (showTooltipInfo.show) {
            setShowTooltipInfo({
                show: false,
                content: ''
            });
        }
        else {
            setShowTooltipInfo({
                show: true,
                content: e.target.dataset.content
            });
        }
    };

    useEffect(() => {
        if (callbackMode === false) {
            setShowTooltipInfo({
                show: false,
                content: ''
            });
        }
    }, [callbackMode]);

    const onSubmit = (data) => {
        //console.log('onSubmit callbackRequest', data);

        // TRACKING - lead type (submit form)

        setTrackingEvent({
            event: 'generate_lead',
            args: {
                lead_type: "callback_request"
            },
            refresh: randomString(6)
        });

        setSuccessMessage();
        setLoaderCta(true);

        // Récupération du Jeton
        setInitJeton({
            callback: sendMail,
            params: data,
            success: function () {
                setErrorsStep({...errorsStep, 'token': false});
            },
            errors: function () {
                setLoaderCta(false);
                setErrorsStep({...errorsStep, 'token': true});
            }
        });
    };


    // Send Mail
    let [paramsSendMail, setParamsSendMail] = useState({});
    const [responseSendMail, isLoadedSendMail] = useApi(paramsSendMail);

    const sendMail = (sessionToken, formDatas) => {
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": sessionToken
        };

        setParamsSendMail({
            name: `api/contact/phone-call?` + randomString(6),
            format: 'json',
            method: 'post',
            data: JSON.stringify({"request_type": requestType, "phone_number": formDatas.callbackTel}),
            config: headers,
            stringifyRequest: false // Body raw
        });
    };

    useEffect(() => {
        if (responseSendMail && isLoadedSendMail) {
            let {status} = responseSendMail;
            setLoaderCta(false);
            if (status === 200 || status === 201) {
                setErrorsStep({...errorsStep, 'step': false});
                setSuccessMessage('Votre demande a bien été envoyée.');
                document.getElementById("formCallback").reset();
            } else {
                setErrorsStep({...errorsStep, 'step': true});
                console.log('error - send mail');
            }
        }
    }, [responseSendMail, isLoadedSendMail]); // eslint-disable-line

    return (
        <>
            <div className={className}>
                <div className="t-contact_callbackbloc">
                    <form className="t-contact_form c-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                          id="formCallback">
                        {text && <label
                            htmlFor="callbackTel" dangerouslySetInnerHTML={{__html: transformText(text)}}/>}

                        <div className="t-contact_line">
                            <div className="t-contact_lineElt">
                                <input type="tel" placeholder="Ex. 0645628897" name="callbackTel"
                                       id="callbackTel"
                                       ref={register({
                                           required: true,
                                           minLength: 10,
                                           maxLength: 10
                                       })}
                                       data-error={errors.callbackTel}
                                       autoComplete="off"
                                       defaultValue={(userInfos && userInfos.phone) ? userInfos.phone : ''}
                                />
                                <div className="t-contact_tooltip" ref={tooltipHelpRef}>
                                    <i className="icon-what"
                                       onMouseOver={handleMouseTooltip}
                                       onMouseOut={handleMouseTooltip}
                                       data-content="Pour vous faire rappeler gratuitement, renseignez un numéro français à 10 chiffres (exemple : 01 23 45 67 89). Si vous êtes à l'étranger, vous pouvez nous joindre au +33 9 69 32 05 70."
                                       data-type="help"
                                       data-typeref="tooltipHelpRef"
                                    />
                                </div>
                            </div>
                            {(errors.callbackTel && (errors.callbackTel.type === 'minLength' || errors.callbackTel.type === 'maxLength')) &&
                            <div
                                className="error--">Votre numéro doit être valide et comporter 10 chiffres.</div>}
                            {(errors.callbackTel && errors.callbackTel.type === 'required') &&
                            <div
                                className="error--">Le champs doit être renseigné.</div>}
                        </div>

                        <button type="submit"
                                className="c-button btn-2">{(loaderCta && !errorsStep.step && !errorsStep.token) ?
                            <Loader/> : "Valider"}</button>
                        {(errorsStep.token || errorsStep.step) &&
                        <div className="error-- error--step">Une erreur est survenue</div>}
                        {successMessage &&
                        <div className="success--">{successMessage}</div>}
                    </form>
                    {(legals && legals.processed) &&
                    <div className="t-contact_legal" dangerouslySetInnerHTML={{__html: legals.processed}}/>}
                </div>
            </div>
            {showTooltipInfo.show &&
            <Tooltip type="help" target={tooltipHelpRef.current}>{showTooltipInfo.content}</Tooltip>}
        </>
    )
};

//Bloc Formulaire de Contact
const MailBloc = ({className, subtype, gdpr, cta, message, media, lname, fname, email, label, options: civilityOptions, setTrackingEvent, requestType, isShown, userInfos, faq, legals}) => {

    const {previousPath} = useSelector(state => state.global);
    const location = useLocation();


    const {register, handleSubmit, setValue, errors, control} = useForm({
        defaultValues: {
            'g-recaptcha-response': null
        }
    });

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);


    // Recaptcha
    //let lang = "fr";
    let captchaRef = useRef(null);
    // const recaptchaKey = '6Lckv_QZAAAAANfqd0W6RUfOVvnGI-5ez67UyFHx';//6Lckv_QZAAAAANfqd0W6RUfOVvnGI-5ez67UyFHx
    let recaptchaKey = useSelector(state => state.global).global.recaptcha.public_key;

    const [defaultValue, setDefaultValue] = useState('');

    let [successMessage, setSuccessMessage] = useState();
    let [loaderCta, setLoaderCta] = useState(false);
    let [errorsStep, setErrorsStep] = useState({
        'step': false,
        'token': false
    });

    const clearForm = () => {
        // Clear form
        document.getElementById("formMail").reset();
        setSuccessMessage();
        setErrorsStep({
            'step': false,
            'token': false
        });
        setDefaultValue('');
        document.getElementById('btnFile').value = null;
        setFile([]);
        setSizeAll(0)
        captchaRef.current.reset();
    };

    useEffect(() => {
        if (!isShown) {
            clearForm()
        }
    }, [isShown]);

    useEffect(() => {
        return () => {
            clearForm();
        }
    }, []);


    const options = [];
    for (const [key, valueObject] of Object.entries(subtype.options)) {
        options.push({value: key, label: valueObject});
    }

    const handleChangeSelect = (option) => {
        setDefaultValue(option);
    };

    // Bloc input file
    const [fileAll, setFile] = useState([]);
    const [sizeAll, setSizeAll] = useState(0);
    const [fileSizeError, setFileSizeError] = useState(false);
    const handleClickFile = () => {
        document.getElementById('btnFile').click();
    };
    const handleChange = (event) => {
        let totalSize = sizeAll;
        let files = fileAll;

        event.target.files.forEach(file => {
            if(files.length < 4){
                totalSize += file.size;
                files.push(file);
            }
        });
        
        if ((totalSize / (1000 * 1000)).toFixed(2) < 10) {
            setFile(files);
            setSizeAll(totalSize);
            setFileSizeError(false);
        } else {
            document.getElementById('btnFile').value = null;
            setFile([]);
            setSizeAll(0)
            setFileSizeError(true);
        }
    };
    const handleDelete = (index) => {
        let files = fileAll.filter((f, i) => index !== i);
        setFile(files);

        let totalSize = 0;
        files.forEach(file => {
            totalSize += file.size;
        });
        setSizeAll(totalSize);

        if(files.length === 0){
            document.getElementById('btnFile').value = '';
        }
    };

    const onSubmit = (data) => {
        //  console.log('onSubmit email', data);

        // TRACKING - lead type (submit form)

        setTrackingEvent({
            event: 'generate_lead',
            args: {
                lead_type: "email"
            },
            refresh: randomString(6)
        });

        setSuccessMessage();
        setLoaderCta(true);

        // Récupération du Jeton
        setInitJeton({
            callback: sendMail,
            params: data,
            success: function () {
                setErrorsStep({...errorsStep, 'token': false});
            },
            errors: function () {
                setLoaderCta(false);
                setErrorsStep({...errorsStep, 'token': true});
            }
        });
    };

    // Send Mail
    let [paramsSendMail, setParamsSendMail] = useState({});
    const [responseSendMail, isLoadedSendMail] = useApi(paramsSendMail);

    const sendMail = (sessionToken, formDatas) => {
        const headers = {
            "Accept": "application/json",
            "X-CSRF-Token": sessionToken
        };


        let referer = process.env.REACT_APP_HOST + location.pathname;
        if (previousPath !== "undefined") {
            referer = process.env.REACT_APP_HOST + previousPath
        }

        const formData = new FormData();

        for(let i = 0 ; i < fileAll.length && i < 4 ; i++){
            let filename = fileAll[i].name.replace(/[^a-zA-Z0-9.]/g, '');
            formData.append("file" + (i+1), fileAll[i], filename);
        }

        formData.append("request_type", requestType);
        if (!faq) {
            formData.append("subtype", formDatas.contactUnivers);
        } else {
            formData.append("subtype", 1);
        }
        formData.append("description", formDatas.contactMessage);
        formData.append("civility", parseInt(formDatas.contactCivility));
        formData.append("fname", formDatas.contactFirstname);
        formData.append("lname", formDatas.contactLastname);
        /*  formData.append("doser_type", '');
          formData.append("address", '');
          formData.append("zip", '');
          formData.append("country", '');*/
        formData.append("email", formDatas.contactEmail);
        formData.append("gdpr", parseInt(formDatas.contactOptin));
        formData.append("referer_url", referer);

        console.log("--------------------------")
        for(let key of formData.entries()) console.log(key)
        console.log("--------------------------")

        setParamsSendMail({
            name: `api/contact/demand?` + randomString(6),
            format: 'json',
            recaptcha_token: formDatas['g-recaptcha-response'],
            method: 'post',
            data: formData,
            config: headers,
            stringifyRequest: false // Body raw
        });
    };

    useEffect(() => {
        if (responseSendMail && isLoadedSendMail) {
            let {status} = responseSendMail;
            setLoaderCta(false);
            if (status === 200 || status === 201) {
                setErrorsStep({...errorsStep, 'step': false});
                setSuccessMessage('Votre message a bien été envoyé.');
                document.getElementById("formMail").reset();
                setDefaultValue('');
                document.getElementById('btnFile').value = null;
                setFile([]);
                setSizeAll(0)
                captchaRef.current.reset();
            } else {
                setLoaderCta(false);
                setErrorsStep({...errorsStep, 'step': true});
                console.log('error - send mail');
            }
        }
    }, [responseSendMail, isLoadedSendMail]); // eslint-disable-line

    return (
        <>
            <div className={className}>
                <div className="t-contact_emailbloc">
                    <p>Écrivez-nous via le formulaire ci-dessous, nous nous engageons à vous répondre dans les meilleurs délais.</p>
                    <form className="t-contact_form c-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                          encType="multipart/form-data" id="formMail">
                        {!faq &&
                        <div className="t-contact_line">
                            {subtype.label && <label htmlFor="contactUnivers">{subtype.label} <sup>*</sup></label>}
                            <Controller
                                isSearchable={false}
                                name="contactUnivers"
                                id="contactUnivers"
                                control={control}
                                defaultValue={defaultValue.value}
                                rules={{required: true}}
                                // menuIsOpen="true"
                                render={({onChange}) => (
                                    <Select
                                        isSearchable={false}
                                        className={`c-form_select ${errors.contactUnivers ? "errorBorder--" : ""}`}
                                        classNamePrefix="c-form_select"
                                        options={options}
                                        placeholder="Choisissez..."
                                        value={defaultValue}
                                        onChange={val => {
                                            onChange(val.value);
                                            handleChangeSelect(val);
                                        }}
                                    />
                                )}
                            />
                            {(errors.contactUnivers && errors.contactUnivers.type === 'required') &&
                            <div className="error--">Le champs doit être renseigné.</div>}
                        </div>
                        }

                        <div className="t-contact_line">
                            {message && <label htmlFor="contactMessage">{message} <sup>*</sup></label>}
                            <textarea placeholder="Votre texte..." name="contactMessage"
                                      id="contactMessage"
                                      ref={register({
                                          required: true
                                      })}
                                      data-error={errors.contactMessage}
                            />
                            {(errors.contactMessage && errors.contactMessage.type === 'required') &&
                            <div className="error--">Le champs doit être renseigné.</div>}
                        </div>
                        <div className="t-contact_line t-contact_file">
                            <button type="button" className="c-button c-button_file" onClick={handleClickFile}><i
                                className="icon-download"/><span>{media &&
                            <strong dangerouslySetInnerHTML={{__html: transformText(media)}}/>}<br/>au format png, jpg, avi, mp4, mov<br/>Taille maximale : 10 Mo
                            </span>
                            </button>
                            <input name="lba_profilepicture" type="file" id="btnFile"
                                    multiple
                                   accept="image/png, image/jpeg, video/*"
                                   onChange={handleChange}/>
                            {
                                fileAll && fileAll.map((f, index) => {
                                    return(
                                        <div key={"image"+ index} className={`fileContent show--`}>{f.name}<button className="icon-close" onClick={() => handleDelete(index)}/></div>
                                    )
                                })
                            }
                            {/* <div className={`fileContent ${filecontent ? 'show--' : ''}`}>{filecontent} {filecontent &&
                            <button className="icon-close" onClick={handleDelete}/>}</div> */}
                            {fileSizeError &&
                            <div className="error--">Votre fichier doit avoir une taille maximale de 10 Mo.</div>}
                        </div>

                        <div className="t-contact_line">
                            {label && <label>{label} <sup>*</sup></label>}
                            <div className="c-form_radio">
                                <div className="c-form_radioItem">
                                    <input type="radio" name="contactCivility"
                                           value="2"
                                           id="contactCivilityWoman"
                                           ref={register({
                                               required: true
                                           })}
                                           defaultChecked={userInfos && userInfos.civility && userInfos.civility === 2}
                                    />
                                    <label htmlFor="contactCivilityWoman"
                                           className="labelRadio">{civilityOptions[2]}</label>
                                </div>
                                <div className="c-form_radioItem"><input type="radio" name="contactCivility"
                                                                         id="contactCivilityMen"
                                                                         value="1"
                                                                         ref={register({
                                                                             required: true
                                                                         })}
                                                                         defaultChecked={userInfos && userInfos.civility && userInfos.civility === 1}
                                /><label htmlFor="contactCivilityMen"
                                         className="labelRadio">{civilityOptions[1]}</label>
                                </div>
                            </div>
                            {(errors.contactCivility && errors.contactCivility.type === 'required') &&
                            <div className="error--">Le champs doit être renseigné.</div>}
                        </div>

                        <div className="t-contact_line">
                            {lname && <label htmlFor="contactLastname">{lname} <sup>*</sup></label>}
                            <input type="text" placeholder="Ex. Dupont" name="contactLastname"
                                   id="contactLastname"
                                   ref={register({
                                       required: 'Le champs doit être renseigné.',
                                       minLength: {
                                           value: 1,
                                           message: 'Votre nom doit contenir au minimum 1 caractère'
                                       },
                                       maxLength: {
                                           value: 50,
                                           message: 'Votre nom doit contenir au maximum 50 caractères'
                                       },
                                       pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                                   })}
                                   data-error={errors.contactLastname}
                                   defaultValue={(userInfos && userInfos.lname) ? userInfos.lname : ''}
                            />
                            {(errors.contactLastname && errors.contactLastname.type === 'pattern') &&
                            <div className="error--">Votre nom doit être valide.</div>}
                            {(errors.contactLastname && errors.contactLastname.type === 'required') &&
                            <div className="error--">Le champs doit être renseigné.</div>}
                            {(errors.contactLastname && errors.contactLastname.type === 'minLength') &&
                            <div className="error--">Votre nom doit contenir au moins 1 caractère.</div>}
                            {(errors.contactLastname && errors.contactLastname.type === 'maxLength') &&
                            <div className="error--">Votre nom doit contenir au maximum 50 caractères.</div>}
                        </div>

                        <div className="t-contact_line">
                            {fname && <label>{fname} <sup>*</sup></label>}
                            <input type="text" placeholder="Ex. Marie" name="contactFirstname"
                                   id="contactFirstname"
                                   ref={register({
                                       required: 'Le champs doit être renseigné.',
                                       minLength: {
                                           value: 1,
                                           message: 'Votre prénom doit contenir au moins 1 caractère'
                                       },
                                       maxLength: {
                                           value: 50,
                                           message: 'Votre prénom doit contenir au maximum 50 caractères'
                                       },
                                       pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                                   })}
                                   data-error={errors.contactFirstname}
                                   defaultValue={(userInfos && userInfos.fname) ? userInfos.fname : ''}
                            />
                            {/*{(errors.contactFirstname && errors.contactFirstname.type === 'pattern') &&*/}
                            {/*<div className="error--">Votre prénom doit être valide.</div>}*/}
                            {/*{(errors.contactFirstname && errors.contactFirstname.type === 'required') &&*/}
                            {/*<div className="error--">Le champs doit être renseigné.</div>}*/}
                            {(errors.contactFirstname && errors.contactFirstname.type === 'pattern') &&
                            <div className="error--">Votre nom doit être valide.</div>}
                            {(errors.contactFirstname && errors.contactFirstname.type === 'required') &&
                            <div className="error--">Le champs doit être renseigné.</div>}
                            {(errors.contactFirstname && errors.contactFirstname.type === 'minLength') &&
                            <div className="error--">Votre nom doit contenir au moins 1 caractère.</div>}
                            {(errors.contactFirstname && errors.contactFirstname.type === 'maxLength') &&
                            <div className="error--">Votre nom doit contenir au maximum 50 caractères.</div>}
                        </div>


                        <div className="t-contact_line">
                            {email && <label>{email} <sup>*</sup></label>}
                            <input type="text" placeholder="Ex. marie.dupont@gmail.com" name="contactEmail"
                                   id="contactEmail"
                                   ref={register({
                                       required: true,
                                       pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
                                   })}
                                   data-error={errors.contactEmail}
                                   defaultValue={(userInfos && userInfos.email) ? userInfos.email : ''}
                            />
                            {(errors.contactEmail && errors.contactEmail.type === 'pattern') &&
                            <div className="error--">Votre email doit être valide.</div>}
                            {(errors.contactEmail && errors.contactEmail.type === 'required') &&
                            <div className="error--">Le champs doit être renseigné.</div>}
                        </div>

                        <div className="t-contact_line">
                            <div className="c-form_checkbox">
                                <input type="checkbox" name="contactOptin"
                                       id="contactOptin"
                                       ref={register({
                                           required: true
                                       })}
                                       value="1"
                                       disabled={(userInfos && userInfos.lname)}
                                       defaultChecked={(userInfos && userInfos.lname) ? 1 : 0}
                                />
                                {(gdpr && gdpr.processed) &&
                                <label
                                    htmlFor="contactOptin"
                                    dangerouslySetInnerHTML={{__html: gdpr.processed + '<sup>*</sup>'}}/>}
                            </div>
                            {(errors.contactOptin && errors.contactOptin.type === 'required') &&
                            <div
                                className="error--">Pour continuer, vous devez accepter les conditions générales d’utilisation du site labelleadresse.com.</div>}
                        </div>

                        <div className="t-contact_line" data-error={errors['g-recaptcha-response'] ? 'true' : 'false'}>
                            <Controller
                                control={control}
                                name="g-recaptcha-response"
                                rules={{required: true}}
                                render={() => (
                                    <ReCaptcha
                                        ref={captchaRef}
                                        sitekey={recaptchaKey}
                                        className="g-recaptcha"
                                        name="g-recaptcha-response"
                                        data-size="compact"
                                        onChange={val => {
                                            setValue("g-recaptcha-response", val);
                                        }}
                                    />
                                )}
                            />
                            {errors['g-recaptcha-response']?.type === "required" &&
                            <div className="error--">Le recaptcha doit être renseigné.</div>}
                        </div>

                        {cta && <button type="submit"
                                        className="c-button btn-2">{(loaderCta && !errorsStep.step && !errorsStep.token) ?
                            <Loader/> : cta}</button>}
                        {(errorsStep.token || errorsStep.step) &&
                        <div className="error-- error--step">Une erreur est survenue</div>}
                        {successMessage &&
                        <div className="success--">{successMessage}</div>}
                    </form>
                    {(legals && legals.processed) &&
                    <div className="t-contact_legal" dangerouslySetInnerHTML={{__html: legals.processed}}/>}
                </div>
            </div>
        </>
    )
};

//Bloc formulaire Doseur
const DoseurBloc = ({label, options: civilityOptions, gdpr, cta, lname, fname, email, address, address2, zip, city, doserType, setTrackingEvent, requestType, isShown, userInfos, legals}) => {

    const {previousPath} = useSelector(state => state.global);
    const location = useLocation();

    const [showForm, setShowForm] = useState(true);

    const {register, handleSubmit, setValue, errors, control} = useForm({
        defaultValues: {
            'g-recaptcha-response': null
        }
    });

    // Jeton
    const [initJeton, setInitJeton] = useState({});
    useApiJeton(initJeton);


    // Recaptcha
    //let lang = "fr";
    let captchaRef = useRef(null);
    // const recaptchaKey = '6Lckv_QZAAAAANfqd0W6RUfOVvnGI-5ez67UyFHx';//6Lckv_QZAAAAANfqd0W6RUfOVvnGI-5ez67UyFHx
    let recaptchaKey = useSelector(state => state.global).global.recaptcha.public_key;

    const [defaultValue, setDefaultValue] = useState();

    let [successMessage, setSuccessMessage] = useState();
    let [loaderCta, setLoaderCta] = useState(false);
    let [errorsStep, setErrorsStep] = useState({
        'step': false,
        'token': false
    });

    useEffect(() => {
        if (!isShown) {
            // Clear form
            document.getElementById("formDoser").reset();
            setSuccessMessage();
            setShowForm(true);
            setDoserTypeValue();
            setErrorsStep({
                'step': false,
                'token': false
            });
            setDefaultValue('');
            captchaRef.current.reset();
        }
    }, [isShown]); // eslint-disable-line


    const [doserTypeValue, setDoserTypeValue] = useState();
    const options = [];
    for (const [key, valueObject] of Object.entries(doserType.options)) {
        options.push({value: key, label: valueObject});
    }

    useEffect(() => {
        setDoserTypeValue(options[0].value ? options[0].value : 1)
    }, [options])


    const handleChangeSelect = (option) => {
        setShowForm(true);
        setDefaultValue(option);
        setDoserTypeValue(option.value);


        setTrackingEvent({
            event: 'lead_step',
            args: {
                lead_type: option.label,
                lead_step_number: 2,
                lead_step_name: "Choix du doseur"
            },
            refresh: randomString(6)
        });
    };

    // Register form

    const onSubmit = (data) => {
        // console.log('onSubmit doseur', data);

        // TRACKING - lead type (submit form)

        setTrackingEvent({
            event: 'generate_lead',
            args: {
                lead_type: "doseur"
            },
            refresh: randomString(6)
        });

        setSuccessMessage();
        setLoaderCta(true);
        // Récupération du Jeton
        setInitJeton({
            callback: sendMail,
            params: data,
            success: function () {
                setErrorsStep({...errorsStep, 'token': false});
            },
            errors: function () {
                setLoaderCta(false);
                setErrorsStep({...errorsStep, 'token': true});
            }
        });
    };

    // Send Mail
    let [paramsSendMail, setParamsSendMail] = useState({});
    const [responseSendMail, isLoadedSendMail] = useApi(paramsSendMail);

    const sendMail = (sessionToken, formDatas) => {
        const headers = {
            "Accept": "application/json",
            "X-CSRF-Token": sessionToken
        };


        let referer = process.env.REACT_APP_HOST + location.pathname;
        if (previousPath !== "undefined") {
            referer = process.env.REACT_APP_HOST + previousPath
        }

        const formData = new FormData();
        formData.append("request_type", requestType ? requestType : 3);
        formData.append("civility", parseInt(formDatas.contactDoseurCivility));
        formData.append("fname", formDatas.contactDoseurFirstname);
        formData.append("lname", formDatas.contactDoseurLastname);
        formData.append("doser_type", doserTypeValue);
        formData.append("address", formDatas.contactDoseurAdresse);
        formData.append("address2", formDatas.contactDoseurAdresseComplement);
        formData.append("zip", formDatas.contactDoseurCp);
        formData.append("city", formDatas.contactDoseurVille);
        // formData.append("country", '');
        formData.append("email", formDatas.contactDoseurEmail);
        formData.append("gdpr", parseInt(formDatas.contactDoseurOptin));
        formData.append("referer_url", referer);


        setParamsSendMail({
            name: `api/contact/demand?` + randomString(6),
            format: 'json',
            recaptcha_token: formDatas['g-recaptcha-response'],
            method: 'post',
            data: formData,
            config: headers,
            stringifyRequest: false // Body raw
        });
    };

    useEffect(() => {
        if (responseSendMail && isLoadedSendMail) {
            let {status} = responseSendMail;
            setLoaderCta(false);
            if (status === 200) {
                setErrorsStep({...errorsStep, 'step': false});
                setSuccessMessage('Votre message a bien été envoyé.');
                document.getElementById("formDoser").reset();
                captchaRef.current.reset();
            } else {
                setErrorsStep({...errorsStep, 'step': true});
                console.log('error - send mail');
            }
        }
    }, [responseSendMail, isLoadedSendMail]); // eslint-disable-line

    return (

        <form className="c-form t-contact_form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"
              id="formDoser">
            <div className="t-contact_line" style={{textAlign: 'center'}}>
                {(doserType && doserType.label) && <h2>{doserType.label}</h2>}
                {/* <input
                    type="text"
                    style={{textAlign: 'center'}}
                    className="t-contact_select"
                    readOnly
                    // classNamePrefix="t-contact_select"
                    defaultValue={options[0].label}
                /> */}
                
                <Select
                    isSearchable={false}
                    className="t-contact_select"
                    classNamePrefix="t-contact_select"
                    options={options}
                    placeholder="Choisissez..."
                    onChange={(e) => handleChangeSelect(e)}
                    value={defaultValue}
                />
            </div>


            <div className={`t-contact_doseurform ${showForm ? 'show--' : ' '}`}>
                <div className="t-contact_line">
                    {label && <label>{label} <sup>*</sup></label>}
                    <div className="c-form_radio">
                        <div className="c-form_radioItem">
                            <input type="radio" name="contactDoseurCivility"
                                   value="2"
                                   id="contactDoseurCivilityWoman"
                                   ref={register({
                                       required: true
                                   })}
                                   defaultChecked={userInfos && userInfos.civility && userInfos.civility === 2}
                            />
                            {civilityOptions[2] && <label htmlFor="contactDoseurCivilityWoman"
                                                          className="labelRadio">{civilityOptions[2]}</label>}
                        </div>
                        <div className="c-form_radioItem"><input type="radio" name="contactDoseurCivility"
                                                                 id="contactDoseurCivilityMen"
                                                                 value="1"
                                                                 ref={register({
                                                                     required: true
                                                                 })}
                                                                 defaultChecked={userInfos && userInfos.civility && userInfos.civility === 1}
                        />
                            {civilityOptions[1] && <label htmlFor="contactDoseurCivilityMen"
                                                          className="labelRadio">{civilityOptions[1]}</label>}
                        </div>
                    </div>
                    {(errors.contactDoseurCivility && errors.contactDoseurCivility.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                </div>

                <div className="t-contact_line">
                    {lname && <label htmlFor="contactDoseurLastname">{lname} <sup>*</sup></label>}
                    <input type="text" placeholder="Ex. Dupont" name="contactDoseurLastname"
                           id="contactDoseurLastname"
                           ref={register({
                               required: true,
                               pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                           })}
                           data-error={errors.contactDoseurLastname}
                           defaultValue={(userInfos && userInfos.lname) ? userInfos.lname : ''}
                    />
                    {(errors.contactDoseurLastname && errors.contactDoseurLastname.type === 'pattern') &&
                    <div className="error--">Votre nom doit être valide.</div>}
                    {(errors.contactDoseurLastname && errors.contactDoseurLastname.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                    {(errors.contactDoseurLastname && errors.contactDoseurLastname.type === 'minLength') &&
                    <div className="error--">Votre nom doit contenir au moins 1 caractère.</div>}
                    {(errors.contactDoseurLastname && errors.contactDoseurLastname.type === 'maxLength') &&
                    <div className="error--">Votre nom doit contenir au maximum 50 caractères.</div>}
                </div>

                <div className="t-contact_line">
                    {fname && <label>{fname} <sup>*</sup></label>}
                    <input type="text" placeholder="Ex. Marie" name="contactDoseurFirstname"
                           id="contactDoseurFirstname"
                           ref={register({
                               required: 'Le champs doit être renseigné.',
                               minLength: {
                                   value: 1,
                                   message: 'Votre prénom doit contenir au moins 1 caractère'
                               },
                               maxLength: {
                                   value: 50,
                                   message: 'Votre prénom doit contenir au maximum 50 caractères'
                               },
                               pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                           })}
                           data-error={errors.contactDoseurFirstname}
                           defaultValue={(userInfos && userInfos.fname) ? userInfos.fname : ''}
                    />
                    {/*{(errors.contactDoseurFirstname && errors.contactDoseurFirstname.type === 'pattern') &&*/}
                    {/*<div className="error--">Votre prénom doit être valide.</div>}*/}
                    {/*{(errors.contactDoseurFirstname && errors.contactDoseurFirstname.type === 'required') &&*/}
                    {/*<div className="error--">Le champs doit être renseigné.</div>}*/}

                    {(errors.contactDoseurFirstname && errors.contactDoseurFirstname.type === 'pattern') &&
                    <div className="error--">Votre prénom doit être valide.</div>}
                    {(errors.contactDoseurFirstname && errors.contactDoseurFirstname.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                    {(errors.contactDoseurFirstname && errors.contactDoseurFirstname.type === 'minLength') &&
                    <div className="error--">Votre prénom doit contenir au moins 1 caractère.</div>}
                    {(errors.contactDoseurFirstname && errors.contactDoseurFirstname.type === 'maxLength') &&
                    <div className="error--">Votre prénom doit contenir au maximum 50 caractères.</div>}
                </div>

                <div className="t-contact_line">
                    {email && <label>{email} <sup>*</sup></label>}
                    <input type="text" placeholder="Ex. dupont@gmail.com" name="contactDoseurEmail"
                           id="contactDoseurEmail"
                           ref={register({
                               required: true,
                               pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
                           })}
                           data-error={errors.contactDoseurEmail}
                           defaultValue={(userInfos && userInfos.email) ? userInfos.email : ''}
                    />
                    {(errors.contactDoseurEmail && errors.contactDoseurEmail.type === 'pattern') &&
                    <div className="error--">Votre email doit être valide.</div>}
                    {(errors.contactDoseurEmail && errors.contactDoseurEmail.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                </div>


                <div className="t-contact_line">
                    {address && <label>{address} <sup>*</sup></label>}
                    <input type="text" placeholder="Ex. 22 rue Carnot" name="contactDoseurAdresse"
                           id="contactDoseurAdresse"
                           ref={register({
                               required: true,
                               pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                           })}
                           data-error={errors.contactDoseurAdresse}
                           defaultValue={(userInfos && userInfos.address) ? userInfos.address : ''}
                    />
                    {(errors.contactDoseurAdresse && errors.contactDoseurAdresse.type === 'pattern') &&
                    <div className="error--">Votre adresse doit être valide.</div>}
                    {(errors.contactDoseurAdresse && errors.contactDoseurAdresse.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                </div>

                <div className="t-contact_line">
                    {address2 && <label>{address2}</label>}
                    <input type="text" placeholder="Bâtiments, escaliers, etc." name="contactDoseurAdresseComplement"
                           id="contactDoseurAdresseComplement"
                           ref={register({
                               required: false,
                               pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                           })}
                           data-error={errors.contactDoseurAdresseComplement}
                           defaultValue={(userInfos && userInfos.address2) ? userInfos.address2 : ''}
                    />
                    {(errors.contactDoseurAdresseComplement && errors.contactDoseurAdresseComplement.type === 'pattern') &&
                    <div className="error--">Votre complément d'adresse doit être valide.</div>}
                    {(errors.contactDoseurAdresseComplement && errors.contactDoseurAdresseComplement.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                </div>


                <div className="t-contact_line">
                    {zip && <label>{zip} <sup>*</sup></label>}
                    <input type="text" placeholder="Ex. 75011" name="contactDoseurCp"
                           id="contactDoseurCp"
                           ref={register({
                               required: true,
                               pattern: /^(([0-8][0-9])|(9[0-9]))[0-9]{3}$/
                           })}
                           data-error={errors.contactDoseurCp}
                           defaultValue={(userInfos && userInfos.zip) ? userInfos.zip : ''}
                    />
                    {(errors.contactDoseurCp && errors.contactDoseurCp.type === 'pattern') &&
                    <div className="error--">Le code postal doit être valide.</div>}
                    {(errors.contactDoseurCp && errors.contactDoseurCp.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                </div>


                <div className="t-contact_line">
                    {city && <label>{city} <sup>*</sup></label>}
                    <input type="text" placeholder="Ex. Paris" name="contactDoseurVille"
                           id="contactDoseurVille"
                           ref={register({
                               required: true,
                               pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                           })}
                           data-error={errors.contactDoseurVille}
                           defaultValue={(userInfos && userInfos.city) ? userInfos.city : ''}
                    />
                    {(errors.contactDoseurVille && errors.contactDoseurVille.type === 'pattern') &&
                    <div className="error--">La ville doit être valide.</div>}
                    {(errors.contactDoseurVille && errors.contactDoseurVille.type === 'required') &&
                    <div className="error--">Le champs doit être renseigné.</div>}
                </div>

                <div className="t-contact_line">
                    <div className="c-form_checkbox">
                        <input type="checkbox" name="contactDoseurOptin"
                               id="contactDoseurOptin"
                               ref={register({
                                   required: true
                               })}
                               value="1"
                               disabled={(userInfos && userInfos.lname)}
                               defaultChecked={(userInfos && userInfos.lname) ? 1 : 0}
                        />
                        {(gdpr && gdpr.processed) && <label
                            htmlFor="contactDoseurOptin"
                            dangerouslySetInnerHTML={{__html: gdpr.processed + '<sup>*</sup>'}}/>}
                    </div>
                    {(errors.contactDoseurOptin && errors.contactDoseurOptin.type === 'required') &&
                    <div
                        className="error--">Pour continuer, vous devez accepter les conditions générales d’utilisation du site labelleadresse.com.</div>}
                </div>

                <div className="t-contact_line" data-error={errors['g-recaptcha-response'] ? 'true' : 'false'}>
                    <Controller
                        control={control}
                        name="g-recaptcha-response"
                        rules={{required: true}}
                        render={() => (
                            <ReCaptcha
                                ref={captchaRef}
                                sitekey={recaptchaKey}
                                className="g-recaptcha"
                                name="g-recaptcha-response"
                                data-size="compact"
                                onChange={val => {
                                    setValue("g-recaptcha-response", val);
                                }}
                            />
                        )}
                    />
                    {errors['g-recaptcha-response']?.type === "required" &&
                    <div className="error--">Le recaptcha doit être renseigné.</div>}
                </div>

                {cta && <button type="submit"
                                className="c-button btn-2">{(loaderCta && !errorsStep.step && !errorsStep.token) ?
                    <Loader/> : cta}</button>}
                {(errorsStep.token || errorsStep.step) &&
                <div className="error-- error--step">Une erreur est survenue</div>}
                {successMessage &&
                <div className="success--">{successMessage}</div>}

                {(legals && legals.processed) &&
                <div className="t-contact_legal" dangerouslySetInnerHTML={{__html: legals.processed}}/>}
            </div>


        </form>
    );
};