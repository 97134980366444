import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

//Hooks
import useTracking from '../Hooks/useTracking';

// Utils
import {randomString} from "../Utils/md5-hash";

const SwitchTab = ({tabs, activeTab, setActiveTab, link, anchorId, needScroll = false, random = Math.random()}) => {

    const renderNumber = (number) => {
        if (typeof number === 'number') {
            return <div className="c-switchTab__list-itemNumber">{number}</div>;
        }
    };

    useEffect(() => {
        if(needScroll){
            let element = document.querySelector("#"+ anchorId);
            if(element){

                if(!window.STAYHERE && !window.ANCHORDONE){
                    window.ANCHORDONE = true;
                    window.DONOTSCROLL = true;
    
                    setTimeout(() => {
                        let top = element.getBoundingClientRect().top;
                        document.querySelector("body").scrollTop += top
                        document.querySelector("html").scrollTop += top

                        setTimeout(() => {
                            window.DONOTSCROLL = false;
                            window.ANCHORDONE = false;
                        }, 1000);
                    }, 250);
                }
            }
        }
      }, [random]);

    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    // Set tracking event - page_view
    const ecoTracking = (refresh) => {
        setTrackingEvent({
            event: 'page_view',
            args: {
                content_category: 'Economies',
                content_category2: activeTab === 2 ? "Me faire rembourser" : "Imprimer mes bons",
                content_category3: '',
            },
            refresh: refresh
        });
    };

    const selectTab = (id) => {
        let hash = document.location.hash;
        setActiveTab(id === 1 ? "cashback" : "webcoupon");
        ecoTracking(randomString(6));
        if(id=== 1 ) {
            window.history.replaceState('', '', '/economies/remboursement' + document.location.search + (hash ? hash : ""));
        }
        else {
            window.history.replaceState('', '', '/economies/bon-reduction' + document.location.search + (hash ? hash : ""));
        }
    };

    return (
        <>
            {tabs && tabs.length > 0 &&
            <div className="c-switchTab">
                {anchorId && 
                    <a id={anchorId}></a>
                }
                <div className="container">
                    <ul className="c-switchTab__list">
                        {tabs.map(({id, title, number}, key) => {
                            return (
                                <li className="c-switchTab__list-item" data-active={activeTab === id} key={key}>
                                    {activeTab === id ?
                                        <span>{title}{renderNumber(number)}</span>
                                        :
                                        <>
                                            {link ?
                                                <Link to={link}>{title}{renderNumber(number)}</Link>
                                                :
                                                <button type="button"
                                                        onClick={() => selectTab(id)}>{title}{renderNumber(number)}</button>
                                            }
                                        </>
                                    }
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
            }
        </>
    )
};

export default SwitchTab;