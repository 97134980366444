import {useEffect} from 'react';
import {useSelector} from 'react-redux';

export default ({event, args, refresh}) => {

    // Get user state
    let {iupid, pseudo} = useSelector(state => state.user);
    let userId = undefined;
    if (iupid && pseudo) userId = iupid;


    useEffect(() => {
        // 1. Paramètres opts : nom de l'event + user id
        let opts = {
            event: event,
            user_id: userId
        };

        if (event) {
            // console.log('track use : ' + event);
            // 2. Rassembler tous les paramètres : opts (de l'étape 1) + args (envoyé depuis les components)
            let params = {
                ...opts,
                ...args
            };

            // 3. Envoyer l'ensemble des paramètres au customDataLayer
            window.customDataLayer.push(params);

            // console.log('customDataLayer hook ' + event, window.customDataLayer);
        }
    }, [event, refresh]); // eslint-disable-line

    return [];
}