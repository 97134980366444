import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { updateModalsOpen } from "../../Store/action";

// Components
import Modal from "./modal";
import CommunityPostCard from "../communityPostCard";

const CommunityPostPreviewModal = ({ params }) => {

    let dispatch = useDispatch();
    const modalsOpen = useSelector(state => state.modals).openModal;

    const handleClosePopin = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'postPreview': false }));
    }
    return (
        <Modal modalName="postPreview" modalNameTracking={`Previsualisation ${params.postType}`}>
            <div className="c-modal_postPreview">
                <CommunityPostCard
                    isPreview={true}
                    {...params}
                />
                <button onClick={() => handleClosePopin()} className="btn-2">Retour</button>
            </div>
        </Modal>
    )
};

export default CommunityPostPreviewModal;