import React, { useEffect, useState } from "react";
import useApi from '../Hooks/useApiGlobal';
import Metatags from '../Layout/metas-tags/meta-tags';
import { useSelector, useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';

// Libraries
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Components
import { Loader } from "../Components/loader";
import VisuelLarge from "../Components/visuelLarge";
import {ItemAccordion} from "../Components/accordion";
import WysiwygSimple from "../Components/wysiwygSimple";
import CLink from "../Components/cLinks";
import CarouselWebcoupon from "../Components/carouselWebcoupon";
import CarouselArticles from "../Components/carouselArticles";
import Comments from "../Components/comments"
import BannerBrand from "../Components/bannerBrand";
import Video from "../Components/article/video";

const TemplateProduct = ({ _uid }) => {

    const contactPath = useSelector(state => state.routes).routes.contact;
    const contactDoseurPath = useSelector(state => state.routes).routes.contactDoseur;

    const { desktop } = useSelector(state => state.breakpoint);

    let limitArticles = 15;

    let dispatch = useDispatch();

    const [dataRedux, setDataRedux] = useState();//Infos principales de la page
    const [articlesRedux, setArticlesRedux] = useState('');// Infos Articles
    const [articlesFixeRedux, setArticlesFixeRedux] = useState('');// Infos Articles
    const [articlesFinaux, setArticlesFinaux] = useState();// Infos Articles

    //Requete pour récup les infos de l'univers
    let brandDataInclude = [
        "field_product_img",
        "field_common_pgph_ban.field_respimg_img_mobile",
        "field_common_pgph_ban.field_respimg_img_desktop",
        "field_common_er_related_contents",
        "field_product_pgph_contents",
        "field_product_pgph_contents.field_common_img_textimage",
        "field_common_er_related_contents.field_common_pgph_ban.field_respimg_img_mobile",
        "field_common_er_related_contents.field_common_pgph_ban.field_respimg_img_desktop",
        "field_common_er_related_contents.field_common_tr_universes",
        "field_product_img_greenpict",
        'field_common_tr_brand.field_api_tr_shelves.field_api_tr_universe',
        'field_product_pgph_contents.field_common_img_videothumb',
        'field_product_pgph_contents.field_landing_video',
        'field_product_pgph_contents.field_common_video',
        'field_product_pgph_contents.field_common_pgph_ban.field_respimg_img_desktop',
        'field_product_pgph_contents.field_common_pgph_ban.field_respimg_img_mobile'
    ];
   
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/product',
        _uid: _uid,
        include: brandDataInclude.join(','),
        fields: '&fields[node--produit]=field_product_txt_advices_body,field_product_txt_advices_title,field_product_txt_greenpict_titl,field_product_txt_introbody,field_product_txt_introtitle,field_common_er_related_contents,field_product_pgph_contents,field_product_img.links,field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_product_bool_dosage_helper,fieldCommonTxtTitle,fieldCommonTxtBody&fields[node--article]=field_common_txt_title,field_common_duration,field_common_pgph_ban,field_common_tr_universes,drupal_internal__nid,path,metatag&fields[taxonomy_term--brand]=field_api_remoteid_brandid,name,path,field_api_tr_shelves',
        method: 'get'
    });

    //Requete qui permet de recup les articles du produit
    const [paramsArticles, setParamsArticles] = useState({ name: '', method: '' });
    const [dataFetchArticles, isLoadedArticles] = useApi(paramsArticles);

    useEffect(() => {
        if (isLoaded && dataFetch) {
            let normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeProduct', _uid));
            setArticlesFixeRedux(build(normalizeJson, 'nodeArticle'));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const [brandIDDefault, setBrandIDDefault] = useState();
    useEffect(() => {
        if (dataRedux) {
            const { fieldCommonTrBrand: { id } } = dataRedux;
            setBrandIDDefault(id);
        }
    }, [dataRedux]);// eslint-disable-line

    useEffect(() => {
        if (articlesFixeRedux) {
            limitArticles = limitArticles - articlesFixeRedux.length; // eslint-disable-line
        }
        setParamsArticles({
            name: 'api/node/article',
            include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
            fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes',
            sort: '&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
            limit: limitArticles,
            method: 'get'
        })
    }, [articlesFixeRedux]);

    useEffect(() => {
        if (isLoadedArticles && dataFetchArticles) {
            let normalizeJsonArticles = normalize(dataFetchArticles.data);//Normalisation du json
            setArticlesRedux(build(normalizeJsonArticles, 'nodeArticle'));
        }
    }, [isLoadedArticles, dataFetchArticles]);//chargement requete ok

    useEffect(() => {
        if (articlesRedux) {
            if (articlesFixeRedux) {
                setArticlesFinaux(articlesFixeRedux.concat(articlesRedux));
            }
            else {
                setArticlesFinaux(articlesRedux);
            }
        }
    }, [articlesRedux]); // eslint-disable-line

    useEffect(() => {
        dispatch(updateGlobalStorePage('t-product'));
    }, [dispatch]); // isLoaded

    // Brand page url
    const [productsUrl, setProductsUrl] = useState(null);
    const [productsParamsUrl, setProductsParamsUrl] = useState({});
    const [productsRequestParamsUrl, isLoadedProductsRequestParamsUrl] = useApi(productsParamsUrl);

    useEffect(() => {
        if (brandIDDefault) {
            setProductsParamsUrl({
                name: 'api/node/brand_page',
                fields: 'fields[node--brand_page]=path',
                filter: '&filter[field_common_tr_brand.id]=' + brandIDDefault,
                method: 'get'
            });
        }
    }, [brandIDDefault]); // eslint-disable-line

    useEffect(() => {
        if (isLoadedProductsRequestParamsUrl === true) {
            let { status, data } = productsRequestParamsUrl;
            if (status === 200) {
                if (data && data.data && data.data.length > 0) {
                    setProductsUrl(data.data[0].attributes.path.alias);
                }
            }
        }
    }, [isLoadedProductsRequestParamsUrl, productsRequestParamsUrl]); // eslint-disable-line


    const renderPage = () => {
        const {fieldCommonTrBrand: {fieldApiRemoteidBrandid: brandID, name: brandName, fieldApiTrShelves:[{fieldApiTrUniverse:{name:universName}}]}, title, fieldProductTxtIntrotitle, fieldProductTxtIntrobody, fieldCommonPgphBan, fieldProductImg, fieldProductTxtGreenpictText, fieldProductImgGreenpict, fieldProductLinkIngredList, fieldProductBoolDosageHelper, metatag, fieldCommonTxtTitle, fieldCommonTxtBody, fieldProductPgphContents} = dataRedux;
        let brandIDs;
        if (brandID) {
            let brandsArray = [];
            brandsArray.push(parseInt(brandID));
            brandIDs = brandsArray;
        }

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'A vos marques',
            category2: fieldProductTxtIntrotitle,
            category3: universName,
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                {(fieldCommonPgphBan || (fieldProductImg.links.commonLogoDesktop.href && fieldProductImg.links.commonLogoMobile.href)) &&
                    <VisuelLarge urlVisuel={fieldCommonPgphBan}
                        urlVisuelSup={desktop ? fieldProductImg.links.commonLogoDesktop.href : fieldProductImg.links.commonLogoMobile.href}
                        altVisuelSup={fieldProductTxtIntrotitle && fieldProductTxtIntrotitle}
                        positionVisuelSup="bottom" desktop={desktop} />
                }

                <div className="container">
                    <div className="t-product_container">
                        {
                            (fieldProductTxtIntrobody || fieldProductTxtIntrotitle) &&
                            <WysiwygSimple 
                                title={fieldProductTxtIntrotitle}
                                content={fieldProductTxtIntrobody && fieldProductTxtIntrobody.processed} />
                        }
                        {
                            fieldProductPgphContents && Array.isArray(fieldProductPgphContents) && fieldProductPgphContents.length > 0 &&
                            fieldProductPgphContents.map((item, key) => {
                                if (item.fieldLandingVideo) {
                                    return (
                                        <Video 
                                            key={key} 
                                            infoVideo={item.fieldLandingVideo} 
                                            videoPoster={item.fieldCommonPgphBan.fieldRespimgImgDesktop.links.brandAccordionDesktop.href} 
                                            desktop={desktop} />
                                    )
                                } else if (item.fieldCommonTxtTexttitle) {
                                    return (
                                        <ItemAccordion
                                            key={key}
                                            title={item.fieldCommonTxtTexttitle}
                                            content={item.fieldCommonTxtTextbody.processed}
                                            visuel={item.fieldCommonImgTextimage && (desktop ? item.fieldCommonImgTextimage.links.brandAccordionDesktop.href : item.fieldCommonImgTextimage.links.brandAccordionMobile.href)}
                                            altVisuel={""}
                                            infoVideo={item.fieldCommonVideo}
                                            videoPoster={item.fieldCommonImgVideothumb && (desktop ? item.fieldCommonImgVideothumb.links.brandAccordionDesktop.href : item.fieldCommonImgVideothumb.links.brandAccordionMobile.href)}
                                            desktop={desktop} />
                                    )
                                } else {
                                    return
                                }
                            })
                        }
                    </div>

                    {brandID &&
                        <CarouselWebcoupon
                            desktop={desktop}
                            brandID={brandIDs}
                            title={`Bons de réduction ${brandName}`}
                            name={title}
                            brandName={brandName} />
                    }

                    <div className="t-product_container">

                        {fieldProductTxtGreenpictText &&
                            <p className="t-product_recycle">
                                {(fieldProductImgGreenpict && fieldProductImgGreenpict.links && fieldProductImgGreenpict.links.brandAccordionDesktop && fieldProductImgGreenpict.links.brandAccordionDesktop.href) &&
                                    <img src={fieldProductImgGreenpict.links.brandAccordionDesktop.href} alt="" />}
                                {fieldProductTxtGreenpictText}
                            </p>
                        }

                        {(fieldCommonTxtBody || fieldCommonTxtTitle) &&
                            <WysiwygSimple content={fieldCommonTxtBody && fieldCommonTxtBody.processed}
                                title2={fieldCommonTxtTitle && fieldCommonTxtTitle} />
                        }

                        {(fieldProductLinkIngredList && fieldProductLinkIngredList.title && fieldProductLinkIngredList.uri) &&
                            <ul className="t-product_buttonList">
                                <li><CLink url={fieldProductLinkIngredList.uri} title={fieldProductLinkIngredList.title}
                                    target="blank" external={true}>{fieldProductLinkIngredList.title}</CLink></li>
                            </ul>
                        }

                        <h2>Une question sur le produit ? </h2>
                        <ul className="t-product_buttonList">
                            <li data-link="Page Contact"><CLink url={contactPath + '?object=produit'}
                                title="Contactez-nous">Contactez-nous</CLink></li>
                            {productsUrl &&
                                <li data-link="Page Marque"><CLink url={`${productsUrl}#nos-produits`}
                                    title={`Voir les autres produits${brandName ? ' ' + brandName : ''}`}>Voir les autres produits{brandName ? ' ' + brandName : ''}</CLink>
                                </li>
                            }
                            {fieldProductBoolDosageHelper &&
                                <li data-link="Page Contact"><CLink url={contactDoseurPath}
                                    title="Je commande un doseur lessive">Je commande un doseur lessive</CLink>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                <BannerBrand />

                <div className="t-product_grey">
                    {(articlesFinaux && articlesFinaux.length > 0) &&
                        <div className="container">
                            <div className="t-product_articles">
                                <h2 className="section-title">Cela pourrait vous plaire</h2>
                                <CarouselArticles carousel={articlesFinaux} desktop={desktop}
                                    carouselName="more-c-articles" />
                            </div>
                        </div>
                    }
                    <Comments _uid={_uid} dataType="node--product" contentType="Page Produit" />
                </div>
            </>
        )
    };
    return (dataRedux && isLoaded) ? renderPage() : <Loader />
};

export default TemplateProduct;