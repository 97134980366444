const hasNumber = value => {
    return new RegExp(/[0-9]/).test(value);
};
const hasMixed = value => {
    return new RegExp(/[a-z]/).test(value) &&
        new RegExp(/[A-Z]/).test(value);
};
const hasUppercase = value => {
    return new RegExp(/[A-Z]/).test(value);
};
const hasSpecial = value => {
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const strengthIndicator = value => {
    let strengths = 0;

    if (value.length > 5)
        strengths++;
    if (hasNumber(value) && value.length > 3)
        strengths++;
    if (hasSpecial(value) && value.length > 3)
        strengths++;
    if (hasMixed(value) && value.length > 3)
        strengths++;
    if (hasUppercase(value) && value.length > 3)
        strengths++;


    return strengths;
};

export const strengthValueIndicator = value => {
    let strengthsValue = 'Très Faible';
    switch (value) {
        case 0:
            strengthsValue = 'Très faible';
            break;
        case 1:
            strengthsValue = 'Faible';
            break;
        case 2:
            strengthsValue = 'Moyen';
            break;
        case 3:
            strengthsValue = 'Bon';
            break;
        case 4:
            strengthsValue = 'Élevé';
            break;
        case 5:
            strengthsValue = 'Très Élevé';
            break;
        default:
            return null;
    }
    return strengthsValue;
};