import React, {useState, useEffect} from "react";
import {useLocation} from 'react-router-dom';

// Hooks
import useTracking from '../../Hooks/useTracking';

// Utils
import {randomString} from "../../Utils/md5-hash";

const Outils = ({time, category, printElt = {}, contentType}) => {

    const [dataCategory, setDataCategory] = useState('');
    const location = useLocation();
    const hostURL = process.env.REACT_APP_HOST;

    // TRACKING - add comment
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); //eslint-disable-line

    const onShare = (e, refresh) => {
        let social = e.target.getAttribute('data-social');

        setTrackingEvent({
            event: 'share',
            args: {
                social_network: social,
                content_type: contentType,
            },
            refresh: refresh
        });
    };

    useEffect(() => {
        if (category)
            setDataCategory(category);
    }, [category]);

    return (
        <div className="c-outils">
            <div className="c-outils_content">
                {time && <div
                    className="c-outils_time">{Math.floor(time / 60) + "min"}{(time - Math.floor(time / 60) * 60) > 0 && time - Math.floor(time / 60) * 60}</div>}

                {dataCategory &&
                <>
                    {Array.isArray(dataCategory) ?
                        dataCategory.map((cat) =>
                            <div className="c-outils_category" key={cat.id}>{cat.name}</div>
                        )
                        :
                        <div className="c-outils_category">{dataCategory}</div>
                    }
                </>
                }
            </div>
            <div className="c-outils_content">
                {printElt &&
                <div className="c-outils_print icon-print no-print" onClick={printElt}></div>
                }
                <div className="c-share no-print">
                    <div className="c-share_button icon-share"></div>
                    <ul className="c-share_pop">
                        <li className="c-share_arrow"></li>
                        <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${hostURL + location.pathname}`}
                               target="_blank" className="icon-share-fbk" rel="noopener noreferrer"
                               data-social="Facebook" onClick={(e) => onShare(e, randomString(6))}>Facebook</a></li>
                        <li><a
                            href={`mailto:?subject=La%20Belle%20Adresse%20-%20Une%20astuce%20%C3%A0%20ne%20pas%20manquer&body=Jette%20un%20%C5%93il%20%C3%A0%20ceci...%20${hostURL + location.pathname}`}
                            className="icon-share-email" data-social="Email" onClick={(e) => onShare(e)}>Email</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Outils;