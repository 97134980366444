import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage, updateModalsOpen, updateModalsLoginType } from '../Store/action';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
//Hooks
import useApi from '../Hooks/useApiGlobal';
//Composant
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from "../Components/loader";
import VisuelLarge from "../Components/visuelLarge";
import CommunityBlockRoundedTitle from "../Components/communityBlockRoundedTitle";
import CommunityForm from "../Components/communityForm";
import CommunitySuccessForm from "../Components/communitySuccessForm";
// Visuel
import backgroundImage from "../assets/img/community/tips.png";
import backgroundImageDesktop from "../assets/img/community/communaute_decolorStop_desktop.jpg";
import backgroundImageMobile from "../assets/img/community/communaute_decolorStop_mobile.jpg";


const TemplateCommunityFormAstuce = ({ _uid }) => {
    const { desktop } = useSelector(state => state.breakpoint);
    const tipsHpPath = useSelector(state => state.routes).routes.tipsHome;

    const [success, setSuccess] = useState(false);

    // Get user
    let { uuid } = useSelector(state => state.user);
    const modalsOpen = useSelector(state => state.modals).openModal;//modal

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-communityForm-tip'));
    }, [dispatch]);

    useEffect(() => {
        if (!uuid) {
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'password': false, 'economy': false }));
            dispatch(updateModalsLoginType('account'));
        }
    }, [uuid]); // eslint-disable-line

    //Requete pour récup les meta data
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag,title',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const onSuccess = () => {
        setSuccess(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const renderPage = () => {
        const { metatag } = dataRedux;
        //-- Tracking
        let pageviewEvent = {
            category: 'Communauté',
            category2: 'Astuces',
            category3: 'Formulaire'
        };
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                {
                    !success ?
                        <>
                            <VisuelLarge
                                urlVisuelStatic={backgroundImage}
                                bgColor="#af8fae"
                            />
                            <div className="container">
                                <CommunityBlockRoundedTitle
                                    title="Ici, je partage mon astuce !"
                                />
                                {
                                    uuid &&
                                    <CommunityForm
                                        formType={"astuce"}
                                        onSuccessSend={onSuccess}
                                    />
                                }
                            </div>
                        </>
                        :
                        <CommunitySuccessForm
                            title={"Merci pour votre partage!"}
                            text={"Votre astuce vient d’être publiée. <br/>Suivez les réactions de la communauté à votre astuce en consultant les notifications dans la rubrique Mon Compte."}
                            ctaUrl={tipsHpPath}
                            ctaText={"Retrouvez toutes nos astuces​"}
                        />
                }
            </>
        )
    }
    return (isLoaded && dataRedux) ? renderPage() : <Loader />
};

export default TemplateCommunityFormAstuce;