import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useApiGlobal from "../Hooks/useApiGlobal";
import { getImage } from "../Utils/getMedia";
import normalize from "json-api-normalizer";
import build from "redux-object";
const BannerBrand = ({ }) => {
  const { desktop } = useSelector((state) => state.breakpoint);

  const [dataBanner, setDataBanner] = useState(null);
  let paramsBanner = {
    name: "api/push-display",
    path: window.location.pathname,
    include:
      "field_push_pgph_ban.field_respimg_img_mobile,field_push_pgph_ban.field_respimg_img_desktop,field_push_img_logo",
    fields: "&fields=field_push_pgph_ban,field_push_img_logo",
    method: "get",
  };

  const [dataFetchBanner, isLoadedBanner] = useApiGlobal(paramsBanner);

  useEffect(() => {
    if (dataFetchBanner && isLoadedBanner && dataFetchBanner.status === 200) {
      const normalizeJson = normalize(dataFetchBanner.data); //Normalisation du json
      let data = build(normalizeJson, "pushPush")[0];
      let title = data?.fieldPushTxtTitle;
      let content = data?.fieldPushTxtDescription;
      let link = { url: data?.fieldPushLink.uri, title: data?.fieldPushLink.title };
      //console.log(data.fieldPushPgphBan);
      let imageUrl = data && data?.fieldPushPgphBan ? getImage(data.fieldPushPgphBan, desktop, "ban") : "#";
      let logoUrl =
        data && data?.fieldPushImgLogo
          ? desktop
            ? data.fieldPushImgLogo.links.commonLogoDesktop.href
            : data.fieldPushImgLogo.links.commonLogoMobile.href
          : "#";
      setDataBanner({
        title,
        content,
        link,
        imageUrl,
        logoUrl,
      });
    }
  }, [isLoadedBanner, dataFetchBanner]);

  if (!dataBanner) return null;
  let { title, content, link, imageUrl, logoUrl } = dataBanner;
  return (
    <div className="c-bannerBrand">
      <div className="container">
        <a
          href={link && link.url ? link.url : "#"}
          className="c-bannerBrandLink"
          title={link && link.title ? link.title : ""}
        //style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : ""}
        >
          {
            imageUrl && <img src={imageUrl} alt="" />
          }
          <div className="c-bannerBrand__box">
            {logoUrl && logoUrl.length >= 5 && (
              <div className="c-bannerBrand__logo">
                <img src={logoUrl} alt={""} />
              </div>
            )}
            <div className="c-bannerBrand__content">
              {title && <div className="c-bannerBrand__content-title">{title}</div>}
              {content && <div className="c-bannerBrand__content-txt">{content}</div>}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default BannerBrand;
