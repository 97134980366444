import React from "react";

import CommunityPostDetails from "../Components/communityPostDetails";

const TemplateCommunityQuestionDetails = ({ _uid }) => {
    return (
        <CommunityPostDetails _uid={_uid} postType={'question'} />
    )
};

export default TemplateCommunityQuestionDetails;