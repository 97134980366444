import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

//Hooks
import useApi from '../Hooks/useApiGlobal';
import { updateGlobalStorePage } from '../Store/action';

//components
import Metatags from '../Layout/metas-tags/meta-tags';
import Univers from "../Components/univers"
import SliderLarge from "../Components/sliderLarge"
import { Loader } from "../Components/loader";


const TemplateHome = ({ _uid }) => {

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('home'));
    }, [dispatch]); // isLoaded

    // Breakpoint
    const { desktop } = useSelector(state => state.breakpoint);

    const [paths, setPaths] = useState([]);
    const [dataRedux, setDataRedux] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        include: 'content_config_form_files',
        fields: '&fields[node--special]=metatag,title,field_special_configformref,content_config_form_files',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));

            // console.log('dataFetch Home', dataFetch.data)
            setPaths(dataFetch.data.data.attributes.field_special_configformref.paths);
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    const renderPage = () => {
        //console.log('dataRedux Home', dataRedux);
        const {metatag, title, contentConfigFormFiles: fullCarouselImages, fieldSpecialConfigformref: {data: {slider: {main: fullCarousel}, rubrics: categories}}} = dataRedux;

        // const categoriesLink = Object.values(dataRedux.fieldSpecialConfigformref.paths);

        const getLink = (value) => {
            let link = paths[value];
            return link;
        };

        //-- Tracking
        let pageviewEvent = {
            category: 'Page d\'accueil',
            category2: '',
            category3: ''
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}

                <SliderLarge carousel={fullCarousel} images={fullCarouselImages} desktop={desktop} name={title}/>

                <div className="container">
                    <div className="c-univers">
                        {categories[0] &&
                            <Univers type="universEconomy" styleType="smaller--" target={getLink(categories[0].link)}
                                targetTitle={categories[0].title} index={categories[0].index}>
                                <strong dangerouslySetInnerHTML={{ __html: categories[0].title }} />
                                <p dangerouslySetInnerHTML={{ __html: categories[0].text }} />
                            </Univers>
                        }
                        {categories[1] &&
                            <Univers type="universTips" styleType="smaller--" target={getLink(categories[1].link)}
                                targetTitle={categories[1].title} index={categories[1].index}>
                                <strong dangerouslySetInnerHTML={{ __html: categories[1].title }} />
                                <p dangerouslySetInnerHTML={{ __html: categories[1].text }} />
                            </Univers>
                        }
                        {categories[2] &&
                            <Univers type="universBrands" styleType="smaller--" target={getLink(categories[2].link)}
                                targetTitle={categories[2].title} index={categories[2].index}>
                                <strong dangerouslySetInnerHTML={{ __html: categories[2].title }} />
                                <p dangerouslySetInnerHTML={{ __html: categories[2].text }} />
                            </Univers>
                        }
                        {categories[3] ?
                            <Univers type="universCommunity" styleType="smaller--" target={getLink(categories[3].link)}
                                targetTitle={categories[3].title} index={categories[3].index}>
                                <strong dangerouslySetInnerHTML={{ __html: categories[3].title }} />
                                <p dangerouslySetInnerHTML={{ __html: categories[3].text }} />
                            </Univers>
                        :
                            <Univers type="universCommunity" styleType="smaller--">
                                <strong dangerouslySetInnerHTML={{__html: "Bientôt disponible"}}/>
                            </Univers>
                        }
                    </div>
                </div>

            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader />
};

export default TemplateHome;