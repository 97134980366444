import { useCallback, useRef, useState, useEffect } from "react"


export const useSafeState = (initialValue) => {

    const isMounted = useRef(true)

    const [state, setState] = useState(initialValue)

    useEffect(() => () => (isMounted.current = false), []) // used to know if component is mounted or not

    const setSafeState = useCallback((value) => {
        if (isMounted.current) {
            setState(value)
        }
    }, [])

    return [state, setSafeState]

}
