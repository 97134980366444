import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SublinkSubMenu } from "../header/menu"

// Components
import CLink from "../../Components/cLinks";
// import Newsletter from '../../Components/newsletter';

const MenuItem = ({ id, children, virtual_page, title, url }) => {
  return (
    <div
      className={`c-footer_navItem ${children ? "hasChildren--" : ""}`}
      key={id}
    >
      <h2>
        {virtual_page === true ? (
          <span className="c-footer_navLink">{title}</span>
        ) : (
          <NavLink
            activeClassName="selected"
            className="c-footer_navLink"
            to={url}
            title={title}
          >
            {title}
          </NavLink>
        )}
      </h2>
      {children && <SubMenu children={children} />}
    </div>
  );
};

const SubMenu = ({ children }) => {
  return (
    <ul className="c-footer_navLink">
      {children &&
        children.map(({ id, url, title, children }) => (
          <li className="c-nav_subItem" key={id}>
            <SublinkSubMenu
              children={children}
              url={url}
              title={title}
              navLinkEvents={url.indexOf("/economies/") > -1 ? (() => setTimeout(() => document.location.reload(), 100)) : null}
            />
          </li>
        ))}
    </ul>
  );
};

const Footer = () => {
  const { desktop } = useSelector((state) => state.breakpoint);

  // Get global info
  const { /*newsletter, */ sharer, menu, links, copyright } = useSelector(
    (state) => state.global
  ).global.footer;

  return (
    <footer className="c-footer">
      <div className="container">
        <div className="c-footer_top">
          {/* <Newsletter {...newsletter} /> */}

          {sharer && Object.keys(sharer).length > 0 && (
            <div className="c-footer_rs">
              {!desktop && <h2>Réseaux sociaux</h2>}
              <ul>
                {Object.keys(sharer).map((keyName, key) => {
                  return (
                    <li key={key}>
                      <CLink
                        url={sharer[keyName].url}
                        target="_blank"
                        external={true}
                      >
                        <img
                          src={sharer[keyName].pictogram}
                          alt={sharer[keyName].title}
                        />
                      </CLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {menu && menu.length > 0 && (
            <div className="c-footer_nav">
              {menu.map((item) => (
                <MenuItem key={item.id} {...item} />
              ))}
            </div>
          )}
        </div>
        <div className="c-footer_mentions">
          {copyright && Object.keys(copyright).length > 0 && (
            <p>
              <strong>{copyright}</strong>
            </p>
          )}

          {links && links.length > 0 && (
            <p>
              {links.map((item) => (
                <CLink
                  key={item.id}
                  url={item.url}
                  title={item.title}
                  target={item.target}
                  external={item.target === "_blank"}
                  className="c-footer_mentions-link"
                >
                  {item.title}
                </CLink>
              ))}
            </p>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
