import {
    CART_CASHBACK,
    CART_CASHBACK_ERROR,
    CART_WEBCOUPON,
    CART_WEBCOUPON_ERROR,
    CART_BEGIN_CHECKOUT
} from "../action";

let initialState = {
    cashback: [],
    cashbackError: null,
    webcoupon: [],
    webcouponError: null,
    beginCheckout: false
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_CASHBACK:
            return {...state, cashback: action.value};
        case CART_WEBCOUPON:
            return {...state, webcoupon: action.value};
        case CART_CASHBACK_ERROR:
            return {...state, cashbackError: action.value};
        case CART_WEBCOUPON_ERROR:
            return {...state, webcouponError: action.value};
        case CART_BEGIN_CHECKOUT:
            return {...state, beginCheckout: action.value};
        default:
            return state;
    }
}
