import React, { useEffect, useState } from "react";
import useApi from '../Hooks/useApiGlobal';
// Components
import { Loader } from "../Components/loader";

// Utils
import { transformText } from "../Utils/transformText";

const CommunityNotifications = () => {

    // GET ALL PROFILE STATS
    const [dataRedux, setDataRedux] = useState();
    const [dataFetchInfos, isLoadedInfos] = useApi({
        name: "api/users/actions?_format=json",
        needLogin: true,
        method: "get",
    });
    useEffect(() => {
        if (isLoadedInfos && dataFetchInfos) {

            if (dataFetchInfos.status === 200) {
                setDataRedux(dataFetchInfos.data);
            }
        }
    }, [isLoadedInfos, dataFetchInfos]); // eslint-disable-line

    // FORMAT TIMESTAMP CREATION DATE 
    const formatDate = (timestamp) => {
        if (timestamp) {
            const date = new Date(timestamp);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }
            return (
                `${day}/${month}/${year}`
            )
        }
    }

    return (
        <div className="c-communityNotifications">
            <h2 className="title-2 container">Mes notifications</h2>
            {
                isLoadedInfos && dataFetchInfos && dataRedux ?
                    <div className="c-communityNotifications__container">
                        <ul className="c-communityNotifications__list container">
                            {
                                Array.isArray(dataRedux.last_actions) &&
                                    dataRedux.last_actions.length > 0 ?
                                    dataRedux.last_actions.map((action, key) => {
                                        return (
                                            <li key={key} className="c-communityNotifications__list-item">
                                                <div className="c-communityNotifications__notif">
                                                    <div className={`c-communityNotifications__notif-visuel ${action.data.attributes.event_params ? action.data.attributes.event_params.content_type : ''}`}>
                                                        <i className="icono"></i>
                                                    </div>
                                                    <div className="c-communityNotifications__notif-description">
                                                        {
                                                            action.data.attributes.created &&
                                                            <p className="date">{formatDate(action.data.attributes.created)}</p>
                                                        }
                                                        {
                                                            action.data.attributes.text &&
                                                            <p dangerouslySetInnerHTML={{ __html: transformText(action.data.attributes.text) }}></p>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }) :
                                    <li style={{ textAlign: 'center' }} className="c-communityNotifications__list-item">
                                        <p>Aucune notification</p>
                                    </li>
                            }

                        </ul>
                    </div>
                    : <Loader />
            }
        </div>
    )
};

export default CommunityNotifications;