import React, {useEffect, useState} from 'react';

//Hooks
import useTracking from '../Hooks/useTracking';
import {useLocation} from "react-router-dom";

// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';


// Components
import CLink from "./cLinks";
import SubUniverse from "../Components/subUniverse";

import {useSelector} from 'react-redux';
import {randomString} from "../Utils/md5-hash";


SwiperCore.use([Navigation, EffectFade, Autoplay]);


/* Item */
const Item = ({name, fieldApiRemoteidUniverseid}) => {

    const tabVisuel = {
        0: require("../assets/img/favoris.png"),                    //Favoris
        1: require("../assets/img/universes/univers-linge.png"),    //Linge
        2: require("../assets/img/universes/univers-maison.png"),   //Maison
        3: "",
        5: require("../assets/img/universes/univers-beaute.png")    //Beauté
    };


    return (
        <>
            <div className="c-listUniverse_visuel">
                {(tabVisuel && tabVisuel[fieldApiRemoteidUniverseid]) &&
                <img src={tabVisuel[fieldApiRemoteidUniverseid]} alt={name}/>}
            </div>
            <h2 className="c-listUniverse_title">{name}</h2>
        </>
    )
};

/* Carousel */
const ListUniverse = ({universList, desktop, subuniverses = "", filterElt = "", setFilterElt = "", showLoader, url=""}) => {
    const accountFavoritesPath = useSelector(state => state.routes).routes.accountFavorites;

    const favoris = [
        {
            name: <>Conseils & <br/> Astuces favoris</>,
            fieldApiRemoteidUniverseid: 0
        }
    ];

    let {uuid} = useSelector(state => state.user);


    const [subuniverse, setSubuniverse] = useState();
    const handleClickUnivers = (e, item) => {
        e.preventDefault();
       
        if (!showLoader)
            if (item.drupalInternalTid === filterElt.univers) {
                window.history.replaceState('', '', url);
                setFilterElt({});
            } else {
                window.history.replaceState('', '', url+item.fieldCommonTxtSlug);
                setFilterElt({univers: item.drupalInternalTid, slugUnivers: item.fieldCommonTxtSlug});
            }
    };

    useEffect(() => {
        if (filterElt) {
            const universeItem = universList.find(element => element.drupalInternalTid === filterElt.univers);
            if (universeItem) {
                setSubuniverse(listHub(subuniverses, universeItem.fieldApiRemoteidUniverseid));
            }
        }
    }, [filterElt]); // eslint-disable-line

    const listHub = (listHub, idRemoteUnivers) => {
        let newTab = [];
        listHub.map((item, key) => {
            if (item.fieldApiTrUniverse.fieldApiRemoteidUniverseid === idRemoteUnivers) {
                newTab.push(item);
            }
            return null;
        });
        return newTab;

    };


    const handleClickSub = (e, item) => {
        if (!showLoader)
            if (item.drupalInternalTid === filterElt.hub) {
                setFilterElt({...filterElt, hub: ""});
                window.history.replaceState('', '', url+filterElt.slugUnivers);
            } else {
                setFilterElt({...filterElt, hub: item.drupalInternalTid});
                window.history.replaceState('', '', url+filterElt.slugUnivers+'/'+item.fieldCommonTxtSlug);
            }
    };


    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const selectContent = (contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: contentName,
                content_name: contentName
            },
            refresh: refresh
        });
    };

    return (
        <div className="c-listUniverse_wrapper">
            {universList &&

            <Swiper className="c-listUniverse_slider"
                    slidesPerView='auto'
                    allowTouchMove={true}
                    autoHeight={false}
                    spaceBetween={desktop ? 16 : 8}
                    loop={((desktop && universList.length > 4) || (!desktop && universList.length > 3))}
                    watchSlidesVisibility={true}
                    breakpoints={{
                        1024: {
                            // allowTouchMove: false,
                        },
                    }}
            >
                {subuniverses !== '' ?
                    <>
                        {uuid &&
                        <SwiperSlide className={`c-listUniverse_item ${filterElt.univers === 0 ? ' active--' : ''}`}>
                            <CLink
                                url={accountFavoritesPath}
                                title="Conseils & astuces favoris">
                                <Item {...favoris[0]} desktop={desktop} showLoader={showLoader}/>
                            </CLink>
                        </SwiperSlide>
                        }
                        {
                            universList.map((item, key) => {
                                return (
                                    <SwiperSlide 
                                        key={key}
                                        className={`c-listUniverse_item ${filterElt.univers === item.drupalInternalTid ? ' active--' : ''} ${showLoader && ' disabled--'}`}
                                        onClick={e => handleClickUnivers(e, item)}>

                                        <CLink url={url + item.fieldCommonTxtSlug} title={"Afficher les astuces de " + item.name} handleClick={e => e.preventDefault()}>
                                            <Item {...item} desktop={desktop} showLoader={showLoader}/>
                                        </CLink>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </>
                    :
                    universList.map((item, key) => {
                        return (
                            <SwiperSlide key={key} className={`c-listUniverse_item  ${showLoader && ' disabled--'}`}>
                                <CLink
                                    url={item.path.alias ? item.path.alias : '/taxonomy/terme/' + item.drupalInternalTid}
                                    title={item.name} handleClick={() => selectContent(item.name, randomString(6))}>
                                    <Item {...item} desktop={desktop} showLoader={showLoader}/>
                                </CLink>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

            }

            {(filterElt.univers && filterElt.univers !== 0 && subuniverse) ?
                <div className="c-subuniverse">
                    {/* {console.log(filterElt.univers)} */}
                    <div
                        className={`swiper-button-next ${(!desktop || (desktop && subuniverse.length < 5)) ? 'swiper-button-disabled' : ''}`}></div>
                    <Swiper
                        className={`c-subuniverse_slider ${((desktop && subuniverse.length > 5) || (!desktop && universList.length > 2)) ? 'swiped' : ''}`}
                        slidesPerView='auto'
                        autoHeight={false}
                        allowTouchMove={true}
                        spaceBetween={desktop ? 10 : 5}
                        loop={((desktop && subuniverse.length > 5) || (!desktop && universList.length > 2))}
                        watchSlidesVisibility={true}
                        navigation={(desktop && subuniverse.length > 5) ? {nextEl: `.c-subuniverse .swiper-button-next`} : false}
                        breakpoints={{
                            1024: {
                                allowTouchMove: false
                            },
                        }}
                    >
                        {
                            subuniverse.map((item, key) => {
                                // console.log(item.fieldCommonTxtSlug)
                                return (
                                    <SwiperSlide 
                                        key={key}
                                        className={`c-subuniverse_item ${filterElt.hub === item.drupalInternalTid ? ' active--' : ''} ${showLoader && ' disabled--'}`}
                                        onClick={e => handleClickSub(e, item)}>
                                        
                                        <CLink url={url+filterElt.slugUnivers+'/'+item.fieldCommonTxtSlug} title={"Afficher les astuces de " + item.name} handleClick={e => e.preventDefault()}>
                                            <SubUniverse {...item} desktop={desktop} filterElt={filterElt}/>
                                        </CLink>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                :
                <></>
            }

        </div>
    )
};

export default ListUniverse;