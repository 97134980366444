import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
// Components
import Comment from "../Components/comment";
import CommentForm from "../Components/commentForm";

const CommentItem = ({
  elt,
  _uid,
  dataType,
  contentType,
  commentsRedux,
  setCommentsRedux,
  contentName
}) => {
  // Commenter
  const [answerComment, setanswerComment] = useState(false);
  // Focus sur input du commentaire
  const [isEditing, setEditing] = useState(false); // eslint-disable-line
  // Voir les autres commentaires
  const [showMore, setshowMore] = useState(false); // eslint-disable-line
  let { uuid } = useSelector(state => state.user);
  const commentFormRef = useRef(null);

  // Répondre à un commentaire
  const handleAnswer = () => {
    if (!answerComment) {
      setanswerComment(true);
      console.log("set answer");
    } else {
      toggleEditing();
      console.log("editing ", inputRef);
    }
  };

  // Focus sur le input pour commenter
  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  const inputRef = useRef(null);
  const parentComment = useRef(null);

  // Voir les commentaires d'un commentaire
  const handleShowMore = () => {
    setshowMore(!showMore);
    if (showMore) {
      parentComment.current.scrollIntoView();
    }
    // Tracking
    window.customDataLayer.push({
        event: 'view_answers',
        user_id: uuid ? uuid : undefined,
        content_type: contentType,
        content_name: contentName
    });
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div ref={parentComment} className="c-commentItem">
      <Comment ref={commentFormRef} {...elt} contentType={contentType}/>
      <div className="c-comment_content options--">
        {/* IF HAS MORE COMMENTS */}
        {elt.childrens && elt.childrens.length > 0 &&
        <div
          className={`c-commentItem_more-content ${showMore ? "show--" : ""}`}
        >
          {elt.childrens.map((c, index) => (
            index == elt.childrens.length - 1 ? <Comment key={index} {...c} /> : <Comment key={index} ref={commentFormRef} {...c} />
          ))}
          
        </div>
        }
        {/* END IF HAS MORE COMMENTS */}

        <div
          className={`c-commentItem_more-content ${
            answerComment ? "show--" : ""
            }`}
        >
          <CommentForm
            _uid={_uid}
            dataType={dataType}
            contentType={contentType}
            commentsRedux={commentsRedux}
            setCommentsRedux={setCommentsRedux}
            commentParentId={elt.id}
            onCommentSend={() => {
              elt.id && setshowMore(true)

              setTimeout(() => {
                elt.id && commentFormRef.current.scrollIntoView({ behavior: "smooth" });
              }, 500)
            }}
          />
        </div>

        {!answerComment && (
          <button className="c-commentItem_add-btn" onClick={handleAnswer}>
            Répondre
          </button>
        )}

        {/* IF HAS MORE COMMENTS */}
        {elt.childrens && elt.childrens.length > 0 && !showMore ? (
          <button
            className={`c-commentItem_see-link ${showMore ? "hide--" : ""}`}
            onClick={handleShowMore}
          >
            {`Voir ${elt.childrens.length} ${elt.childrens.length > 1 ? 'réponses' : 'réponse'}`}
          </button>
        ) : elt.childrens.length > 0 && (
          <button
            className={`c-commentItem_see-link`}
            onClick={handleShowMore}
          >
            {`Masquer ${elt.childrens.length > 1 ? 'les' : 'la'} réponse${elt.childrens.length > 1 ? 's' : ''}`}
          </button>
        )}
        {/* END IF HAS MORE COMMENTS */}
      </div>
    </div>
  );
};

export default CommentItem;
