import React from "react";

// Components
import TemplateArticle from './TemplateArticleGlobal';
import Chatbot from "../Components/chatbot";

const TemplateDiy = ({_uid}) => {
    return (
        <>
            <TemplateArticle contentType="node--diy" _uid={_uid}/>
            <Chatbot/>
        </>
    )
};

export default TemplateDiy;