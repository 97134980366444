export const decodeEntities = (str) => {
    // this prevents any overhead from creating the object each time
    let element = document.createElement('div');

    // regular expression matching HTML entities
    let entity = /&(?:#x[a-f0-9]+|#[0-9]+|[a-z0-9]+);?/ig;

    // find and replace all the html entities
    str = str.replace(entity, function (m) {
        element.innerHTML = m;
        return element.textContent;
    });

    // reset the value
    element.textContent = '';

    return str;
};