import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Hooks
import useApi from "../Hooks/useApiGlobal";
import useTracking from "../Hooks/useTracking";
import { updateCartWebcoupon } from "../Store/action";

// Components
import { Loader } from "./loader";
import SwitchTab from "./switchTab";
import ListProducts from "./listProducts";
import Product from "./product";

import {getMsgError} from '../Utils/getMsgError';
import {formatNumber} from '../Utils/transformText';

// Utils
import { md5, randomString } from '../Utils/md5-hash';
import {getCookie} from '../Utils/cookie';

const Step0 = ({ activeStep, setActiveStep, error, products, productsSelected, setProductsSelected, offersAmount, hasPercent, relatedProducts, type, deleteProduct }) => {
    const history = useHistory();
    let dispatch = useDispatch();

    const [cartPath, setCartPath] = useState('');
    const cartPathCashback = useSelector(state => state.routes).routes.cartCashback;
    const cartPathWebcoupon = useSelector(state => state.routes).routes.cartWebcoupon;
    const cartCashbackNumber = useSelector(state => state.cart).cashback.length;
    const cartWebcouponNumber = useSelector(state => state.cart).webcoupon.length;

    // User
    let { uuid, iupid } = useSelector(state => state.user);

    // Tracking
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    /*   const checkoutProgressTracking = (activeStepName) => {
           setTrackingEvent({
               event: 'checkout_progress',
               args: {
                   membership_level: uuid ? 'member' : 'no-member',
                   checkout_step_number: 1,
                   checkout_step_name: activeStepName
               }
           });
       };*/

    const purchaseTracking = (items, transaction, refresh) => {
        setTrackingEvent({
            event: 'purchase',
            args: {
                membership_level: uuid ? 'member' : 'no-member',
                currency: 'EUR',
                ecommerce: {
                    value: offersAmount,
                    transaction_id: transaction,
                    currency: 'EUR',
                    items: items
                }
            },
            refresh: refresh
        });
    };

    const viewCartTracking = (items) => {
        setTrackingEvent({
            event: 'view_cart',
            args: {
                value: 0,
                ecommerce: {
                    items: items
                }
            }
        });
    };

    useEffect(() => {
        if (products) {
            let productsTracking = [];
            products.map(({ offerref, discount, brandname, shelfname, universename, ean }, key) => {
                productsTracking.push({
                    quantity: 1,
                    price: discount,
                    item_variant: type === 'refund' ? 'cashback' : 'coupon',
                    item_list_name: 'undefined',
                    item_list_id: 'undefined',
                    item_id: ean || offerref,
                    item_category3: brandname,
                    item_category2: shelfname,
                    item_category: universename,
                    index: key,
                });
                return null;
            });
            viewCartTracking(productsTracking);
        }
    }, []); // eslint-disable-line

    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        if (type === "refund") {
            setCartPath(cartPathWebcoupon);
            setActiveTab(1);
        }
        if (type === "print") {
            setCartPath(cartPathCashback);
            setActiveTab(2);
        }
    }, [type, cartPathCashback, cartPathWebcoupon]);


    //-- SwitchTab
    let tabs = [
        {
            id: 1,
            title: "Me faire rembourser",
            number: cartCashbackNumber
        },
        {
            id: 2,
            title: "Imprimer mes bons",
            number: cartWebcouponNumber
        }
    ];


    // Get PDF of offers
    const [errors, setErrors] = useState({
        'print': null,
        'email': null,
        'message': ''
    });
    const [printLoader, setPrintLoader] = useState(false);
    const [emailLoader, setEmailLoader] = useState(false);
    const [pdfParamsPrint, setPdfParamsPrint] = useState({});
    const [pdfParamsEmail, setPdfParamsEmail] = useState({});
    const [pdfRequestPrint, isLoadedPdfRequestPrint] = useApi(pdfParamsPrint);
    const [pdfRequestEmail, isLoadedPdfRequestEmail] = useApi(pdfParamsEmail);

    const getOffersPDF = (type) => {
        let productsTracking = [];
        products.map(({ discount, brandname, shelfname, universename, ean }, key) => {
            productsTracking.push({
                quantity: 1,
                price: discount,
                item_variant: type === "print" ? "coupon - print" : "coupon - email",
                item_list_name: 'undefined',
                item_list_id: 'undefined',
                item_id: ean,
                item_category3: brandname,
                item_category2: shelfname,
                item_category: universename,
                index: key
            });
            return null;
        });
        let transactionID = md5(iupid + Math.round(new Date().getTime() / 1000) + randomString(8));
        purchaseTracking(productsTracking, transactionID, randomString(6));
        if (type === 'print') {
            setPrintLoader(true);
            setPdfParamsPrint({
                name: `api/lba-economy/print?useragent=${navigator.userAgent}${getCookie('srcid') ? '&srcid=' + getCookie('srcid') : ''}`,
                method: 'get'
            });
            //checkoutProgressTracking('Imprimer');
        }
        if (type === 'email') {
            setEmailLoader(true);
            setPdfParamsEmail({
                name: 'api/lba-economy/email',
                method: 'get'
            });
            //checkoutProgressTracking('Email');
        }
    };
    // Print
    useEffect(() => {
        if (pdfRequestPrint && isLoadedPdfRequestPrint) {
            let { status, data } = pdfRequestPrint;
            //console.log(pdfRequestPrint);
            if (status === 200) {
                if (data && data.printurl && data.thanksurl) {
                    // console.log('printURL with Return : ' + data.printurl + '&ReturnUrl=' + process.env.REACT_APP_HOST + data.thanksurl);
                    dispatch(updateCartWebcoupon([]));
                    window.location = data.printurl + '&ReturnUrl=' + process.env.REACT_APP_HOST + data.thanksurl
                    setPrintLoader(false);
                }
                setErrors({...errors, print: false, message:''});
            } else {
                //Erreur
                console.log('error - get PDF Path for print');
                setErrors({...errors, print: true, message:data.error_code ? data.error_code : "Une erreur est survenue" });
            }
        }
    }, [pdfRequestPrint, isLoadedPdfRequestPrint]); // eslint-disable-line
    // Email
    useEffect(() => {
        if (pdfRequestEmail && isLoadedPdfRequestEmail) {
            let { status, data } = pdfRequestEmail;
            //console.log(pdfRequestEmail);
            if (status === 200) {
                if (data && data.thanksurl) {
                    // dispatch(updateCartWebcoupon([]));
                    history.replace({ pathname: data.thanksurl });
                    setEmailLoader(false);
                }
                setErrors({...errors, email: false, message:''});
            } else {
                //Erreur
                console.log('error - get PDF Path for email');
                setErrors({...errors, email: true, message:data.error_code ? data.error_code : "Une erreur est survenue"});
            }
        }
    }, [pdfRequestEmail, isLoadedPdfRequestEmail]); // eslint-disable-line


    // Products selection
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectAllItems = () => {
        setSelectAll(!selectAll);
        if (productsSelected.length > 0) {
            console.log(selectAll.length)
            setProductsSelected([]);
        } else {
            console.log(productsSelected.length)
            setProductsSelected(products);
        }
    };
    const handleSelectItem = (product) => {
        setSelectAll(true);
        setProductsSelected([...productsSelected, product]);
        // if (selectAll === true) {
        //     setSelectAll(false);
        // }
    };
    const handleRemoveItem = (product) => {
        setProductsSelected(productsSelected.filter(item => item.offerref !== product.offerref));
    };

    const renderStep = () => {
        switch (type) {
            case "refund":
                return (
                    <div className="container">
                        <div
                            className="title-30">Sélectionnez tous les articles figurants sur le même ticket de caisse
                        </div>
                        {products ?
                            <>
                                {Array.isArray(products) && products.length > 0 ?
                                    <>
                                        <div className="cart__products">
                                            <div className="cartFlex">
                                                <div className="cartPrefix cart__products-header">
                                                    <button type="button"
                                                        className="cart__products-button"
                                                        onClick={() => handleSelectAllItems()}>
                                                            {selectAll && productsSelected.length > 0? "Tout déselectionner" : "Tout sélectionner"}
                                                    </button>
                                                </div>
                                                <div className="cartContainer cart__products-header">
                                                    <div><strong>Produit</strong></div>
                                                    <div><strong>Descriptif</strong></div>
                                                    <div><strong>Réduction</strong></div>
                                                </div>
                                            </div>

                                            <div className="cart__products-listCtn">
                                                <ul className="cart__products-list refund--">
                                                    {
                                                        products.map((product, key) => {
                                                            return <ProductItemRefund product={product} key={key}
                                                                index={key}
                                                                handleSelectItem={handleSelectItem}
                                                                handleRemoveItem={handleRemoveItem}
                                                                /*selectAll={selectAll}*/
                                                                deleteProduct={deleteProduct}
                                                                isSelected={productsSelected.filter(item => item.offerref === product.offerref).length > 0} />
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="cart__refurb refund--">
                                            <p>Ma sélection : <strong>{productsSelected.length} {productsSelected.length > 1 ? 'offres' : 'offre'}</strong>
                                            </p>
                                            <p>
                                                <span>Total de mes remboursements sélectionnés : </span><span>{formatNumber(offersAmount)}€</span>
                                            </p>
                                        </div>
                                        {(offersAmount > 0 || hasPercent) &&
                                            <div className="cart__button">
                                                <button type="button" className="btn-2"
                                                    onClick={() => setActiveStep(activeStep + 1)}>Se faire rembourser
                                            </button>
                                            </div>
                                        }
                                    </>
                                    : <div className="cart__refurb">Aucune offre dans votre panier</div>
                                }
                            </>
                            :
                            <>{error ? <div className="cart__refurb">{error}</div> : <Loader />}</>
                        }
                    </div>
                );
            case "print":
                return (
                    <div className="container container--mini">
                        {products ?
                            <>
                                {Array.isArray(products) && products.length > 0 ?
                                    <>
                                        <div className="cart__products">
                                            <div className="cart__products-header">
                                                <div><strong>Produit</strong></div>
                                                <div><strong>Descriptif</strong></div>
                                                <div><strong>Réduction</strong></div>
                                            </div>

                                            <div className="cart__products-listCtn">
                                                <ul className="cart__products-list">
                                                    {
                                                        products.map((product, key) => {
                                                            return (
                                                                <li className="cart__products-list-item" key={key}>
                                                                    <div className="itemProduct">
                                                                        <Product {...product} cart={true}
                                                                            type={type} index={key} />
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="cart__refurb">
                                            <span>Total de mes réductions : </span><span>{formatNumber(offersAmount.toFixed(1))}€</span>
                                        </div>
                                        {offersAmount > 0 &&
                                            <div className="cart__button">
                                                <button type="button" className="btn-2"
                                                    onClick={() => getOffersPDF('print')}>{printLoader && !errors.print ?
                                                        <Loader /> : "Imprimer mes bons"}
                                                </button>
                                                <button type="button" className="btn-2"
                                                    onClick={() => getOffersPDF('email')}>{emailLoader && !errors.email ?
                                                        <Loader /> : "Me les envoyer par email"}
                                                </button>
                                            </div>
                                        }
                                        {(errors.email || errors.print) &&
                                        <div className="error-- error--step">{getMsgError(errors.message)}</div>}
                                    </>
                                    : <div className="cart__refurb">Aucune offre dans votre panier</div>
                                }
                            </>
                            :
                            <>{error ? <div className="cart__refurb">{error}</div> : <Loader />}</>
                        }
                    </div>
                );
            default:
                return null;
        }

    };

    return (
        <div className="cart-step0">
            <SwitchTab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} link={cartPath} />

            {renderStep()}

            {(relatedProducts && relatedProducts.length > 0) &&
            <ListProducts title="Ces réductions peuvent vous intéresser" products={relatedProducts}
                sliderMobile={true} type={type} deleteProduct={deleteProduct} />
            }
        </div>
    )
};

const ProductItemRefund = ({ product, index, handleSelectItem, handleRemoveItem, selectAll, isSelected, deleteProduct }) => {
    const [selected, setSelected] = useState(false);
    /* useEffect(() => {
         if (selectAll === true) {
             setSelected(true);
         }
     }, [selectAll]);*/
    useEffect(() => {
        setSelected(isSelected);
    }, [isSelected]);
    return (
        <li className="cartFlex cart__products-list-item">
            <div className="cartPrefix" onClick={() => {
                if (selected) {
                    handleRemoveItem(product);
                } else {
                    handleSelectItem(product);
                }
                setSelected(!selected);
            }}>
                <button type="button"
                    className={`cart__products-select${selected ? ' selected' : ''}`}><i
                        className="icon-check-2"></i>
                </button>
            </div>
            <div className="cartContainer">
                <div className="itemProduct">
                    <Product {...product} cart={true}
                        type="refund" index={index} deleteProduct={deleteProduct} />
                </div>
            </div>
        </li>
    )
};

export default Step0;