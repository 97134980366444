import React from "react";
import {useSelector, useDispatch} from "react-redux";

// Hooks
import {
    updateModalsOpen,
    updateModalsLoginType,
    updateGlobalCartStepsReset
} from '../Store/action';

// Components
import CLink from "./cLinks";


const Basket = () => {
    const dispatch = useDispatch();

    const {uuid} = useSelector(state => state.user);
    const cartWebcouponPath = useSelector(state => state.routes).routes.cartWebcoupon;
    let {webcoupon, cashback} = useSelector(state => state.cart);
    const itemsInCart = parseInt(webcoupon.length + cashback.length);

    const handleOpen = () => {
        dispatch(updateModalsOpen({ 'login': true }));
        dispatch(updateModalsLoginType('login'));
    };
    return (
        <div className="c-header_basket" onClick={() => dispatch(updateGlobalCartStepsReset(true))}>
            {
                (uuid) ?
                    <CLink url={cartWebcouponPath} title="Voir mon panier">
                        <i className="icon-basket"></i>
                        {itemsInCart > 0 &&
                        <span className="c-header_basketNumber">{itemsInCart}</span>
                        }
                    </CLink>
                    :
                    <div onClick={() => handleOpen()}>
                        <i className="icon-basket"></i>
                    </div>
            }
        </div>
    )
};

export default Basket;