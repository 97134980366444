import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
// API NORMALISATION 
import normalize from 'json-api-normalizer';
import build from 'redux-object';
// HOOKS
import useApi from '../Hooks/useApiGlobal';
import useTracking from '../Hooks/useTracking';
//COMPOSANTS
import CommunityPostCard from "../Components/communityPostCard";
import CommunityThematicsList from "../Components/communityThematicsList";
import Comments from "../Components/comments";
import { Loader } from "../Components/loader";
import CarouselCashback from "../Components/carouselCashback";
import CommunityCarouselUploads from "../Components/communityCarouselUploads";
import Metatags from '../Layout/metas-tags/meta-tags';
// Utils
import { randomString } from "../Utils/md5-hash";

const CommunityPostDetails = ({ _uid, postType }) => {
    const { desktop } = useSelector(state => state.breakpoint);
    const [dataRedux, setDataRedux] = useState();
    const [vueApi, setVueApi] = useState({});
    const [dataFetchVue, isLoadedVue] = useApi(vueApi); // eslint-disable-line

    const [popinPreviewOpen, setPopinPreviewOpen] = useState(false);
    const [activePopinSlide, setActivePopinSlide] = useState(0);

    // TRACKING - Popin img open
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line
    const popinImgTracking = (refresh, thematic) => {
        setTrackingEvent({
            event: "popin_display",
            args: {
                popin_name: "carrousel images",
                content_category: "Communauté",
                content_category2: postType,
                content_category3: thematic,
            },
            refresh: refresh
        });
    };

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('c-communityPostDetails'));
    }, [dispatch]);
    // RELATION ENTRE LES TABLES
    let infosDataInclude = [
        'field_community_tr_themes',
        'field_community_file_medias',
        'field_community_tr_tags',
        'uid',
        'uid.user_picture'
    ];
    // name -> voir onglet API BO 
    // fields -> Optimiser recherche en filtrant les resultats. Filtre fait dans l'ojbjet attributes
    // include -> Joindre les tables de la BDD (relationship)
    const [dataFetchInfos, isLoadedInfos] = useApi({
        name: `api/node/${postType === 'astuce' ? 'tip' : 'question'}`,
        _uid: _uid,
        include: infosDataInclude.join(','),
        fields: `&fields[${postType === 'astuce' ? 'node--tip' : 'node--question'}]=title,created,field_common_txt_body,node_view,likes_count,drupal_internal__nid,field_community_tr_themes,field_community_file_medias,uid,field_community_tr_tags,field_common_comments,metatag&fields[file--file]=uri,filemime,filename,links&fields[field_community_tr_tags]=name&fields[taxonomy_term--community_theme]=name,field_api_remoteid_themeid&fields[users]=display_name,user_picture`,
        method: 'get'
    });

    // CHECK WHEN API IS LOAD
    useEffect(() => {
        if (isLoadedInfos && dataFetchInfos) {
            let normalizeJson = normalize(dataFetchInfos.data);
            setDataRedux(build(normalizeJson, `${postType === 'astuce' ? 'nodeTip' : 'nodeQuestion'}`, _uid));
        }
    }, [isLoadedInfos, dataFetchInfos]);// eslint-disable-line

    useEffect(() => {
        if (dataRedux) {
            const headers = {
                "Content-Type": "application/json"
            };
            setVueApi({
                name: 'api/node-view/' + dataRedux.drupalInternalNid,
                method: 'post',
                noData: true,
                config: headers
            });
        }
    }, [dataRedux]);// eslint-disable-line
    // HANDLE POPIN PREVISUALISATON 
    const openPopin = (key) => {
        setActivePopinSlide(key);
        setPopinPreviewOpen(true);
        popinImgTracking(randomString(6), dataRedux.fieldCommunityTrThemes.name)
    }

    // GET SAME THEMATICS QUESTION 
    const [questionThematics, setQuestionThematics] = useState();
    const [astuceThematics, setAstuceThematics] = useState();
    let infosDataThematic = [
        'field_community_tr_themes'
    ];
    const [dataFetchQuestions, isLoadedQuestions] = useApi({
        name: `api/node/question`,
        include: infosDataThematic.join(','),
        fields: `&fields[node--question]=title,field_community_tr_themes,path&fields[taxonomy_term--community_theme]=name`,
        filter: `&filter[field_community_tr_themes.id]=${dataRedux && dataRedux.fieldCommunityTrThemes.id}`,
        limit: 2,
        method: 'get'
    });
    const [dataFetchAstuces, isLoadedAstuces] = useApi({
        name: `api/node/tip`,
        include: infosDataThematic.join(','),
        fields: `&fields[node--tip]=title,field_community_tr_themes,path,likes_count&fields[taxonomy_term--community_theme]=name`,
        filter: `&filter[field_community_tr_themes.id]=${dataRedux && dataRedux.fieldCommunityTrThemes.id}`,
        limit: 2,
        method: 'get'
    });
    // CHECK WHEN API IS LOAD FOR ALL THEMATICS QUESTIONS
    useEffect(() => {
        if (isLoadedQuestions && dataFetchQuestions) {
            let normalizeJson = normalize(dataFetchQuestions.data);
            setQuestionThematics(build(normalizeJson, 'nodeQuestion'));
        }
    }, [isLoadedQuestions, dataFetchQuestions]);// eslint-disable-line
    useEffect(() => {
        if (dataFetchAstuces && isLoadedAstuces) {
            let normalizeJson = normalize(dataFetchAstuces.data);
            setAstuceThematics(build(normalizeJson, 'nodeTip'));
        }
    }, [dataFetchAstuces, isLoadedAstuces]);// eslint-disable-line

    const renderPage = () => {

        const {
            fieldCommunityFileMedias,
            metatag,
            fieldCommonComments
        } = dataRedux;

        let pageviewEvent = {
            category: 'Communauté',
            category2: postType === "astuce" ? "Astuces" : "Questions et réponses",
            category3: dataRedux.fieldCommunityTrThemes.name,
        };
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                {
                    fieldCommunityFileMedias && Array.isArray(fieldCommunityFileMedias) && popinPreviewOpen &&
                    <CommunityCarouselUploads
                        files={fieldCommunityFileMedias}
                        closePopin={() => setPopinPreviewOpen(false)}
                        activeSlide={activePopinSlide}
                    />
                }
                <div className="container">
                    <CommunityPostCard
                        {...dataRedux}
                        postType={postType}
                        openPreview={(key) => openPopin(key)}
                    />
                    {/* {
                        fieldCommonComments.commentCount &&
                        <p className="title-comments-counter">
                            La communauté a apporté {fieldCommonComments.commentCount} {postType === 'astuce' ? 'commentaire':'réponse'}{fieldCommonComments.commentCount <= 1 ? '' : 's'}
                        </p>
                    } */}
                </div>
                <Comments
                    _uid={_uid}
                    contentName={dataRedux.fieldCommonTxtBody.processed}
                    dataType={postType === 'astuce' ? 'node--tip' : 'node--question'}
                    contentType={dataRedux.fieldCommunityTrThemes.name} />
                {
                    dataRedux && dataRedux.fieldCommunityTrThemes.id &&
                    <>
                        <CommunityThematicsList
                            thematicQuestions={questionThematics}
                            thematicAstuces={astuceThematics}
                            postType={postType}
                            currentIdPost={_uid}
                        />
                        <div className="container">
                            <CarouselCashback desktop={desktop} themeID={dataRedux.fieldCommunityTrThemes.fieldApiRemoteidThemeid} />
                        </div>
                    </>
                }
            </>
        )
    };
    return (isLoadedInfos && dataRedux) ? renderPage() : <Loader />
};

export default CommunityPostDetails;