import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';

// Hooks
import useApi from "../Hooks/useApiGlobal";
import {
    updateModalsOpen,
    updateModalsLoginType,
    updateCartCashback,
    updateCartWebcoupon
} from '../Store/action';
import useTracking from '../Hooks/useTracking';
import useApiJeton from "../Hooks/useApiJeton";
import useIntersection from '../Hooks/useIntersection';
import {getMsgError} from '../Utils/getMsgError';
import {formatNumber} from '../Utils/transformText';


// Components
import Tooltip from "./tooltip";
import {Loader} from "./loader";

// Utils
import {decodeEntities} from "../Utils/decodeEntities";
import {randomString} from "../Utils/md5-hash";

const Product = ({ean, offerref, name: title, discounttype, discount, percentage, largemediaurl: image, longdesc: tooltip, type, cart, hasUpdate = false, updated, brandlogo, brandname, universename, shelfname, content, price, panier = false, index, deleteProduct, skin, pageName, productsViewed}) => {
        // Jeton
        const [initJeton, setInitJeton] = useState({});
        useApiJeton(initJeton);

        // User's id
        let {uuid} = useSelector(state => state.user);

        const store = useSelector(state => state);

        // Tracking
        const [trackingEvent, setTrackingEvent] = useState({});
        const tracking = useTracking(trackingEvent); // eslint-disable-line

        const selectItemTracking = (refresh) => {
            setTrackingEvent({
                event: 'select_item',
                args: {
                    value: price || discount,
                    currency: 'EUR',
                    ecommerce: {
                        items: [{
                            quantity: 1,
                            price: price || discount,
                            item_variant: type === 'refund' ? 'cashback' : 'coupon',
                            item_list_name: undefined,
                            item_list_id: undefined,
                            item_id: offerref || ean,
                            item_category3: brandname,
                            item_category2: shelfname,
                            item_category: universename,
                            index: index,
                        }]
                    }
                },
                refresh: refresh
            });
        };

        const addToCartTracking = useCallback((refresh) => {
            // console.log('addtocart track');
            setTrackingEvent({
                event: 'add_to_cart',
                args: {
                    value: price || discount,
                    currency: 'EUR',
                    ecommerce: {
                        items: [{
                            quantity: 1,
                            price: price || discount,
                            item_variant: type === 'refund' ? 'cashback' : 'coupon',
                            item_list_name: undefined,
                            item_list_id: undefined,
                            item_id: offerref || ean,
                            item_category3: brandname,
                            item_category2: shelfname,
                            item_category: universename,
                            index: index,
                        }]
                    }
                },
                refresh: refresh
            });
        }, [brandname, discount, ean, index, offerref, price, shelfname, type, universename]);

        const deleteFromCartTracking = (refresh) => {
            setTrackingEvent({
                event: 'remove_from_cart',
                args: {
                    value: price || discount,
                    currency: 'EUR',
                    ecommerce: {
                        items: [{
                            quantity: 1,
                            price: price || discount,
                            item_variant: type === 'refund' ? 'cashback' : 'coupon',
                            item_list_name: undefined,
                            item_list_id: undefined,
                            item_id: offerref || ean,
                            item_category3: brandname,
                            item_category2: shelfname,
                            item_category: universename,
                            index: index,
                        }]
                    }
                },
                refresh: refresh
            });
        };

        // TRACKING - View item (Product)
        const productRef = useRef(null);
        const inViewport = useIntersection(productRef, '-100px');

        const viewItem = (refresh) => {
            if (productsViewed) {
                if(!productsViewed.includes(offerref || ean)){
                    setTrackingEvent({
                        event: 'view_item',
                        args: {
                            value: price || discount,
                            currency: 'EUR',
                            ecommerce: {
                                items: [{
                                    quantity: 1,
                                    price: price || discount,
                                    item_variant: type === 'refund' ? 'cashback' : 'coupon',
                                    item_list_name: pageName,
                                    item_list_id: undefined,
                                    item_id: offerref || ean,
                                    item_category3: brandname,
                                    item_category2: shelfname,
                                    item_category: universename,
                                    index: index,
                                }]
                            }
                        },
                        refresh: refresh
                    });
                }
            } else {
                setTrackingEvent({
                    event: 'view_item',
                    args: {
                        value: price || discount,
                        currency: 'EUR',
                        ecommerce: {
                            items: [{
                                quantity: 1,
                                price: price || discount,
                                item_variant: type === 'refund' ? 'cashback' : 'coupon',
                                item_list_name: pageName,
                                item_list_id: undefined,
                                item_id: offerref || ean,
                                item_category3: brandname,
                                item_category2: shelfname,
                                item_category: universename,
                                index: index,
                            }]
                        }
                    },
                    refresh: refresh
                });
            }
            // console.log('customDataLayer viewItem', window.customDataLayer);

        };

        useEffect(() => {
            if (inViewport && (offerref || ean) && !cart) {
                viewItem(randomString(6));
            }
        }, [inViewport, offerref, ean]);


        // Modals - Login
        const modalsOpen = useSelector(state => state.modals).openModal;
        const dispatch = useDispatch();

        const handleOpen = () => {
            dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'password': false, 'economy': true}));
            dispatch(updateModalsLoginType('currentPage'));
        };


        // Links to cart within page type
        const cartCashbackPath = useSelector(state => state.routes).routes.cartCashback;
        const cartWebcouponPath = useSelector(state => state.routes).routes.cartWebcoupon;
        const cartPath = type === "refund" ? cartCashbackPath : cartWebcouponPath;


        const renderType = (type) => {
            switch (type) {
                case 'print':
                    return (
                        <div className="c-product__actions-type print print--" onClick={() => handleOpen()}>Pour <br/>impression
                        </div>
                    );
                case 'refund':
                    return (
                        <div className="c-product__actions-type refund--" onClick={() => handleOpen()}>Pour <br/>remboursement
                        </div>
                    );
                default:
                    return null;
            }
        };

        const renderDiscount = (type, cart) => {
            switch (type) {
                case 'amount_off':
                    return (
                        <>
                            {discount && <h4 className="c-product__price">{formatNumber(discount)}€</h4>}
                        </>
                    );
                case 'percent_off':
                    return (
                        <>
                            {percentage &&
                            <h4 className="c-product__price">{percentage} % <span>remboursé</span></h4>}
                        </>
                    );
                default:
                    return (
                        <>
                            {discount && <h4 className="c-product__price">{formatNumber(discount)}€</h4>}
                        </>
                    );
            }
        };

        // Breakpoint
        const {mobile, desktop} = useSelector(state => state.breakpoint);

        // Loader
        const [loading, setLoading] = useState(false);

        // Tooltip Info
        const tooltipInfoRef = useRef();
        const [showTooltipInfo, setShowTooltipInfo] = useState({
            show: false,
            content: '',
            ref: '',
            type: 'info'
        });
        const handleMouseTooltip = (e) => {
            if (showTooltipInfo.show) {
                setShowTooltipInfo({
                    show: false,
                    content: '',
                    ref: '',
                    type: 'info'
                });
            }
            else {
                let refTooltip = tooltipInfoRef.current;
                setShowTooltipInfo({
                    show: true,
                    content: e.target.dataset.content,
                    ref: refTooltip,
                    type: e.target.dataset.type
                });
            }
        };
        // END Tooltip Info

        const [jeton, setJeton] = useState();

        // Selected state // if in cart or not
        const [selected, setSelected] = useState(false);
        const [selectClicked, setSelectClicked] = useState(false);
        const [selectOffer, setSelectOffer] = useState("");

        useEffect(() => {
            setSelected(panier);
        }, [panier, offerref, ean]); // eslint-disable-line


        const addToCart = useCallback((token, offerref) => {
            const headers = {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
            };

            if (type === "refund") {
                setParamsAddToCart({
                    name: 'api/lba-cashback/basket/add?' + randomString(4),
                    method: 'post',
                    data: {
                        "offerref": offerref, // This is the body part
                    },
                    config: headers,
                    stringifyRequest: false // Body raw
                });
            } else {
                setParamsAddToCart({
                    name: 'api/lba-economy/basket/add?' + randomString(4),
                    method: 'post',
                    data: {
                        "ean": offerref, // This is the body part
                    },
                    config: headers,
                    stringifyRequest: false // Body raw
                });
            }
        }, [type]);


        useEffect(() => {
            if (selectClicked && uuid) {
                setLoading(true);
                setTimeout(() => {
                    if (jeton) {
                        addToCart(jeton, selectOffer);
                    } else {
                        // Récupération du Jeton
                        setInitJeton({
                            callback: addToCart,
                            params: selectOffer,
                            success: function (token) {
                                setJeton(token);
                            }
                        });
                    }
                }, 30);
            }
        }, [addToCart, selectClicked, selectOffer, setSelectClicked, uuid]);


// Select Product - Add to cart
        const handleSelect = (offerref) => {
            //console.log('select: ' + offerref);
            setParamsAddToCart({});
            setSelectClicked(true);
            setSelectOffer(offerref);
            selectItemTracking(randomString(6));
            setTimeout(() => {
                if (uuid) {
                    setLoading(true);
                    if (jeton) {
                        addToCart(jeton, offerref);
                    } else {
                        // Récupération du Jeton
                        setInitJeton({
                            callback: addToCart,
                            params: offerref,
                            success: function (token) {
                                setJeton(token);
                            }
                        });
                    }
                } else {
                    //  console.log('not logged');
                    dispatch(updateModalsOpen({...modalsOpen, 'login': true, 'password': false, 'economy': false}));
                    dispatch(updateModalsLoginType('currentPage'));
                    setLoading(false);
                }
            }, 10);
        };

// Add to cart
        let [paramsAddToCart, setParamsAddToCart] = useState({});
        const [responseAddToCart, isLoadedAddToCart] = useApi(paramsAddToCart);
        let [offerAlreadyUsed, setofferAlreadyUsed] = useState(false)

        useEffect(() => {
            if (responseAddToCart && isLoadedAddToCart) {
                let {status, data} = responseAddToCart;


                setSelectClicked(false);
                setSelectOffer("");
                // if(status === 400) {
                //     window.location.replace(window.location.pathname)
                // }
                if (status === 200) {
                    //console.log(data);
                    setSelected(true);
                    setLoading(false);
                    if (data) {
                        if (type === 'refund') {
                            dispatch(updateCartCashback(data));
                        }
                        if (type === 'print') {
                            dispatch(updateCartWebcoupon(data));
                        }
                    }

                    addToCartTracking(randomString(6));
                } else if (data.error_code === "COUPON_MAX_SUBMISSION_OR_EXPIRED" || data.error_code === "SOGEC_MAX_SUBMISSION_OR_EXPIRED"){
                    setLoading(false)
                    setofferAlreadyUsed(true)
                }

                else {
                    //Erreur
                    console.log('error - add to cart');
                }
            }
        }, [responseAddToCart, isLoadedAddToCart]);


// Delete Product - Delete from cart
        const [errorDelete, setErrorDelete] = useState(false);
        const handleDelete = (offerref) => {
            setParamsDeleteFromCart({});
            //console.log('delete: ' + offerref);

            setLoading(true);

            if (jeton) {
                deleteFromCart(jeton, offerref);
            } else {
                setInitJeton({
                    callback: deleteFromCart,
                    params: offerref,
                    success: function (token) {
                        setJeton(token);
                    }
                });
            }
        };

// Delete from cart
        let [paramsDeleteFromCart, setParamsDeleteFromCart] = useState({});
        const [responseDeleteFromCart, isLoadedDeleteFromCart] = useApi(paramsDeleteFromCart);
        const [canRender, setCanRender] = useState(false);

        useEffect(() => {
            if(!modalsOpen.login && uuid && uuid.length > 0 && (store.cart.cashback.length > 0 || store.cart.webcoupon.length > 0)) {
                setCanRender(true)
            } else {
                setCanRender(false);
            }
        }, [modalsOpen.login, store.cart.cashback.length, store.cart.webcoupon.length, uuid]);

        const deleteFromCart = (token, offerref) => {
            const headers = {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
            };

            if (type === 'refund') {
                //console.log('cashback delete : ' + offerref);
                setParamsDeleteFromCart({
                    name: 'api/lba-cashback/offer/delete?' + randomString(4),
                    method: 'post',
                    data: {
                        "offerref": offerref, // This is the body part
                    },
                    config: headers,
                    stringifyRequest: false // Body raw
                });
            } else {
                //console.log('webcoupon delete : ' + offerref);
                setParamsDeleteFromCart({
                    name: 'api/lba-economy/offer/delete?' + randomString(4),
                    method: 'post',
                    data: {
                        "ean": offerref, // This is the body part
                    },
                    config: headers,
                    stringifyRequest: false // Body raw
                });
            }
        };

        let {cashback, webcoupon} = useSelector(state => state.cart);
        const [listeCoupons, setListeCoupons] = useState([]);

        useEffect(() => {
            setListeCoupons([...cashback, ...webcoupon]);
        }, [cashback, webcoupon])

        const isSelected = (offerref, ean) => {

            if(offerref){
                let couponSelected = listeCoupons.filter(item => item.offerref === offerref);
                if (couponSelected.length > 0) return true;
            } else if(ean){
                let couponSelected = listeCoupons.filter(item => item.ean === ean);
                if (couponSelected.length > 0) return true;
            }

            return false;
        }

        useEffect(() => {
            if (responseDeleteFromCart && isLoadedDeleteFromCart) {
                // console.log(responseDeleteFromCart);
                let {status, data} = responseDeleteFromCart;

                if (status === 200) {
                    // console.log(data);

                    deleteFromCartTracking(randomString(6));

                    setTimeout(() => {
                        setSelected(false);

                        let data = [];
                        if (type === 'refund') {
                            data = cashback.filter(item => item.offerref !== offerref);
                            dispatch(updateCartCashback(data));
                        }
                        if (type === 'print') {
                            data = webcoupon.filter(item => item.ean !== ean);
                            dispatch(updateCartWebcoupon(data));
                        }
                        setErrorDelete(false);
                        setLoading(false);

                        if (deleteProduct) {

                            deleteProduct(offerref);

                        }
                    }, 10);


                } else {
                    //Erreur
                    setErrorDelete(data.error_code ? data.error_code : 'Une erreur est survenue lors de la suppresion, veuillez réessayer.');
                    setLoading(false);
                    console.log('error - delete from cart');
                }
            }
        }, [responseDeleteFromCart, isLoadedDeleteFromCart]); // eslint-disable-line

        return (
            <>
            {
                !offerAlreadyUsed &&
                <div className="c-product" data-cart={cart} data-id={offerref || ean} data-hasupdate={hasUpdate}
                    data-updated={updated} data-producttype={type} ref={productRef}>
                    <div className="c-productInner">
                        <div className="c-product__content">
                            {(brandlogo && !cart) &&
                            <div className="c-product__logo">
                                <img src={brandlogo} alt={brandname ? brandname : title}/>
                            </div>
                            }
                            <div className="c-product__img">
                                {image &&
                                <img src={image} alt={decodeEntities(title)}/>
                                }
                                {cart && hasUpdate && mobile && title && <h3>{title}</h3>}
                            </div>
                            {(!cart || (cart && !hasUpdate) || (cart && hasUpdate && desktop)) &&
                            <div className="c-product__desc">
                                {title && <h3 dangerouslySetInnerHTML={{__html: title}}/>}
                                {content && <p>{content}</p>}
                            </div>
                            }
                        </div>
                        {cart ?
                            <>

                                {/*{(hasUpdate || desktop) &&*/}
                                <div className="c-product__info c-form_tooltip2" ref={tooltipInfoRef}>
                                    {tooltip &&
                                    <i className='icon-info'
                                    onMouseOver={handleMouseTooltip}
                                    onMouseOut={handleMouseTooltip}
                                    data-content={decodeEntities(tooltip)}
                                    data-typeref="tooltipInfoRef"
                                    data-type="info"
                                    />
                                    }
                                </div>
                                {/*}*/}
                                {showTooltipInfo.show && <Tooltip type={showTooltipInfo.type}
                                                                target={showTooltipInfo.ref}>{showTooltipInfo.content}</Tooltip>}

                                {renderDiscount(discounttype, true)}
                                <div className="c-product__delete" onClick={() => handleDelete(offerref || ean)}>
                                    <i className="icon-delete"/>
                                </div>
                            </>
                            :
                            <div className={`c-product__actions skin-${skin}`}>
                                <div>&nbsp;</div>
                                <div>
                                    {renderDiscount(discounttype, false)}
                                    <button className="c-product__actions-select"
                                            onClick={() => handleSelect(offerref || ean)}>Sélectionner
                                    </button>
                                </div>
                                {renderType(type)}
                            </div>
                        }
                    </div>

                    {loading && !canRender ?
                        <div className="c-productInner select--" data-selected="true">
                            <Loader/>
                        </div>
                        :
                        <>
                            {!cart && uuid && uuid.length > 0 &&
                            <div className="c-productInner select--" data-selected={isSelected(offerref, ean)}>
                                <>
                                    <div className="c-product__content">
                                        Dans votre panier !
                                        {!cart &&
                                        <Link className="c-product__actions-select" to={cartPath}
                                            title="Voir votre panier">Voir votre panier</Link>
                                        }
                                    </div>
                                    <div className="c-product__actions">
                                        {renderDiscount(discounttype, false)}
                                        {!cart &&
                                        <button className="c-product__actions-select"
                                                onClick={() => handleDelete(offerref || ean)}>Supprimer du panier
                                        </button>
                                        }
                                    </div>
                                </>
                            </div>
                            }
                        </>
                    }

                    {(errorDelete && errorDelete !== '') &&
                    <div className="error--">{getMsgError(errorDelete)}</div>}
                </div>
            }
            </>
        )
    }
;

export default Product;