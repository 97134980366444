import React, { useEffect, useState } from 'react';
import { renderMarkup } from '../Utils/misc';
import { transformText } from '../Utils/transformText';

import useApi from '../Hooks/useApiGlobal';

const WysiwygSimple = ({ title, title2, content, transform=true }) => {

  const [parsedContent, setParsedContent] = useState(null);
  const [cashbackMatch, setCashbackMatch] = useState(null);
  const [operationMatch, setOperationMatch] = useState(null);

  useEffect(() => {
    if(content){
      const cashbackRegex = /\[cashback:submissions_count:(\w+)\]/;
      const operationRegex = /\[operation:participations_count:(\w+)\]/;

      setCashbackMatch(content.match(cashbackRegex));
      setOperationMatch(content.match(operationRegex));
    }
  }, [content]);

  const [cashbackData] = useApi({
    name: cashbackMatch ? `api/lba-cashback/statistics?type=submission_count&offerref=${cashbackMatch[1]}` : null,
    method: 'get',
  },  [cashbackMatch]);

  const [operationData] = useApi({
    name: operationMatch ? `api/lba-operations/statistics/${operationMatch[1]}?type=participations_count` : null,
    method: 'get',
  }, [operationMatch]);

  useEffect(() => {
    if (content && (cashbackMatch || operationMatch)) {
      let newContent = content;
      if (cashbackData && cashbackData.status === 200) {
        newContent = newContent.replace(cashbackMatch[0], cashbackData.data);
      }
      if (operationData && operationData.status === 200) {
        newContent = newContent.replace(operationMatch[0], operationData.data);
      }
      setParsedContent(newContent);
    } else {
      setParsedContent(content)
    }
  }, [cashbackData, operationData, content]);

  return (
    <div className="c-wysiwygSimple">
      <div className="c-wysiwygSimple_content c-wysiwyg">
        {title && <h1 dangerouslySetInnerHTML={renderMarkup(title)} />}
        {title2 && <h2>{title2}</h2>}
        {parsedContent && 
          <div dangerouslySetInnerHTML={renderMarkup(transform ? transformText(parsedContent) : parsedContent)} />
        }
      </div>
    </div>
  );
};

export default WysiwygSimple;