import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

// Hooks
import useApi from '../Hooks/useApiGlobal';
import { updateGlobalStorePage } from '../Store/action';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import { Loader } from "../Components/loader";
import VisuelLarge from "../Components/visuelLarge";
import WysiwygSimple from "../Components/wysiwygSimple";
import {ItemAccordion} from "../Components/accordion";
import CarouselProducts from "../Components/carouselProducts";
import CarouselAllReduction from "../Components/carouselAllReduction";
import CarouselArticles from "../Components/carouselArticles";
import Comments from "../Components/comments";
import Video from "../Components/article/video";

const TemplateBrands = ({ _uid }) => {

    const { desktop } = useSelector(state => state.breakpoint);

    let hash = useLocation().hash;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-brands'));
    }, [dispatch]); // isLoaded

    const [dataRedux, setDataRedux] = useState();//Infos principales de la page
    const [visuels, setVisuels] = useState({ logo: '', intro: '' });//visuels : Banniere + logo + Intro
    const [actuRedux, setActuRedux] = useState(''); // eslint-disable-line
    const [productsRedux, setProductsRedux] = useState('');// Infos Produits
    const [articlesRedux, setArticlesRedux] = useState('');// Infos Actu

    //Requete pour récup tout le contenu principal de la marque
    let brandDataInclude = [
        'field_brand_img_logo',
        'field_brand_pgph_contents.field_common_img_textimage',
        'field_brand_img_introimage',
        'field_brand_img_mob_introimage',
        'field_common_pgph_ban.field_respimg_img_mobile',
        'field_common_pgph_ban.field_respimg_img_desktop',
        'field_common_er_related_contents',
        'field_common_er_related_contents.field_common_pgph_ban.field_respimg_img_mobile',
        'field_common_er_related_contents.field_common_pgph_ban.field_respimg_img_desktop',
        'field_common_er_related_contents.field_common_tr_universes',
        'field_common_tr_brand.field_api_tr_shelves.field_api_tr_universe',
        'field_brand_pgph_contents.field_common_img_videothumb',
        'field_brand_pgph_contents.field_landing_video',
        'field_brand_pgph_contents.field_common_video',
        'field_brand_pgph_contents.field_common_pgph_ban.field_respimg_img_desktop',
        'field_brand_pgph_contents.field_common_pgph_ban.field_respimg_img_mobile'
    ];

    const [dataFetchBrands, isLoadedBrands] = useApi({
        name: 'api/node/brand_page',
        _uid: _uid,
        include: brandDataInclude.join(','),
        fields: '&fields[taxonomy_term--brand]=field_api_remoteid_brandid,name,field_brand_bool_products_cta,field_api_tr_shelves',
        method: 'get'
    });

    useEffect(() => {
        if (isLoadedBrands && dataFetchBrands) {
            let normalizeJson = normalize(dataFetchBrands.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeBrandPage', _uid));
        }
    }, [isLoadedBrands, dataFetchBrands]);// eslint-disable-line

    //Requete qui permet de recup les actus de la marque
    const [paramsActu, setParamsActu] = useState({ name: '', method: '' });
    const [dataFetchActu, isLoadedActu] = useApi(paramsActu);

    //Requete qui permet de recup les articles de la marque
    const [paramsArticles, setParamsArticles] = useState({ name: '', method: '' });
    const [dataFetchArticles, isLoadedArticles] = useApi(paramsArticles);

    //Requete qui permet de recup les produits de la marque
    const [paramsProducts, setParamsProducts] = useState({ name: '', method: '' });
    const [dataFetchProducts, isLoadedProducts] = useApi(paramsProducts);

    useEffect(() => {
        if (dataRedux) {
            //Recuperation banniere + logo + image intro
            let value = { logo: '', intro: '' };
            if (dataRedux.fieldBrandImgLogo)
                value.logo = desktop ? dataRedux.fieldBrandImgLogo.links.commonLogoDesktop.href : dataRedux.fieldBrandImgLogo.links.commonLogoMobile.href;
            if (dataRedux.fieldBrandImgIntroimage)
                value.intro = desktop ? dataRedux.fieldBrandImgIntroimage.links.commonFullWidthBanDesktop.href : dataRedux.fieldBrandImgMobIntroimage.links.commonFullWidthBanMobile.href;
            setVisuels(value);

            //Récuperation id marque ==> MAJ state pour recuperations de la partie actu;
            if (dataRedux.fieldCommonTrBrand.id) {
                setParamsActu({
                    name: 'api/node/article',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                    fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,drupal_internal__nid,path,field_common_tr_universes',
                    filter: '&filter[field_common_tr_brands.id]=' + dataRedux.fieldCommonTrBrand.id + '&filter[promote]=1',
                    sort: '&sort[sort-view][path]=node_view&sort[sort-view][direction]=DESC&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                    limit: 15,
                    method: 'get'
                });

                //Récuperation id marque ==> MAJ state pour recup de la partie Ce qui pourrait vous plaire;
                // let excludeDataArticles = '&filter[]!=id'
                setParamsArticles({
                    name: 'api/node/article',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
                    fields: '&fields[node--article]=field_common_txt_title,field_common_pgph_ban,field_common_tr_universes,path,drupal_internal__nid,field_common_duration',
                    filter: '&filter[field_common_tr_brands.id]=' + dataRedux.fieldCommonTrBrand.id + '&filter[promote]=0',
                    sort: '&sort[sort-view][path]=node_view&sort[sort-view][direction]=DESC&sort[sort-created][path]=created&sort[sort-created][direction]=DESC',
                    limit: 15,
                    method: 'get'
                });

                // Récuperation produits
                setParamsProducts({
                    name: 'api/node/product',
                    include: 'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_product_img',
                    // fields:'&fields[node--product]=title,field_common_txt_title,field_common_pgph_ban,field_common_pgph_ban.field_respimg_img_desktop,field_product_img,path,drupal_internal__nid',
                    filter: '&filter[field_common_tr_brand.id]=' + dataRedux.fieldCommonTrBrand.id,
                    sort: '&sort[sort-field_content_weight][path]=field_content_weight&sort[sort-changed][path]=changed&sort[sort-changed][direction]=DESC',
                    method: 'get'
                })
            }
        }
    }, [dataRedux, desktop]); // Infos recup et normalisées

    //Recup les infos normalisées des Articles de la marques
    useEffect(() => {
        if (isLoadedActu && dataFetchActu) {
            let normalizeJsonActu = normalize(dataFetchActu.data);//Normalisation du json
            setActuRedux(build(normalizeJsonActu, 'nodeArticle'));
        }
    }, [isLoadedActu, dataFetchActu]);

    //Recup les infos normalisés des Articles "ce qui pourrait vous plaire". Tous les articles de la marque intemporels du + récent au plus ancien
    useEffect(() => {
        if (isLoadedArticles && dataFetchArticles) {
            let normalizeJsonArticles = normalize(dataFetchArticles.data);//Normalisation du json
            setArticlesRedux(build(normalizeJsonArticles, 'nodeArticle'));
        }
    }, [isLoadedArticles, dataFetchArticles]);

    //Recup les infos normalisés des Produits de la marque
    useEffect(() => {
        if (isLoadedProducts && dataFetchProducts) {
            let normalizeJsonProducts = normalize(dataFetchProducts.data);//Normalisation du json
            setProductsRedux(build(normalizeJsonProducts, 'nodeProduct'));
        }
    }, [isLoadedProducts, dataFetchProducts]);

    const renderPage = () => {
        const {fieldBrandPgphContents, fieldBrandTxtTitle, fieldBrandTxtIntrotitle, fieldBrandTxtIntrobody, fieldCommonPgphBan, metatag, title, fieldBrandBoolProductsCta, fieldCommonTrBrand: {fieldApiRemoteidBrandid: brandID, name: brandName, fieldApiTrShelves:[{fieldApiTrUniverse:{name:universName}}]}} = dataRedux;

        let brandIDs;
        if (brandID) {
            let brandsArray = [];
            brandsArray.push(parseInt(brandID));
            brandIDs = brandsArray;
        }

        // Articles reliés depuis la marque + articles de la marque
        let relatedArticles = [];
        if (Array.isArray(articlesRedux) && Array.isArray(dataRedux.fieldCommonErRelatedContents)) {
            relatedArticles = articlesRedux.concat(dataRedux.fieldCommonErRelatedContents);
        } else if (!Array.isArray(articlesRedux) && Array.isArray(dataRedux.fieldCommonErRelatedContents)) {
            relatedArticles = dataRedux.fieldCommonErRelatedContents;
        } else if (Array.isArray(articlesRedux) && !Array.isArray(dataRedux.fieldCommonErRelatedContents)) {
            relatedArticles = articlesRedux;
        }

        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'A vos marques',
            category2: fieldBrandTxtTitle,
            category3: universName,
        };

        if (hash) {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const yOffset = -document.querySelector('.c-header').offsetHeight;
                const element = document.getElementById(id);
                if (element && yOffset) {
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }, 0);
        }

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <VisuelLarge urlVisuel={fieldCommonPgphBan && fieldCommonPgphBan}
                    urlVisuelSup={visuels.logo && visuels.logo} altVisuelSup="titre du produit"
                    desktop={desktop} isTrueSize={true} />

                <div className="container">
                    <div className="t-brands_container">
                        <div className="t-brands_capsule">
                            <h1>{fieldBrandTxtTitle}</h1>
                            {fieldBrandBoolProductsCta && productsRedux &&
                                <p><a href="#nos-produits" className="t-brands_button">Voir les produits</a></p>
                            }
                        </div>
                        <div className="t-brands_content">
                            <div className="t-brands_intro">
                                {fieldBrandTxtIntrotitle &&
                                    <div className="c-wysiwyg"><h2>{fieldBrandTxtIntrotitle}</h2></div>}
                                {visuels.intro && <img src={visuels.intro} alt="" />}
                                {fieldBrandTxtIntrobody && <WysiwygSimple content={fieldBrandTxtIntrobody.processed} />}
                            </div>
                            {
                                fieldBrandPgphContents && Array.isArray(fieldBrandPgphContents) && fieldBrandPgphContents.length > 0 &&
                                fieldBrandPgphContents.map((item, key) => {
                                    if (item.fieldLandingVideo) {
                                        return (
                                            <Video 
                                                key={key} 
                                                infoVideo={item.fieldLandingVideo} 
                                                videoPoster={item.fieldCommonPgphBan.fieldRespimgImgDesktop.links.brandAccordionDesktop.href} 
                                                desktop={desktop} />
                                        )
                                    } else if (item.fieldCommonTxtTexttitle) {
                                        return (
                                            <ItemAccordion
                                                key={key}
                                                title={item.fieldCommonTxtTexttitle}
                                                content={item.fieldCommonTxtTextbody.processed}
                                                visuel={item.fieldCommonImgTextimage && (desktop ? item.fieldCommonImgTextimage.links.brandAccordionDesktop.href : item.fieldCommonImgTextimage.links.brandAccordionMobile.href)}
                                                altVisuel={""}
                                                infoVideo={item.fieldCommonVideo}
                                                videoPoster={item.fieldCommonImgVideothumb && (desktop ? item.fieldCommonImgVideothumb.links.brandAccordionDesktop.href : item.fieldCommonImgVideothumb.links.brandAccordionMobile.href)}
                                                desktop={desktop} />
                                        )
                                    } else {
                                        return
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>

                {productsRedux &&
                    <div className="container">
                        <div className="t-brands_products" id="nos-produits">
                            <h2 className="section-title">Les produits {fieldBrandTxtTitle}</h2>
                            <CarouselProducts carousel={productsRedux} desktop={desktop} />
                        </div>
                    </div>
                }

                {brandID &&
                    <div className="container">
                        <div className="t-brands_br">
                            <CarouselAllReduction
                                desktop={desktop}
                                brandID={brandIDs}
                                name={`Page marque ${title}`}
                                brandName={brandName} />
                        </div>
                    </div>
                }

                <div className="t-brands_grey">

                    <Comments _uid={_uid} dataType="node--brand_page" contentType="Page Marque" />

                    {relatedArticles.length > 0 &&
                        <div className="container">
                            <div className="t-brands_articles">
                                <h2 className="section-title">Cela pourrait vous plaire</h2>
                                <CarouselArticles carousel={relatedArticles} desktop={desktop} />
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    };
    return (isLoadedBrands && dataRedux) ? renderPage() : <Loader />
};

export default TemplateBrands;
