import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

//Hooks
import {updateGlobalStorePage} from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import {Loader} from "../Components/loader";
import CLink from "../Components/cLinks";
import VisuelLarge from "../Components/visuelLarge";


const TemplateThanks = ({_uid, type, pageviewEvent, content}) => {
    const {desktop} = useSelector(state => state.breakpoint);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-thanks t-contentPage'));
    }, [dispatch]); // isLoaded

    // Meta - Get page info
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            // console.log('thanksPage ' + type, dataFetch);
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line


    const renderPage = () => {
        const {metatag} = dataRedux;

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}
                {content.image &&
                <VisuelLarge urlVisuelStatic={require('../assets/img/register2.jpg')}
                             altVisuelSup={content.title}
                             desktop={desktop}/>
                }
                <div className="container">
                    <div className="t-contentPage_container">
                        {content.title && <h2 className="title-2">{content.title}</h2>}
                        {content.text && content.text.text &&
                        <div dangerouslySetInnerHTML={{__html: content.text.text}}/>}
                        {content.cta && <div className="t-contentPage_container__button">
                            <CLink className="btn-2" url={content.ctaUrl}
                                   title={content.cta}/>
                        </div>
                        }
                    </div>
                </div>
            </>
        )
    };
    return (isLoaded && dataRedux) ? renderPage() : <Loader/>
};


export const TemplateThanksPrint = ({_uid}) => {
    // Home path
    const homePath = useSelector(state => state.routes).routes.home;

    let pageviewEvent = {
        category: 'Economies',
        category2: 'Imprimer mes bons',
        category3: 'Page de remerciement - Impression',
    };

    let content = {
        /*'image': 'https://qualif-henkel-labelleadresse.havasdigitalfactory.net/sites/default/files/styles/common_full_width_ban_desktop/public/lba_economy/page/Slider_1_Economies_Desk_0.jpg',*/
        'title': 'Merci',
        'text': {
            'text': "<p><strong>Merci d'avoir imprimé vos bons de réduction</strong></p><p>Chaque offre de réduction est unique sur le mois en cours : une fois le bon de réduction imprimé, tout autre bon de réduction ou remboursement sur cette même offre ne sera plus disponible avant le premier jour du mois suivant.<br>N’oubliez pas que vous disposez d’un délai de 1 mois à partir de la date d’impression du bon pour l’utiliser en magasin.<br>Ces réductions ne peuvent pas être utilisées sur des produits déjà promotionnés et ne peuvent être cumulées à aucune autre offre promotionnelle (lots ou formats promotionnels, réductions en magasin, carte de fidélité, …).</p>"
        },
        'cta': 'Retourner vers l\'accueil',
        'ctaUrl': homePath
    };

    return (
        <TemplateThanks _uid={_uid} type="print" pageviewEvent={pageviewEvent} content={content}/>
    )
};


export const TemplateThanksEmail = ({_uid}) => {

    // Account edit path
    const accountEditPath = useSelector(state => state.routes).routes.accountEdit;
    // Home path
    const homePath = useSelector(state => state.routes).routes.home;

    let pageviewEvent = {
        category: 'Economies',
        category2: 'Imprimer mes bons',
        category3: 'Page de remerciement - Email',
    };

    const [content, setContent] = useState();

    // Get user
    let {uuid} = useSelector(state => state.user);
    let [params, setParams] = useState('');
    useEffect(() => {
        if (uuid) {
            setParams({
                name: `api/users/${uuid}`,
                fields: '&fields[users]=profile_completion',
                method: 'get'
            });
        }
    }, [uuid]); // eslint-disable-line

    // Requete pour récupérer les infos de l'user
    const [profileCompletion, setProfileCompletion] = useState();
    const [dataFetch, isLoaded] = useApi(params);
    useEffect(() => {
        if (isLoaded && dataFetch) {
            setProfileCompletion(dataFetch.data.data.attributes.profile_completion);
        }
    }, [isLoaded, dataFetch]);

    useEffect(() => {
        if (profileCompletion && profileCompletion > 99) {
            //console.log('profileCompletion', profileCompletion);
            setContent({
                'title': 'Félicitations',
                'text': {
                    'text': "<p>Votre sélection de bons de réduction vous a bien été envoyée par email.<br/>Vous pourrez les imprimer dès que vous serez connecté(e) à une imprimante.</p>",
                },
                'cta': 'Retourner vers l\'accueil',
                'ctaUrl': homePath
            });
        } else {
            setContent({
                'title': 'Félicitations',
                'text': {
                    'text': "<p>Votre sélection de bons de réduction vous a bien été envoyée par email.<br/>Vous pourrez les imprimer dès que vous serez connecté(e) à une imprimante.</p><p>Dans l'immédiat, prenez 30 secondes pour répondre à ce questionnaire.</p>",
                },
                'cta': 'Accéder au questionnaire',
                'ctaUrl': accountEditPath + '/#more-info-profil'
            });
        }
    }, [profileCompletion]);

    return (
        <TemplateThanks _uid={_uid} type="email" pageviewEvent={pageviewEvent} content={content}/>
    )
};