import { MODALS_OPEN, MODALS_LOGINTYPE, MODALS_REDIRECTBUTTON, MODALS_PASSWORDRESET , MODALS_DATAISLOADED} from "../action";

const initialState = {
    openModal: {
        'login': false,
        'password': false,
        'economy': false,
        'deleteAccount': false,
        'changePassword': false,
        'errorMessage': false,
        'postPreview': false,
        'hasError': false
    },
    loginType: null,
    redirectButton: null,
    passwordToken: null,
    dataIsLoaded: true,
};

/**
 * MODALS REDUCER
 * @param {*} state
 * @param {*} action
 */
export function modalsReducer(state = initialState, action) {
    switch (action.type) {
        case MODALS_OPEN:
            return { ...state, openModal: action.value };
        case MODALS_LOGINTYPE:
            return { ...state, loginType: action.value };
        case MODALS_REDIRECTBUTTON:
            return { ...state, redirectButton: action.value };
        case MODALS_PASSWORDRESET:
            return { ...state, passwordToken: action.value };
        case MODALS_DATAISLOADED:
            return {...state, dataIsLoaded: action.value};
        default:
            return state
    }
}
