import React, {useState} from 'react';

//Hooks
import useTracking from '../Hooks/useTracking';

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

// Components
import CLink from "./cLinks";

// Utils
import {randomString} from "../Utils/md5-hash";


SwiperCore.use([Navigation, EffectFade, Autoplay]);


const CarouselBrands = ({desktop, carousel}) => {

    carousel = carousel.filter(item => item.fieldCommonTrBrand && item.fieldCommonTrBrand.id !== "missing")

    // TRACKING - Select content
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    const selectContent = (contentName, refresh) => {
        setTrackingEvent({
            event: 'select_content',
            args: {
                content_type: "Marque",
                content_name: contentName
            },
            refresh: refresh
        });
    };

    return (
        <>
            {carousel.length > 0 &&
            <div className="c-carouselBrands">

                <div
                    className={`swiper-button-prev swiper-button-prev-brands ${!(desktop && carousel.length > 8) && 'swiper-button-disabled'}`}></div>
                <div
                    className={`swiper-button-next swiper-button-next-brands ${!(desktop && carousel.length > 8) && 'swiper-button-disabled'}`}></div>

                <Swiper className="c-carouselBrands_items"
                        slidesPerView='auto'
                        autoHeight={false}
                        watchSlidesVisibility={true}
                        loop={(desktop && carousel.length > 8) ? true : false}
                        spaceBetween={desktop ? 16 : 8}
                        autoplay={true}
                    // centeredSlides={true}
                        navigation={(desktop && carousel.length > 8) ? {
                            nextEl: '.swiper-button-next-brands',
                            prevEl: '.swiper-button-prev-brands'
                        } : false}
                >
                    {
                        carousel.map(({path, title, drupalInternalNid, fieldBrandImgLogo}, key) => {
                            return (
                                <SwiperSlide key={key} className="c-carouselBrands_item">
                                    <CLink url={path.alias ? path.alias : '/node/' + drupalInternalNid}
                                           handleClick={() => selectContent(title, randomString(6))}>
                                        <div className="c-carouselBrands_visuel">
                                            {fieldBrandImgLogo && <img
                                                src={desktop ? fieldBrandImgLogo.links.commonLogoDesktop.href : fieldBrandImgLogo.links.commonLogoMobile.href}
                                                alt={title}/>}
                                        </div>
                                    </CLink>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            }
        </>
    )
};

export default CarouselBrands;