const initialState = {
    page: 'homepage',
    categories: {
        category: undefined,
        category2: undefined,
        category3: undefined
    },
    logFrom: '/'
};

function getCategories(value) {
    switch (value) {
        case 'home':
            return { category: "Page d'accueil" };
        case 't-economy':
            return { category: "Economies" };
        case 't-cart-cashback':
            return { category: "Economies", category2: "Me faire rembourser", category3: "Panier" };
        case 't-tips':
            return { category: "Conseils et Astuces" };
        case 't-article':
            return { category: "Conseils et Astuces", category2: "Univers", category3: "Article" };
        case 't-onYourMarks':
            return { category: "A vos Marques" };
        case 't-brands':
            return { category: "A vos Marques", category2: "Marques", category3: "Page marque" };
        case 't-univers':
            return { category: "A vos Marques", category2: "Univers" };
        case 't-product':
            return { category: "A vos Marques", category2: "Marques", category3: "Page Produit" };
        case 't-accountHome':
            return { category: "Mon Compte" };
        case 't-accountForm':
            return { category: "Mon Compte", category2: "Gérer mon profil", category3: "Modifier mes données" };
        case 't-accountMyInfo':
            return { category: "Mon Compte", category2: "Gérer mon profil", category3: "Toutes mes données" };
        case 't-accountEconomy':
            return { category: "Mon Compte", category2: "Mes économies" };
        case 't-accountFavorite':
            return { category: "Mon Compte", category2: "Mes conseils et astuces", category3: "Mes favoris" };
        case 't-accountCommunity':
            return { category: "Mon Compte", category2: "Ma communauté", category3: "Mes contributions" };
        case 't-accountNotif':
            return { category: "Mon Compte", category2: "Notifications" };
        case 't-communityForm-tip':
            return { category: "Communauté", category2: "Astuce", category3: "Formulaire" };
        case 't-communityForm-question':
            return { category: "Communauté", category2: "Question", category3: "Formulaire" };
        case 't-operationsPage':
            return { category: "Opérations", category2: "", category3: "" };
        case 't-search':
            return { category: "Page de recherche" };
        case 't-contact':
            return { category: "Nous contacter" };
        case 't-landingPage':
            return { category: "Landing page" };
        case 't-mentions':
            return { category: "Mentions légales" };
        case 't-register':
            return { category: "Inscription" };
        case 't-sitemap':
            return { category: "Plan du site" };
        case 't-404':
            return { category: "404" };
        default:
            return value
    }
}

/**
 * PAGE NAME REDUCER
 * @param {*} state
 * @param {*} action
 */
export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_PAGE':
            return { ...state, page: action.value, categories: getCategories(action.value) };
        case 'UPDATE_LOGFROM_PAGE':
            return { ...state, logFrom: action.value };
        default:
            return state
    }
}
