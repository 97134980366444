//permet de convertir les balises [BOLD] et //
function replaceStr(str, find, replace) {
    for (let i = 0; i < find.length; i++) {
        str = str.replace(find[i], replace[i]);
    }
    return str;
}

// RTH : Pas mega cool le fait de remplacer les // par des br : il faut penser au fait que des liens ca existe dans un wysiwig....
export const transformText = (text) => {
    if (text) {
        let find = ["[BOLD]", "[/BOLD]", "[STRONG]", "[/STRONG]", "[LIKE]"];
        let replace = ['<strong>', '</strong>', '<strong>', '</strong>', '<i class="icon-up"></i>'];
        text = replaceStr(text, find, replace);

        //Et donc on fait un code crade ici
        let currentIndex = text.indexOf("//");

        while(currentIndex > -1){
            let currentIndexHttp = text.lastIndexOf("http", currentIndex);
            
            if(currentIndexHttp === -1 || currentIndex - currentIndexHttp > 6){
                text = text.substring(0, currentIndex) + "<br/>" + text.substring(currentIndex + 2)
            }

            currentIndex = text.indexOf("//", currentIndex + 1);
        }
    }
    return text;
};


export const camelize = (value) => {
    value = value.replace(/[\(\)\[\]\{\}\=\?\!\.\:,\-_\+\\\"#~\/]/g, " "); // eslint-disable-line
    let returnValue = "";
    let makeNextUppercase = true;
    value = value.toLowerCase();
    for (let i = 0; value.length > i; i++) {
        let c = value.charAt(i);
        if (c.match(/^\s+$/g) || c.match(/[\(\)\[\]\{\}\\\/]/g)) {// eslint-disable-line
            makeNextUppercase = true;
        } else if (makeNextUppercase) {
            c = c.toUpperCase();
            makeNextUppercase = false;
        }
        returnValue += c;
    }
    returnValue = returnValue.replace(/\s+/g, "");
    return returnValue.charAt(0).toLowerCase() + returnValue.slice(1);
};

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const formatNumber = (int) => {
    let str = int.toString().replace('.', ',');
    if(str.indexOf(',')<=0) {
        str = str+',00'
    }
    else {
        if((str.indexOf(',') + 2) >= str.replace('.', ',').length) {
            str = str+'0'
        }
    }                                             
 
    return str;
}

export const formatNumberBis = (str) => {
    if(str === "0,00€"){
        return "-"
    } else {
        return str;
    }
}