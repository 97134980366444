import React from "react";
// Components
import CommunityPostDetails from "../Components/communityPostDetails";

const TemplateCommunityTipDetails = ({ _uid }) => {
    return (
        <CommunityPostDetails _uid={_uid} postType={'astuce'} />
    )
};

export default TemplateCommunityTipDetails;