import React from "react";


// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';


// Utils
import Game from "./game";


SwiperCore.use([Navigation, EffectFade, Autoplay]);

const CarouselGames = ({carousel, desktop}) => {
    return (
        <>
            {carousel.length > 0 &&
            <div className="c-carouselActus">
                <Swiper className="c-carouselActus_items"
                    navigation={true}
                    slidesPerView="auto"
                    spaceBetween={desktop ? 20 : 10}
                    watchSlidesVisibility={true}
                    allowTouchMove={((desktop && carousel.length > 3) || (!desktop && carousel.length > 1)) ? true : false}
                    loop={((desktop && carousel.length > 3) || (!desktop && carousel.length > 1)) ? true : false}
                    pagination={((desktop && carousel.length > 3) || (!desktop && carousel.length > 1)) ? {clickable: true} : false}
                >

                    {
                        carousel.map((item, key) => {
                            return (
                                <SwiperSlide key={key} className="c-carouselActus_item">
                                    <Game {...item}/>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            }
        </>
    )
};

export default CarouselGames;