import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
//Hooks
import useApi from '../Hooks/useApiGlobal';
import { updateGlobalStorePage } from '../Store/action';
//Composant
import Metatags from '../Layout/metas-tags/meta-tags';
import VisuelLarge from "../Components/visuelLarge";
import CommunityHpCard from "../Components/communityHpCard";
import CommunityBlockRoundedTitle from "../Components/communityBlockRoundedTitle";
import { Loader } from "../Components/loader";
// Visuels
// import visualHomeCommunity from '../assets/img/community/homeCommunity.png';
// import visualQuestions from '../assets/img/community/questions.png';
// import visualTips from '../assets/img/community/tips.png';

const TemplateCommunityHP = ({ _uid }) => {
    const baseURL = process.env.REACT_APP_APP;
    const tipsHpPath = useSelector(state => state.routes).routes.tipsHome;
    const questionsHpPath = useSelector(state => state.routes).routes.questionsHome;
    const { desktop } = useSelector(state => state.breakpoint);
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-communityHp'));
    }, [dispatch]);

    //Requete pour récup les meta data
    const [dataRedux, setDataRedux] = useState();
    const [dataFetch, isLoaded] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        include: 'content_config_form_files',
        fields: '&fields[node--special]=metatag,title,field_special_configformref,content_config_form_files',
        method: 'get'
    });

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);
            setDataRedux(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    const getVisuals = (array, uuidDesktop, uuidMobile) => {
        let imgUrl
        array.forEach((obj) => {
            if (desktop) {
                if (Object.values(obj).indexOf(uuidDesktop) > -1) {
                    imgUrl = obj.uri.url
                    return false
                }
            } else {
                if (Object.values(obj).indexOf(uuidMobile) > -1) {
                    imgUrl = obj.uri.url
                    return false
                }
            }
        })
        return imgUrl
    }

    const renderPage = () => {
        const { metatag, contentConfigFormFiles } = dataRedux;
        const { hero, questionsAnswers, experiences } = dataRedux.fieldSpecialConfigformref.data;

        //-- Tracking
        let pageviewEvent = {
            category: 'Communauté'
        };
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <VisuelLarge
                    urlVisuelStatic={baseURL + getVisuals(contentConfigFormFiles, hero.images.imageDesktop.uuid, hero.images.imageMobile.uuid)}
                />
                <div className="container">
                    <div className="t-communityHp_wrapper">
                        {
                            hero &&
                            <CommunityBlockRoundedTitle
                                title={hero.text ? hero.text : "Bienvenue dans la communauté d'entraide de la belle adresse"}
                            />
                        }
                        <ul className="t-communityHp-cardsList">
                            {
                                questionsAnswers &&
                                <li className="t-communityHp-cardsList-item">
                                    <CommunityHpCard
                                        title={questionsAnswers.title ? questionsAnswers.title : "Questions & réponses"}
                                        text={questionsAnswers.text ? questionsAnswers.text : "Besoin d'un conseil ? Posez vos questions aux membres de la communauté ou répondez-leur ici."}
                                        imgSrc={baseURL + getVisuals(contentConfigFormFiles, questionsAnswers.images.imageDesktop.uuid, questionsAnswers.images.imageMobile.uuid)}
                                        link={questionsHpPath}
                                    />
                                </li>
                            }
                            {
                                experiences &&
                                <li className="t-communityHp-cardsList-item">
                                    <CommunityHpCard
                                        title={experiences.title ? experiences.title : "Partagez vos expériences"}
                                        text={experiences.text ? experiences.text : "Pour partager et consulter toutes les astuces du quotidien avec la communauté c'est par ici !"}
                                        imgSrc={baseURL + getVisuals(contentConfigFormFiles, experiences.images.imageDesktop.uuid, experiences.images.imageMobile.uuid)}
                                        link={tipsHpPath}
                                    />
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }
    return (isLoaded && dataRedux && dataRedux.fieldSpecialConfigformref.data) ? renderPage() : <Loader />
};

export default TemplateCommunityHP;