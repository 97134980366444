import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import normalize from "json-api-normalizer";
import build from "redux-object";
import { useHistory } from "react-router-dom";

// Hooks
import {updateGlobalStorePage, updateModalsLoginType, updateModalsOpen} from '../Store/action';
import useApi from "../Hooks/useApiGlobal";
import useTracking from "../Hooks/useTracking";

// Components
import {Loader} from "../Components/loader";
import Metatags from "../Layout/metas-tags/meta-tags";
import Step0 from "../Components/cartStep0";
import Chatbot from "../Components/chatbot";
import {randomString} from "../Utils/md5-hash";


const TemplateCartWebcoupon = ({_uid}) => {
    let history = useHistory();
    let dispatch = useDispatch();

    // User
    let {uuid} = useSelector(state => state.user);

    // Tracking
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line


    const [beginCheckout, setBeginCheckout] = useState(false);
    const beginCheckoutTracking = (refresh) => {
        setTrackingEvent({
            event: 'begin_checkout',
            args: {
                membership_level: uuid ? 'member' : 'no-member',
                currency: 'EUR',
                ecommerce: {
                    currency: 'EUR',
                    items: itemsInitialTracking
                }
            },
            refresh: refresh
        });
    };

    const modalsOpen = useSelector(state => state.modals).openModal;//modal
    useEffect(() => {
        if (uuid) {
            setDataPageParams({
                name: 'api/node/special',
                _uid: _uid,
                fields: '&fields[node--special]=metatag',
                method: 'get'
            });
            setAllProductsParams({
                name: 'api/lba-economy/selection',
                method: 'get'
            });
        } else {
            dispatch(updateModalsOpen({ 'login': true }));
        }
    }, [uuid]); // eslint-disable-line

    // Page's classname
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-cart-cashback t-cart-webcoupon'));
    }, [dispatch]);

    // Get page's infos
    const [dataPageParams, setDataPageParams] = useState({});
    const [dataPage, setDataPage] = useState();

    const [dataFetch, isLoaded] = useApi(dataPageParams);

    useEffect(() => {
        if (isLoaded && dataFetch) {
            const normalizeJson = normalize(dataFetch.data);//Normalisation du json
            setDataPage(build(normalizeJson, 'nodeSpecial', _uid));
            //checkoutProgressTracking();
        }
    }, [isLoaded, dataFetch]); // eslint-disable-line

    /*   useEffect(() => {
           if (dataPage) {
               console.log('dataPage', dataPage);
           }
       }, [dataPage]);*/


    // Get cart from store
    let {webcoupon, webcouponError} = useSelector(state => state.cart);
    const [products, setProducts] = useState([]);
    const [errorGetCartProducts, setErrorGetCartProducts] = useState(null);
    useEffect(() => {
        // if (webcoupon && webcoupon.length > 0) {
        if (webcoupon) {
            setProducts(webcoupon);
        }
        if (webcouponError) {
            setErrorGetCartProducts(webcouponError);
        }

        setAllProductsParams({
            name: 'api/lba-economy/selection?' + randomString(6),
            method: 'get'
        });
    }, [webcoupon, webcouponError]);

    // Amount of offers
    const [offersAmount, setOffersAmount] = useState(0);
    const [itemsInitialTracking, setItemsInitialTracking] = useState([]);
    useEffect(() => {
        //console.log(products);
        let discountAmountList = [];
        if (products && products.length > 0) {
            products.map(product => {
                let discount = product.discount;
                discountAmountList.push(parseFloat(discount.toString().replace(',', '.')));
                return null;
            });
            // Sum of discountAmountList
            setOffersAmount(discountAmountList.reduce(function (acc, val) {
                return acc + val;
            }, 0));

            let items = [];
            products.map((item, key) => {
                items.push({
                    quantity: 1,
                    price: item.price || item.discount,
                    item_variant: 'coupon',
                    item_list_name: undefined,
                    item_list_id: undefined,
                    item_id: item.ean,
                    item_category3: item.brandname,
                    item_category2: item.shelfname,
                    item_category: item.universename,
                    index: key,
                    currency: 'EUR'
                });
                return null;
            });
            setBeginCheckout(false);
            setItemsInitialTracking(items);
        }
    }, [products]);


    useEffect(() => {
        if (itemsInitialTracking && itemsInitialTracking.length > 0 && !beginCheckout) {
            setBeginCheckout(true);
            beginCheckoutTracking(randomString(6));
        }
    }, [itemsInitialTracking]); // eslint-disable-line


    // Related Products
    const [relatedProducts, setRelatedProducts] = useState([]);
    // Related Products - Get all products
    const [allProductsParams, setAllProductsParams] = useState({});
    const [allProducts, isLoadedAllProducts] = useApi(allProductsParams);

    useEffect(() => {
        if (isLoadedAllProducts === true) {
            if (allProducts && allProducts.data && allProducts.data.length > 0) {
                // console.log('allProducts', allProducts.data);
                const array = [];
                allProducts.data.map((product) => {
                    if (product.panier === false) {
                        array.push(product);
                    }
                    return null;
                });
                // Pick random elements
                let shuffled = array.sort(function () {
                    return .5 - Math.random()
                });
                let selected = shuffled.slice(0, 4);
                // setRelatedProducts(...relatedProducts, selected);
                setRelatedProducts(selected);
            }
        }
    }, [isLoadedAllProducts, allProducts]); // eslint-disable-line

    /*  useEffect(() => {
          console.log('relatedProducts', relatedProducts);
      }, [relatedProducts]);*/

    const renderPage = () => {
        const {metatag} = dataPage;

        let pageviewEvent = {
            category: 'Economies',
            category2: 'Imprimer mes bons',
            category3: 'Panier',
        };

        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent}/>}
                <Step0 products={products}
                       offersAmount={offersAmount} error={errorGetCartProducts}
                       relatedProducts={relatedProducts} type="print"/>
                <Chatbot/>
            </>
        )
    };

    return dataPage ? renderPage() : <Loader/>;
};


export default TemplateCartWebcoupon;