import React, { useEffect, useRef, useState } from "react";

export const Tableau = (text) => {
    const renderMarkup = (markup) => {
        return { __html: markup };
    };

    const [isSelected, setSelected] = useState(false);
    const [isSelectedBarre, setSelectedBarre] = useState(false);
    const [initialX, setInitialX] = useState(0);
    const [initialScroll, setInitialScroll] = useState(0);
    const [initialXBarre, setInitialXBarre] = useState(0);
    const [initialScrollBarre, setInitialScrollBarre] = useState(0);
    const refTable = useRef(null);
    const refScrollMobile = useRef(null);
    const refScrollDesktop = useRef(null);

    useEffect(() => {
        const setRule = () => {
            let ratio = Math.round(100 * refTable.current.clientWidth / refTable.current.scrollWidth);
            refScrollMobile.current && (refScrollMobile.current.querySelector(".c-table_scroll_regle").style.width = Math.round(ratio) + "%");
            refScrollDesktop.current && (refScrollDesktop.current.querySelector(".c-table_scroll_regle").style.width = Math.round(ratio) + "%");
        }

        window.addEventListener("resize", setRule);
        setRule();

        return () => {
            window.removeEventListener("resize", setRule);
        }

    }, []);

    const getX = (e) => {
        if (typeof (e) === "undefined")
            return 0;
        else if (e.clientX)
            return e.clientX;
        else if (e.touches[0]?.clientX)
            return e.touches[0].clientX;
        else
            return 0;
    }

    const changeSelected = (e, value) => {
        setSelected(value);
        setInitialX(getX(e));
        if (refTable.current) {
            setInitialScroll(refTable.current.scrollLeft);
        }
    }

    const move = e => {
        if (isSelected && refTable.current) {
            refTable.current.scrollLeft = initialScroll + initialX - getX(e);

            let scrollLeft = refTable.current.scrollLeft;
            let percent = scrollLeft / (refTable.current.scrollWidth);
            percent = percent > 1 ? 100 : 100 * percent;
            refScrollMobile.current && (refScrollMobile.current.querySelector(".c-table_scroll_regle").style.left = Math.round(percent) + "%");
            refScrollDesktop.current && (refScrollDesktop.current.querySelector(".c-table_scroll_regle").style.left = Math.round(percent) + "%");
        }
    }

    const wheel = e => {
        let delta = e.deltaX;

        if(refTable.current && delta !== 0){
            refTable.current.scrollLeft += delta;

            let scrollLeft = refTable.current.scrollLeft;
            let percent = scrollLeft / (refTable.current.scrollWidth);
            percent = percent > 1 ? 100 : 100 * percent;
            refScrollMobile.current && (refScrollMobile.current.querySelector(".c-table_scroll_regle").style.left = Math.round(percent) + "%");
            refScrollDesktop.current && (refScrollDesktop.current.querySelector(".c-table_scroll_regle").style.left = Math.round(percent) + "%");
            setInitialScroll(refTable.current.scrollLeft);
        }
    }

    const changeSelectedBarre = (e, value) => {
        setSelectedBarre(value);
        setInitialXBarre(getX(e));
        if (refScrollMobile.current && refScrollMobile.current.clientWidth > 0) {
            let currentLeft = refScrollMobile.current.querySelector(".c-table_scroll_regle").style.left;
            currentLeft = isNaN(parseFloat(currentLeft)) ? 0 : parseFloat(currentLeft);
            setInitialScrollBarre(currentLeft);
        }
        else if (refScrollDesktop.current && refScrollDesktop.current.clientWidth > 0) {
            let currentLeft = refScrollDesktop.current.querySelector(".c-table_scroll_regle").style.left;
            currentLeft = isNaN(parseFloat(currentLeft)) ? 0 : parseFloat(currentLeft);
            setInitialScrollBarre(currentLeft);
        }
    }

    const moveCursor = e => {
        if (isSelectedBarre && refScrollMobile.current && refScrollDesktop.current) {

            let parentMax = Math.max(refScrollMobile.current.clientWidth, refScrollDesktop.current.clientWidth);
            let ruleMax = Math.max(refScrollMobile.current.querySelector(".c-table_scroll_regle").clientWidth, refScrollDesktop.current.querySelector(".c-table_scroll_regle").clientWidth)
            let percentMax = 100 * ((parentMax - ruleMax) / parentMax)
            let nextLeft = (initialScrollBarre + 0.25 * (getX(e) - initialXBarre));
            nextLeft = nextLeft < 0 ? 0 : nextLeft > percentMax ? percentMax : nextLeft;


            refScrollMobile.current.querySelector(".c-table_scroll_regle").style.left = nextLeft + "%";
            refScrollDesktop.current.querySelector(".c-table_scroll_regle").style.left = nextLeft + "%";

            refTable.current.scrollLeft = nextLeft * refTable.current.scrollWidth * 0.01;
        }
    }

    const wheelBarre = e => {
        let delta = 0.1 * e.deltaX;

        if(refScrollMobile.current && refScrollDesktop.current && delta !== 0){

            let parentMax = Math.max(refScrollMobile.current.clientWidth, refScrollDesktop.current.clientWidth);
            let ruleMax = Math.max(refScrollMobile.current.querySelector(".c-table_scroll_regle").clientWidth, refScrollDesktop.current.querySelector(".c-table_scroll_regle").clientWidth)
            let percentMax = 100 * ((parentMax - ruleMax) / parentMax)
            let nextLeft = (initialScrollBarre + delta);
            nextLeft = nextLeft < 0 ? 0 : nextLeft > percentMax ? percentMax : nextLeft;

            refScrollMobile.current.querySelector(".c-table_scroll_regle").style.left = nextLeft + "%";
            refScrollDesktop.current.querySelector(".c-table_scroll_regle").style.left = nextLeft + "%";

            refTable.current.scrollLeft = nextLeft * refTable.current.scrollWidth * 0.01;
            setInitialScrollBarre(nextLeft);
        }
    }

    return (
        <>
            <div className="c-table_content">
                <div
                    className="c-table_scroll c-table_scroll--mobile"
                    ref={refScrollMobile}
                    onMouseMove={moveCursor}
                    onMouseDown={e => changeSelectedBarre(e, true)}
                    onMouseUp={e => changeSelectedBarre(e, false)}
                    onMouseLeave={e => changeSelectedBarre(e, false)}
                    onTouchMove={moveCursor}
                    onTouchStart={e => changeSelectedBarre(e, true)}
                    onTouchEnd={e => changeSelectedBarre(e, false)}
                    onWheel={e => wheelBarre(e)}
                >
                    <div className="c-table_scroll_regle"></div>
                </div>
                <div className="c-table_bottom"></div>
                <div
                    className="c-table"
                    ref={refTable}
                    onMouseMove={move}
                    onMouseDown={e => changeSelected(e, true)}
                    onMouseUp={e => changeSelected(e, false)}
                    onMouseLeave={e => changeSelected(e, false)}
                    onTouchMove={move}
                    onTouchStart={e => changeSelected(e, true)}
                    onTouchEnd={e => changeSelected(e, false)}
                    onWheel={e => wheel(e)}
                >
                    <div dangerouslySetInnerHTML={renderMarkup(text.processed)}></div>
                </div>
                <div className="c-table_bottom"></div>
                <div
                    className="c-table_scroll c-table_scroll--desktop"
                    ref={refScrollDesktop}
                    onMouseMove={moveCursor}
                    onMouseDown={e => changeSelectedBarre(e, true)}
                    onMouseUp={e => changeSelectedBarre(e, false)}
                    onMouseLeave={e => changeSelectedBarre(e, false)}
                    onTouchMove={moveCursor}
                    onTouchStart={e => changeSelectedBarre(e, true)}
                    onTouchEnd={e => changeSelectedBarre(e, false)}
                    onWheel={e => wheelBarre(e)}
                >
                    <div className="c-table_scroll_regle"></div>
                </div>
            </div>
        </>
    )
};

export default Tableau;