import {saveState, loadState} from '../../Utils/loadState';
import {
    USER_UUID,
    USER_IUPID,
    USER_PSEUDO,
    USER_DATEINSCRIPTION,
    USER_FAVORITES
} from "../action";

let initialState = {
    uuid: loadState('uuid') || null,
    iupid: loadState('iupid') || null,
    pseudo: loadState('pseudo') || null,
    dateInscription: loadState('dateInscription') || null,
    favorites: null
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_UUID:
            saveState('uuid', action.value);
            return {...state, uuid: action.value};
        case USER_IUPID:
            saveState('iupid', action.value);
            return {...state, iupid: action.value};
        case USER_PSEUDO:
            saveState('pseudo', action.value);
            return {...state, pseudo: action.value};
        case USER_DATEINSCRIPTION:
            saveState('dateInscription', action.value);
            return {...state, dateInscription: action.value};
        case USER_FAVORITES:
            return {...state, favorites: action.value};
        default:
            return state;
    }
}
