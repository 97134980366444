import React from "react";

// Components
import Article from "./article";

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, EffectFade, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';


SwiperCore.use([Navigation, EffectFade, Autoplay]);


const CarouselArticles = ({carousel, desktop, carouselName}) => {
    return (
        <>
            {carousel.length > 0 &&
            <div
                className={`c-carouselArticles ${carouselName ? carouselName : ''} ${(desktop && carousel.length < 5) ? 'no-carousel' : ''}`}>
                <div className={`swiper-button-prev ${(!desktop || (desktop && carousel.length < 5)) ? 'swiper-button-disabled' : ''}`}></div>
                <div className={`swiper-button-next ${(!desktop || (desktop && carousel.length < 5)) ? 'swiper-button-disabled' : ''}`}></div>
                <Swiper className="c-carouselArticles_items"
                        slidesPerView="auto"
                        autoHeight={false}
                        allowTouchMove={true}
                        watchSlidesVisibility={true}
                        observer={true}
                        spaceBetween={desktop ? 16 : 8}
                        // onObserverUpdate={e => {
                        //     console.log(e)
                        //     e.updateSlides();
                        // }}
                    // loop={((desktop && carousel.length > 4) || (!desktop && carousel.length > 2))}
                        loop={false}
                        navigation={{
                            nextEl: `.${carouselName} .swiper-button-next`,
                            prevEl: `.${carouselName} .swiper-button-prev`
                        }}
                        breakpoints={{
                            // when window width is >= 1024px
                            1024: {
                                //allowTouchMove: false,
                            },
                        }}
                >

                    {
                        carousel.map((item, key) => {
                            return (
                                <SwiperSlide key={key} className="c-carouselArticles_item">
                                    <Article {...item}/>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            }
        </>
    )
};

export default CarouselArticles;