import React from "react";
import { Link } from "react-router-dom";

/**
 * If no children, 
 * title will be displayed in dangerouslySetInnerHTML 
 * to decode special character.
 */
const CLink = ({
  children,
  className,
  url,
  title,
  target,
  external = false,
  handleClick,
}) => {
  const isLocalLink = (url) => {
    let link = document.createElement("a");
    link.href = url;
    return link.host === document.location.host;
  };

  return (
    <>
      {isLocalLink(url) && !external ? (
        children ? (
          <Link
            to={url}
            title={title}
            target={target}
            className={className}
            onClick={handleClick}
          >
            {children}
          </Link>
        ) : (
          <Link
            to={url}
            title={title}
            target={target}
            className={className}
            onClick={handleClick}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )
      ) : children ? (
        <a
          href={url}
          title={title}
          target={target}
          className={className}
          onClick={handleClick}
        >
          {children}
        </a>
      ) : (
        <a
          href={url}
          title={title}
          target={target}
          className={className}
          onClick={handleClick}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
    </>
  );
};

export default CLink;
