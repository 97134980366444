import React, { useEffect, useState } from "react";
import useApi from '../Hooks/useApiGlobal';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
// Composants
import { Loader } from "../Components/loader";
import { GoBack } from "../Components/goBack";
import CommunityNotifications from "../Components/communityNotifications";
import Metatags from '../Layout/metas-tags/meta-tags';

const TemplateAccountCommunity = ({ _uid }) => {
    // GET ID PROFIL
    let { uuid } = useSelector(state => state.user);
    const baseURL = process.env.REACT_APP_APP;
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-accountCommunity'));
    }, []); // eslint-disable-line

    const [dataMetaTag, setDataMetaTag] = useState();
    //Requete pour récup les infos de la page spéciale
    const [dataFetchMetaTags, isLoadedMetaTags] = useApi({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get',
        needLogin: true
    });

    useEffect(() => {
        if (isLoadedMetaTags && dataFetchMetaTags) {
            const normalizeJson = normalize(dataFetchMetaTags.data);//Normalisation du json
            setDataMetaTag(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [isLoadedMetaTags, dataFetchMetaTags]); // eslint-disable-line

    // GET ALL PROFILE STATS
    const [dataRedux, setDataRedux] = useState();
    const [dataFetchInfos, isLoadedInfos] = useApi({
        name: "api/lba-community/stats",
        needLogin: true,
        method: "get",
    });
    useEffect(() => {
        if (isLoadedInfos && dataFetchInfos) {
            if (dataFetchInfos.status === 200) {
                setDataRedux(dataFetchInfos.data);
            }
        }
    }, [isLoadedInfos, dataFetchInfos]); // eslint-disable-line

    // GET PROFILE PICTURE AND NAME
    const [profilePictureDataRedux, setProfilePictureDataRedux] = useState();
    let profilePictureDataInclude = [
        'user_picture',
    ];
    const [dataFetchProfilePicture, isLoadedProfilePicture] = useApi({
        name: `api/users/${uuid}`,
        include: profilePictureDataInclude.join(','),
        fields: `&fields[users]=user_picture,display_name`,
        method: 'get'
    });
    useEffect(() => {
        if (dataFetchProfilePicture && isLoadedProfilePicture && uuid) {
            let normalizeJson = normalize(dataFetchProfilePicture.data);
            setProfilePictureDataRedux(build(normalizeJson, 'users'));
        }
    }, [dataFetchProfilePicture, isLoadedProfilePicture]);// eslint-disable-line

    const renderPage = () => {
        const { metatag } = dataMetaTag;
        // Page info for tracking page_view event
        let pageviewEvent = {
            category: 'Mon compte',
            category2: 'Communauté',
            category3: 'Mes contributions',
        };
        return (
            <>
                {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} />}
                <GoBack />
                <div className="container t-accountCommunity_wrapper">
                    <h1 className="title-2">Mes contributions</h1>
                    {
                        dataRedux &&
                        <div className="t-accountCommunity_content">
                            {
                                profilePictureDataRedux &&
                                <div className="t-accountCommunity_profil">
                                    {
                                        profilePictureDataRedux[0].userPicture === undefined || profilePictureDataRedux[0].userPicture === null ?
                                            <div style={{ backgroundImage: `url('${require("../assets/img/default-profil.png")}')` }}></div> :
                                            <div style={{ backgroundImage: `url('${baseURL + profilePictureDataRedux[0].userPicture.uri.url}')` }}></div>
                                    }
                                    <p>{profilePictureDataRedux[0].displayName}</p>
                                </div>
                            }
                            <div className="t-accountCommunity_infos">
                                <div className="t-accountCommunity_qr t-accountCommunity_elt">
                                    <p><strong>{dataRedux.questions_count}</strong> {dataRedux.questions_count <= 1 ? "Question" : "Questions"}</p>
                                    <p><strong>{dataRedux.answers_count}</strong> {dataRedux.answers_count <= 1 ? "Réponse" : "Réponses"}</p>
                                </div>
                                <div className="t-accountCommunity_tips t-accountCommunity_elt">
                                    <p><strong>{dataRedux.tips_count}</strong> {dataRedux.tips_count <= 1 ? "Astuce" : "Astuces"}</p>
                                </div>
                                <div className="t-accountCommunity_comments t-accountCommunity_elt">
                                    <p><strong>{dataRedux.comments_count}</strong> {dataRedux.comments_count <= 1 ? "Commentaire" : "Commentaires"}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <CommunityNotifications />
            </>
        )
    }
    return (isLoadedInfos && dataRedux && dataMetaTag && isLoadedMetaTags && isLoadedProfilePicture) ? renderPage() : <Loader />
};

export default TemplateAccountCommunity;