import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import normalize from 'json-api-normalizer';
import build from 'redux-object';
import { useHistory } from "react-router-dom";

//Hooks
import {updateGlobalStorePage, updateModalsOpen} from '../Store/action';
import { useApiGlobal } from "../Hooks/useApiGlobal/index";
// import { useLocalStorage } from '../Hooks';
import {formatNumber, formatNumberBis} from '../Utils/transformText';


// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import {GoBack} from "../Components/goBack";
import {Loader} from "../Components/loader";
import Chatbot from "../Components/chatbot";

const pageviewEvent = {
    category: 'Mon compte',
    category2: 'Mes économies',
    category3: ''
};



function formatDateTable(d) {
    let date = new Date(d);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    return d = dd + '/' + mm + '/' + yyyy
}

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

let oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);

const startDate = formatDate(oneYearFromNow);
const d = new Date();
let endDate = d.setDate(d.getDate() + 1);
endDate = new Date(endDate).toISOString().slice(0, 10);

// const endDate = new Date().toISOString().slice(0, 10);

const Economies = ({ renderDate, renderStatus, dataPage }) => {

    const { mobile } = useSelector((state) => state.breakpoint);
    const { data: webcouponSavings, status: couponsStatus } = useApiGlobal({
        name: 'api/lba-economy/savings/' + startDate + '/' + endDate,
        method: 'get'
    });
    const { data: cashbackSavingsRequest, status: cashBackStatus } = useApiGlobal({
        name: 'api/lba-cashback/savings/' + startDate + '/' + endDate,
        method: 'get',
        needLogin : true
    });
    
    // console.log('hello', enDate2Tomorrow)

    return (
        <>
            {dataPage && dataPage.metatag && <Metatags listMeta={dataPage.metatag} pageviewEvent={pageviewEvent}/>}
            <>
                {couponsStatus === 'LOADING' || cashBackStatus === 'LOADING'
                    ? <Loader />
                    : <div className="t-accountEconomy_wrapper">
                        <div className="container">
                        <h1>Mes économies</h1>
                            <div className="t-accountEconomy_content-wrapper">
                                <div className="t-accountEconomy_content">
                                    {webcouponSavings && typeof webcouponSavings.data.mesEconomies === 'number' &&
                                    <div className="t-accountEconomy_content-inner">
                                        <p>
                                            <strong>{formatNumber(webcouponSavings.data.mesEconomies)} {webcouponSavings.data.mesEconomies > 1 ? 'euros' : 'euro'}</strong>
                                        </p>
                                        <p>de réductions imprimées*</p>
                                    </div>
                                    }
                                    {cashbackSavingsRequest && typeof cashbackSavingsRequest.data.mesEconomies === 'number' &&
                                    <div className="t-accountEconomy_content-inner">
                                        <p>
                                            <strong>{formatNumber(cashbackSavingsRequest.data.mesEconomies)} {cashbackSavingsRequest.data.mesEconomies > 1 ? 'euros' : 'euro'}</strong> 
                                        </p>
                                        <p>{cashbackSavingsRequest.data.mesEconomies > 1 ? 'remboursés' : 'remboursé'}*</p>
                                    </div>
                                }
                            </div>
                            <p className="t-accountEconomy_content-mention">*Total de mes economies​ des 12 derniers mois</p>
                        </div>

                        {cashbackSavingsRequest &&
                        <>
                        {
                            cashbackSavingsRequest.data.mesRemboursements.length > 0 &&
                            <div className="t-accountEconomy_table-wrapper">
                                <h2 className="t-accountEconomy_table-maintitle">Mes remboursements</h2>
                                {mobile ?
                                    cashbackSavingsRequest && cashbackSavingsRequest.data.mesRemboursements.reverse().map(({submitteddate, acceptdate, rejectdate, description, discount, submissionref, status}, key) => {
                                        return (
                                            <div className="t-accountEconomy_tableContent">
                                                <table className="t-accountEconomy_table" key={key}>
                                                    <tr>
                                                        <th>N° de participation</th>
                                                        <td>{submissionref ? submissionref : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Date</th>
                                                        <td>{renderDate(submitteddate, acceptdate, rejectdate)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Réduction</th>
                                                        <td>{formatNumber(discount)}€</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Statut</th>
                                                        <td className={`status ${status}--`}>{renderStatus(status)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        );
                                    })
                                :
                                    <div className="t-accountEconomy_overflow">
                                        <table className="t-accountEconomy_table">
                                            <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th className="text-center">Réduction</th>
                                                <th className="text-center">N° de participation</th>
                                                <th>Statut</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {cashbackSavingsRequest && cashbackSavingsRequest.data.mesRemboursements.reverse().map(({submitteddate, acceptdate, rejectdate, description, discount, submissionref, status}, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{renderDate(submitteddate, acceptdate, rejectdate)}</td>
                                                        <td className="text-center">{formatNumberBis(formatNumber(discount) + "€")}</td>
                                                        <td className="text-center">{submissionref ? submissionref : ""}</td>
                                                        <td className={`status ${status}--`}>{renderStatus(status)}</td>
                                                    </tr>
                                                );
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        }
                        </>
                        }
                    </div>
                </div>
                }
            </>
        </>
    )
}

const TemplateAccountEconomy = ({ _uid }) => {

    let dispatch = useDispatch();
    const { uuid } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(updateGlobalStorePage('t-accountEconomy'));
    }, [dispatch]); // isLoaded
    
    useEffect(() => {
        if(!uuid) {
            dispatch(updateModalsOpen({ 'login': true }));
        }
    }, [uuid])

    // Meta - Get page info
    const [dataPage, setDataPage] = useState();
    //Requete pour récupérer les infos de la page spéciale
    const { data: dataFetchPage, status } = useApiGlobal({
        name: 'api/node/special',
        _uid: _uid,
        fields: '&fields[node--special]=metatag',
        method: 'get'
    });

    useEffect(() => {
        if (status === 'SUCCESS' && dataFetchPage) {
            const normalizeJson = normalize(dataFetchPage.data);//Normalisation du json
            setDataPage(build(normalizeJson, 'nodeSpecial', _uid));
        }
    }, [dataFetchPage, status]); // eslint-disable-line

    const renderDate = (submit, accept, reject) => {
        // if (accept || reject) {
        //     if (accept) {
        //         return formatDateTable(accept);
        //     }
        //     if (reject) {
        //         return formatDateTable(reject);
        //     }
        // } else {
        //     return formatDateTable(submit) || '';
        // }
        return formatDateTable(submit) || '';
    };

    const renderStatus = (status) => {
        switch (status) {
            case "submitted":
                return "En attente";
            case "accepted":
                return "Accepté";
            case "paid":
                return "Paiement OK";
            case "rejected":
                return "Non conforme";
            case "refused":
                return "Non conforme";
            default:
                return null;
        }
    };

    return (
        <>
            {status === 'LOADING'
                ? (
                    <>
                        <Loader/>
                    </>
                ) 
                : (
                <>
                    <GoBack/>
                    <>
                        {uuid && (
                            <Economies
                                renderStatus={renderStatus}
                                renderDate={renderDate}
                                dataPage={dataPage}
                            />
                        )}
                    </>
                    <Chatbot/>
                </>
                )
            }
        </>
    )
};

export default TemplateAccountEconomy;